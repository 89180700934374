import {
  Grid,
  Box,
  Tab,
  Tabs,
  CircularProgress,
  Typography,
} from "@mui/material";
import React, {
  FC,
  useState,
  SyntheticEvent,
  useEffect,
  useCallback,
} from "react";
import PrimaryButton from "../../../../../../../../components/Button/PrimaryButton";
import TabPanel from "../../../../../../../../components/TabPanel";
import FromDevice from "./FromDevice";
import FromRepository from "./FromRepository";
import "./UploadFiles.scss";
import {
  setValue,
  setName,
  setReload,
  setRequestData,
  setNotificationMessage,
} from "../../../../../../../../utils/redux";
import { useDispatch, useSelector } from "react-redux";
import mime from "mime";
import axiosInstance from "../../../../../../../../utils/axios";
import { URLS } from "../../../../../../../../utils/constants/urls";
import axios from "axios";
import {
  DocumentName,
  Reload,
  RequestData,
} from "../../../../../../../../utils/redux/reducer/candidate-vetting-slice";
import LinearProgress, {
  LinearProgressProps,
} from "@mui/material/LinearProgress";
import { identifiers } from "../../../../../../../../utils/constants/identifiers";
import { useTour } from "@reactour/tour";
import { tourStep } from "../../../../../../../../utils/constants/coachMarks";
import {
  User,
  setUserData,
} from "../../../../../../../../utils/redux/reducer/authentication-slice";
import { CognitoUser } from "amazon-cognito-identity-js";
import * as Amplify from "../../../../../../../../utils/services/amplify";

const LinearProgressWithLabel: FC<
  { value: number; reqNumber: number } & LinearProgressProps
> = ({ value, reqNumber, ...props }) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress
          color="success"
          variant="determinate"
          {...props}
          value={(value / reqNumber) * 100}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          value
        )}%`}</Typography>
      </Box>
    </Box>
  );
};
const UploadFiles: FC<{ showBack?: boolean }> = ({ showBack = true }) => {
  const dispatch = useDispatch();
  const { setIsOpen, currentStep, setCurrentStep } = useTour();

  const user: { [key: string]: string } = useSelector(User) as {
    [key: string]: string;
  };
  const name = useSelector(DocumentName);
  const reload = useSelector(Reload);
  const request = useSelector(RequestData);

  const [values, setValues] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [files, setFiles] = useState<File[]>();
  const [isUploading, setIsUploading] = useState(false);
  const [percentageProcess, setPercentageProcess] = useState(0);
  const [selectedFile, setSelectedFile] = useState<string>("");
  const [fileName, setFileName] = useState<string>("");
  const [isBtnActive, setIsBtnActive] = useState(false);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValues(newValue);
  };

  const setCoachMarksValue = useCallback(async () => {
    //if (!!user && user["custom:reference_tutorial"] !== "true") {
    const currentUser: CognitoUser = await Amplify.UserDetail();
    const body = {
      "custom:upload_tutorial": "true",
    };
    await Amplify.UpdateUserDetails(body, currentUser);
    await Amplify.RefreshSession();
    dispatch(setUserData({ ...user, ...body }));
    //}
  }, [user, dispatch]);

  useEffect(() => {
    if (!!user && user["custom:upload_tutorial"] !== "true") {
      setCurrentStep(tourStep.candidate_upload_files_page.index);
    }
  }, [setCurrentStep, user]);

  useEffect(() => {
    if (tourStep.candidate_upload_files_page.index === currentStep) {
      setIsOpen(true);
    } else if (
      currentStep ===
      tourStep.candidate_upload_files_page.index +
        tourStep.candidate_upload_files_page.steps
    ) {
      setIsOpen(false);
      if (!!user && user["custom:upload_tutorial"] !== "true") {
        (async () => {
          await setCoachMarksValue();
        })();
      }
    }
  }, [currentStep, setCoachMarksValue, setIsOpen, user]);

  const handleFileUpload = async () => {
    if (files && files.length !== 0) {
      setLoading(true);
      setIsUploading(true);
      const file: File = files[0];
      const formData: {
        name: string;
        type: string;
        directory: string;
        file_name: string;
      } = {
        type: mime.getType(file.name)
          ? (mime.getType(file.name) as string)
          : file.type,
        name: `${new Date().getTime()}_${file.name}`,
        file_name: name?.replace(" / ", " or ").replace(" : ", "") as string,
        directory: name === "CV" ? "cv" : "documents",
      };
      if (formData.directory === "documents") {
        setFileName("Document");
      } else {
        setFileName(formData.directory);
      }
      try {
        const { data } = await axiosInstance.post(
          URLS.candidate_file_upload,
          formData
        );
        await uploadFile(
          file,
          data.url,
          mime.getType(file.name)
            ? (mime.getType(file.name) as string)
            : file.type,
          data.id
        );
      } catch (e: any) {
        setIsUploading(false);
        setLoading(false);
        dispatch(
          setNotificationMessage({
            display: true,
            severity: "error",
            message: `Unable to Upload File`,
          })
        );
      }
    } else {
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "error",
          message: `Please choose a file to upload`,
        })
      );
    }
  };

  const uploadFile = async (
    file: File,
    url: string,
    mime: string,
    file_id: string
  ) => {
    try {
      const { status } = await axios.put(url, file, {
        headers: { "Content-Type": mime },
        onUploadProgress: function (progressEvent) {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / (progressEvent.total || 100)
          );
          setPercentageProcess(percentCompleted);
        },
      });
      if (status === 200) {
        setTimeout(async () => await linkDocument(file_id), 4000);
        // await linkDocument(file_id);
      }
    } catch (e) {
      setLoading(false);
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "error",
          message: `Unable to Upload File`,
        })
      );
    }
  };

  const linkDocument = async (file_id?: string) => {
    try {
      setLoading(true);
      const file_name: string = name
        ?.replace(" / ", " or ")
        .replace(" : ", "") as string;
      await axiosInstance.post(
        `${URLS.link_document}?stage=${process.env.REACT_APP_YOTI_ENV}`,
        {
          ...request,
          document_repo_id: file_id || selectedFile,
        }
      );
      setIsUploading(false);
      dispatch(setReload(!reload));
      setTimeout(() => {
        dispatch(setRequestData({ ...request, document_repo_id: file_id }));
        dispatch(setValue(file_name === identifiers.cv ? 0 : 1));
        dispatch(setName(""));
      }, 500);
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "success",
          message: `File ${file_name} has been uploaded Successfully`,
        })
      );
      setLoading(false);
    } catch (e) {
      setIsUploading(false);
      setLoading(false);
      console.log(e, fileName);
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "error",
          message: `Unable to upload File `,
        })
      );
    }
  };
  useEffect(() => {
    if (values === 0 && !files) {
      setIsBtnActive(true);
    } else if (values === 1 && !selectedFile) {
      setIsBtnActive(true);
    } else {
      setIsBtnActive(false);
    }
  }, [files, selectedFile, values]);

  return (
    <Grid container justifyContent={"center"} lg={12} sx={{ pl: 0 }}>
      <Grid
        item
        xs={12}
        md={12}
        sx={{ px: { xs: 1, md: 5 }, py: { xs: 1, md: 5 } }}
      >
        <Box sx={{ borderBottom: 0 }}>
          <Box sx={{ borderBottom: 1, borderColor: "#fdf8f8" }}>
            <Tabs
              value={values}
              onChange={handleChange}
              sx={{ boxShadow: "none" }}
              TabIndicatorProps={{ sx: { display: "none" } }}
            >
              <Tab
                data-tut="candidate_upload_files_tab_1"
                className={"document-repository-tabs"}
                label="Upload File"
              />
              <Tab
                data-tut="candidate_upload_files_tab_2"
                className={"document-repository-tabs"}
                label="Select from Document Hub"
              />
            </Tabs>
          </Box>
          <TabPanel value={values} index={0}>
            <FromDevice files={files} setFiles={setFiles} />
          </TabPanel>
          <TabPanel value={values} index={1}>
            <FromRepository
              selectedFile={selectedFile}
              setSelectedFile={setSelectedFile}
            />
          </TabPanel>
        </Box>
      </Grid>
      {isUploading && (
        <Grid
          md={12}
          item
          display={"flex"}
          justifyContent={"center"}
          padding={"0% 0% 5% 0%"}
        >
          <Grid className="dotted-card text-start pb-2 pt-2" md={10}>
            <Typography variant="h6" sx={{ mb: 2 }}>
              <span
                style={{
                  textTransform: fileName === "cv" ? "uppercase" : "capitalize",
                }}
              >
                {fileName}{" "}
              </span>{" "}
              is Uploading
            </Typography>
            <LinearProgressWithLabel
              variant="determinate"
              value={percentageProcess}
              reqNumber={100}
            />
          </Grid>
        </Grid>
      )}
      <Grid
        item
        display={"flex"}
        justifyContent="center"
        className="card-footer"
        md={12}
        xs={12}
        sx={{ p: 3 }}
      >
        {showBack && (
          <PrimaryButton
            fullWidth={false}
            sx={{
              width: { xs: "150px", md: "212px" },
              height: "36px",
              background: "#FFFFFF",
              border: " 1px solid #5AB9F9",
              borderRadius: "5px",
              padding: { xs: "0px", md: "4px 44px" },
              color: "#5AB9F9",
              fontSize: "14px",
              margin: { xs: "0px 20px 0px 0px", md: "0px 20px" },
            }}
            onClick={() => {
              dispatch(setValue(1));
              dispatch(setName(""));
            }}
          >
            Go back
          </PrimaryButton>
        )}
        <PrimaryButton
          fullWidth={false}
          sx={{
            width: { xs: "150px", md: "212px" },
            height: "36px",
            background: " #5AB9F9",
            boxShadow: "0px 10px 25px rgba(90, 185, 249, 0.25)",
            borderRadius: "6px",
            color: "#ffffff",
          }}
          disabled={loading || isBtnActive}
          onClick={() => (values === 0 ? handleFileUpload() : linkDocument())}
        >
          {loading && <CircularProgress sx={{ mr: 1 }} size={20} />}
          Continue
        </PrimaryButton>
      </Grid>
    </Grid>
  );
};

export default UploadFiles;
