import React, {
  FC,
  SyntheticEvent,
  useRef,
  useState,
  ChangeEvent,
  useMemo,
  useCallback,
  useEffect,
} from "react";
import {
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Stack,
  CircularProgress,
  Button,
} from "@mui/material";
import dayjs from "dayjs";
import mime from "mime";
import { Delete, Download, Visibility } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import InputField from "../../../../../../../components/InputField";
import PrimaryButton from "../../../../../../../components/Button/PrimaryButton";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import { identifiers } from "../../../../../../../utils/constants/identifiers";
import DateInput from "../../../../../../../components/DateInput/DateInput";
import { URLS } from "../../../../../../../utils/constants/urls";
import axiosInstance from "../../../../../../../utils/axios";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  AcademicQualificationIds,
  setNotificationMessage,
} from "../../../../../../../utils/redux";
import { useParams } from "react-router-dom";
import {
  setReload,
  AcademicQualificationData,
} from "../../../../../../../utils/redux";
import { Reload } from "../../../../../../../utils/redux";
import { AcademicDocument } from "../../../../../../../utils/redux/reducer/candidate-vetting-slice";
import { Document, Page } from "react-pdf";
import Modal from "../../../../../../../components/modal/Modal";
import CheckBox from "../../../../../../../components/CheckBox";
import CancelIcon from "@mui/icons-material/Cancel";
import PageLoader from "../../../../../../../components/PageLoader";
import { useTour } from "@reactour/tour";
import { tourStep } from "../../../../../../../utils/constants/coachMarks";
import { CognitoUser } from "amazon-cognito-identity-js";
import * as Amplify from "../../../../../../../utils/services/amplify";
import {
  User,
  setUserData,
} from "../../../../../../../utils/redux/reducer/authentication-slice";

export interface SelectFilesProps {
  active: number;
}

const AcademicQualification: FC<SelectFilesProps> = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const documents = useSelector(AcademicQualificationData);
  const ids = useSelector(AcademicQualificationIds);
  const reload = useSelector(Reload);
  const fileUploadRef = useRef<any>(null);
  const [instituteName, setInstituteName] = useState<string>("");
  const [subject, setSubject] = useState<string>("");
  const [graduationDate, setGraduationDate] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [documentType, setDocumentType] = useState<"pdf" | "image">();
  const [academicDocuments, setAcademicDocuments] =
    useState<AcademicDocument>();
  const [openModal, setOpenModal] = useState(false);
  const [attachedFile, setAttachedFile] = useState<File>();
  const [documentUrl, setDocumentUrl] = useState<string>();
  const [viewDocument, setViewDocument] = useState(false);
  const [numPages, setNumPages] = useState<number | null>(null);
  const user: { [key: string]: string } = useSelector(User) as {
    [key: string]: string;
  };
  const { setIsOpen, currentStep, setCurrentStep } = useTour();

  const deleteFile = async (document: AcademicDocument) => {
    setAcademicDocuments(document);
    setOpenModal(true);
  };

  const selectDocument = async (document_id: string) => {
    await updateQualification(
      ids && ids.indexOf(document_id) > -1 ? "delete" : "add",
      document_id
    );
  };

  const setCoachMarksValue = useCallback(async () => {
    //if (!!user && user["custom:reference_tutorial"] !== "true") {
    const currentUser: CognitoUser = await Amplify.UserDetail();
    const body = {
      "custom:academic_tutorial": "true",
    };
    await Amplify.UpdateUserDetails(body, currentUser);
    await Amplify.RefreshSession();
    dispatch(setUserData({ ...user, ...body }));
    //}
  }, [user, dispatch]);

  useEffect(() => {
    if (!!user && user["custom:academic_tutorial"] !== "true") {
      setCurrentStep(tourStep.candidate_academic.index);
    }
  }, [setCurrentStep, user]);

  useEffect(() => {
    if (tourStep.candidate_academic.index === currentStep) {
      setIsOpen(true);
    } else if (documents?.length > 0 && ids !== undefined) {
      if (
        currentStep ===
        tourStep.candidate_academic.index + tourStep.candidate_academic.steps
      ) {
        setIsOpen(false);
        if (!!user && user["custom:academic_tutorial"] !== "true") {
          (async () => {
            await setCoachMarksValue();
          })();
        }
      }
    } else {
      if (
        currentStep ===
        tourStep.candidate_academic.index +
          (tourStep.candidate_academic.steps - 1)
      ) {
        setIsOpen(false);
        if (!!user && user["custom:academic_tutorial"] !== "true") {
          (async () => {
            await setCoachMarksValue();
          })();
        }
      }
    }
  }, [
    currentStep,
    documents?.length,
    ids,
    setCoachMarksValue,
    setIsOpen,
    user,
  ]);

  const updateQualification = useCallback(
    async (state: "add" | "delete", document_id?: string) => {
      setLoading(true);
      try {
        let updated_ids: string[] = [];
        switch (state) {
          case "add":
            if (!!document_id) {
              if (ids) {
                updated_ids = [...ids, document_id];
              } else {
                updated_ids = [document_id];
              }
            } else {
              if (ids) {
                updated_ids = [...ids, academicDocuments?.id as string];
              } else {
                updated_ids = [academicDocuments?.id as string];
              }
            }
            break;
          case "delete":
            ids.forEach((doc_id) => {
              if (!!document_id) {
                if (doc_id !== document_id) {
                  updated_ids.push(doc_id);
                }
              } else {
                if (doc_id !== academicDocuments?.id) {
                  updated_ids.push(doc_id);
                }
              }
            });
            break;
        }

        await axiosInstance.patch(
          `${URLS.candidate_request_details}${id}/request-candidate`,
          {
            academic_qualification_ids: updated_ids,
          }
        );
        dispatch(setReload(!reload));
        setLoading(false);
      } catch (e) {
        setLoading(false);
        dispatch(
          setNotificationMessage({
            display: true,
            severity: "error",
            message: `Unable to ${
              state === "add" ? "add" : "remove"
            } Credentials from Request`,
          })
        );
      }
    },
    [id, reload, ids, academicDocuments?.id, dispatch]
  );

  const confirmDelete = useCallback(async () => {
    try {
      setLoading(true);
      await axiosInstance.delete(
        `${URLS.candidate_academic}${academicDocuments?.id}`
      );
      await updateQualification("delete");
      setOpenModal(false);
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "success",
          message: `File ${academicDocuments?.document_name} has been deleted Successfully`,
        })
      );
      dispatch(setReload(!reload));
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setOpenModal(false);
      console.log(e);
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "error",
          message: "There is an error in deleting document",
        })
      );
    }
  }, [
    academicDocuments?.id,
    academicDocuments?.document_name,
    updateQualification,
    dispatch,
    reload,
  ]);

  const modalText = useMemo(() => {
    return (
      <Grid container>
        <Grid item>
          <Typography
            variant="h6"
            sx={{
              marginBottom: "20px",
              fontSize: 18,
              fontWeight: 500,
              color: "#000000",
              textAlign: "center",
              letterSpacing: "0.15px",
            }}
          >
            Delete Document Permanently
          </Typography>
          <Typography
            variant="h6"
            sx={{
              mb: 3,
              fontSize: 16,
              fontWeight: 500,
              color: "#727272",
            }}
          >
            {academicDocuments?.document_name} will be deleted permanently from
            the repository.
          </Typography>
          <Typography
            variant="h6"
            sx={{
              mb: 3,
              fontSize: 16,
              fontWeight: 500,
              color: "#727272",
            }}
          >
            Are you sure?
          </Typography>
        </Grid>
        <Grid container alignItems="right" justifyContent="right">
          <Button
            sx={{
              border: "none",
              color: "#727272",
              fontSize: 16,
              fontWeight: 500,
              mr: 2,
              mt: 1,
              p: "10px",
            }}
            disabled={loading}
            onClick={confirmDelete}
          >
            {loading && <CircularProgress sx={{ mr: 1 }} />}Delete Document
          </Button>
          <PrimaryButton
            disabled={loading}
            fullWidth={false}
            sx={{
              mt: 1,
              background: "#5AB9F9",
              color: "#ffffff",
              fontSize: 16,
              fontWeight: 500,
            }}
            onClick={() => {
              setOpenModal(false);
              setAcademicDocuments(undefined);
            }}
          >
            Cancel
          </PrimaryButton>
        </Grid>
      </Grid>
    );
  }, [confirmDelete, academicDocuments?.document_name, loading]);

  const openVerificationModal = useMemo(() => {
    return (
      <Modal
        open={openModal}
        setModalClose={setOpenModal}
        children={modalText}
        title={undefined}
        size="sm"
        className={undefined}
      />
    );
  }, [openModal, modalText]);

  const download = async (id: string, view: boolean) => {
    try {
      const { data } = await axiosInstance.get(`${URLS.document}/${id}`);
      const type =
        data.mimetype.indexOf("pdf") > -1
          ? "pdf"
          : data.mimetype.indexOf("image") > -1
          ? "image"
          : null;
      if (!!type && view) {
        setDocumentType(type);
        setDocumentUrl(data.url);
        setViewDocument(true);
      } else {
        window.open(data.url, "_blank");
      }
    } catch (e) {
      console.log(e);
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "error",
          message: "There is an error in downloading",
        })
      );
    }
  };

  const viewModalText = useMemo(() => {
    return (
      <Grid container>
        <Grid item>
          {documentType === "pdf" && (
            <Document
              file={{ url: documentUrl }}
              onLoadSuccess={({ numPages }) => setNumPages(numPages)}
            >
              {Array.from(new Array(numPages), (el, index) => (
                <Page key={`page_${index + 1}`} pageNumber={index + 1} />
              ))}
            </Document>
          )}
          {documentType === "image" && (
            <img src={documentUrl} alt={"document"} className={"img-fluid"} />
          )}
        </Grid>
      </Grid>
    );
  }, [documentType, documentUrl, numPages]);

  const openViewModal = useMemo(() => {
    return (
      <Modal
        open={viewDocument}
        setModalClose={setViewDocument}
        children={viewModalText}
        title={undefined}
        size="sm"
        className={undefined}
      />
    );
  }, [viewDocument, viewModalText]);

  const handleUpload = (e: SyntheticEvent) => {
    e.preventDefault();
    if (fileUploadRef.current) {
      fileUploadRef.current.click();
    }
  };

  const selectFile = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setAttachedFile(event.target.files[0]);
    }
  };

  const handleFileUpload = async () => {
    setLoading(true);
    if (attachedFile) {
      const max_size = identifiers.agency_max_file_size as number;
      if (attachedFile.size <= max_size) {
        const additionalForm: {
          name: string;
          type: string;
          directory: string;
          file_name: string;
        } = {
          type: mime.getType(attachedFile.name)
            ? (mime.getType(attachedFile.name) as string)
            : attachedFile.type,
          name: `Credentials_${new Date().getTime()}_${attachedFile.name}`,
          file_name: `${instituteName}-${subject}-${graduationDate}`,
          directory: "documents",
        };
        try {
          const { data } = await axiosInstance.post(
            URLS.candidate_file_upload,
            additionalForm
          );
          await uploadFile(
            data.url,
            mime.getType(attachedFile.name)
              ? (mime.getType(attachedFile.name) as string)
              : attachedFile.type,
            data.id
          );
        } catch (e: any) {
          setLoading(false);
          if (fileUploadRef.current) {
            fileUploadRef.current.value = null;
          }
          if (e.response.status === 409) {
            dispatch(
              setNotificationMessage({
                display: true,
                severity: "error",
                message: `File with name  already exists`,
              })
            );
          } else {
            console.log(e);
          }
        }
      } else {
        setLoading(false);
        dispatch(
          setNotificationMessage({
            display: true,
            severity: "error",
            message: `You cannot upload file more than ${
              (identifiers.agency_max_file_size as number) / 1000000
            }MB`,
          })
        );
        if (fileUploadRef.current) {
          fileUploadRef.current.value = null;
        }
      }
    }
  };

  const uploadFile = async (
    url: string,
    mime: string,
    document_repo_id: string
  ) => {
    try {
      const { status } = await axios.put(url, attachedFile, {
        headers: { "Content-Type": mime },
        onUploadProgress: function (progressEvent) {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          console.log(percentCompleted);
        },
      });
      if (status === 200) {
        setLoading(false);
        // dispatch(
        //   setNotificationMessage({
        //     display: true,
        //     severity: "success",
        //     message: `File  has been uploaded Successfully`,
        //   })
        // );
        // setAttachedFile(file.name);
        await uploadCertificate(document_repo_id);
        if (fileUploadRef.current) {
          fileUploadRef.current.value = null;
        }
      }
    } catch (e) {
      setLoading(false);
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "error",
          message: "There is an error in uploading document",
        })
      );
    }
  };

  const uploadCertificate = async (document_repo_id: string) => {
    setLoading(true);
    const additionalForm: {
      document_repo_id: string;
      institution_name: string | undefined;
      graduation_date: number;
      subject: string | undefined;
    } = {
      document_repo_id,
      institution_name: instituteName,
      graduation_date: new Date(graduationDate).getTime(),
      subject: subject,
    };
    try {
      await axiosInstance.post(
        `${URLS.candidate_request_details}${id}/request-candidate/academic-qualification`,
        additionalForm
      );
      setLoading(false);
      dispatch(setReload(!reload));
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "success",
          message: `Credentials has been Added Successfully`,
        })
      );
      setInstituteName("");
      setSubject("");
      setGraduationDate(undefined);
      fileUploadRef.current.value = null;
      setAttachedFile(undefined);
    } catch (e: any) {
      setLoading(false);
      console.log(e);
    }
  };

  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#F5FBFF",
      color: "#727272",
      border: "none",
      maxWidth: "300px",
      width: "300px",
    },
    [`&.${tableCellClasses.body}`]: {
      borderBottom: "none",
      color: "#263238",
      fontSize: "14px",
      fontWeight: 600,
    },
    [`&.${tableCellClasses.head}:first-of-type`]: {
      borderTopLeftRadius: "4px",
      borderBottomLeftRadius: "4px",
    },
    [`&.${tableCellClasses.head}:last-of-type`]: {
      borderTopRightRadius: "4px",
      borderBottomRightRadius: "4px",
    },
    [`&.${tableCellClasses.head}:nth-of-type(2)`]: {
      maxWidth: "200px",
      width: "200px",
    },
    [`&.${tableCellClasses.head}:nth-of-type(5)`]: {
      maxWidth: "200px",
      width: "200px",
    },
    [`&.${tableCellClasses.head}:nth-of-type(6)`]: {
      maxWidth: "180px",
      width: "180px",
    },
    [`&.${tableCellClasses.head}:last-of-type`]: {
      width: "3em",
      maxWidth: "3em",
      textAlign: "center",
    },
    [`&.${tableCellClasses.body}:first-of-type`]: {
      borderTopLeftRadius: "12px",
      borderBottomLeftRadius: "12px",
      padding: "5px 5px 5px 12px",
    },
    [`&.${tableCellClasses.body}:last-of-type`]: {
      borderTopRightRadius: "12px",
      borderBottomRightRadius: "12px",
      padding: "5px 5px",
    },
  }));

  const StyledTableRow = styled(TableRow)(() => ({
    "&": {
      background: "white",
    },
  }));

  return (
    <>
      {openViewModal}
      {openVerificationModal}
      {loading && <PageLoader />}
      <Grid container>
        <Grid
          container
          xs={12}
          md={12}
          display={"flex"}
          justifyContent={"center"}
        >
          <Grid item xs={11} md={11}>
            <TableContainer
              component={Paper}
              sx={{ boxShadow: "none", backgroundColor: "#f5fbff" }}
            >
              <Table
                sx={{
                  minWidth: 600,
                  borderSpacing: "0 1rem",
                  borderCollapse: "separate",
                }}
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell data-tut="candidate_academic_qualification_table"></StyledTableCell>
                    <StyledTableCell sx={{ minWidth: "150px" }}>
                      Institution Name
                    </StyledTableCell>
                    <StyledTableCell sx={{ minWidth: "100px" }}>
                      Subject
                    </StyledTableCell>
                    <StyledTableCell sx={{ minWidth: "150px" }}>
                      Completion Date
                    </StyledTableCell>
                    <StyledTableCell sx={{ minWidth: "150px" }}>
                      Uploaded Doc
                    </StyledTableCell>
                    <StyledTableCell>Action</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody className={"request-table-body"}>
                  {documents?.map(
                    (document: AcademicDocument, index: number) => (
                      <>
                        {(ids?.indexOf(document.id) > -1 ||
                          !document.is_deleted) && (
                          <StyledTableRow key={index}>
                            <StyledTableCell>
                              <CheckBox
                                checked={ids?.indexOf(document.id) > -1}
                                type="circle"
                                sx={{ marginBottom: "8px" }}
                                onClick={async () => {
                                  await selectDocument(document.id);
                                }}
                              />
                            </StyledTableCell>
                            <StyledTableCell>
                              {document.institution_name}
                            </StyledTableCell>
                            <StyledTableCell>
                              {document.subject}
                            </StyledTableCell>
                            <StyledTableCell>
                              {dayjs(new Date(document.graduation_date)).format(
                                "DD-MM-YYYY"
                              )}
                            </StyledTableCell>
                            <StyledTableCell>
                              {document.document_name}
                            </StyledTableCell>
                            <StyledTableCell>
                              <Stack
                                data-tut={
                                  index === 0
                                    ? "candidate_academic_qualification_action"
                                    : ""
                                }
                                direction={"row"}
                              >
                                <PrimaryButton
                                  sx={{
                                    height: "36px",
                                    background: " #5AB9F9",
                                    boxShadow:
                                      "0px 10px 25px rgba(90, 185, 249, 0.25)",
                                    borderRadius: "6px",
                                    color: "white",
                                    minWidth: "45px",
                                  }}
                                  //   disabled={loading}
                                  onClick={() =>
                                    download(document.document_repo_id, true)
                                  }
                                >
                                  <Visibility sx={{ color: "#ffffff" }} />
                                </PrimaryButton>
                                <PrimaryButton
                                  sx={{
                                    height: "36px",
                                    background: " #5AB9F9",
                                    boxShadow:
                                      "0px 10px 25px rgba(90, 185, 249, 0.25)",
                                    borderRadius: "6px",
                                    color: "white",
                                    mx: 3,
                                    minWidth: "45px",
                                  }}
                                  //  disabled={loading}
                                  onClick={() =>
                                    download(document.document_repo_id, false)
                                  }
                                >
                                  <Download sx={{ color: "#ffffff" }} />
                                </PrimaryButton>
                                <PrimaryButton
                                  sx={{
                                    height: "36px",
                                    background: " #5AB9F9",
                                    boxShadow:
                                      "0px 10px 25px rgba(90, 185, 249, 0.25)",
                                    borderRadius: "6px",
                                    color: "white",
                                    minWidth: "45px",
                                  }}
                                  //  disabled={loading}
                                  onClick={() => deleteFile(document)}
                                >
                                  <Delete sx={{ color: "#ffffff" }} />
                                </PrimaryButton>
                              </Stack>
                            </StyledTableCell>
                          </StyledTableRow>
                        )}
                      </>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid
            item
            xs={12}
            md={11}
            sx={{
              overflowX: { xs: "unset", md: "auto" },
            }}
          >
            <Grid
              item
              minWidth={{ xs: "100%", md: "550px" }}
              sx={{
                background: { xs: "transperant", md: "white" },
                padding: "5px",
                paddingTop: "20px",
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                flexWrap: { xs: "wrap", md: "nowrap" },
                mt: 3,
              }}
            >
              <Grid
                item
                xs={12}
                md={3}
                px={1}
                display="flex"
                justifyContent={"center"}
              >
                <InputField
                  textChange={setInstituteName}
                  label="Institution Name"
                  id="outlined-name"
                  size="small"
                  value={instituteName}
                  inputProps={{
                    sx: {
                      py: 1,
                    },
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={3}
                px={1}
                display="flex"
                justifyContent={"center"}
              >
                <InputField
                  label="Subject"
                  textChange={setSubject}
                  id="outlined-basic"
                  size="small"
                  value={subject}
                  variant="outlined"
                  inputProps={{
                    sx: {
                      py: 1,
                    },
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={3}
                px={1}
                display="flex"
                justifyContent={"center"}
              >
                <DateInput
                  value={graduationDate || null}
                  label="Graduation Date"
                  disableFuture={true}
                  textChange={setGraduationDate}
                  onChange={(e) => console.log(e)}
                  renderInput={(params) => (
                    <TextField
                      sx={{
                        width: "100%",
                      }}
                      size="small"
                      {...params}
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                lg={3}
                px={1}
                display="flex"
                justifyContent={"center"}
              >
                {!!attachedFile ? (
                  <PrimaryButton
                    variant={"text"}
                    sx={{ minWidth: "180px", mr: 1, pb: "15px" }}
                    onClick={() => {
                      fileUploadRef.current.value = null;
                      setAttachedFile(undefined);
                    }}
                  >
                    <Typography
                      variant={"body2"}
                      width={"100%"}
                      whiteSpace={"nowrap"}
                      overflow={"hidden"}
                      textOverflow={"ellipsis"}
                    >
                      {attachedFile.name}
                    </Typography>
                    <CancelIcon />
                  </PrimaryButton>
                ) : (
                  <PrimaryButton
                    sx={{
                      border: "1px dashed #5AB9F9",
                      borderRadius: "6px",
                      background: "white",
                      color: "#5AB9F9",
                      minWidth: "180px",
                    }}
                    disabled={loading}
                    className="uploadBtn mb-2"
                    onClick={handleUpload}
                  >
                    <AddCircleRoundedIcon />
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "22px",
                        ml: 1,
                      }}
                      data-tut="candidate_upload_academic_qualification_"
                    >
                      Upload Document
                    </Typography>
                  </PrimaryButton>
                )}
                <input
                  ref={fileUploadRef}
                  hidden
                  accept={identifiers.accept as string}
                  size={identifiers.maxFileSize as number}
                  type="file"
                  onChange={selectFile}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          display={"flex"}
          justifyContent={"center"}
          // className="card-footer"
          md={12}
          xs={12}
          sx={{
            px: 3,
            py: 1,
            background: "#e9f6ff",
            borderRadius: "0px 0px 15px 15px",
            mt: 2,
          }}
        >
          <PrimaryButton
            fullWidth={false}
            sx={{
              width: { xs: "100%", md: "252px" },
              height: "36px",
              background: " #5AB9F9",
              borderRadius: "6px",
              color: "white",
            }}
            data-tut="candidate_add_academic_qualification"
            disabled={
              !instituteName || !subject || !graduationDate || !attachedFile
            }
            onClick={handleFileUpload}
          >
            {/*{loading && (*/}
            {/*  <CircularProgress sx={{ mr: 1, color: "white" }} size={20} />*/}
            {/*)}{" "}*/}
            Add Credentials
          </PrimaryButton>
        </Grid>
      </Grid>
    </>
  );
};

export default AcademicQualification;
