import React, { useCallback, useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { MainWrapper } from "./utils/theme";
import Routes from "./routes";
import * as Amplify from "./utils/services/amplify";
import { useDispatch } from "react-redux";
import { setAuthentication } from "./utils/redux";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Formio } from "@formio/react";
import FormioContrib from "@formio/contrib";

Formio.use(FormioContrib);

function App() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const getAuthentication = useCallback(async () => {
    try {
      const status = await Amplify.IsAuthenticated();
      if (status) {
        await Amplify.RefreshSession();
        const user = await Amplify.UserDetail();
        const { attributes } = user as any;
        if (attributes["custom:role"] === "agency") {
          if (
            attributes.given_name &&
            attributes.family_name &&
            attributes.phone_number &&
            attributes.address &&
            attributes["custom:company"] &&
            attributes["custom:designation"]
          ) {
            dispatch(
              setAuthentication({
                authenticated: true,
                isProfileComplete: true,
                role: attributes["custom:role"],
                user: attributes,
              })
            );
          } else {
            dispatch(
              setAuthentication({
                authenticated: true,
                isProfileComplete: false,
                role: attributes["custom:role"],
                user: attributes,
              })
            );
          }
        } else if (attributes["custom:role"] === "candidate") {
          if (attributes.phone_number_verified) {
            dispatch(
              setAuthentication({
                authenticated: true,
                isProfileComplete: true,
                role: attributes["custom:role"],
                user: attributes,
              })
            );
          } else {
            await Amplify.Logout();
            dispatch(
              setAuthentication({
                authenticated: false,
                isProfileComplete: false,
                role: null,
                user: null,
              })
            );
          }
        }
      } else {
        dispatch(
          setAuthentication({
            authenticated: false,
            isProfileComplete: false,
            role: null,
            user: null,
          })
        );
      }
    } catch (e: any) {
      dispatch(
        setAuthentication({
          authenticated: false,
          isProfileComplete: false,
          role: null,
          user: null,
        })
      );
      // dispatch(
      //   setNotificationMessage({
      //     display: true,
      //     severity: "error",
      //     message: "There is an error in authentication",
      //   })
      // );
    }
    setLoading(false);
  }, [dispatch]);

  useEffect(() => {
    (async () => {
      await getAuthentication();
    })();
  }, [getAuthentication]);

  return (
    <MainWrapper>
      {!loading && (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Grid container className="main-body">
            <Routes />
          </Grid>
        </LocalizationProvider>
      )}
    </MainWrapper>
  );
}

export default App;
