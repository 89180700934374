import React, { ChangeEvent, FC, useState } from "react";
import { CircularProgress, Grid, Typography } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import PrimaryButton from "../../../../../../../components/Button/PrimaryButton";
import { useParams } from "react-router-dom";
import axiosInstance from "../../../../../../../utils/axios";
import { URLS } from "../../../../../../../utils/constants/urls";
import { useDispatch, useSelector } from "react-redux";
import { Reload, setReload } from "../../../../../../../utils/redux";

// import { VettingDocuments } from "../../../../../../../utils/redux";

export interface UpdatedServicesProps {
  vettingDocId: string;
  setDbs: (value: boolean) => void;
  comments?: string;
  name?: string;
}

const UpdatedServices: FC<UpdatedServicesProps> = ({
  setDbs,
  vettingDocId,
  comments,
  name,
}) => {
  const dispatch = useDispatch();
  // const documents = useSelector(VettingDocuments);
  const reload = useSelector(Reload);
  const { id } = useParams() as any;

  const [loading, setLoading] = useState<boolean>(false);
  const [updatedServices, setUpdatedServices] = useState<boolean | string>("");

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setUpdatedServices(event.target.value === "true");
  };

  const confirmUpdatedServices = async () => {
    try {
      setLoading(true);
      await axiosInstance.patch(`${URLS.requests}/${id}/document`, {
        on_updated_dbs_services: updatedServices,
        vetting_doc_id: vettingDocId,
      });
      setLoading(false);
      dispatch(setReload(!reload));
      setDbs(updatedServices as boolean);
    } catch (e: any) {
      console.log(e);
    }
  };

  return (
    <Grid container justifyContent={"center"} spacing={2} sx={{ p: 5 }}>
      <Grid
        xs={12}
        md={7}
        className="pop-up-dotted-card-dbs content-card"
        sx={{ marginTop: "55px", marginBottom: "40px" }}
        data-tut="dbs_updated_pop_up"
      >
        <Typography
          variant={"body1"}
          color={"#333333"}
          sx={{
            mb: 1,
            fontSize: "18px",
            fontWeight: "500",
            letterSpacing: 0.15,
            marginBottom: "30px",
          }}
        >
          Is your {name} on updated service ?
        </Typography>
        <FormControl>
          <RadioGroup
            className="radio-group"
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={updatedServices}
            onChange={handleChange}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <FormControlLabel value={true} control={<Radio />} label="Yes" />
            <FormControlLabel value={false} control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl>
      </Grid>
      {!!comments && (
        <Grid
          xs={8}
          className="custom-card-dbs content-card"
          sx={{ marginTop: "25px" }}
        >
          <Typography
            variant={"body1"}
            color={"#333333"}
            sx={{
              mb: 1,
              fontSize: "14px",
              fontWeight: "500",
              letterSpacing: 0.15,
              marginBottom: "8px",
              textAlign: "center",
            }}
          >
            Custom Message
          </Typography>
          <Typography
            variant={"body1"}
            color={"#727272"}
            sx={{
              fontSize: "12px",
              fontWeight: "400",
              letterSpacing: 0.15,
              marginBottom: "10px",
              lineHeight: "24px",
            }}
          >
            {comments}
          </Typography>
        </Grid>
      )}
      <Grid item xs={12} textAlign={"center"} sx={{ mt: 5 }}>
        <PrimaryButton
          fullWidth={false}
          sx={{
            width: "212px",
            height: "36px",
            background: " #5AB9F9",
            boxShadow: "0px 10px 25px rgba(90, 185, 249, 0.25)",
            borderRadius: "6px",
            color: "#ffffff",
          }}
          disabled={loading || updatedServices === ""}
          onClick={confirmUpdatedServices}
        >
          {loading && <CircularProgress sx={{ mr: 1 }} size={20} />}
          Continue
        </PrimaryButton>
      </Grid>
    </Grid>
  );
};

export default UpdatedServices;
