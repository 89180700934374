import React, { FC } from "react";
import { Grid } from "@mui/material";
import InputField from "../../../../../components/InputField";
import "./AdditionalComments.scss";

const AdditionalComments: FC<{ comment: string }> = ({ comment }) => {
  return (
    <Grid item md={12} className="card-additional-comments">
      <Grid container className="card-head-table">
        <Grid item xs={12} md={12} className="card-header-text pl-3 py-3">
          Notes to Request
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} sx={{ px: 3 }}>
          <InputField
            multiline
            rows={10}
            sx={{
              background: "#ffffff",
            }}
            placeholder={
              "The applicant may email once they have fulfilled the requirement of this request."
            }
            disabled={true}
            inputValue={comment}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AdditionalComments;
