import React, { FC, useState } from "react";
import { images } from "../../utils/constants/images";
import {
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { mainOptionsConfig, subOptionsConfig } from "./config";
import * as Amplify from "../../utils/services/amplify";
import { setAuthentication } from "../../utils/redux";
import { styled } from "@mui/material/styles";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Box } from "@mui/system";

import { UserRole } from "../../utils/redux";

const DrawerContent: FC<{
  isMobile: boolean;
  open: boolean;
  setOpen: (value: boolean) => void;

}> = ({ isMobile, open, setOpen }) => {
  const Navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [selected, setSelected] = useState("");
  const userRole = useSelector(UserRole);

  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: isMobile ? "flex-end" : open ? "flex-start" : "center",
    padding: theme.spacing(0, 0.9),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }));

  return (
    <>
      <DrawerHeader>
        {!isMobile && (
          <img
            src={open ? images.kedExpandIcon : images.kedSmallIcon}
            alt="ked.io"
          />
        )}
        {isMobile && (
          <IconButton onClick={() => setOpen(false)}>
            {<ChevronLeftIcon />}
          </IconButton>
        )}
      </DrawerHeader>
      {isMobile && (
        <Box alignItems="center" padding="0px 19px 0px 19px">
          <Typography
            variant="h6"
            fontSize="24px"
            sx={{ display: "inline-block" }}
          >
            Hello
          </Typography>
          <img src={images.helloIcon} alt="hello" className="helloIcon" />
          <Typography variant="h6" fontSize="24px">
            Welcome to <span style={{ fontSize: "30px" }}>KED</span>
          </Typography>
          <Typography
            variant="h6"
            fontSize="16px"
            fontWeight="500"
            sx={{ marginTop: "16px" }}
          >
            My Profile
          </Typography>
          <Box
            textAlign="center"
            sx={{ background: "#F5FBFF", borderRadius: "8px" }}
          >
            <img src={images.dummyProfilePic} alt="profilepic" />
            <Typography variant="h6" fontSize="18px" fontWeight="500">
              Siddharth Mohan
            </Typography>
          </Box>
        </Box>
      )}
      <List
        sx={{
          display: "flex",
          gap: "20px",
          flexDirection: "column",
        }}
      >
        {mainOptionsConfig(userRole).map((item) => (
          <>
            {!!item && (
              <ListItem
                key={item.text}
                disablePadding
                sx={{ display: "block" }}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                  onClick={() => {
                    setSelected(item.text);
                    Navigate(item.url);
                    if(isMobile){
                      setOpen(false)
                    }
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                    data-tut={!isMobile?item.tourCofig:''}
                  >
                    <img
                      src={
                        selected === item.text || location.pathname === item.url
                          ? item.selectedIcon
                          : item.icon
                      }
                      alt={item.text}
                    />
                  </ListItemIcon>
                  <ListItemText
                    data-tut={isMobile?item.tourCofig:''}
                    primary={item.text}
                    sx={{
                      opacity: open ? 1 : 0,
                      color:
                        selected === item.text || location.pathname === item.url
                          ? "#00B2FF"
                          : "#212121",
                    }}
                  />
                </ListItemButton>
              </ListItem>
            )}
          </>
        ))}
      </List>
      <List
        sx={{
          display: "flex",
          gap: { xs: "10px", sm: "10px", md: "20px", lg: "40px" },
          pb: { xs: "10px", sm: "10px", md: "20px", lg: "40px" },
          flexDirection: "column",
        }}
      >
        {subOptionsConfig(userRole).map((item) => (
          <>
            {!!item && (
              <ListItem
                key={item.text}
                disablePadding
                sx={{ display: item.isMobile }}
                data-tut={item.tourCofig}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                  onClick={async () => {
                    if (item.option === "logout") {
                      await Amplify.Logout();
                      dispatch(
                        setAuthentication({
                          authenticated: false,
                          isProfileComplete: false,
                          user: null,
                        })
                      );
                    } else {
                      setSelected(item.text);
                      Navigate(item.url);
                      if(isMobile){
                        setOpen(false)
                      }
                    }
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                    data-tut={item.tourCofig}
                  >
                    <img
                      src={
                        selected === item.text ? item.selectedIcon : item.icon
                      }
                      alt={item.text}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={item.text}
                    sx={{
                      opacity: open ? 1 : 0,
                      color: selected === item.text ? "#00B2FF" : "#212121",
                    }}
                  />
                </ListItemButton>
              </ListItem>
            )}
          </>
        ))}
      </List>
    </>
  );
};

export default DrawerContent;
