import React, {
  FC,
  MouseEvent,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Box,
  CircularProgress,
  Grid,
  Stack,
  Typography,
  Button,
} from "@mui/material";
import Form, { FormDataModel } from "../../../components/Form";
import { SignUpForm } from "./SignUpForm";
import PrimaryButton from "../../../components/Button/PrimaryButton";
import { removeErrorFieldsFromValues } from "../../../utils/validators";
import { images } from "../../../utils/constants/images";
import * as Amplify from "../../../utils/services/amplify";
import { CognitoUser } from "amazon-cognito-identity-js";
import Modal from "../../../components/modal/Modal";
import OtpInputField from "../../../components/OtpInputField/OtpInputField";
import { useDispatch, useSelector } from "react-redux";
import {
  setAuthentication,
  setNotificationMessage,
} from "../../../utils/redux";
import { candidate } from "../../../utils/constants/routes";
import "./SignUp.scss";
import { identifiers } from "../../../utils/constants/identifiers";
import { RequestId } from "../../../utils/redux";
import axiosInstance from "../../../utils/axios";
import { URLS } from "../../../utils/constants/urls";
import RefreshIcon from "@mui/icons-material/Refresh";
import PageLoader from "../../../components/PageLoader";
import { useTour } from "@reactour/tour";
import { tourStep } from "../../../utils/constants/coachMarks";

const SignUp: FC = () => {
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const { setIsOpen, currentStep, setCurrentStep } = useTour();
  let userDetailForm: RefObject<Form | null | undefined> = useRef();

  const requestId = useSelector(RequestId);

  const [confirmationLoading, setConfirmationLoading] =
    useState<boolean>(false);
  const [resendLoading, setResendLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [pageLoading, setPageLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState(false);
  const [otp, setOtp] = useState("");
  const [buttonActive, setButtonActive] = useState<boolean>(false);
  const [formValue, setFormValue] = useState<any>({});
  const [country, setCountry] = useState<string>();
  const [modalOpen, setModalOpen] = useState(false);
  const [verifyAttribute, setVerifyAttribute] = useState<
    "email" | "phone" | undefined
  >();

  useEffect(() => {
    if (!requestId) {
      Navigate(candidate.login);
    }
  }, [Navigate, requestId]);

  useEffect(() => {
    setCurrentStep(tourStep.signup.index);
  }, [setCurrentStep]);

  useEffect(() => {
    if (tourStep.signup.index === currentStep) {
      setIsOpen(true);
    } else if (currentStep === tourStep.signup.steps + tourStep.signup.index) {
      setIsOpen(false);
    }
  }, [currentStep, setIsOpen]);

  const register = useCallback(
    async (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      setLoading(true);
      const { getFormData } = userDetailForm.current as {
        getFormData: () => { formData: FormDataModel; isFormValid: boolean };
      };
      const { formData, isFormValid } = getFormData();
      const body: any = removeErrorFieldsFromValues(formData);
      try {
        setHasError(false);
        if (isFormValid) {
          if (body.password === body.confirm_password) {
            const data = await Amplify.Register(body);
            if (data.codeDeliveryDetails && !data.userConfirmed) {
              setVerifyAttribute(undefined);
              dispatch(
                setNotificationMessage({
                  display: true,
                  severity: "success",
                  message: "OTP has been sent to your Email",
                })
              );
              setFormValue(body);
              setOpenModal(true);
              setLoading(false);
              setModalOpen(false);
            }
          } else {
            setLoading(false);
            dispatch(
              setNotificationMessage({
                display: true,
                message: "Your Passwords do not match",
                severity: "error",
              })
            );
          }
        } else {
          setHasError(true);
        }
      } catch (error: any) {
        setLoading(false);
        setModalOpen(false);
        switch (error.code) {
          case "UsernameExistsException":
            dispatch(
              setNotificationMessage({
                display: true,
                severity: "error",
                message: "Email Already Exists",
              })
            );
            break;
          case "InvalidPasswordException":
            dispatch(
              setNotificationMessage({
                display: true,
                severity: "error",
                message: error.message,
              })
            );
            break;
          default:
            dispatch(
              setNotificationMessage({
                display: true,
                severity: "error",
                message: "Unable to Register",
              })
            );
            break;
        }
      }
    },
    [dispatch]
  );

  const login = useCallback(async () => {
    const data: CognitoUser = await Amplify.Login({
      ...formValue,
    } as {
      email: string;
      password: string;
    });
    const { attributes } = data as any;
    if (!attributes.phone_number_verified) {
      await Amplify.SendVerificationCode("phone_number");
      setVerifyAttribute("phone");
      setOtp("");
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "success",
          message: "Email has been verified Successfully",
        })
      );
      setLoading(false);
      setConfirmationLoading(false);
    }
  }, [dispatch, formValue]);

  const verifyAccount = useCallback(async () => {
    setConfirmationLoading(true);
    try {
      await Amplify.ConfirmUser(otp, formValue);
      await login();
    } catch (error: any) {
      if (error.code === "CodeMismatchException") {
        dispatch(
          setNotificationMessage({
            display: true,
            severity: "error",
            message:
              "You seem to have put an Incorrect Verification Code. Please re-enter your Verification Code",
          })
        );
      } else {
        dispatch(
          setNotificationMessage({
            display: true,
            severity: "error",
            message: "Unable to Verify Account",
          })
        );
      }
      setConfirmationLoading(false);
    }
  }, [otp, formValue, login, dispatch]);

  const resendConfirmationCode = useCallback(async () => {
    try {
      setResendLoading(true);
      if (verifyAttribute === "email") {
        await Amplify.resendConfirmationCode(formValue.email);
      } else {
        await Amplify.SendVerificationCode("phone_number");
      }
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "success",
          message:
            "Confirmation code has been re-sent to your " +
            (verifyAttribute === "email" ? "Email ID" : "Phone Number"),
        })
      );
      setResendLoading(false);
    } catch (err) {
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "error",
          message: "Unable to resend verification code. Reason: " + err,
        })
      );
      setResendLoading(false);
    }
  }, [dispatch, formValue.email, verifyAttribute]);

  const confirmOtp = useCallback(async () => {
    setConfirmationLoading(true);
    try {
      await Amplify.VerifyAttributeUpdate("phone_number", otp);
      await Amplify.RefreshSession();
      const user = await Amplify.UserDetail();
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "success",
          message: "OTP has been verified Successfully",
        })
      );
      setOpenModal(false);
      dispatch(
        setAuthentication({
          authenticated: true,
          isProfileComplete: true,
          role: "candidate",
          user: user.attributes,
        })
      );
      setConfirmationLoading(false);
      setPageLoading(true);
      await axiosInstance.put(`${URLS.requests}/${requestId}/map`);
      setPageLoading(false);
      Navigate(`${candidate.requests}/${requestId}`);
    } catch (e: any) {
      setConfirmationLoading(false);
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "error",
          message:
            "You seem to have put an Incorrect Verification Code. Please re-enter your Verification Code",
        })
      );
    }
  }, [Navigate, dispatch, otp, requestId]);

  const verificationModal = useMemo(() => {
    return (
      <Box sx={{ p: 1 }}>
        <Stack>
          <Typography
            variant={"h5"}
            fontWeight={600}
            color={"#333333"}
            sx={{ mb: 1 }}
          >
            {!!verifyAttribute
              ? "Verify " +
                (verifyAttribute === "email" ? "Email" : "Phone Number")
              : "Your Sign Up is Successful"}
          </Typography>
          {!!verifyAttribute && (
            <Box>
              <Typography variant={"caption"} color={"#727272"} sx={{ mb: 5 }}>
                Please provide OTP sent to
                {verifyAttribute === "email" ? " Email" : " Phone Number"}
              </Typography>
              <img
                onClick={() => {
                  setOpenModal(false);
                }}
                style={{
                  position: "absolute",
                  top: "8px",
                  right: "8px",
                  width: "20px",
                  cursor: "pointer",
                }}
                src={images.closeBtn}
                alt={"close button"}
              />
            </Box>
          )}
        </Stack>
        {!!verifyAttribute ? (
          <Stack direction={"row"} justifyContent={"center"}>
            <OtpInputField otpValue={otp} handleOtpCallback={setOtp} />
          </Stack>
        ) : (
          <Stack>
            <Typography variant={"body1"} color={"#333333"} sx={{ mt: 4 }}>
              Congratulations! You have successfully signed up.
            </Typography>
            <Typography variant={"body1"} color={"#333333"} sx={{ mt: 1 }}>
              Please note that you need to verify your email id and mobile
              number in the next steps to access the application.
            </Typography>
          </Stack>
        )}
        <Stack>
          <PrimaryButton
            disabled={confirmationLoading || resendLoading || loading}
            sx={{ mt: 3, color: "#ffffff" }}
            variant={"contained"}
            onClick={() =>
              !!verifyAttribute
                ? verifyAttribute === "email"
                  ? verifyAccount()
                  : confirmOtp()
                : setVerifyAttribute("email")
            }
          >
            {!!verifyAttribute ? (
              <>
                {confirmationLoading && (
                  <CircularProgress sx={{ mr: 1 }} size={20} />
                )}{" "}
                Confirm
              </>
            ) : (
              "Proceed"
            )}
          </PrimaryButton>
          {!!verifyAttribute && (
            <PrimaryButton
              fullWidth={false}
              disabled={confirmationLoading || resendLoading || loading}
              sx={{ mt: 3, color: "#333333", width: "150px" }}
              variant={"text"}
              onClick={resendConfirmationCode}
            >
              {resendLoading && <CircularProgress sx={{ mr: 1 }} size={20} />}
              <RefreshIcon /> Resend OTP
            </PrimaryButton>
          )}
        </Stack>
      </Box>
    );
  }, [
    verifyAttribute,
    otp,
    confirmationLoading,
    resendLoading,
    loading,
    resendConfirmationCode,
    verifyAccount,
    confirmOtp,
  ]);

  const confirmSubmitModal = useMemo(() => {
    return (
      <Grid container padding={"0px"}>
        <Grid item md={12}>
          <Stack
            sx={{
              background: "#F3FCFF",
              padding: "20px ",
              justifyContent: "space-between",
            }}
            display={"flex"}
            direction={"row"}
            alignItems={"center"}
          >
            <Box
              sx={{ display: "flex", direction: "row", alignItems: "center" }}
            >
              <img src={images.warningTooltip} alt={"tooltip"} />
              <Typography
                variant="h6"
                sx={{
                  ml: 2,
                  fontSize: 18,
                  fontWeight: 500,
                  color: "#000000",
                  textAlign: "center",
                  letterSpacing: "0.15px",
                }}
              >
                Confirm SignUp Details
              </Typography>
            </Box>
            <img
              onClick={() => {
                setModalOpen(false);
              }}
              style={{ width: "20px" }}
              src={images.closeBtn}
              alt={"close button"}
            />
          </Stack>
        </Grid>
        <Grid item md={12} padding={"20px"}>
          <Typography
            variant="h6"
            sx={{
              fontSize: 18,
              fontWeight: 400,
              color: "#333333",
              textAlign: "center",
              letterSpacing: "0.15px",
            }}
          >
            Please double check applicant information as once submitted, you
            will not be able to change it.
          </Typography>
        </Grid>
        <Grid
          item
          md={12}
          padding={"20px"}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Button
            sx={{
              background: "white",
              border: "1px solid #5AB9F9",
              color: "#5AB9F9",
              fontSize: 16,
              fontWeight: 500,
              mr: 2,
              mt: 1,
              p: "10px",
            }}
            disabled={loading}
            onClick={register}
          >
            {loading && <CircularProgress sx={{ mr: 1 }} size={20} />}Continue
          </Button>
          <PrimaryButton
            disabled={loading}
            fullWidth={false}
            sx={{
              mt: 1,
              background: "#5AB9F9",
              color: "#ffffff",
              fontSize: 16,
              fontWeight: 500,
            }}
            onClick={() => {
              setModalOpen(false);
            }}
          >
            Cancel
          </PrimaryButton>
        </Grid>
      </Grid>
    );
  }, [register, loading]);

  const openVerificationModal = useMemo(() => {
    return (
      <Modal
        open={openModal}
        setModalClose={setOpenModal}
        children={verificationModal}
        isBackdrop={true}
        title={undefined}
        size={"sm"}
        className={undefined}
      />
    );
  }, [openModal, verificationModal]);

  const openConfirmSubmitModal = useMemo(() => {
    return (
      <Modal
        open={modalOpen}
        setModalClose={setModalOpen}
        children={confirmSubmitModal}
        title={undefined}
        size="sm"
        className={undefined}
        padding="0px"
        Radius="15px"
      />
    );
  }, [modalOpen, confirmSubmitModal]);

  return (
    <>
      {openConfirmSubmitModal}
      {openVerificationModal}
      {pageLoading ? (
        <PageLoader />
      ) : (
        <>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="flex-start"
              spacing={0}
            >
              <Typography
                variant={"h4"}
                fontWeight={600}
                sx={{
                  mb: 1,
                  color: "#333333",
                }}
                data-tut="sign_up"
              >
                Basic Details
              </Typography>
              <Typography
                variant={"caption"}
                sx={{
                  mb: 5,
                  color: "#727272",
                }}
              >
                Please provide your basic details to continue
              </Typography>
            </Stack>
            <img src={images.kedIcon} alt="kedIcon" />
          </Stack>
          <Grid container spacing={2}>
            <Form
              hasError={hasError}
              fieldError={identifiers.field_error as string}
              ref={userDetailForm as RefObject<Form>}
              model={SignUpForm(country as string)}
              values={formValue}
              onChange={(field, value, formData, isFormValid) => {
                if (field === "country") {
                  setCountry(value);
                }
                setButtonActive(isFormValid);
              }}
            />
          </Grid>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={1}
            sx={{ mt: 4 }}
          >
            <PrimaryButton
              disabled={
                loading || !buttonActive || confirmationLoading || resendLoading
              }
              sx={{
                mt: 1,
                pl: 6,
                pr: 6,
                background: "#5AB9F9",
                color: "#ffffff",
                fontSize: 16,
                fontWeight: 500,
              }}
              fullWidth={false}
              onClick={() => setModalOpen(true)}
            >
              {loading && <CircularProgress sx={{ mr: 1 }} size={20} />}
              Agree & Continue
            </PrimaryButton>
          </Stack>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={1}
            sx={{ mt: 4 }}
          >
            <Typography
              variant="body1"
              sx={{
                color: "#727272",
                fontSize: 12,
                fontWeight: 500,
              }}
              align={"center"}
            >
              By clicking Agree & Continue, you agree to the KED User Agreement,
              Privacy Policy, and Cookie Policy.
            </Typography>
          </Stack>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={1}
            sx={{ mt: 4 }}
          >
            <Typography
              variant="body1"
              sx={{
                color: "#727272",
                fontSize: 12,
                fontWeight: 500,
              }}
              align={"center"}
            >
              Already have an account?{" "}
            </Typography>
            <Link to={candidate.login} className="redirection-link">
              <Typography
                variant="body1"
                sx={{
                  fontSize: 12,
                  fontWeight: 500,
                  color: "#5AB9F9",
                }}
              >
                Sign In
              </Typography>
            </Link>
          </Stack>
        </>
      )}
    </>
  );
};

export default SignUp;
