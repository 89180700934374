import React, {
  ChangeEvent,
  FC,
  useEffect,
  useRef,
  useState,
  useMemo,
  Dispatch,
  SetStateAction,
  useCallback,
} from "react";
import {
  Box,
  Grid,
  Paper,
  Typography,
  Stack,
  useMediaQuery,
} from "@mui/material";
import "../RequiredReferences.scss";
import PrimaryButton from "../../../../../../components/Button/PrimaryButton";
import { useDispatch, useSelector } from "react-redux";
import {
  PreviewReference,
  Reference,
  setNotificationMessage,
  setPreviewReference,
  setReference,
  setValue,
} from "../../../../../../utils/redux";
import PageLoader from "../../../../../../components/PageLoader";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import CheckBox from "../../../../../../components/CheckBox";
import AdditionalQuestionnaire from "./AdditionalQuestionnaire";
import { styled, useTheme } from "@mui/material/styles";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import CreateQuestionnaire from "./CreateQuestionnaire";
import { debounce } from "lodash";
import axiosInstance from "../../../../../../utils/axios";
import { URLS } from "../../../../../../utils/constants/urls";
import Modal from "../../../../../../components/modal/Modal";
import FromDevice from "../../../../Candidate/ViewRequest/VettingDocuments/DocumentList/common/UploadFiles/FromDevice";
import UploadOwnDocuments from "./UploadOwnDocuments";
import mime from "mime";
import axios from "axios";
import { identifiers } from "../../../../../../utils/constants/identifiers";
import { images } from "../../../../../../utils/constants/images";
import { RequestReferenceList } from "../../../../../../utils/redux/reducer/request-slice";
import LinearProgress, {
  LinearProgressProps,
} from "@mui/material/LinearProgress";
import { useTour } from "@reactour/tour";
import { tourStep } from "../../../../../../utils/constants/coachMarks";
import * as Amplify from "../../../../../../utils/services/amplify";
import { CognitoUser } from "amazon-cognito-identity-js";
import {
  User,
  setUserData,
} from "../../../../../../utils/redux/reducer/authentication-slice";
import { Auth } from "aws-amplify";

const LinearProgressWithLabel: FC<
  { value: number; reqNumber: number } & LinearProgressProps
> = ({ value, reqNumber, ...props }) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress
          color="success"
          variant="determinate"
          {...props}
          value={(value / reqNumber) * 100}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          value
        )}%`}</Typography>
      </Box>
    </Box>
  );
};

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  margin: "20px 0px",
}));

const Questionnaire: FC<{
  expanded:
    | "ked-questionnaire"
    | "additional-questionnaire"
    | "upload-own-ref"
    | "";
  setExpanded: Dispatch<
    SetStateAction<
      "" | "ked-questionnaire" | "additional-questionnaire" | "upload-own-ref"
    >
  >;
  handleNext: () => void;
  handleBack: () => void;
  type: string;
  activeStep: number;
}> = ({ expanded, setExpanded, handleNext, handleBack, type, activeStep }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const { setIsOpen, currentStep, setCurrentStep } = useTour();
  const user: { [key: string]: string } = useSelector(User) as {
    [key: string]: string;
  };
  const reference = useSelector(Reference);
  const previewReference = useSelector(PreviewReference);

  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState(false);
  const [additionalFormChecked, setAdditionalFormChecked] =
    useState<boolean>(false);
  const [referenceFormsChecked, setReferenceFormsChecked] =
    useState<boolean>(false);
  const [additionalQuestionnaires, setAdditionalQuestionnaires] = useState<
    any[]
  >([]);
  const [additionalReferenceDocuments, setAdditionalReferenceDocuments] =
    useState<any[]>([]);
  const [openUploadFile, setOpenUploadFile] = useState(false);
  const [files, setFiles] = useState<File[]>();
  const [filter, setFilter] = useState<{
    search: string;
    index: number;
    page_size: number;
    delay: number;
    name: string;
    sort: boolean;
  }>({
    search: "",
    index: 0,
    page_size: 10,
    delay: 0,
    name: "created_at",
    sort: false,
  });
  const [percentageProcess, setPercentageProcess] = useState(0);
  const [fileName, setFileName] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [openView, setOpenView] = useState<boolean>(false);
  const [referenceIndex, setReferenceIndex] = useState<number>(0);

  const setRequiredReferenceCoachMarksValue = useCallback(async () => {
    //if (!!user && user["custom:reference_tutorial"] !== "true") {
    const currentUser: CognitoUser = await Amplify.UserDetail();
    const body = {
      "custom:ref_second_step": "true",
    };
    await Amplify.UpdateUserDetails(body, currentUser);
    await Amplify.RefreshSession();
    dispatch(setUserData({ ...user, ...body }));
    //}
  }, [user, dispatch]);

  useEffect(() => {
    if (activeStep === 1) {
      if (!!user && user["custom:ref_second_step"] !== "true") {
        setCurrentStep(tourStep.required_ref_second_step.index);
      }
    }
  }, [activeStep, setCurrentStep, user]);

  useEffect(() => {
    if (activeStep === 1) {
      if (!!user && user["custom:ref_second_step"] !== "true") {
        if (tourStep.required_ref_second_step.index === currentStep) {
          setIsOpen(true);
        } else if (
          currentStep ===
          tourStep.required_ref_second_step.index +
            tourStep.required_ref_second_step.steps
        ) {
          setIsOpen(false);
          if (!!user && user["custom:ref_second_step"] !== "true") {
            (async () => {
              await setRequiredReferenceCoachMarksValue();
            })();
          }
        }
      }
    }
  }, [
    currentStep,
    setIsOpen,
    setRequiredReferenceCoachMarksValue,
    user,
    activeStep,
  ]);

  const getReferenceForms = async (
    // search: string,
    sort_column: string,
    sort: boolean,
    data_type: "form" | "document"
  ) => {
    // const searchQuery = !!search && `&keyword=${search}`;
    const sortQuery =
      !!sort_column && `&asc_sort=${sort}&sort_column=${sort_column}`;
    let page_key = null;
    const questionnaire_list: any[] = [];
    const form_list: any[] = [];

    try {
      setLoading(true);
      do {
        const { data }: { data: any } = await axiosInstance.post(
          `${URLS.reference_forms}/${type}/${data_type}?page_size=${
            filter.page_size
          }${sortQuery || ""}`,
          page_key
        );
        setLoading(false);
        if (data_type === "form") {
          questionnaire_list.push(...data.data);
          if (!!page_key && page_key !== "null") {
            setAdditionalQuestionnaires((prevState) => [
              ...prevState,
              ...data.data,
            ]);
          } else {
            setAdditionalQuestionnaires(data.data);
          }
        } else {
          form_list.push(...data.data);
          if (!!page_key && page_key !== "null") {
            setAdditionalReferenceDocuments((prevState) => [
              ...prevState,
              ...data.data,
            ]);
          } else {
            setAdditionalReferenceDocuments(data.data);
          }
        }
        page_key = JSON.parse(data.page_key);
      } while (!!page_key && page_key !== "null");
      if (data_type === "form") {
        setAdditionalQuestionnaires(questionnaire_list);
      } else {
        setAdditionalReferenceDocuments(form_list);
      }
    } catch (e: any) {
      setLoading(false);
      if (await Auth.currentUserInfo()) {
        dispatch(
          setNotificationMessage({
            display: true,
            severity: "error",
            message: "Unable to get Questionnaire",
          })
        );
      }
    }
  };

  const delayedQuery = useRef(
    debounce(getReferenceForms, filter.delay)
  ).current;

  useEffect(() => {
    (async () => {
      if (expanded === "additional-questionnaire") {
        await delayedQuery(
          // filter.search,
          filter.name,
          filter.sort,
          "form"
        );
      }
      if (expanded === "upload-own-ref") {
        await delayedQuery(
          // filter.search,
          filter.name,
          filter.sort,
          "document"
        );
      }
    })();
    console.log(filter);
  }, [delayedQuery, expanded, filter]);

  useEffect(() => {
    const request_references = JSON.parse(
      JSON.stringify(reference.request_references)
    );
    const index = request_references.findIndex(
      (request_reference: RequestReferenceList) =>
        request_reference.type === type
    );
    setReferenceIndex(index);
  }, [reference.request_references, type]);

  const uploadFileApi = useCallback(
    async (
      formData: any,
      name: string,
      file: File,
      url: string,
      mime: string,
      file_id: string
    ) => {
      try {
        const { status } = await axios.put(url, file, {
          headers: { "Content-Type": mime },
          onUploadProgress: function (progressEvent) {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / (progressEvent.total || 100)
            );
            setPercentageProcess(percentCompleted);
          },
        });
        if (status === 200) {
          let referenceFormdata = {
            id: file_id,
            created_at: new Date().getTime(),
            type: formData?.type,
            reference_type: formData.reference_type,
            name: name,
            reference_form_type: formData.reference_form_type,
          };

          const preview_reference = JSON.parse(
            JSON.stringify(previewReference)
          );
          const preview_reference_documents = JSON.parse(
            JSON.stringify(preview_reference[referenceIndex].reference_forms)
          );

          preview_reference_documents.push(referenceFormdata);

          preview_reference[referenceIndex].reference_forms =
            preview_reference_documents;

          dispatch(setPreviewReference(preview_reference));
          setIsUploading(false);
          setFiles(undefined);
          setOpenUploadFile(false);
          setLoading(false);
          if (referenceIndex !== undefined) {
            const request_references = JSON.parse(
              JSON.stringify(reference.request_references)
            );
            request_references[referenceIndex].reference_documents.push(
              file_id
            );
            dispatch(
              setReference({
                ...reference,
                request_references,
              })
            );
          }
          setTimeout(
            () => setFilter((prevState) => ({ ...prevState, delay: 0 })),
            3000
          );
        }
      } catch (e) {
        setIsUploading(false);
        setLoading(false);
        dispatch(
          setNotificationMessage({
            display: true,
            severity: "error",
            message: `Unable to Upload File`,
          })
        );
      }
    },
    [dispatch, previewReference, reference, referenceIndex]
  );

  const handleFileUpload = useCallback(async () => {
    if (files && files.length !== 0) {
      setLoading(true);
      const file: File = files[0];
      const file_name = file.name.split(".");
      file_name.splice(file.name.split(".").length - 1, 1);
      const formData: {
        name: string;
        type: string;
        directory: string;
        file_name: string;
        document_type: string;
        reference_form_type: string;
        reference_type: string;
      } = {
        type: mime.getType(file.name)
          ? (mime.getType(file.name) as string)
          : file.type,
        name: `${new Date().getTime()}_${file.name}`,
        file_name: file_name.join("."),
        directory: "form",
        document_type: "reference_form",
        reference_form_type: "DOCUMENT",
        reference_type: type,
      };
      setFileName(file.name);
      setIsUploading(true);
      try {
        const { data } = await axiosInstance.post(URLS.file_upload, formData);
        await uploadFileApi(
          formData,
          file.name,
          file,
          data.url,
          mime.getType(file.name)
            ? (mime.getType(file.name) as string)
            : file.type,
          data.id
        );
      } catch (e: any) {
        setIsUploading(false);
        setLoading(false);
        dispatch(
          setNotificationMessage({
            display: true,
            severity: "error",
            message: `Unable to Upload File`,
          })
        );
      }
    } else {
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "error",
          message: `Please choose a file to upload`,
        })
      );
    }
  }, [dispatch, files, type, uploadFileApi]);

  const setAdditionalQuestionnaire = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    if (!checked) {
      if (referenceIndex !== undefined) {
        const request_references = JSON.parse(
          JSON.stringify(reference.request_references)
        );
        const preview_reference = JSON.parse(JSON.stringify(previewReference));
        request_references[referenceIndex].additional_questionnaires = [];
        preview_reference[referenceIndex].additional_questionnaires = [];
        dispatch(
          setReference({
            ...reference,
            request_references,
          })
        );
        dispatch(setPreviewReference(preview_reference));
      }
      setAdditionalFormChecked(checked);
    }
  };

  const setReferenceDocuments = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    if (!checked) {
      if (referenceIndex !== undefined) {
        const request_references = JSON.parse(
          JSON.stringify(reference.request_references)
        );
        const preview_reference = JSON.parse(JSON.stringify(previewReference));
        request_references[referenceIndex].reference_documents = [];
        preview_reference[referenceIndex].reference_documents = [];
        dispatch(
          setReference({
            ...reference,
            request_references,
          })
        );
        dispatch(setPreviewReference(preview_reference));
      }
      setReferenceFormsChecked(checked);
    }
  };

  const setKedQuestionnaire = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    const request_references = JSON.parse(
      JSON.stringify(reference.request_references)
    );
    const preview_reference = JSON.parse(JSON.stringify(previewReference));
    request_references[referenceIndex].ked_questionnaire = checked;
    preview_reference[referenceIndex].ked_questionnaire = checked;
    dispatch(
      setReference({
        ...reference,
        request_references,
      })
    );
    dispatch(setPreviewReference(preview_reference));
  };

  const viewKedQuestionnaireModal = useMemo(() => {
    const imageURLs = {
      [identifiers.character]: images.CharacterReference,
      [identifiers.academic]: images.AcademicReference,
      [identifiers.employment]: images.EmploymentReference,
    };
    return (
      <Grid container>
        <Grid item xs={12}>
          {referenceIndex !== undefined && (
            <img
              src={
                imageURLs[
                  reference.request_references[referenceIndex].type as
                    | "character"
                    | "academic"
                    | "employment"
                ]
              }
              alt="view document"
              className={"img-fluid"}
            />
          )}
        </Grid>
      </Grid>
    );
  }, [reference.request_references, referenceIndex]);

  const uploadFile = useMemo(() => {
    return (
      <Grid container padding={"10px"}>
        <Grid item xs={12}>
          <Typography
            variant="h6"
            sx={{
              color: "#5AB9F9",
              borderBottom: "3px solid #5AB9F9",
              display: "inline",
            }}
          >
            Upload File
          </Typography>
        </Grid>
        <FromDevice files={files} setFiles={setFiles} />
        {isUploading && (
          <Grid item xs={12} md={12}>
            <Typography>Uploading {fileName} File</Typography>
            <LinearProgressWithLabel
              variant="determinate"
              value={percentageProcess}
              reqNumber={100}
            />
          </Grid>
        )}
        {!isUploading && (
          <Grid item textAlign={"center"} xs={12} md={12}>
            <PrimaryButton
              disabled={isUploading}
              fullWidth={false}
              sx={{
                mt: 1,
                background: "#5AB9F9",
                color: "#ffffff",
                fontSize: 16,
                fontWeight: 500,
              }}
              onClick={handleFileUpload}
            >
              Upload File
            </PrimaryButton>
          </Grid>
        )}
      </Grid>
    );
  }, [files, isUploading, fileName, percentageProcess, handleFileUpload]);

  const openViewKedQuestionnaire = useMemo(() => {
    return (
      <Modal
        open={openView}
        setModalClose={setOpenView}
        children={viewKedQuestionnaireModal}
        title={undefined}
        size="md"
        className={undefined}
      />
    );
  }, [openView, viewKedQuestionnaireModal]);

  const UploadFilesModal = useMemo(() => {
    return (
      <Modal
        open={openUploadFile}
        setModalClose={setOpenUploadFile}
        children={uploadFile}
        title={undefined}
        size="sm"
        className={undefined}
      />
    );
  }, [openUploadFile, uploadFile]);

  return (
    <>
      {loading && <PageLoader />}
      {openViewKedQuestionnaire}
      {UploadFilesModal}
      <Grid container justifyContent="space-around" spacing={2}>
        <Grid
          item
          xs={12}
          lg={open ? 10 : 8}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Box sx={{ textAlign: "center" }}>
            <Typography
              variant="h6"
              sx={{ fontSize: "16px", marginTop: "20px" }}
            >
              Select at least one from the following types of questions you want
              to share with the referee for{" "}
              <span style={{ textTransform: "capitalize" }}>{type} </span>
              reference.
            </Typography>
          </Box>
          <Paper
            sx={{
              background: " #FFFFFF",
              border: "1px dashed #5AB9F9",
              boxShadow: "0px 4px 10px rgba(120, 144, 156, 0.1)",
              borderRadius: "12px",
              padding: "18px 26px",
              marginTop: "10px",
              width: "100%",
            }}
          >
            {open && referenceIndex !== undefined ? (
              <CreateQuestionnaire
                loading={loading}
                setLoading={setLoading}
                setFilter={setFilter}
                setExpanded={setExpanded}
                setOpen={setOpen}
                open={open}
                type={type}
                referenceIndex={referenceIndex}
              />
            ) : (
              <Stack spacing={3}>
                {referenceIndex !== undefined && (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        mt: "10px",
                        fontSize: "14px",
                        fontWeight: "500",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "18px",
                          fontWeight: "500",
                          color: "#212121",
                          textTransform: "capitalize",
                        }}
                      >
                        {type} Reference
                      </Typography>
                    </Box>
                    <Box>
                      <MuiAccordion
                        disableGutters
                        elevation={0}
                        square
                        expanded={expanded === "ked-questionnaire"}
                        sx={{
                          border: 0,
                          marginBottom: "40px",
                          "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded":
                            {
                              transform: "none",
                            },
                          "&:before": {
                            display: "none",
                          },
                        }}
                      >
                        <MuiAccordionSummary
                          sx={{
                            boxShadow: "0px 4px 10px rgba(120, 144, 156, 0.1)",
                            borderRadius: "8px",
                            border: "1px solid #E8EBFE",
                            padding: "0px 5px 0px 0px",
                            height: { xs: "100px", md: "fit-content" },
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                            }}
                          >
                            <Stack
                              sx={{ width: "100%" }}
                              direction={"row"}
                              justifyContent={"space-between"}
                              alignItems={"center"}
                            >
                              <Stack
                                flexDirection={"row"}
                                width={"calc(100% - 40px)"}
                                alignItems={"center"}
                              >
                                <Stack
                                  justifyContent={"flex-end"}
                                  flexDirection={"row"}
                                  width={"40px"}
                                >
                                  <CheckBox
                                    type={"circle"}
                                    checked={
                                      reference.request_references[
                                        referenceIndex
                                      ].ked_questionnaire
                                    }
                                    onChange={setKedQuestionnaire}
                                  />
                                </Stack>
                                <Stack direction={"column"}>
                                  <Typography
                                    sx={{
                                      color: "#45C9D0",
                                      fontWeight: 500,
                                      fontSize: { xs: "12px", md: "16px" },
                                      m: { xs: "5px", md: 1 },
                                    }}
                                  >
                                    KED Questionnaire
                                  </Typography>
                                  <Typography
                                    sx={{
                                      color: "#000000",
                                      fontWeight: 300,
                                      fontSize: { xs: "12px", md: "12px" },
                                      mx: { xs: "5px", md: 1 },
                                      display: {
                                        xs: "none",
                                        md: "inline-block",
                                      },
                                    }}
                                  >
                                    If selected, Referee will be asked questions
                                    as per KED standardized questionnaire
                                  </Typography>
                                </Stack>
                              </Stack>
                              <Typography
                                sx={{
                                  border: "1px dashed #5AB9F9",
                                  borderRadius: "5px",
                                  padding: "6px 13px",
                                  color: "white",
                                  background: "#5AB9F9",
                                  fontWeight: 500,
                                  fontSize: { xs: "12px", md: "14px" },
                                  margin: "10px 10px 10px auto",
                                  minWidth: "170px",
                                  display: { xs: "none", md: "inline" },
                                  height: "36px",
                                }}
                                data-tut={
                                  isDesktop ? "Ked_employment_questions" : ""
                                }
                                onClick={() => setOpenView(true)}
                              >
                                View Questionnaire
                              </Typography>
                            </Stack>
                            <Typography
                              sx={{
                                border: "1px dashed #5AB9F9",
                                borderRadius: "5px",
                                padding: "6px 13px",
                                color: "white",
                                background: "#5AB9F9",
                                fontWeight: 500,
                                fontSize: { xs: "12px", md: "14px" },
                                margin: "5px 0px 0px 0px",
                                display: { xs: "inline", md: "none" },
                              }}
                              data-tut={
                                !isDesktop ? "Ked_employment_questions" : ""
                              }
                              // onClick={() => setOpen(true)}
                            >
                              View Questionnaire
                            </Typography>
                          </Box>
                        </MuiAccordionSummary>
                      </MuiAccordion>
                      <MuiAccordion
                        disableGutters
                        elevation={0}
                        square
                        expanded={expanded === "additional-questionnaire"}
                        sx={{
                          border: 0,
                          marginBottom: "40px",
                          "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded":
                            {
                              transform: "none",
                            },
                          "&:before": {
                            display: "none",
                          },
                        }}
                      >
                        <MuiAccordionSummary
                          sx={{
                            boxShadow: "0px 4px 10px rgba(120, 144, 156, 0.1)",
                            borderRadius: "8px",
                            border: "1px solid #E8EBFE",
                            padding: "0px 5px 0px 0px",
                            height: { xs: "100px", md: "fit-content" },
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                            }}
                          >
                            <Stack
                              sx={{ width: "100%" }}
                              direction={"row"}
                              justifyContent={"space-between"}
                              alignItems={"center"}
                            >
                              <Stack
                                flexDirection={"row"}
                                width={"calc(100% - 40px)"}
                                alignItems={"center"}
                                onClick={() =>
                                  setExpanded(
                                    expanded === "additional-questionnaire"
                                      ? ""
                                      : "additional-questionnaire"
                                  )
                                }
                              >
                                <Stack
                                  justifyContent={"flex-end"}
                                  flexDirection={"row"}
                                  width={"40px"}
                                >
                                  <CheckBox
                                    type={"circle"}
                                    checked={
                                      reference.request_references[
                                        referenceIndex
                                      ].additional_questionnaires.length > 0 ||
                                      additionalFormChecked
                                    }
                                    onChange={setAdditionalQuestionnaire}
                                  />
                                </Stack>
                                <Stack direction={"column"}>
                                  <Typography
                                    sx={{
                                      color: "#45C9D0",
                                      fontWeight: 500,
                                      fontSize: { xs: "12px", md: "16px" },
                                      m: { xs: "5px", md: 1 },
                                    }}
                                  >
                                    Custom Question
                                  </Typography>
                                  <Typography
                                    sx={{
                                      color: "#000000",
                                      fontWeight: 300,
                                      fontSize: { xs: "12px", md: "12px" },
                                      mx: { xs: "5px", md: 1 },
                                      display: {
                                        xs: "none",
                                        md: "inline-block",
                                      },
                                    }}
                                  >
                                    Create your own questions on this digital
                                    platform.
                                  </Typography>
                                </Stack>
                              </Stack>
                              <Typography
                                sx={{
                                  border: "1px dashed #5AB9F9",
                                  borderRadius: "5px",
                                  padding: "6px 13px",
                                  color: "white",
                                  fontWeight: 500,
                                  fontSize: { xs: "12px", md: "14px" },
                                  margin: "10px 10px 10px auto",
                                  background: "#45C9D0",
                                  minWidth: "170px",
                                  display: { xs: "none", md: "inline" },
                                  height: "36px",
                                }}
                                onClick={() => setOpen(true)}
                                data-tut={isDesktop ? "own_questions" : ""}
                              >
                                Create Questionnaire
                              </Typography>
                            </Stack>
                            <Typography
                              sx={{
                                border: "1px dashed #5AB9F9",
                                borderRadius: "5px",
                                padding: "6px 13px",
                                color: "white",
                                fontWeight: 500,
                                background: "#45C9D0",
                                fontSize: { xs: "12px", md: "14px" },
                                margin: "5px 0px 0px 0px",
                                display: { xs: "inline", md: "none" },
                              }}
                              onClick={() => setOpen(true)}
                              data-tut={!isDesktop ? "own_questions" : ""}
                            >
                              Create Questionnaire
                            </Typography>
                          </Box>
                        </MuiAccordionSummary>
                        <AccordionDetails>
                          {!loading && (
                            <AdditionalQuestionnaire
                              filter={filter}
                              setFilter={setFilter}
                              additionalQuestionnaires={
                                additionalQuestionnaires
                              }
                              referenceIndex={referenceIndex}
                            />
                          )}
                        </AccordionDetails>
                      </MuiAccordion>
                      <MuiAccordion
                        disableGutters
                        elevation={0}
                        square
                        expanded={expanded === "upload-own-ref"}
                        sx={{
                          border: 0,
                          marginBottom: "40px",
                          "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded":
                            {
                              transform: "none",
                            },
                          "&:before": {
                            display: "none",
                          },
                        }}
                      >
                        <MuiAccordionSummary
                          sx={{
                            boxShadow: "0px 4px 10px rgba(120, 144, 156, 0.1)",
                            borderRadius: "8px",
                            border: "1px solid #E8EBFE",
                            padding: "0px 5px 0px 0px",
                            height: { xs: "100px", md: "fit-content" },
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                            }}
                          >
                            <Stack
                              sx={{ width: "100%" }}
                              direction={"row"}
                              justifyContent={"space-between"}
                              alignItems={"center"}
                            >
                              <Stack
                                flexDirection={"row"}
                                width={"calc(100% - 40px)"}
                                alignItems={"center"}
                                onClick={() =>
                                  setExpanded(
                                    expanded === "upload-own-ref"
                                      ? ""
                                      : "upload-own-ref"
                                  )
                                }
                              >
                                <Stack
                                  justifyContent={"flex-end"}
                                  flexDirection={"row"}
                                  width={"40px"}
                                >
                                  <CheckBox
                                    type={"circle"}
                                    checked={
                                      reference.request_references[
                                        referenceIndex
                                      ].reference_documents.length > 0 ||
                                      referenceFormsChecked
                                    }
                                    onChange={setReferenceDocuments}
                                  />
                                </Stack>
                                <Stack direction={"column"}>
                                  <Typography
                                    sx={{
                                      color: "#45C9D0",
                                      fontWeight: 500,
                                      fontSize: { xs: "12px", md: "16px" },
                                      m: { xs: "5px", md: 1 },
                                    }}
                                  >
                                    Upload your own reference form
                                  </Typography>
                                  <Typography
                                    sx={{
                                      color: "#000000",
                                      fontWeight: 300,
                                      fontSize: { xs: "12px", md: "12px" },
                                      mx: { xs: "5px", md: 1 },
                                      display: {
                                        xs: "none",
                                        md: "inline-block",
                                      },
                                    }}
                                  >
                                    Upload your own employment reference form
                                    for referee to complete.
                                  </Typography>
                                </Stack>
                              </Stack>

                              <Typography
                                sx={{
                                  border: "1px dashed #5AB9F9",
                                  borderRadius: "5px",
                                  padding: "6px 13px",
                                  color: "white",
                                  background: "#5AB9F9",
                                  fontWeight: 500,
                                  fontSize: { xs: "12px", md: "14px" },
                                  margin: "10px 10px 10px auto",
                                  minWidth: "170px",
                                  maxHeight: "36px",
                                  display: { xs: "none", md: "inline" },
                                }}
                                data-tut={isDesktop ? "own_form" : ""}
                                onClick={() => setOpenUploadFile(true)}
                              >
                                Upload File
                              </Typography>
                            </Stack>
                            <Typography
                              sx={{
                                border: "1px dashed #5AB9F9",
                                borderRadius: "5px",
                                padding: "6px 13px",
                                color: "white",
                                fontWeight: 500,
                                background: "#5AB9F9",
                                fontSize: { xs: "12px", md: "14px" },
                                margin: "5px 0px 0px 0px",
                                display: { xs: "inline", md: "none" },
                              }}
                              data-tut={!isDesktop ? "own_form" : ""}
                              onClick={() => setOpenUploadFile(true)}
                            >
                              Upload File
                            </Typography>
                          </Box>
                        </MuiAccordionSummary>
                        <AccordionDetails>
                          {!loading && (
                            <UploadOwnDocuments
                              filter={filter}
                              setFilter={setFilter}
                              referenceIndex={referenceIndex}
                              additionalReferenceDocuments={
                                additionalReferenceDocuments
                              }
                            />
                          )}
                        </AccordionDetails>
                      </MuiAccordion>
                    </Box>
                  </>
                )}
              </Stack>
            )}
          </Paper>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            marginTop: "35px",
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <PrimaryButton
            fullWidth={false}
            sx={{
              width: "212px",
              height: "36px",
              background: "#FFFFFF",
              border: " 1px solid #5AB9F9",
              borderRadius: "5px",
              padding: "4px 44px",
              color: "#5AB9F9",
              fontSize: "14px",
              margin: "0px 10px 0px 0px",
            }}
            onClick={() => {
              dispatch(setValue(1));
              handleBack();
            }}
          >
            Go back
          </PrimaryButton>
          <PrimaryButton
            fullWidth={false}
            disabled={
              !reference.request_references[referenceIndex].ked_questionnaire &&
              reference.request_references[referenceIndex]
                .additional_questionnaires.length === 0 &&
              reference.request_references[referenceIndex].reference_documents
                .length === 0
            }
            sx={{
              width: "250px",
              height: "36px",
              background: " #5AB9F9",
              boxShadow: "0px 10px 25px rgba(90, 185, 249, 0.25)",
              borderRadius: "6px",
              color: "white",
              minWidth: "150px",
            }}
            onClick={() => {
              //dispatch(setReference({ ...reference, reference_created: true }));
              //dispatch(setValue(1));
              if (activeStep === 3) {
                dispatch(
                  setReference({
                    ...reference,
                    reference_created: true,
                    reference_editing: false,
                  })
                );
              }
              handleNext();
            }}
          >
            {activeStep === 3 ? "Preview Request" : "Next"}
          </PrimaryButton>
        </Grid>
      </Grid>
    </>
  );
};

export default Questionnaire;
