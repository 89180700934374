import {
  Button,
  Card,
  CircularProgress,
  Grid,
  Stack,
  TextFieldProps,
  Typography,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  // TableFooter,
  TableHead,
  // TablePagination,
  TableRow,
  Paper,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PrimaryButton from "../../../../../../../components/Button/PrimaryButton";
import {
  Reload,
  setName,
  setNotificationMessage,
  setReload,
  setRequestData,
  setValue,
  VettingDocuments,
  uCheckStatus,
} from "../../../../../../../utils/redux";
import Modal from "../../../../../../../components/modal/Modal";
import axiosInstance from "../../../../../../../utils/axios";
import { URLS } from "../../../../../../../utils/constants/urls";
import { identifiers } from "../../../../../../../utils/constants/identifiers";
import { Document, Page } from "react-pdf";
import { useParams } from "react-router-dom";
import InputField from "../../../../../../../components/InputField";
import dayjs from "dayjs";
import { Validators } from "../../../../../../../utils/validators";
import DateInput from "../../../../../../../components/DateInput/DateInput";
import { Delete, Download, Visibility } from "@mui/icons-material";
import { images } from "../../../../../../../utils/constants/images";
import { Box } from "@mui/system";
import UCheckForm from "./UCheck/UCheckForm";

interface InformationProps {
  active: number;
  comments?: string;
}

const Information: FC<InformationProps> = ({ active, comments }) => {
  const dispatch = useDispatch();
  const { id } = useParams() as any;
  const reload = useSelector(Reload);
  const status = useSelector(uCheckStatus);
  const documents = useSelector(VettingDocuments);

  const [loading, setLoading] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState(false);
  const [document, setDocument] = useState<string | null>(null);
  const [documentType, setDocumentType] = useState<"pdf" | "image">();
  const [documentUrl, setDocumentUrl] = useState<string>();
  const [viewDocument, setViewDocument] = useState(false);
  const [numPages, setNumPages] = useState<number | null>(null);
  const [dob, setDob] = useState<string>();
  const [cNumber, setCNumber] = useState<string>();
  const [viewPopUpModal, setViewPopUpModal] = useState(false);

  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#F5FBFF",
      color: "#727272",
      border: "none",
      maxWidth: "300px",
      width: "300px",
    },
    [`&.${tableCellClasses.body}`]: {
      borderBottom: "none",
      color: "#263238",
      fontSize: "14px",
      fontWeight: 600,
    },
    [`&.${tableCellClasses.head}:first-of-type`]: {
      borderTopLeftRadius: "4px",
      borderBottomLeftRadius: "4px",
    },
    [`&.${tableCellClasses.head}:last-of-type`]: {
      borderTopRightRadius: "4px",
      borderBottomRightRadius: "4px",
    },
    [`&.${tableCellClasses.head}:nth-of-type(2)`]: {
      maxWidth: "200px",
      width: "200px",
    },
    [`&.${tableCellClasses.head}:nth-of-type(5)`]: {
      maxWidth: "200px",
      width: "200px",
    },
    [`&.${tableCellClasses.head}:nth-of-type(6)`]: {
      maxWidth: "180px",
      width: "180px",
    },
    [`&.${tableCellClasses.head}:last-of-type`]: {
      width: "3em",
      maxWidth: "3em",
      textAlign: "center",
    },
    [`&.${tableCellClasses.body}:first-of-type`]: {
      borderRadius: "12px",
      background: "white",
      padding: "5px 10px",
    },
    [`&.${tableCellClasses.body}:last-of-type`]: {
      borderTopRightRadius: "12px",
      borderBottomRightRadius: "12px",
      padding: "5px 10px",
    },
  }));

  const StyledTableRow = styled(TableRow)(() => ({
    "&": {
      backgroundColor: "#f9fbfc",
      //paddingBottom: "15px",
      //paddingTop: "15px",
    },
  }));
  useEffect(() => {
    setDob(documents[active].dbs_dob);
    setCNumber(documents[active].dbs_cnumber);
  }, [active, documents]);

  const download = async (document: string, view: boolean) => {
    try {
      const { data } = await axiosInstance.get(`${URLS.document}/${document}`);
      const type =
        data.mimetype.indexOf("pdf") > -1
          ? "pdf"
          : data.mimetype.indexOf("image") > -1
          ? "image"
          : null;
      if (!!type && view) {
        setDocumentType(type);
        setDocumentUrl(data.url);
        setViewDocument(true);
      } else {
        window.open(data.url, "_blank");
      }
    } catch (e) {
      console.log(e);
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "error",
          message: "There is an error in getting document Link ",
        })
      );
    }
  };

  const unlinkDocument = async (document: string) => {
    setDocument(document);
    setOpenModal(true);
  };

  const saveData = async () => {
    if (!!dob || !!cNumber) {
      try {
        setLoading(true);
        await axiosInstance.patch(`${URLS.requests}/${id}/document`, {
          dbs_cnumber: cNumber,
          dbs_dob: dob,
          vetting_doc_id: documents[active].id,
        });
        setLoading(false);
        const both: boolean = !!dob && !!cNumber;
        dispatch(
          setNotificationMessage({
            display: true,
            severity: "success",
            message: `${
              both
                ? "C-Number & Date of Birth"
                : !!dob
                ? "Date of Birth"
                : "C-Number"
            } saved successfully`,
          })
        );
        dispatch(setReload(!reload));
      } catch (e: any) {
        setLoading(false);
        console.log(e);
        dispatch(
          setNotificationMessage({
            display: true,
            severity: "error",
            message: "There is an error in saving the document",
          })
        );
      }
    } else {
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "error",
          message: "Please enter DOB / C-Number",
        })
      );
    }
  };

  const confirmDelete = useCallback(async () => {
    try {
      setLoading(true);
      if (
        documents[active].name === identifiers.cv ||
        documents[active].name === identifiers.opc ||
        documents[active].name === identifiers.dbs
      ) {
        await axiosInstance.post(
          `${URLS.unlink_document}?stage=${process.env.REACT_APP_YOTI_ENV}`,
          {
            request_id: id,
            vetting_document_id: documents[active].id,
          }
        );
      } else {
        await axiosInstance.post(
          `${URLS.unlink_document}?stage=${process.env.REACT_APP_YOTI_ENV}`,
          {
            request_id: id,
            sub_document_id: document,
          }
        );
      }
      dispatch(setReload(!reload));
      if (
        documents[active].name === identifiers.cv ||
        documents[active].name === identifiers.opc
      ) {
        dispatch(setValue(1));
        dispatch(setName(""));
      }
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "success",
          message: `DBS Certificate has been removed Successfully`,
        })
      );
      setLoading(false);
      setOpenModal(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "error",
          message: "There is an error in removing the document",
        })
      );
      setOpenModal(false);
    }
  }, [active, dispatch, document, documents, id, reload]);

  const deleteModalText = useMemo(() => {
    return (
      <Grid container>
        <Grid item>
          <Typography
            variant="h6"
            sx={{
              marginBottom: "20px",
              fontSize: 18,
              fontWeight: 500,
              color: "#000000",
              textAlign: "center",
              letterSpacing: "0.15px",
            }}
          >
            Remove DBS Certificate from Request?
          </Typography>
          <Typography
            variant="h6"
            sx={{
              mb: 3,
              fontSize: 16,
              fontWeight: 500,
              color: "#727272",
            }}
          >
            DBS Certificate will still be available in your Document Repository.
          </Typography>
          <Typography
            variant="h6"
            sx={{
              mb: 3,
              fontSize: 16,
              fontWeight: 500,
              color: "#727272",
            }}
          >
            Are you sure?
          </Typography>
        </Grid>
        <Grid container alignItems="right" justifyContent="right">
          <Button
            sx={{
              border: "none",
              color: "#727272",
              fontSize: 16,
              fontWeight: 500,
              mr: 2,
              mt: 1,
              p: "10px",
            }}
            disabled={loading}
            onClick={confirmDelete}
          >
            {loading && <CircularProgress sx={{ mr: 1 }} />}Remove Document
          </Button>
          <PrimaryButton
            disabled={loading}
            fullWidth={false}
            sx={{
              mt: 1,
              background: "#5AB9F9",
              color: "#ffffff",
              fontSize: 16,
              fontWeight: 500,
            }}
            onClick={() => {
              setOpenModal(false);
              setDocument(null);
            }}
          >
            Cancel
          </PrimaryButton>
        </Grid>
      </Grid>
    );
  }, [confirmDelete, loading]);

  const viewModalText = useMemo(() => {
    return (
      <Grid container>
        <Grid item>
          {documentType === "pdf" && (
            <Document
              file={{ url: documentUrl }}
              onLoadSuccess={({ numPages }) => setNumPages(numPages)}
            >
              {Array.from(new Array(numPages), (el, index) => (
                <Page key={`page_${index + 1}`} pageNumber={index + 1} />
              ))}
            </Document>
          )}
          {documentType === "image" && (
            <img src={documentUrl} alt={"document"} className={"img-fluid"} />
          )}
        </Grid>
      </Grid>
    );
  }, [documentType, documentUrl, numPages]);

  const deleteModal = useMemo(() => {
    return (
      <Modal
        open={openModal}
        setModalClose={setOpenModal}
        children={deleteModalText}
        title={undefined}
        size="sm"
        className={undefined}
      />
    );
  }, [openModal, deleteModalText]);

  const openViewModal = useMemo(() => {
    return (
      <Modal
        open={viewDocument}
        setModalClose={setViewDocument}
        children={viewModalText}
        title={undefined}
        size="sm"
        className={undefined}
      />
    );
  }, [viewDocument, viewModalText]);

  const viewUCheckModalText = useMemo(() => {
    return (
      <UCheckForm
        type={documents[active].sub_documents[0].name}
        setViewPopUpModal={setViewPopUpModal}
      />
    );
  }, [active, documents]);

  const openUCheckViewModal = useMemo(() => {
    return (
      <Modal
        open={viewPopUpModal}
        setModalClose={setViewPopUpModal}
        children={viewUCheckModalText}
        title={undefined}
        size="xl"
        padding="10px"
        Radius={"15px"}
        isBackdrop={false}
        className={undefined}
      />
    );
  }, [viewPopUpModal, viewUCheckModalText]);

  return (
    <>
      {openUCheckViewModal}
      <Grid container justifyContent={"center"}>
        {deleteModal}
        {openViewModal}
        <Grid
          item
          xs={12}
          md={4}
          sx={{ margin: { xs: "0px", md: "55px 10px 0px" } }}
        >
          <Typography
            variant={"body1"}
            color={"#333333"}
            sx={{
              fontSize: "18px",
              fontWeight: "400",
              letterSpacing: 0.15,
              marginBottom: "10px",
              textAlign: "center",
            }}
          >
            C Number
          </Typography>
          <Card
            sx={{
              boxShadow: "none",
              position: "relative",
              background: "#FFFFFF",
              border: "1px dashed #5AB9F9",
              borderRadius: "12px",
              p: "30px",
            }}
          >
            <Typography
              variant={"body2"}
              color={"#727272"}
              sx={{
                fontWeight: 400,
                fontSize: "14px",
                textAlign: "center",
                mb: 2,
              }}
            >
              Enter Number
            </Typography>
            <InputField
              textChange={setCNumber}
              inputValue={cNumber}
              type={"text"}
              className={"py-0 mb-0"}
              sx={{
                backgroundColor: "#FFFFFF",
              }}
              inputProps={{
                sx: {
                  py: 1,
                  fontWeight: "bold",
                },
              }}
              validators={[
                {
                  check: Validators.required,
                  message: "This field is required",
                },
              ]}
            />
          </Card>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          sx={{ margin: { xs: "0px", md: "55px 0px 0px" } }}
        >
          <Typography
            variant={"body1"}
            color={"#333333"}
            sx={{
              fontSize: "18px",
              fontWeight: "400",
              letterSpacing: 0.15,
              marginBottom: "10px",
              textAlign: "center",
            }}
          >
            Date Of Birth
          </Typography>
          <Card
            sx={{
              boxShadow: "none",
              position: "relative",
              background: "#FFFFFF",
              border: "1px dashed #5AB9F9",
              borderRadius: "12px",
              p: "30px",
            }}
          >
            <Typography
              variant={"body2"}
              color={"#727272"}
              sx={{
                fontWeight: 400,
                fontSize: "14px",
                textAlign: "center",
                mb: 2,
              }}
            >
              Enter DOB
            </Typography>
            <DateInput
              field={"date"}
              disableFuture={true}
              textChange={setDob}
              inputFormat={identifiers.date_format as string}
              onChange={(e) => e}
              value={dob || null}
              renderInput={(params: TextFieldProps) => {
                return (
                  <InputField
                    {...params}
                    className={"py-0 mb-0"}
                    inputProps={{
                      ...params.inputProps,
                      sx: {
                        py: 1,
                        fontWeight: "bold",
                        backgroundColor: "#FFFFFF",
                      },
                    }}
                    InputProps={{
                      ...params.InputProps,
                      sx: {
                        backgroundColor: "#FFFFFF",
                      },
                    }}
                    // fieldError={dateError ? dateError.message : ""}
                    // hasError={dateError ? dateError.error : false}
                  />
                );
              }}
              validators={[
                {
                  check: Validators.required,
                  message: "This field is required",
                },
                {
                  check: Validators.maxDate,
                  message: "Please enter valid Date",
                  num: new Date(
                    dayjs(new Date()).format("YYYY-MM-DD 00:00:00.000")
                  ).getTime(),
                },
              ]}
            />
          </Card>
        </Grid>
        <Grid item xs={12} textAlign={"center"} sx={{ mt: 3, mb: 5 }}>
          <PrimaryButton
            fullWidth={false}
            sx={{
              width: "212px",
              height: "36px",
              background: " #5AB9F9",
              boxShadow: "0px 10px 25px rgba(90, 185, 249, 0.25)",
              borderRadius: "6px",
              color: "#ffffff",
            }}
            disabled={loading}
            onClick={saveData}
          >
            {loading && <CircularProgress sx={{ mr: 1 }} size={20} />}
            Save
          </PrimaryButton>
        </Grid>
        {!!documents[active].dbs_certificate_repo_id && (
          <Grid item xs={10} textAlign={"center"} sx={{ mt: 3, mb: 5 }}>
            <TableContainer
              component={Paper}
              sx={{ boxShadow: "none", backgroundColor: "#f5fbff" }}
            >
              <Table
                sx={{
                  minWidth: 600,
                  borderSpacing: "0 1rem",
                  borderCollapse: "separate",
                }}
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Document Name</StyledTableCell>
                    <StyledTableCell>Action</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody className={"request-table-body"}>
                  <StyledTableRow className={"request-table-body-row"}>
                    <StyledTableCell
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        minHeight: "77px",
                      }}
                    >
                      DBS Certificate
                      {documents[active].approval_status === "REJECTED" && (
                        <Tooltip
                          title={
                            "There is a problem with document reported by agency please upload a new one"
                          }
                          arrow
                        >
                          <img src={images.tooltip} alt={"tooltip"} />
                        </Tooltip>
                      )}
                    </StyledTableCell>

                    <StyledTableCell>
                      <div className="action-btn-groups">
                        <Stack direction={"row"}>
                          <PrimaryButton
                            sx={{
                              height: "36px",
                              background: " #5AB9F9",
                              boxShadow:
                                "0px 10px 25px rgba(90, 185, 249, 0.25)",
                              borderRadius: "6px",
                              color: "white",
                              minWidth: "45px",
                            }}
                            disabled={loading}
                            onClick={() =>
                              download(
                                documents[active]
                                  .dbs_certificate_repo_id as string,
                                true
                              )
                            }
                          >
                            <Visibility sx={{ color: "#ffffff" }} />
                          </PrimaryButton>
                          <PrimaryButton
                            sx={{
                              height: "36px",
                              background: " #5AB9F9",
                              boxShadow:
                                "0px 10px 25px rgba(90, 185, 249, 0.25)",
                              borderRadius: "6px",
                              color: "white",
                              mx: 3,
                              minWidth: "45px",
                            }}
                            disabled={loading}
                            onClick={() =>
                              download(
                                documents[active]
                                  .dbs_certificate_repo_id as string,
                                false
                              )
                            }
                          >
                            <Download sx={{ color: "#ffffff" }} />
                          </PrimaryButton>
                          <PrimaryButton
                            sx={{
                              height: "36px",
                              background: " #5AB9F9",
                              boxShadow:
                                "0px 10px 25px rgba(90, 185, 249, 0.25)",
                              borderRadius: "6px",
                              color: "white",
                              minWidth: "45px",
                            }}
                            disabled={loading}
                            onClick={() =>
                              unlinkDocument(
                                documents[active]
                                  .dbs_certificate_repo_id as string
                              )
                            }
                          >
                            <Delete sx={{ color: "#ffffff" }} />
                          </PrimaryButton>
                        </Stack>
                      </div>
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )}

        {!!comments && (
          <Grid
            xs={8}
            className="custom-card-dbs content-card"
            sx={{ marginTop: "25px" }}
          >
            <Typography
              variant={"body1"}
              color={"#333333"}
              sx={{
                mb: 1,
                fontSize: "14px",
                fontWeight: "500",
                letterSpacing: 0.15,
                marginBottom: "8px",
                textAlign: "center",
              }}
            >
              Custom Message
            </Typography>
            <Typography
              variant={"body1"}
              color={"#727272"}
              sx={{
                fontSize: "12px",
                fontWeight: "400",
                letterSpacing: 0.15,
                marginBottom: "10px",
                lineHeight: "24px",
              }}
            >
              {comments}
            </Typography>
          </Grid>
        )}
        <Grid item md={10} mb={2}>
          {status === "SUBMITTED" && (
            <Stack
              direction={"row"}
              alignItems="center"
              sx={{
                background: "#279b4eb0",
                color: "black",
                borderRadius: "10px",
                padding: "10px",
                border: "1px dashed #45C9D0",
              }}
            >
              <img src={images.successIcon} alt="success icon" />
              <Box sx={{ ml: 2 }}>
                Your DBS application has been submitted. Once you have received
                your physical copy of DBS, please upload a clear copy of the
                front and back side on this page itself.
              </Box>
            </Stack>
          )}
          {status === "IN_REVIEW" && (
            <Stack
              direction={"row"}
              alignItems="center"
              sx={{
                background: "#edd294",
                color: "black",
                borderRadius: "10px",
                padding: "10px",
                border: "1px dashed #EF9F02",
              }}
            >
              <img src={images.successIcon} alt="success icon" />
              <Box sx={{ ml: 2 }}>
                Thank you for completing your DBS Application. Your application
                is under review by the Compliance Officer. If you want to make
                changes in Form
                <span
                  style={{
                    textDecoration: "underline",
                    cursor: "pointer",
                    paddingLeft: "5px",
                  }}
                  onClick={() => setViewPopUpModal(true)}
                >
                  Click here
                </span>{" "}
              </Box>
            </Stack>
          )}
          {status === "REJECTED" && (
            <Stack
              direction={"row"}
              alignItems="center"
              sx={{
                background: "#E56D6D",
                color: "black",
                borderRadius: "10px",
                padding: "10px",
                border: "1px dashed #EB0000",
              }}
            >
              <img src={images.tooltip} alt="success icon" />
              <Box sx={{ ml: 2 }}>
                Your DBS Application has some issues. Please review the changes
                by{" "}
                <span
                  style={{ textDecoration: "underline", cursor: "pointer" }}
                  onClick={() => setViewPopUpModal(true)}
                >
                  clicking here
                </span>{" "}
                and make changes on your application
              </Box>
            </Stack>
          )}
        </Grid>
        {(status !== "SUBMITTED" &&
          status !== "IN_REVIEW" &&
          status !== "REJECTED") && (
          <Grid
            item
            display={"flex"}
            justifyContent={"center"}
            //className="card-footer"
            xs={12}
            md={12}
            sx={{
              px: 3,
              py: 1,
              background: "#e9f6ff",
              borderRadius: "0px 0px 15px 15px",
            }}
          >
            <PrimaryButton
              fullWidth={false}
              sx={{
                width: "212px",
                height: "36px",
                background: " #5AB9F9",
                boxShadow: "0px 10px 25px rgba(90, 185, 249, 0.25)",
                borderRadius: "6px",
                color: "white",
              }}
              onClick={() => {
                dispatch(setValue(3));
                dispatch(setName(" : DBS Certificate"));
                dispatch(
                  setRequestData({
                    request_id: id,
                    vetting_document_id: documents[active].id,
                    document_repo_id: "",
                  })
                );
              }}
            >
              Upload Certificate
            </PrimaryButton>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default Information;
