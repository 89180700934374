import React, { FC } from "react";
import { Box, Grid, TextFieldProps, Typography } from "@mui/material";
import InputField from "../../../../../components/InputField";
import { Edit } from "@mui/icons-material";
import dayjs from "dayjs";
import { CopyToClipboard } from "react-copy-to-clipboard";
import PrimaryButton from "../../../../../components/Button/PrimaryButton";
import { useNavigate } from "react-router-dom";
import { Validators } from "../../../../../utils/validators";
import { identifiers } from "../../../../../utils/constants/identifiers";
import DateInput from "../../../../../components/DateInput/DateInput";
import { useDispatch, useSelector } from "react-redux";
import { IsEditable, setNotificationMessage } from "../../../../../utils/redux";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

interface TopSectionProps {
  requestId: string;
  requestDetails: {
    [key: string]: string | { error: boolean; message: string } | undefined;
  };
  setData: (
    value: string,
    field: string,
    error: { error: boolean; message: string } | undefined
  ) => void;
  status: boolean;
  params: any;
  publish: (status: boolean) => void;
  loading: boolean;
  showCandidate: boolean;
}

const TopSection: FC<TopSectionProps> = ({
  requestId,
  requestDetails,
  setData,
  status,
  params,
  publish,
  loading,
  showCandidate,
}) => {
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const minDate: number = new Date(
    dayjs(new Date()).format("YYYY-MM-DD 00:00:00.000")
  ).getTime();
  const isEditable = useSelector(IsEditable);

  const navigate = () => {
    if (showCandidate) {
      Navigate("");
    } else {
      Navigate("applicants");
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item md={8}>
        <Grid
          container
          spacing={2}
          sx={{
            flexDirection: "row",
            flexWrap: { xs: "nowrap", md: "nowrap" },
            width: { xs: "88vw", sm: "90vw", md: "90vw", lg: "100%" },
            overflowX: { xs: "scroll", md: "scroll", lg: "visible" },
          }}
        >
          <Grid item md={3}>
            <Box
              sx={{
                backgroundColor: "#F5FBFF",
                px: 2,
                paddingTop: "13px",
                paddingBottom: "13px",
                minWidth: { xs: "200px", md: "100px" },
              }}
            >
              <Typography
                variant={"subtitle1"}
                gutterBottom={true}
                className="request-details-box-heading"
              >
                Request ID
              </Typography>
              <Typography
                variant={"h6"}
                sx={{ fontWeight: "bold" }}
                className="request-details-box"
              >
                {requestId}
              </Typography>
            </Box>
          </Grid>
          <Grid item md={3}>
            <Box
              sx={{
                backgroundColor: "#F5FBFF",
                px: 2,
                py: 1,
                minWidth: { xs: "200px", md: "100px" },
              }}
            >
              <Typography
                variant={"subtitle1"}
                gutterBottom={true}
                className="request-details-box-heading"
              >
                Hiring Company
              </Typography>
              <InputField
                field={"company"}
                textChange={setData}
                inputValue={requestDetails.company as string}
                type={"text"}
                sx={{
                  backgroundColor: "#FFFFFF",
                }}
                className={"py-0 mb-0"}
                inputProps={{
                  sx: {
                    py: 1,
                    fontWeight: "bold",
                  },
                }}
                InputProps={{
                  endAdornment: <Edit />,
                }}
                validators={[
                  {
                    check: Validators.required,
                    message: "This field is required",
                  },
                ]}
                disabled={!isEditable}
              />
            </Box>
          </Grid>
          <Grid item md={3}>
            <Box
              sx={{
                backgroundColor: "#F5FBFF",
                px: 2,
                py: 1,
                minWidth: { xs: "200px", md: "100px" },
              }}
            >
              <Typography
                variant={"subtitle1"}
                gutterBottom={true}
                className="request-details-box-heading"
              >
                Job Role
              </Typography>
              <InputField
                field={"designation"}
                textChange={setData}
                inputValue={requestDetails.designation as string}
                type={"text"}
                className={"py-0 mb-0"}
                sx={{
                  backgroundColor: "#FFFFFF",
                }}
                inputProps={{
                  sx: {
                    py: 1,
                    fontWeight: "bold",
                  },
                }}
                InputProps={{
                  endAdornment: <Edit />,
                }}
                validators={[
                  {
                    check: Validators.required,
                    message: "This field is required",
                  },
                ]}
                disabled={!isEditable}
              />
            </Box>
          </Grid>
          <Grid item md={3}>
            <Box
              sx={{
                backgroundColor: "#F5FBFF",
                px: 2,
                py: 1,
                minWidth: { xs: "200px", md: "100px" },
              }}
            >
              <Typography
                variant={"subtitle1"}
                gutterBottom={true}
                className="request-details-box-heading"
              >
                Last Date
              </Typography>
              <DateInput
                field={"date"}
                disablePast={true}
                textChange={setData}
                inputFormat={identifiers.date_format as string}
                onChange={(e) => e}
                value={
                  requestDetails.date
                    ? dayjs(new Date(requestDetails.date as string))
                    : null
                }
                renderInput={(params: TextFieldProps) => {
                  const dateError = requestDetails.dateError as
                    | { error: boolean; message: string }
                    | undefined;
                  return (
                    <InputField
                      {...params}
                      className={"py-0 mb-0"}
                      inputProps={{
                        ...params.inputProps,
                        sx: {
                          py: 1,
                          fontWeight: "bold",
                          backgroundColor: "#FFFFFF",
                        },
                      }}
                      InputProps={{
                        ...params.InputProps,
                        sx: {
                          backgroundColor: "#FFFFFF",
                        },
                      }}
                      fieldError={dateError ? dateError.message : ""}
                      hasError={dateError ? dateError.error : false}
                    />
                  );
                }}
                validators={[
                  {
                    check: Validators.minDate,
                    message: "Please enter valid Date",
                    num: minDate,
                  },
                ]}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={12} lg={4} className="text-right btn-groups">
        {status && (
          <CopyToClipboard
            text={window.location.origin + "/requests/" + params.id}
            onCopy={() =>
              dispatch(
                setNotificationMessage({
                  display: true,
                  severity: "success",
                  message: "Request Link Copied to Clipboard",
                })
              )
            }
          >
            <PrimaryButton
              sx={{
                border: "none",
                color: "#727272",
                fontSize: 16,
                fontWeight: 500,
                mt: 1,
                p: "10px",
              }}
              variant={"text"}
              fullWidth={false}
            >
              <ContentCopyIcon sx={{ color: "#5AB9F9" }} />
            </PrimaryButton>
          </CopyToClipboard>
        )}

        {!showCandidate && (
          <>
            {!status && (
              <PrimaryButton
                sx={{
                  border: "1px solid #5AB9F9 ",
                  color: "#5AB9F9",
                  fontSize: 16,
                  fontWeight: 500,
                  mr: 2,
                  mt: 1,
                  p: "10px",
                }}
                variant={"text"}
                fullWidth={false}
                onClick={() => publish(true)}
              >
                Save as Draft
              </PrimaryButton>
            )}
            <PrimaryButton
              disabled={loading}
              data-tut="publish_btn"
              fullWidth={false}
              sx={{
                mt: 1,
                background: "#5AB9F9",
                color: "#ffffff",
                fontSize: 16,
                fontWeight: 500,
              }}
              onClick={() => publish(false)}
            >
              {!status ? "Publish" : "Update"} Request
            </PrimaryButton>
          </>
        )}
        {status && !isEditable && (
          <PrimaryButton
            sx={{
              mt: 1,
              ml: 1,
              border: "1px solid #5AB9F9 ",
            }}
            variant="text"
            fullWidth={false}
            onClick={navigate}
          >
            {showCandidate ? "Hide " : "Show "}Applicants
          </PrimaryButton>
        )}
      </Grid>
    </Grid>
  );
};

export default TopSection;
