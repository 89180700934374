import React, { FC, useCallback, useState, useEffect } from "react";
import {
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import DocumentList from "../DocumentList";
import {
  SubDocument,
  VettingDocument,
  DBSWorkforceType,
  DBSRBDetails,
} from "../RequestDetailsModel";
import CheckBox from "../../../../../components/CheckBox";
import { useDispatch, useSelector } from "react-redux";
import { IsEditable, setVettingDocuments } from "../../../../../utils/redux";
import { identifiers } from "../../../../../utils/constants/identifiers";
import {
  User,
  setUserData,
} from "../../../../../utils/redux/reducer/authentication-slice";
import { useTour } from "@reactour/tour";
import { tourStep } from "../../../../../utils/constants/coachMarks";
import * as Amplify from "../../../../../utils/services/amplify";
import { CognitoUser } from "amazon-cognito-identity-js";

interface VettingDocumentsProps {
  documents: VettingDocument[];
}

const VettingDocuments: FC<VettingDocumentsProps> = ({ documents }) => {
  const dispatch = useDispatch();
  const [active, setActive] = useState<number>(0);
  const isEditable = useSelector(IsEditable);

  const { setIsOpen, currentStep, setCurrentStep } = useTour();
  const user: { [key: string]: string } = useSelector(User) as {
    [key: string]: string;
  };

  const setCoachMarksValue = useCallback(async () => {
    //if (!!user && user["custom:reference_tutorial"] !== "true") {
    const currentUser: CognitoUser = await Amplify.UserDetail();
    const body = {
      "custom:vetting_docs": "true",
    };
    await Amplify.UpdateUserDetails(body, currentUser);
    await Amplify.RefreshSession();
    dispatch(setUserData({ ...user, ...body }));
    //}
  }, [user, dispatch]);

  useEffect(() => {
    if (!!user && user["custom:vetting_docs"] !== "true") {
      setTimeout(() => {
        setCurrentStep(tourStep.vetting_docs.index);
      }, 1000);
    }
  }, [setCurrentStep, user]);

  useEffect(() => {
    if (tourStep.vetting_docs.index === currentStep) {
      setIsOpen(true);
    } else if (
      currentStep ===
      tourStep.vetting_docs.index + tourStep.vetting_docs.steps
    ) {
      setIsOpen(false);
      if (!!user && user["custom:vetting_docs"] !== "true") {
        (async () => {
          await setCoachMarksValue();
        })();
      }
    }
  }, [currentStep, setCoachMarksValue, setIsOpen, user]);

  const handleToggle = (item: VettingDocument) => {
    const document: VettingDocument = JSON.parse(JSON.stringify(item));
    if (document.checked) {
      document.checked = false;
      if (document.sub_documents) {
        document.sub_documents.forEach((sub_document) => {
          sub_document.checked = false;
        });
      }
      if (document.dbs_workforce_type) {
        document.dbs_workforce_type.forEach((type: DBSWorkforceType) => {
          type.checked = false;
        });
      }
      if (document.dbs_rb_details) {
        document.dbs_rb_details.forEach((type: DBSRBDetails) => {
          type.value = "";
        });
      }
    } else {
      document.checked = true;
      if (document.sub_documents) {
        document.sub_documents.forEach(
          (subDocument: SubDocument, index: number) => {
            if (document.type === "single") {
              if (index === 0) {
                subDocument.checked = true;
              }
            } else {
              subDocument.checked = true;
              subDocument.value = subDocument.default;
            }
          }
        );
      }
      if (document.dbs_workforce_type) {
        document.dbs_workforce_type.forEach((type: DBSWorkforceType) => {
          if (type.label === "Other Workforce") {
            type.checked = true;
          }
        });
      }
      if (document.dbs_rb_details) {
        document.dbs_rb_details.forEach((type: DBSRBDetails) => {
          type.value = "n";
        });
      }
    }
    const vettingDocuments = JSON.parse(JSON.stringify(documents));
    vettingDocuments[document.index] = document;
    dispatch(setVettingDocuments(vettingDocuments));
  };

  const handleActive = (item: VettingDocument) => {
    if (item?.sub_documents?.length > 0) {
      setActive(item.candidate_index);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item md={12} lg={4}>
        <List
          dense
          sx={{
            width: "100%",
            bgcolor: "background.paper",
            p: 0,
          }}
        >
          {documents?.map((item) => {
            return (
              <ListItem
                key={item.index}
                sx={{ px: { xs: 0, md: 3 } }}
                disablePadding
              >
                <ListItemIcon
                  data-tut={
                    item.name === identifiers.rtw ? "selection_radio_btns" : ""
                  }
                >
                  <CheckBox
                    style={{ zoom: 0.8, marginBottom: "8px" }}
                    type={"square"}
                    checked={item.checked}
                    disabled={!isEditable}
                    onChange={() => handleToggle(item)}
                  />
                </ListItemIcon>
                <ListItemButton onClick={() => handleActive(item)}>
                  <ListItemText
                    data-tut={
                      item.name === identifiers.rtw
                        ? "right_to_work_tab"
                        : item.name === identifiers.poa
                        ? "proof_of_address_tab"
                        : item.name === identifiers.dbs
                        ? "dbs_check_tab"
                        : ""
                    }
                    sx={{
                      background:
                        item.index === active
                          ? "#E3F4FF"
                          : item.checked
                          ? "#F5FBFF"
                          : "#FFFFFF",
                      border: "1px solid rgba(90, 185, 249, 0.25)",
                      borderRadius: "8px",
                      margin: "0 0 10px 0",
                      padding: "8px 20px",
                    }}
                    primary={
                      <Typography
                        variant={"body2"}
                        fontWeight={500}
                        color={"#212121"}
                        fontSize={14}
                      >
                        {item.name}
                      </Typography>
                    }
                    secondary={
                      <Typography
                        variant={"body2"}
                        fontWeight={400}
                        color={"#333333"}
                        fontSize={14}
                      >
                        {item.name === identifiers.cv
                          ? "Upload your latest CV"
                          : item.sub_text}
                      </Typography>
                    }
                  />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </Grid>
      {documents.length > 0 && (
        <Grid item xs={12} md={12} lg={8}>
          <DocumentList documents={documents} active={active} />
        </Grid>
      )}
    </Grid>
  );
};

export default VettingDocuments;
