import { createSlice } from '@reduxjs/toolkit';

export interface PersonalInformation {
  title: string;
  forename: string;
  middlename_1: string;
  middlename_2: string;
  middlename_3: string;
  present_surname: string;
  date_of_birth: string;
  gender: string;
  contact_email: string;
  birth_nationality: string;
  birth_county: string;
  birth_country: string;
  birth_town: string;
  mother_maidenname: string;
  contact_number: string;
  has_nin: string;
  nin: string;
  form_valid: boolean;
}

export interface OtherNames {
  other_name_type: 'surname' | 'forename' | '';
  forename: string;
  surname: string;
  used_from: string;
  used_to: string;
}

export interface PersonalHistory {
  nationality_change: 'y' | 'n' | '';
  current_nationality: string;
  unspent_convictions: 'y' | 'n' | '';
  surname_changed: 'y' | 'n' | '';
  has_other_name: 'y' | 'n' | '';
  birth_surname: string;
  birth_forename: string;
  birth_surname_until: string;
  other_names: OtherNames[];
  form1_valid: boolean;
  form2_valid: boolean;
}

export interface PassportInformation {
  has_passport: 'y' | 'n' | '';
  passport_number: string;
  passport_dob: string;
  passport_issue_date: string;
  passport_nationality: string;
  form_valid: boolean;
}

export interface DrivingLicenceInformation {
  uk_driving_licence: 'y' | 'n' | '';
  driver_licence_number: string;
  driver_licence_type: string;
  driver_licence_dob: string;
  driver_licence_valid_from: string;
  driver_licence_issue_country: string;
  form_valid: boolean;
}

export interface AddressHistory {
  address_line1?: string;
  address_line2?: string;
  address_town?: string;
  address_county?: string;
  postcode?: string;
  country_code?: string;
  resident_from_gyear_month?: string;
  resident_to_gyear_month?: string;
}

export interface UserInformation{
  phone_number:string;
  email:string;
  dbs_type:string;
  comment:string;
}
interface UCheckState {
  id?: string;
  personal_information: PersonalInformation;
  personal_history: PersonalHistory;
  current_address: { address: AddressHistory; form_valid: boolean };
  previous_address: { address: AddressHistory[]; form_valid: boolean };
  passport_information: PassportInformation;
  drivingLicenceInformation: DrivingLicenceInformation;
  user_information:UserInformation;
  status:string;
}

export const uCheckInitialState: UCheckState = {
  personal_information: {
    title: '',
    forename: '',
    middlename_1: '',
    middlename_2: '',
    middlename_3: '',
    present_surname: '',
    date_of_birth: '',
    gender: '',
    contact_email: '',
    birth_nationality: '',
    birth_county: '',
    birth_country: '',
    birth_town: '',
    mother_maidenname: '',
    contact_number: '',
    has_nin: '',
    nin: '',
    form_valid: false,
  },
  personal_history: {
    current_nationality: '',
    nationality_change: '',
    unspent_convictions: '',
    surname_changed: '',
    has_other_name: '',
    birth_surname: '',
    birth_forename: '',
    birth_surname_until: '',
    other_names: [],
    form1_valid: false,
    form2_valid: false,
  },
  current_address: {
    address: {
      address_line1: '',
      address_line2: '',
      address_town: '',
      address_county: '',
      postcode: '',
      country_code: '',
      resident_from_gyear_month: '',
    },
    form_valid: false,
  },
  previous_address: {
    address: [],
    form_valid: true,
  },
  passport_information: {
    has_passport: '',
    passport_number: '',
    passport_dob: '',
    passport_issue_date: '',
    passport_nationality: '',
    form_valid: false,
  },
  drivingLicenceInformation: {
    uk_driving_licence: '',
    driver_licence_number: '',
    driver_licence_type: '',
    driver_licence_dob: '',
    driver_licence_valid_from: '',
    driver_licence_issue_country: '',
    form_valid: false,
  },
  user_information:{
    phone_number:'',
    email:'',
    dbs_type:'',
    comment:''   
  },
  status:''
};

const uCheckSlice = createSlice({
  name: 'uCheckSlice',
  initialState: uCheckInitialState,
  reducers: {
    setUCheckFormID(state, action) {
      state.id = action.payload;
    },
    setPersonalInformation(state, action) {
      state.personal_information = action.payload;
    },
    setPersonalHistory(state, action) {
      state.personal_history = action.payload;
    },
    setCurrentAddress(state, action) {
      state.current_address = action.payload;
    },
    setPreviousAddress(state, action) {
      state.previous_address = action.payload;
    },
    setPassPortInformation(state, action) {
      state.passport_information = action.payload;
    },
    setLicenceInformation(state, action) {
      state.drivingLicenceInformation = action.payload;
    },
    setUserInformation(state, action) {
      state.user_information.phone_number = action.payload.contact_number;
      state.user_information.email = action.payload.email;
      state.user_information.dbs_type = action.payload.dbs_type;
      state.user_information.comment = action.payload.comment;
    },
    setStatus(state, action) {
      state.status = action.payload;
    },
  },
});

export default uCheckSlice.reducer;

export const {
  setUCheckFormID,
  setPersonalInformation,
  setPersonalHistory,
  setCurrentAddress,
  setPreviousAddress,
  setPassPortInformation,
  setLicenceInformation,
  setUserInformation,
  setStatus
} = uCheckSlice.actions;

export const UCheckFormID = (state: { uCheckSlice: UCheckState }) =>
  state.uCheckSlice.id;

export const UCheckPersonalInformation = (state: {
  uCheckSlice: UCheckState;
}) => state.uCheckSlice.personal_information;

export const UCheckPersonalHistory = (state: { uCheckSlice: UCheckState }) =>
  state.uCheckSlice.personal_history;

export const UCheckCurrentAddress = (state: { uCheckSlice: UCheckState }) =>
  state.uCheckSlice.current_address;

export const UCheckPreviousAddresses = (state: { uCheckSlice: UCheckState }) =>
  state.uCheckSlice.previous_address;

export const UCheckPassportInformation = (state: {
  uCheckSlice: UCheckState;
}) => state.uCheckSlice.passport_information;

export const UCheckDrivingLicence = (state: { uCheckSlice: UCheckState }) =>
  state.uCheckSlice.drivingLicenceInformation;

  export const UserInformationData = (state: { uCheckSlice: UCheckState }) =>
  state.uCheckSlice.user_information;

  export const uCheckStatus = (state: { uCheckSlice: UCheckState }) =>
  state.uCheckSlice.status;

// personalInformation: any;
// addressInformation: any;
// personalHistory: any;
// personalHistoryOtherName: any;
// idInformation: any;
// drivingLicence: any;
// previousAddress: any;
