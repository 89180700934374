import React, { FC, SyntheticEvent, useState } from "react";
import { Box, Grid, Tab, Tabs } from "@mui/material";
import { images } from "../../../../../utils/constants/images";
import TabPanel from "../../../../../components/TabPanel";
import VettingDocumentsTab from "../VettingDocuments";
import RequiredReferences from "../RequiredReferences/RequiredReferences";
import AdditionalFormsTab from "../AdditionalForms";
import AdditionalComments from "../AdditionalComments";
import { useSelector } from "react-redux";
import { VettingDocument } from "../RequestDetailsModel";
import { AdditionalForms, VettingDocuments } from "../../../../../utils/redux";

const RequestDetailsTabs: FC = () => {
  const [value, setValue] = useState<number>(0);
  const vettingDocuments: VettingDocument[] = useSelector(VettingDocuments);
  const additionalForms: string[] = useSelector(AdditionalForms);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Grid container spacing={1} sx={{ mt: 3 }}>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 0 }}>
          <Tabs
            value={value}
            onChange={handleChange}
            sx={{ boxShadow: "none" }}
            className="tab-main-con"
            scrollButtons={true}
            TabIndicatorProps={{ sx: { display: "none" } }}
          >
            <Tab
              className={"request-tabs"}
              data-tut="vetting_document_tab"
              label={
                <span>
                  Vetting, ID & Checks{" "}
                  <img
                    src={value === 0 ? images.infoIconActive : images.infoIcon}
                    alt={"info icon"}
                    className="vertical-align-middle"
                  />
                </span>
              }
            />
            <Tab
            data-tut="required_ref_tab"
              className={"request-tabs"}
              label={
                <span>
                  Required References{" "}
                  <img
                    src={value === 1 ? images.infoIconActive : images.infoIcon}
                    alt={"info icon"}
                    className="vertical-align-middle"
                  />
                </span>
              }
            />
            <Tab
            data-tut="additional_forms_tab"
              className={"request-tabs"}
              label={
                <span>
                  Forms and Declarations
                  <img
                    src={value === 2 ? images.infoIconActive : images.infoIcon}
                    alt={"info icon"}
                    className="vertical-align-middle"
                  />
                </span>
              }
            />
            <Tab
             data-tut="additional_comments_tab"
              className={"request-tabs"}
              label={
                <span>
                  Notes to Request{" "}
                  <img
                    src={value === 3 ? images.infoIconActive : images.infoIcon}
                    alt={"info icon"}
                    className="vertical-align-middle"
                  />
                </span>
              }
            />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <VettingDocumentsTab documents={vettingDocuments} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <RequiredReferences />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <AdditionalFormsTab forms={additionalForms} />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <AdditionalComments />
        </TabPanel>
      </Box>
    </Grid>
  );
};

export default RequestDetailsTabs;
