import React, { FC, useState } from "react";
import { Grid, Typography, Box, CircularProgress, Stack } from "@mui/material";
import PrimaryButton from "../../../../../components/Button/PrimaryButton";
import axiosInstance from "../../../../../utils/axios";
import { URLS } from "../../../../../utils/constants/urls";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  Reload,
  setReload,
  setNotificationMessage,
} from "../../../../../utils/redux";

const RightSideFrame: FC<{
  token: string;
  signed: boolean;
  sign_required: boolean;
  is_rejected?: boolean;
}> = ({ token, signed, sign_required, is_rejected }) => {
  const dispatch = useDispatch();
  const { id } = useParams() as any;
  const reload = useSelector(Reload);
  const [loading, setLoading] = useState<boolean>(false);

  const createEnvelope = async () => {
    try {
      setLoading(true);
      await axiosInstance.post(
        `${URLS.requests}/${id}/additional-forms/create-envelope?stage=${process.env.REACT_APP_YOTI_ENV}&webhook_base_url=${process.env.REACT_APP_BASE_API_URL}`
      );
      setLoading(false);
      dispatch(setReload(!reload));
    } catch (e) {
      console.log(e);
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "error",
          message: "There is an error in creating envelope",
        })
      );
      setLoading(false);
    }
  };

  return (
    <Grid container className="card-vetting pb-3 pb-md-1">
      <Grid container className="card-head-table">
        <Grid item xs={12} md={6} sx={{ pl: { xs: 1, md: 3 }, py: 3 }}>
          <Typography
            variant={"body2"}
            fontSize={"16px"}
            fontWeight={"bold"}
            sx={{ pl: 2 }}
          >
            {!!token
              ? "Forms and Declarations for Request"
              : "Instructions for Forms and Declarations"}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Box
          sx={{
            background: "#D9D9D9",
            borderRadius: "20px",
            minHeight: "550px",
            display: "flex",
          }}
          margin={3}
          marginTop={0}
          justifyContent={"center"}
          alignItems={"center"}
        >
          {sign_required ? (
            <>
              {!token || is_rejected ? (
                <Stack>
                  <Typography
                    variant={"body1"}
                    sx={{ color: "#3a3a3a", mb: 3 }}
                  >
                    You will be taken to Yoti to perform the E-Sign on documents
                    given by the Agency
                  </Typography>
                  <Typography
                    variant={"body1"}
                    sx={{ color: "#3a3a3a", mb: 3 }}
                  >
                    Document signing will be a one time process and the state
                    might not be saved.
                  </Typography>
                  <PrimaryButton
                    disabled={loading}
                    fullWidth={false}
                    sx={{
                      mt: 1,
                      background: "#5AB9F9",
                      color: "#ffffff",
                      fontSize: 16,
                      fontWeight: 500,
                    }}
                    onClick={createEnvelope}
                  >
                    {loading && <CircularProgress sx={{ mr: 1 }} size={20} />}
                    Proceed to E-Sign
                  </PrimaryButton>
                </Stack>
              ) : (
                <>
                  {!signed ? (
                    <iframe
                      src={`${process.env.REACT_APP_YOTI_SIGN_URL}${token}`}
                      style={{
                        height: "550px",
                        width: "100%",
                        borderRadius: "20px",
                        border: "2px solid lightgray",
                      }}
                      title={"Yoti E-Sign"}
                    ></iframe>
                  ) : (
                    <Typography
                      variant={"h5"}
                      sx={{ color: "#3a3a3a" }}
                      fontWeight={700}
                    >
                      All Documents have been Signed
                    </Typography>
                  )}
                </>
              )}
            </>
          ) : (
            <Stack>
              <Typography variant={"body1"} sx={{ color: "#3a3a3a", mb: 3 }}>
                E-Signing Not Required for Request
              </Typography>
            </Stack>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default RightSideFrame;
