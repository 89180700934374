export const gender_titles: { [key: string]: string[] } = {
  male: [
    'MR',
    'BARON',
    'BRIGADIER',
    'CANON',
    'CAPTAIN',
    'DR',
    'DUCHESS',
    'DUKE',
    'ESQ',
    'FATHER',
    'HON',
    'INSPECTOR',
    'LADY',
    'LORD',
    'LT COL',
    'MAJOR',
    'MOST REVER',
    'PASTOR',
    'PROFESSOR',
    'RABBI',
    'REV DR',
    'REVEREND',
    'RT REVEREN',
    'SIR',
    'SQUADRON L',
    'WG CDR',
  ],
  female: [
    'BARONESS',
    'BRIGADIER',
    'CANON',
    'CAPTAIN',
    'DR',
    'DUCHESS',
    'HON',
    'INSPECTOR',
    'LADY',
    'LORD',
    'LT COL',
    'MAJOR',
    'MISS',
    'MOST REVER',
    'MRS',
    'MS',
    'PASTOR',
    'PROFESSOR',
    'RABBI',
    'REV DR',
    'REVEREND',
    'RT REVEREN',
    'SISTER',
    'SQUADRON L',
    'WG CDR',
  ],
};

export const title_gender: { [key: string]: string[] } = {
  MR: ['male'],
  MRS: ['female'],
  MISS: ['female'],
  MS: ['female'],
  BARON: ['male'],
  BARONESS: ['female'],
  BRIGADIER: ['male', 'female'],
  CANON: ['male', 'female'],
  CAPTAIN: ['male', 'female'],
  DR: ['male', 'female'],
  DUCHESS: ['male', 'female'],
  DUKE: ['male'],
  ESQ: ['male'],
  FATHER: ['male'],
  HON: ['male', 'female'],
  INSPECTOR: ['male', 'female'],
  LADY: ['female'],
  LORD: ['male'],
  'LT COL': ['male', 'female'],
  MAJOR: ['male', 'female'],
  'MOST REVER': ['male', 'female'],
  PASTOR: ['male', 'female'],
  PROFESSOR: ['male', 'female'],
  RABBI: ['male', 'female'],
  'REV DR': ['male', 'female'],
  REVEREND: ['male', 'female'],
  'RT REVEREN': ['male', 'female'],
  SIR: ['male'],
  SISTER: ['female'],
  'SQUADRON L': ['male', 'female'],
  'WG CDR': ['male', 'female'],
};

export const regex = {
  name: new RegExp("^[A-Z '-]+$"),
  town_county: new RegExp("^[A-Z0-9 '\\-()]+$"),
  passport: new RegExp('^(?!^0+$)[a-zA-Z0-9]{3,20}$'),
  licence: new RegExp('^[A-Z9]{5}d{6}[A-Z9]{2}d[A-Z]{2}$'),
};

export class UCheckValidators {
  static Name(value: string, message: string) {
    if (!!value) {
      const result = regex.name.test(value);
      if (!result) {
        return { error: true, message };
      }
    }
  }

  static Passport(value: string, message: string) {
    const result = regex.passport.test(value);
    if (!result) {
      return { error: true, message };
    }
  }

  static DrivingLicence(value: string, message: string) {
    const result = regex.licence.test(value);
    if (!result) {
      return { error: true, message };
    }
  }

  static Gender(value: string, message: string, title: string) {
    if (!!title && !!value) {
      const result = gender_titles[value].indexOf(title) > -1;
      if (!result) {
        return { error: true, message };
      }
    }
  }

  static Title(value: string, message: string, gender: string) {
    if (!!gender && !!value) {
      const result = title_gender[value].indexOf(gender) > -1;
      if (!result) {
        return { error: true, message };
      }
    }
  }

  static TownCounty(value: string, message: string) {
    if (!!value) {
      const result = regex.town_county.test(value);
      if (!result) {
        return { error: true, message };
      }
    }
  }

  static Surname(value: string, message: string, title: string) {
    if (!!value) {
      if (title.toLowerCase() === 'mrs') {
        if (value.toLowerCase() !== 'y') {
          return { error: true, message };
        }
      }
    }
  }
}
