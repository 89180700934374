import React, {
  FC,
  useMemo,
  useState,
  useEffect,
  useCallback,
  ReactNode,
} from "react";
import {
  CircularProgress,
  Grid,
  Typography,
  Select,
  TableCell,
  styled,
  tableCellClasses,
  TableRow,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  Paper,
  Alert,
  FormControl,
  MenuItem,
} from "@mui/material";
import InputField from "../../../../../../components/InputField";
import { DocumentListProps } from "../../../RequestDetails/DocumentList/DocumentList";
import "../../CandidateProfile.scss";
import dayjs from "dayjs";
import { images } from "../../../../../../utils/constants/images";
import { identifiers } from "../../../../../../utils/constants/identifiers";
import { URLS } from "../../../../../../utils/constants/urls";
import axiosInstance from "../../../../../../utils/axios";
import PrimaryButton from "../../../../../../components/Button/PrimaryButton";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Document, Page } from "react-pdf";
import Modal from "../../../../../../components/modal/Modal";
import { SubDocument } from "../../../RequestDetails/RequestDetailsModel";
import {
  UCheckCurrentAddress,
  UCheckDrivingLicence,
  UCheckPassportInformation,
  UCheckPersonalHistory,
  UCheckPersonalInformation,
  UCheckPreviousAddresses,
  setNotificationMessage,
  UserInformationData,
  uCheckStatus,
} from "../../../../../../utils/redux";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Box, Stack } from "@mui/system";
import YotiResult from "../YotiResult";
import * as Amplify from "../../../../../../utils/services/amplify";

import {
  setCandidateProfileReload,
  CandidateProfileReload,
  AcademicData,
} from "../../../../../../utils/redux";
import axios from "axios";
import PageLoader from "../../../../../../components/PageLoader";
import Review from "../../../../Candidate/ViewRequest/VettingDocuments/DocumentList/DBS/UCheck/Review";
import { generatePayload } from "../../../../Candidate/ViewRequest/VettingDocuments/DocumentList/DBS/UCheck/UCheckModel";
import { useTour } from "@reactour/tour";
import { tourStep } from "../../../../../../utils/constants/coachMarks";
import { CandidateInfo } from "../../../../../../utils/redux/reducer/candidate-vetting-slice";
import { CognitoUser } from "amazon-cognito-identity-js";
import {
  User,
  setUserData,
} from "../../../../../../utils/redux/reducer/authentication-slice";
import {
  idTypeSecond,
  idTypeOne,
} from "../../../../../../utils/constants/constants";

const Placeholder = ({ children }: { children: ReactNode }) => {
  return <Box>{children}</Box>;
};

const DocumentList: FC<DocumentListProps> = ({
  documents,
  active,
  yotiReport,
}) => {
  const dispatch = useDispatch();
  const { candidate_id, id } = useParams();

  const reload = useSelector(CandidateProfileReload);
  const profile = useSelector(AcademicData);
  const candidateInfo = useSelector(UserInformationData);
  const [uCheckPopUpOpen, setUCheckPopUpOpen] = useState(false);
  const [yotiResultOpen, setYotiResultOpen] = useState(false);
  const status = useSelector(uCheckStatus);

  const [expandCommentSec, setExpandCommentSec] = useState(false);
  const [report, setReport] = useState(false);
  const [documentType, setDocumentType] = useState<"pdf" | "image">();
  const [documentUrl, setDocumentUrl] = useState<string>();
  const [viewDocument, setViewDocument] = useState(false);
  const [numPages, setNumPages] = useState<number | null>(null);
  const [displayTimePeriod, setDisplayTimePeriod] = useState<boolean>(false);
  const [subDocumentId, setSubDocumentId] = useState("");
  const [uCheckInputValues, setUCheckInputValues] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [pageLoader, setPageLoader] = useState(false);
  const [displayComment, setDisplayComment] = useState<number>(0);
  const [showUCheckForm, setShowUCheckForm] = useState(false);
  const [inputValues, setInputValues] = useState<string>("");
  const [uCheckReportModal, setUCheckReportModal] = useState(false);
  const [submitApiAlert, setSubmitApiAlert] = useState(false);
  const candidate_info = useSelector(CandidateInfo);

  const DbsPersonalInformationValues = useSelector(UCheckPersonalInformation);
  const uCheckPersonalHistory = useSelector(UCheckPersonalHistory);
  const uCheckCurrentAddress = useSelector(UCheckCurrentAddress);
  const uCheckPreviousAddresses = useSelector(UCheckPreviousAddresses);
  const uCheckPassportInformation = useSelector(UCheckPassportInformation);
  const uCheckDrivingLicenceInformation = useSelector(UCheckDrivingLicence);
  const [idFirstId, setIdFirstId] = useState("");
  const [secondId, setSecondId] = useState("");
  const user: { [key: string]: string } = useSelector(User) as {
    [key: string]: string;
  };
  const [idTypePopUpModal, setIdTypePopUpModal] = useState(false);
  const { setIsOpen, currentStep, setCurrentStep } = useTour();
  const [errorArray, setErrorArray] = useState<string[]>([]);
  const [errorMessages, setErrorMessages] = useState<
    {
      code: string;
      message: string;
    }[]
  >([]);
  const [validationAlertPopUp, setValidationAlertPopUp] = useState(false);

  const downloadAllDocuments = async (vetting_id: string) => {
    const session = await Amplify.currentSession();
    const token = session.getIdToken().getJwtToken();
    setIsLoading(true);
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_DOWNLOADS_API_URL}${URLS.download_vetting_documents}`,
        {
          request_id: id,
          vetting_document_id: vetting_id,
          candidate_id: candidate_id,
        },
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      window.open(data.download_url, "_self");
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.log(e);
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "error",
          message: "Unable to download all documents",
        })
      );
    }
  };

  useEffect(() => {
    if (documents[active].comment) {
      setInputValues(documents[active]?.comment || "");
    }
    let a = documents[active].sub_documents.findIndex(
      (document) => document.approval_status === "REJECTED"
    );
    setDisplayComment(a);
  }, [documents, active]);

  const setCoachMarks = useCallback(
    async (value: any) => {
      //if (!!user && user["custom:reference_tutorial"] !== "true") {
      const currentUser: CognitoUser = await Amplify.UserDetail();
      const body = value;
      await Amplify.UpdateUserDetails(body, currentUser);
      await Amplify.RefreshSession();
      dispatch(setUserData({ ...user, ...body }));
      //}
    },
    [user, dispatch]
  );

  useEffect(() => {
    let a = documents[active].sub_documents.findIndex(
      (document) => document.approval_status === "REJECTED"
    );
    let b = documents[active].sub_documents.findIndex(
      (document) => !!document.uploaded_at
    );
    console.log(b, documents[active]);
    //using req_ref_list for download all coach marks
    if (!!user && user["custom:download_all"] !== "true") {
      setTimeout(() => {
        if (b >= 0) {
          setCurrentStep(tourStep.candidate_profile_download_all.index);
        }
      }, 1000);
    }

    if (!!user && user["custom:docs_report_field"] !== "true") {
      if (a >= 0) {
        setExpandCommentSec(true);
        setCurrentStep(tourStep.candidate_vetting_docs_report_field.index);
      }
    }
  }, [documents, active, user, setCurrentStep]);

  useEffect(() => {
    let a = documents[active].sub_documents.findIndex(
      (document) => document.approval_status === "REJECTED"
    );
    let b = documents[active].sub_documents.findIndex(
      (document) => !!document.uploaded_at
    );
    //reported docs
    if (a >= 0) {
      if (tourStep.candidate_vetting_docs_report_field.index === currentStep) {
        setIsOpen(true);
      } else if (
        currentStep ===
        tourStep.candidate_vetting_docs_report_field.index +
          tourStep.candidate_vetting_docs_report_field.steps
      ) {
        setIsOpen(false);
        if (!!user && user["custom:docs_report_field"] !== "true") {
          (async () => {
            await setCoachMarks({
              "custom:docs_report_field": "true",
            });
          })();
        }
      }
    }
    //download all docs
    if (b >= 0) {
      if (tourStep.candidate_profile_download_all.index === currentStep) {
        setIsOpen(true);
      } else if (
        currentStep ===
        tourStep.candidate_profile_download_all.index +
          tourStep.candidate_profile_download_all.steps
      ) {
        setIsOpen(false);
        //using download_all for download all coach marks
        if (!!user && user["custom:download_all"] !== "true") {
          (async () => {
            await setCoachMarks({
              "custom:download_all": "true",
            });
          })();
        }
      }
    }
  }, [currentStep, setCoachMarks, setIsOpen, user, active, documents]);

  useEffect(() => {
    if (documents[active].name === identifiers.dbs) {
      if (!!user && user["custom:ucheck_review_state"] !== "true") {
        if (showUCheckForm) {
          setCurrentStep(tourStep.ucheck_application_review_state.index);
        }
      }
    }
  }, [showUCheckForm, documents, active, user, setCurrentStep]);

  useEffect(() => {
    if (showUCheckForm) {
      if (tourStep.ucheck_application_review_state.index === currentStep) {
        setIsOpen(true);
      } else if (
        currentStep ===
        tourStep.ucheck_application_review_state.index +
          tourStep.ucheck_application_review_state.steps
      ) {
        setIsOpen(false);
        if (!!user && user["custom:ucheck_review_state"] !== "true") {
          (async () => {
            await setCoachMarks({
              "custom:ucheck_review_state": "true",
            });
          })();
        }
      }
    }
  }, [showUCheckForm, currentStep, setIsOpen, user, setCoachMarks]);

  useEffect(() => {
    if (documents[active].name === identifiers.dbs) {
      if (!!user && user["custom:report_pop_up_modal"] !== "true") {
        if (uCheckReportModal) {
          setCurrentStep(tourStep.report_pop_up_modal.index);
        }
      }
    }
  }, [uCheckReportModal, documents, active, user, setCurrentStep]);

  useEffect(() => {
    if (uCheckReportModal) {
      if (tourStep.report_pop_up_modal.index === currentStep) {
        setIsOpen(true);
      } else if (
        currentStep ===
        tourStep.report_pop_up_modal.index + tourStep.report_pop_up_modal.steps
      ) {
        setIsOpen(false);
        if (!!user && user["custom:report_pop_up_modal"] !== "true") {
          (async () => {
            await setCoachMarks({
              "custom:report_pop_up_modal": "true",
            });
          })();
        }
      }
    }
  }, [uCheckReportModal, currentStep, setIsOpen, user, setCoachMarks]);

  useEffect(() => {
    if (documents[active].name === identifiers.dbs) {
      if (!!user && user["custom:ucheck_after_submit"] !== "true") {
        if (status === "submitted") {
          setCurrentStep(tourStep.ucheck_after_submit_state.index);
        }
      }
    }
  }, [status, documents, active, user, setCurrentStep]);

  useEffect(() => {
    if (status === "submitted") {
      if (tourStep.ucheck_after_submit_state.index === currentStep) {
        setIsOpen(true);
      } else if (
        currentStep ===
        tourStep.ucheck_after_submit_state.index +
          tourStep.ucheck_after_submit_state.steps
      ) {
        setIsOpen(false);
        if (!!user && user["custom:ucheck_after_submit"] !== "true") {
          (async () => {
            await setCoachMarks({
              "custom:ucheck_after_submit": "true",
            });
          })();
        }
      }
    }
  }, [status, currentStep, setIsOpen, user, setCoachMarks]);

  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#f5fbff",
      color: "#727272",
      border: "none",
    },
    [`&.${tableCellClasses.body}`]: {
      color: "#263238",
      fontSize: "14px",
      fontWeight: 500,
      padding: "5px 16px",
    },
    [`&.${tableCellClasses.head}:first-of-type`]: {
      borderTopLeftRadius: "4px",
      borderBottomLeftRadius: "4px",
    },
    [`&.${tableCellClasses.head}:last-of-type`]: {
      borderTopRightRadius: "4px",
      borderBottomRightRadius: "4px",
      width: "14rem",
      textAlign: "start",
      paddingLeft: "35px",
    },
    [`&.${tableCellClasses.body}:first-of-type`]: {
      borderTopLeftRadius: "12px",
      borderBottomLeftRadius: "12px",
      borderLeft: "1px solid #DADADA",
    },
    [`&.${tableCellClasses.body}:last-of-type`]: {
      borderTopRightRadius: "12px",
      borderBottomRightRadius: "12px",
      borderRight: "1px solid #DADADA",
      textAlign: "center",
    },
  }));

  const StyledTableRow = styled(TableRow)(() => ({
    "&": {
      borderRadius: "8px",
      borderTop: "1px solid #DADADA",
      backgroundColor: "white",
    },
  }));

  useEffect(() => {
    let hasOptions: boolean = false;
    documents[active].sub_documents.forEach((document: SubDocument) => {
      if (document.options) {
        hasOptions = true;
      }
    });
    setDisplayTimePeriod(hasOptions);
  }, [active, documents]);

  const download = async (id: string, view: boolean) => {
    setPageLoader(true);
    try {
      const { data } = await axiosInstance.get(`${URLS.document}/${id}`);
      const type =
        data.mimetype.indexOf("pdf") > -1
          ? "pdf"
          : data.mimetype.indexOf("image") > -1
          ? "image"
          : null;
      if (!!type && view) {
        setDocumentType(type);
        setDocumentUrl(data.url);
        setViewDocument(true);
      } else {
        window.open(data.url, "_blank");
      }
      setPageLoader(false);
    } catch (e) {
      console.log(e);
      setPageLoader(false);
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "error",
          message: "There is an error in downloading document",
        })
      );
    }
  };

  const reportDocs = useCallback(async () => {
    try {
      setIsLoading(true);
      let payload;
      if (
        documents[active].name !== identifiers.opc &&
        documents[active].name !== identifiers.dbs
      ) {
        payload = {
          approval_status: "REJECTED",
          vetting_document_id: documents[active].id,
          sub_document_id: subDocumentId,
        };
      } else {
        payload = {
          approval_status: "REJECTED",
          vetting_document_id: documents[active].id,
        };
      }
      await axiosInstance.patch(
        `${URLS.agency_request}${id}/candidate/${candidate_id}`,
        payload
      );
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "success",
          message: "Document reported successfully",
        })
      );
      dispatch(setCandidateProfileReload(!reload));
      setIsLoading(false);
      setReport(false);
    } catch (e) {
      console.log(e);
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "error",
          message: "There is an error in reporting  document",
        })
      );
      setIsLoading(false);
      setReport(false);
    }
  }, [documents, active, id, candidate_id, subDocumentId, dispatch, reload]);

  const submitFeedback = async () => {
    setIsLoading(true);
    try {
      let payload = {
        vetting_document_id: documents[active].id,
        comment: inputValues,
      };

      await axiosInstance.patch(
        `${URLS.agency_request}${id}/candidate/${candidate_id}/comment`,
        payload
      );
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "success",
          message: "Additional comments has been sent successfully",
        })
      );
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "error",
          message: "There is an error in saving the Additional comments",
        })
      );
      setIsLoading(false);
    }
  };

  const reportModalText = useMemo(() => {
    return (
      <Grid container>
        <Grid item>
          <Typography
            variant="h6"
            sx={{
              marginBottom: "20px",
              fontSize: 18,
              fontWeight: 500,
              color: "#000000",
              textAlign: "center",
              letterSpacing: "0.15px",
            }}
          >
            Report Document?
          </Typography>
          <Typography
            variant="h6"
            sx={{
              mb: 3,
              fontSize: 16,
              fontWeight: 500,
              color: "#727272",
            }}
          >
            Document will be rejected and Candidate will have to upload a new
            document.
            <br />
            Are you sure?
          </Typography>
        </Grid>
        <Grid
          container
          alignItems="right"
          justifyContent="right"
          sx={{ flexWrap: "noWrap" }}
        >
          <PrimaryButton
            variant={"text"}
            fullWidth={false}
            sx={{
              mt: 1,
              mr: 2,
              background: "white",
              border: "1px solid #5AB9F9",
              color: "#5AB9F9",
              fontSize: 16,
              fontWeight: 500,
              maxHeight: "45px",
            }}
            onClick={reportDocs}
            disabled={isLoading}
          >
            {isLoading && <CircularProgress sx={{ mr: 1 }} size={20} />}
            Report Document
          </PrimaryButton>
          <PrimaryButton
            disabled={isLoading}
            fullWidth={false}
            sx={{
              mt: 1,
              background: "#5AB9F9",
              color: "#ffffff",
              fontSize: 16,
              fontWeight: 500,
            }}
            onClick={() => {
              setReport(false);
              // setDocument(null);
            }}
          >
            Cancel
          </PrimaryButton>
        </Grid>
      </Grid>
    );
  }, [isLoading, reportDocs]);

  const viewModalText = useMemo(() => {
    return (
      <Grid container>
        <Grid item>
          {documentType === "pdf" && (
            <Document
              file={{ url: documentUrl }}
              onLoadSuccess={({ numPages }) => setNumPages(numPages)}
            >
              {Array.from(new Array(numPages), (el, index) => (
                <Page key={`page_${index + 1}`} pageNumber={index + 1} />
              ))}
            </Document>
          )}
          {documentType === "image" && (
            <img src={documentUrl} alt={"document"} className={"img-fluid"} />
          )}
        </Grid>
      </Grid>
    );
  }, [documentType, documentUrl, numPages]);

  const openReportModal = useMemo(() => {
    return (
      <Modal
        open={report}
        setModalClose={setReport}
        children={reportModalText}
        title={undefined}
        size="sm"
        className={undefined}
      />
    );
  }, [report, reportModalText]);

  const openViewModal = useMemo(() => {
    return (
      <Modal
        open={viewDocument}
        setModalClose={setViewDocument}
        children={viewModalText}
        title={undefined}
        size="sm"
        className={undefined}
      />
    );
  }, [viewDocument, viewModalText]);

  const reportUCheckApplicationApi = useCallback(async () => {
    try {
      setIsLoading(true);
      let payload = {
        request_id: id,
        candidate_id: candidate_id,
        status: "REJECTED",
        comments: uCheckInputValues,
      };
      await axiosInstance.put(
        `${URLS.authenticated}ucheck/application/status`,
        payload
      );
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "success",
          message: "Document reported successfully",
        })
      );
      dispatch(setCandidateProfileReload(!reload));
      setIsLoading(false);
      setUCheckReportModal(false);
    } catch (e) {
      console.log(e);
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "error",
          message: "There is an error in reporting  document",
        })
      );
      setIsLoading(false);
      setUCheckReportModal(false);
    }
  }, [id, candidate_id, uCheckInputValues, dispatch, reload]);

  const uCheckSubmitApi = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await axiosInstance.post(
        `${URLS.requests}/${id}/candidate/${candidate_id}/ucheck/application?stage=${process.env.REACT_APP_UCHECK_ENV}`
      );
      if (!!data && !!data.ERROR) {
        if (!!data.ERROR.response) {
          if (!!data.ERROR.response.validationERROR) {
            if (typeof data.ERROR.response.validationERROR === "string") {
              setErrorArray([data.ERROR.response.validationERROR]);
            } else {
              setErrorArray(data.ERROR.response.validationERROR);
            }
          }
          if (!!data.ERROR.response.error) {
            setErrorArray([data.ERROR.response.error]);
          }
        }
        dispatch(
          setNotificationMessage({
            display: true,
            severity: "error",
            message: "There is an error in submitting DBS Application Form",
          })
        );
        setSubmitApiAlert(false);
        setIsLoading(false);
        setValidationAlertPopUp(true);
      } else if (data?.errors) {
        setErrorMessages(data?.errors);
        dispatch(
          setNotificationMessage({
            display: true,
            severity: "error",
            message: "There is an error in submitting DBS Application Form",
          })
        );
        setIsLoading(false);
        setSubmitApiAlert(false);
        setValidationAlertPopUp(true);
      } else {
        dispatch(
          setNotificationMessage({
            display: true,
            severity: "success",
            message: "DBS Application form submitted Successfully",
          })
        );
        setSubmitApiAlert(false);
        setIsLoading(false);
        dispatch(setCandidateProfileReload(!reload));
      }
    } catch (e) {
      console.log(e);
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "error",
          message: "There is an error in submitting DBS Application Form",
        })
      );
      setSubmitApiAlert(false);
      setIsLoading(false);
    }
  }, [candidate_id, dispatch, id, reload]);

  const saveToDraft = useCallback(
    async (submit: boolean) => {
      setIsLoading(true);
      try {
        const payload = generatePayload(
          DbsPersonalInformationValues,
          uCheckPersonalHistory,
          uCheckCurrentAddress,
          uCheckPreviousAddresses,
          uCheckPassportInformation,
          uCheckDrivingLicenceInformation,
          "agency",
          user,
          candidateInfo,
          idFirstId,
          secondId
        );
        const { data } = await axiosInstance.put(
          `${URLS.requests}/${id}/candidate/${candidate_id}/ucheck/draft?stage=${process.env.REACT_APP_UCHECK_ENV}`,
          payload
        );
        if (submit && !data?.errors) {
          await uCheckSubmitApi();
        } else if (data?.errors) {
          setValidationAlertPopUp(true);
          setErrorMessages(data?.errors);
          setIsLoading(false);
          dispatch(
            setNotificationMessage({
              display: true,
              severity: "error",
              message: "There is an error in saving DBS Application Form",
            })
          );
          setIdTypePopUpModal(false);
        } else {
          dispatch(
            setNotificationMessage({
              display: true,
              severity: "success",
              message: "DBS Application form saved as Draft Successfully",
            })
          );
          setIsLoading(false);
        }
      } catch (e) {
        console.log(e);
        dispatch(
          setNotificationMessage({
            display: true,
            severity: "error",
            message: "There is an error in saving DBS Application Form",
          })
        );
        setIsLoading(false);
      }
    },
    [
      DbsPersonalInformationValues,
      uCheckPersonalHistory,
      uCheckCurrentAddress,
      uCheckPreviousAddresses,
      uCheckPassportInformation,
      uCheckDrivingLicenceInformation,
      user,
      candidateInfo,
      dispatch,
      id,
      candidate_id,
      uCheckSubmitApi,
      idFirstId,
      secondId,
    ]
  );

  const reportUCheckApplication = useMemo(() => {
    return (
      <Grid container>
        <Grid
          item
          xs={12}
          sx={{
            background: "#FEFBEC",
            padding: "20px ",
            justifyContent: "space-between",
            flexDirection: "row",
          }}
          display={"flex"}
          alignItems={"center"}
        >
          <Box sx={{ display: "flex", direction: "row", alignItems: "center" }}>
            <img src={images.warningTooltip} alt={"tooltip"} />
            <Typography
              variant="h6"
              sx={{
                ml: 2,
                fontSize: 18,
                fontWeight: 500,
                color: "#000000",
                textAlign: "center",
                letterSpacing: "0.15px",
              }}
            >
              Report Application ?
            </Typography>
          </Box>
          <img
            onClick={() => {
              setUCheckReportModal(false);
            }}
            style={{ width: "20px", cursor: "pointer" }}
            src={images.closeBtn}
            alt={"close button"}
          />
        </Grid>
        <Grid padding="5px 20px" item xs={12}>
          <Typography
            variant="h6"
            sx={{
              mb: 3,
              fontSize: 16,
              fontWeight: 500,
              color: "#727272",
            }}
          >
            Application will be rejected and Candidate will have to upload a new
            Application.
            <br />
            Are you sure?
          </Typography>
          <Grid container>
            <Grid item>
              <Grid
                item
                xs={12}
                className=" pl-2 py-3"
                display={"flex"}
                alignItems={"center"}
              >
                Additional Comments
              </Grid>
            </Grid>

            <Grid item data-tut="ucheck_report_inputfield" xs={12}>
              <InputField
                value={uCheckInputValues}
                textChange={(e) => setUCheckInputValues(e)}
                multiline
                rows={5}
                sx={{
                  background: "#ffffff",
                  marginTop: "10px",
                  border: "0px !important",
                  borderRadius: "16px !important",
                }}
                placeholder={" ..."}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          padding="5px 20px"
          container
          alignItems="right"
          justifyContent="right"
          sx={{ flexWrap: "noWrap" }}
        >
          <PrimaryButton
            variant={"text"}
            fullWidth={false}
            sx={{
              mt: 1,
              mr: 2,
              background: "white",
              border: "1px solid #5AB9F9",
              color: "#5AB9F9",
              fontSize: 16,
              fontWeight: 500,
            }}
            data-tut="ucheck_report_save_comment_btn"
            onClick={reportUCheckApplicationApi}
            disabled={isLoading || uCheckInputValues === ""}
          >
            {isLoading && <CircularProgress sx={{ mr: 1 }} size={20} />}
            Report Reference
          </PrimaryButton>
          <PrimaryButton
            disabled={isLoading}
            fullWidth={false}
            sx={{
              mt: 1,
              background: "#5AB9F9",
              color: "#ffffff",
              fontSize: 16,
              fontWeight: 500,
            }}
            onClick={() => {
              setUCheckReportModal(false);
            }}
          >
            Cancel
          </PrimaryButton>
        </Grid>
      </Grid>
    );
  }, [uCheckInputValues, reportUCheckApplicationApi, isLoading]);

  const openUCheckReportModal = useMemo(() => {
    return (
      <Modal
        open={uCheckReportModal}
        setModalClose={setUCheckReportModal}
        children={reportUCheckApplication}
        title={undefined}
        size="sm"
        padding="0px"
        className={undefined}
      />
    );
  }, [uCheckReportModal, reportUCheckApplication]);

  const alertModal = useMemo(() => {
    return (
      <Grid container>
        <Grid
          item
          xs={12}
          sx={{
            background: "#F5FBFF",
            padding: "20px ",
            justifyContent: "space-between",
            flexDirection: "row",
          }}
          display={"flex"}
          alignItems={"center"}
        >
          <Box sx={{ display: "flex", direction: "row", alignItems: "center" }}>
            <img src={images.warningTooltip} alt={"tooltip"} />
            <Typography
              variant="h6"
              sx={{
                ml: 2,
                fontSize: 18,
                fontWeight: 500,
                color: "#000000",
                textAlign: "center",
                letterSpacing: "0.15px",
              }}
            >
              Confirm Submit
            </Typography>
          </Box>
          <img
            onClick={() => {
              setSubmitApiAlert(false);
            }}
            style={{ width: "20px", cursor: "pointer" }}
            src={images.closeBtn}
            alt={"close button"}
          />
        </Grid>
        <Grid padding="5px 20px" item xs={12}>
          <Typography
            variant="h6"
            sx={{
              mb: 3,
              fontSize: 16,
              fontWeight: 500,
              color: "#727272",
            }}
          >
            Confirm Application?
          </Typography>
          <Typography
            variant="h6"
            sx={{
              mb: 3,
              fontSize: 16,
              fontWeight: 400,
              color: "#727272",
            }}
          >
            Please make sure that you have reviewed the candidates DBS
            Application before submission.
          </Typography>
        </Grid>
        <Grid
          padding="5px 20px"
          container
          alignItems="right"
          justifyContent="right"
          sx={{ flexWrap: "noWrap" }}
        >
          <PrimaryButton
            variant={"text"}
            fullWidth={false}
            sx={{
              mt: 1,
              mr: 2,
              background: "white",
              border: "1px solid #5AB9F9",
              color: "#5AB9F9",
              fontSize: 16,
              fontWeight: 500,
            }}
            onClick={async () => {
              await saveToDraft(true);
            }}
            disabled={isLoading}
          >
            {isLoading && <CircularProgress sx={{ mr: 1 }} size={20} />}
            Submit Application
          </PrimaryButton>
        </Grid>
      </Grid>
    );
  }, [isLoading, saveToDraft]);

  const openSubmitApiAlert = useMemo(() => {
    return (
      <Modal
        open={submitApiAlert}
        setModalClose={setSubmitApiAlert}
        children={alertModal}
        title={undefined}
        size="sm"
        padding="0px"
        className={undefined}
      />
    );
  }, [submitApiAlert, alertModal]);

  const uCheckPopUp = useMemo(() => {
    return (
      <Grid container>
        <Grid
          item
          xs={12}
          sx={{
            background: "#5AB9F9",
            padding: "20px ",
            justifyContent: "space-between",
            flexDirection: "row",
          }}
          display={"flex"}
          alignItems={"center"}
        >
          <Box sx={{ display: "flex", direction: "row", alignItems: "center" }}>
            <Typography
              variant="h6"
              sx={{
                ml: 2,
                fontSize: 18,
                fontWeight: 500,
                color: "#000000",
                textAlign: "center",
                letterSpacing: "0.15px",
              }}
            >
              U Check Application
            </Typography>
          </Box>
          <img
            onClick={() => {
              setUCheckPopUpOpen(false);
            }}
            style={{ width: "20px", cursor: "pointer" }}
            src={images.closeBtn}
            alt={"close button"}
          />
        </Grid>
        <Grid item md={12} padding={"10px"}>
          <Review
            role="agency"
            dbs_type={documents[active].sub_documents[0].key as string}
            disabled={true}
          />
        </Grid>
      </Grid>
    );
  }, [active, documents]);

  const openUCheckPopUp = useMemo(() => {
    return (
      <Modal
        open={uCheckPopUpOpen}
        setModalClose={setUCheckPopUpOpen}
        children={uCheckPopUp}
        title={undefined}
        size="md"
        padding="0px"
        className={undefined}
      />
    );
  }, [uCheckPopUp, uCheckPopUpOpen]);

  const yotiPopUp = useMemo(() => {
    return (
      <Grid container>
        <Grid
          item
          xs={12}
          sx={{
            background: "#5AB9F9",
            padding: "20px ",
            justifyContent: "space-between",
            flexDirection: "row",
          }}
          display={"flex"}
          alignItems={"center"}
        >
          <Box sx={{ display: "flex", direction: "row", alignItems: "center" }}>
            <Typography
              variant="h6"
              sx={{
                ml: 2,
                fontSize: 18,
                fontWeight: 500,
                color: "#000000",
                textAlign: "center",
                letterSpacing: "0.15px",
              }}
            >
              Yoti Result
            </Typography>
          </Box>
          <img
            onClick={() => {
              setYotiResultOpen(false);
            }}
            style={{ width: "20px", cursor: "pointer" }}
            src={images.closeBtn}
            alt={"close button"}
          />
        </Grid>
        <Grid item md={12} padding={"10px"}>
          <YotiResult
            yotiReport={
              yotiReport as {
                check_result: any;
                documents: any[];
                status: string;
              }
            }
          />
        </Grid>
      </Grid>
    );
  }, [yotiReport]);

  const openYotiPopUp = useMemo(() => {
    return (
      <Modal
        open={yotiResultOpen}
        setModalClose={setYotiResultOpen}
        children={yotiPopUp}
        title={undefined}
        size="md"
        padding="0px"
        className={undefined}
      />
    );
  }, [yotiResultOpen, yotiPopUp]);

  const removeMessage = (i: string) => {
    let a = errorMessages.filter((a: any) => a.message !== i);
    setErrorMessages(a);
  };

  const removeAlert = (i: string) => {
    let a = errorArray.filter((a: any) => a !== i);
    setErrorArray(a);
  };

  const idTypePopUp = useMemo(() => {
    return (
      <Grid container>
        <Grid
          item
          xs={12}
          sx={{
            background: "#F5FBFF",
            padding: "20px ",
            justifyContent: "space-between",
            flexDirection: "row",
          }}
          display={"flex"}
          alignItems={"center"}
        >
          <Box sx={{ display: "flex", direction: "row", alignItems: "center" }}>
            <img src={images.warningTooltip} alt={"tooltip"} />
            <Typography
              variant="h6"
              sx={{
                ml: 2,
                fontSize: 18,
                fontWeight: 500,
                color: "#000000",
                textAlign: "center",
                letterSpacing: "0.15px",
              }}
            >
              ID Verification
            </Typography>
          </Box>
          <img
            onClick={() => {
              setIdTypePopUpModal(false);
            }}
            style={{ width: "20px", cursor: "pointer" }}
            src={images.closeBtn}
            alt={"close button"}
          />
        </Grid>
        <Grid item xs={12} md={12} padding={"10px"} textAlign={"start"}>
          <Typography
            variant="h6"
            sx={{
              ml: 2,
              fontSize: 18,
              fontWeight: 500,
              color: "#000000",
              textAlign: "center",
              letterSpacing: "0.15px",
            }}
          >
            Please select one of the following ID’s have you verified before
            submitting an application for Scottish DBS
          </Typography>
          <FormControl
            fullWidth
            sx={{
              textAlign: "start",
              borderRadius: "8px",
              ".MuiOutlinedInput-notchedOutline": {
                border: 0,
              },
              "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              display: "flex",
              alignItems: "center",
            }}
          >
            <Select
              value={idFirstId}
              sx={{
                height: 38,
                py: 1,
                backgroundColor: "#F6F7FB",
                mb: 2,
                width: "100%",
              }}
              onChange={(e) => {
                console.log(e);
                setIdFirstId(e.target.value);
                //setHasError(false);
              }}
              displayEmpty
              renderValue={
                !idFirstId
                  ? () => <Placeholder>Please Select Id proof</Placeholder>
                  : undefined
              }
            >
              {idTypeOne.map((idType, index) => (
                <MenuItem value={idType.value} key={index}>
                  {idType.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl
            fullWidth
            sx={{
              textAlign: "start",
              borderRadius: "8px",
              ".MuiOutlinedInput-notchedOutline": {
                border: 0,
              },
              "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              display: "flex",
              alignItems: "center",
            }}
          >
            <Select
              value={secondId}
              sx={{
                height: 38,
                py: 1,
                backgroundColor: "#F6F7FB",
                mb: 2,
                width: "100%",
              }}
              onChange={(e) => {
                console.log(e);
                setSecondId(e.target.value);
                //setHasError(false);
              }}
              displayEmpty
              renderValue={
                !secondId
                  ? () => <Placeholder>Please Select Address proof</Placeholder>
                  : undefined
              }
            >
              {idTypeSecond.map((idType, index) => (
                <MenuItem value={idType.value} key={index}>
                  {idType.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid
          padding="5px 20px"
          container
          alignItems="right"
          justifyContent="right"
          sx={{ flexWrap: "noWrap" }}
        >
          <PrimaryButton
            variant={"text"}
            fullWidth={false}
            sx={{
              mt: 1,
              mr: 2,
              background: "white",
              border: "1px solid #5AB9F9",
              color: "#5AB9F9",
              fontSize: 16,
              fontWeight: 500,
            }}
            onClick={() => saveToDraft(false)}
            disabled={secondId === "" || idFirstId === ""}
          >
            {isLoading && <CircularProgress sx={{ mr: 1 }} size={20} />}
            Confirm
          </PrimaryButton>
        </Grid>
      </Grid>
    );
  }, [idFirstId, secondId, isLoading, saveToDraft]);

  const openIdTypePopUp = useMemo(() => {
    return (
      <Modal
        open={idTypePopUpModal}
        setModalClose={setIdTypePopUpModal}
        children={idTypePopUp}
        title={undefined}
        size="md"
        padding="0px"
        className={undefined}
      />
    );
  }, [idTypePopUp, idTypePopUpModal]);

  return (
    <>
      {pageLoader && <PageLoader />}
      {openSubmitApiAlert}
      {openIdTypePopUp}
      {openReportModal}
      {openUCheckReportModal}
      {openViewModal}
      {openUCheckPopUp}
      {openYotiPopUp}
      <Grid xs={12} container className="card-vetting">
        <Grid
          container
          className="card-head-table"
          justifyContent={"space-between"}
        >
          <Grid
            item
            xs={documents[active].name === identifiers.dbs ? 6 : 12}
            md={4}
            lg={6}
            sx={{ pl: 1, py: 3 }}
          >
            <Typography
              variant={"body2"}
              fontSize={"16px"}
              fontWeight={"bold"}
              paddingTop={"10px"}
              sx={{ pl: { xs: "18px", md: 2 } }}
            >
              {documents[active].name}
            </Typography>
          </Grid>
          {(documents[active].name === identifiers.rtw &&
            documents[active].is_british_or_irish_citizen) ||
          documents[active].name === identifiers.dbs ? null : (
            <>
              {documents[active].documents_required !== "N/A" && (
                <>
                  <Grid
                    item
                    xs={12}
                    md={4}
                    lg={4}
                    sx={{
                      py: { xs: 0, md: 3 },
                      pl: { xs: 3, md: 0 },
                      textAlign: { xs: "left", md: "right" },
                    }}
                  >
                    <Typography
                      variant={"body2"}
                      fontSize={"16px"}
                      fontWeight={"bold"}
                      paddingTop={"10px"}
                    >
                      Number of Documents required for this section
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4} lg={2} sx={{ px: 2, py: 1 }}>
                    <FormControl
                      sx={{
                        minWidth: "52px",
                        px: 1,
                        paddingY: "8px",
                        width: "100%",
                      }}
                    >
                      <InputField
                        inputValue={documents[active].documents_required}
                        size={"small"}
                        className="headerTimePeriod mt-2"
                        disabled={true}
                      />
                    </FormControl>
                  </Grid>
                </>
              )}
            </>
          )}
          {documents[active].name === identifiers.dbs &&
            !documents[active].on_updated_dbs_services &&
            showUCheckForm && (
              <Grid
                item
                xs={12}
                md={6}
                lg={6}
                pr={1}
                sx={{
                  py: { xs: 0, md: 3 },
                  pl: { xs: 3, md: 0 },
                  textAlign: { xs: "left", md: "right" },
                }}
                alignItems={"center"}
                display={"flex"}
                justifyContent={"flex-end"}
              >
                {documents[active]?.sub_documents[0]?.key === "SCOTTISH" && (
                  <PrimaryButton
                    onClick={() => setIdTypePopUpModal(true)}
                    //disabled={reference.invalid}
                    data-tut="ucheck_report_application_btn"
                    sx={{
                      borderRadius: "8px",
                      p: "0px 10px",
                      mt: "6px",
                      mr: 2,
                      height: "40px",
                      fontSize: "14px",
                      fontWeight: "400",
                      border: "1px solid #00B2FF",
                      background: "white",
                      color: "#00B2FF",
                      minWidth: "150px",
                    }}
                    variant={"text"}
                    fullWidth={false}
                  >
                    Select Verified ID
                  </PrimaryButton>
                )}
                {status !== "REJECTED" && (
                  <PrimaryButton
                    onClick={() => setUCheckReportModal(true)}
                    //disabled={reference.invalid}
                    data-tut="ucheck_report_application_btn"
                    sx={{
                      borderRadius: "8px",
                      p: "0px 21px",
                      mt: "6px",
                      height: "40px",
                      fontSize: "14px",
                      fontWeight: "400",
                      border: "1px solid red",
                      background: "white",
                      color: "red",
                      minWidth: "100px",
                    }}
                    variant={"text"}
                    fullWidth={false}
                  >
                    Report
                  </PrimaryButton>
                )}
              </Grid>
            )}
        </Grid>
        <Grid xs={12} item md={12}>
          <Grid
            container
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
          >
            {documents[active].name === identifiers.rtw &&
            documents[active].is_british_or_irish_citizen ? (
              <>
                {yotiReport && yotiReport.status === "COMPLETED" ? (
                  <YotiResult
                    yotiReport={
                      yotiReport as {
                        check_result: any;
                        documents: any[];
                        status: string;
                      }
                    }
                  />
                ) : (
                  <Box
                    sx={{
                      background: "#D9D9D9",
                      borderRadius: "20px",
                      minHeight: "550px",
                      display: "flex",
                    }}
                    margin={3}
                    marginTop={0}
                    padding={3}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <Typography
                      variant={"h5"}
                      sx={{ color: "#3a3a3a" }}
                      fontWeight={700}
                    >
                      Candidate is yet to complete Yoti Verification Process
                    </Typography>
                  </Box>
                )}
              </>
            ) : (
              <>
                {documents[active].name === identifiers.dbs &&
                !documents[active].on_updated_dbs_services ? (
                  <>
                    {yotiReport && yotiReport.status === "COMPLETED" ? (
                      <>
                        {!showUCheckForm && (
                          <YotiResult
                            yotiReport={
                              yotiReport as {
                                check_result: any;
                                documents: any[];
                                status: string;
                              }
                            }
                          />
                        )}
                        {showUCheckForm && (
                          <Grid container padding={2}>
                            {validationAlertPopUp &&
                              errorMessages?.length !== 0 && (
                                <Grid item xs={12} mb={2}>
                                  {errorMessages.map((value) => (
                                    <Alert
                                      sx={{ mb: 2 }}
                                      icon={false}
                                      onClose={() => {
                                        removeMessage(value.message);
                                      }}
                                      severity={"error"}
                                    >
                                      {value.message}
                                    </Alert>
                                  ))}
                                </Grid>
                              )}
                            {validationAlertPopUp &&
                              errorArray?.length !== 0 && (
                                <Grid item xs={12} mb={2}>
                                  {errorArray.map((value) => (
                                    <Alert
                                      sx={{ mb: 2 }}
                                      icon={false}
                                      onClose={() => {
                                        removeAlert(value);
                                      }}
                                      severity={"error"}
                                    >
                                      {value}
                                    </Alert>
                                  ))}
                                </Grid>
                              )}
                            <Review
                              role="agency"
                              dbs_type={
                                documents[active].sub_documents[0].key as string
                              }
                            />
                          </Grid>
                        )}
                        <Grid
                          container
                          className="card-footer-table pt-2 pb-2"
                          display={"flex"}
                          justifyContent={"center"}
                        >
                          {!!status &&
                            status !== "DRAFT" &&
                            status !== "SUBMITTED" && (
                              <Grid
                                item
                                md={12}
                                display={"flex"}
                                flexDirection={{ xs: "column", md: "row" }}
                                justifyContent={"space-evenly"}
                              >
                                <PrimaryButton
                                  fullWidth={false}
                                  sx={{
                                    mt: 1,
                                    background: "#5AB9F9",
                                    border: "1px solid #5AB9F9",
                                    color: "#ffffff",
                                    fontSize: 16,
                                    fontWeight: 500,
                                  }}
                                  onClick={async () =>
                                    setShowUCheckForm(!showUCheckForm)
                                  }
                                >
                                  {!showUCheckForm
                                    ? "View DBS check"
                                    : "View verified ID"}
                                </PrimaryButton>
                                {showUCheckForm && (
                                  <PrimaryButton
                                    fullWidth={false}
                                    sx={{
                                      mt: 1,
                                      background: "#5AB9F9",
                                      border: "1px solid #5AB9F9",
                                      color: "#ffffff",
                                      fontSize: 16,
                                      fontWeight: 500,
                                    }}
                                    data-tut="ucheck_draft_application_btn"
                                    disabled={isLoading}
                                    onClick={async () => {
                                      await saveToDraft(false);
                                    }}
                                  >
                                    {isLoading && (
                                      <CircularProgress
                                        sx={{ mr: 1 }}
                                        size={20}
                                      />
                                    )}
                                    Save Draft
                                  </PrimaryButton>
                                )}
                                {showUCheckForm && status !== "REJECTED" && (
                                  <PrimaryButton
                                    data-tut="ucheck_submit_application_btn"
                                    fullWidth={false}
                                    sx={{
                                      mt: 1,
                                      background: "#5AB9F9",
                                      border: "1px solid #5AB9F9",
                                      color: "#ffffff",
                                      fontSize: 16,
                                      fontWeight: 500,
                                    }}
                                    disabled={
                                      !(
                                        uCheckPersonalHistory.form1_valid &&
                                        uCheckPersonalHistory.form2_valid
                                      ) ||
                                      !(
                                        uCheckCurrentAddress.form_valid &&
                                        uCheckPreviousAddresses.form_valid
                                      ) ||
                                      !(
                                        uCheckPassportInformation.form_valid &&
                                        uCheckDrivingLicenceInformation.form_valid
                                      ) ||
                                      (uCheckPassportInformation.has_passport ===
                                        "n" &&
                                        uCheckDrivingLicenceInformation.uk_driving_licence ===
                                          "n")
                                    }
                                    onClick={() => setSubmitApiAlert(true)}
                                  >
                                    Submit DBS Application
                                  </PrimaryButton>
                                )}
                              </Grid>
                            )}
                        </Grid>
                      </>
                    ) : (
                      <Box
                        sx={{
                          background: "#D9D9D9",
                          borderRadius: "20px",
                          minHeight: "550px",
                          display: "flex",
                        }}
                        margin={3}
                        marginTop={0}
                        padding={3}
                        justifyContent={"center"}
                        alignItems={"center"}
                      >
                        <Typography
                          variant={"h5"}
                          sx={{ color: "#3a3a3a" }}
                          fontWeight={700}
                        >
                          Candidate is yet to complete Yoti Verification Process
                        </Typography>
                      </Box>
                    )}
                  </>
                ) : (
                  <>
                    {documents[active].sub_document_count !== 0 ? (
                      <>
                        <TableContainer
                          component={Paper}
                          sx={{ boxShadow: "none", width: "90%" }}
                        >
                          <Table
                            sx={{
                              minWidth: 600,
                              borderSpacing: "0 1rem",
                              borderCollapse: "separate",
                              backgroundColor: "#f5fbff",
                            }}
                          >
                            <TableHead>
                              <TableRow>
                                <StyledTableCell sx={{ minWidth: "150px" }}>
                                  Document Name
                                </StyledTableCell>
                                {displayTimePeriod && (
                                  <StyledTableCell>Time Period</StyledTableCell>
                                )}
                                <StyledTableCell sx={{ minWidth: "111px" }}>
                                  Upload Date
                                </StyledTableCell>
                                <StyledTableCell>Action</StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody className={"documents-table-body"}>
                              {documents[active].sub_documents.map(
                                (document, index) => (
                                  <>
                                    {document.uploaded_at && (
                                      <StyledTableRow
                                        className={"documents-table-body-row"}
                                        key={index}
                                      >
                                        <StyledTableCell>
                                          {document.name}
                                        </StyledTableCell>
                                        {displayTimePeriod &&
                                          document.options && (
                                            <StyledTableCell>
                                              <FormControl
                                                sx={{ minWidth: 120 }}
                                              >
                                                <Select
                                                  className="timePeriod"
                                                  sx={{
                                                    boxShadow: "none",
                                                    ".MuiOutlinedInput-notchedOutline":
                                                      {
                                                        border: 0,
                                                      },
                                                  }}
                                                  value={
                                                    document.options[
                                                      document.value
                                                    ].value
                                                  }
                                                  inputProps={{
                                                    "aria-label":
                                                      "Without label",
                                                  }}
                                                  disabled={true}
                                                >
                                                  {document.options.map(
                                                    (option, index) => (
                                                      <MenuItem
                                                        key={index}
                                                        value={option.value}
                                                        sx={{
                                                          color: "#727272",
                                                          fontWeight: "600",
                                                        }}
                                                      >
                                                        {option.label}
                                                      </MenuItem>
                                                    )
                                                  )}
                                                </Select>
                                              </FormControl>
                                            </StyledTableCell>
                                          )}
                                        <StyledTableCell
                                          sx={{ position: "relative" }}
                                        >
                                          {dayjs(
                                            new Date(document.uploaded_at)
                                          ).format(
                                            identifiers.date_format as string
                                          )}
                                          {document.approval_status ===
                                            "NEW" && (
                                            <Box
                                              sx={{
                                                background: "#5AB9F9",
                                                width: "10px",
                                                height: "10px",
                                                borderRadius: "50px",
                                                display: "inline-block",
                                                ml: 2,
                                                position: "absolute",
                                                top: "0px",
                                                right: "0px",
                                              }}
                                            ></Box>
                                          )}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                          <Grid
                                            item
                                            display={"flex"}
                                            flexWrap={"nowrap"}
                                            md={6}
                                          >
                                            <PrimaryButton
                                              onClick={() =>
                                                download(
                                                  document.document_repository_id,
                                                  true
                                                )
                                              }
                                              sx={{
                                                cursor: "pointer",
                                              }}
                                              variant={"text"}
                                              fullWidth={false}
                                            >
                                              <img
                                                src={images.viewIcon}
                                                alt="view"
                                                className="action-icons"
                                              />
                                            </PrimaryButton>
                                            {documents[active].name ===
                                              identifiers.dbs && (
                                              <PrimaryButton
                                                onClick={() =>
                                                  download(
                                                    document.document_repository_id,
                                                    false
                                                  )
                                                }
                                                sx={{
                                                  cursor: "pointer",
                                                }}
                                                variant={"text"}
                                                fullWidth={false}
                                              >
                                                <img
                                                  src={images.downloadIcon}
                                                  alt="view"
                                                  className="action-icons"
                                                />
                                              </PrimaryButton>
                                            )}
                                            {document.name !==
                                              identifiers.cv && (
                                              <>
                                                {profile.application_status !==
                                                  "Approved" && (
                                                  <PrimaryButton
                                                    onClick={() => {
                                                      if (
                                                        document.approval_status !==
                                                        "REJECTED"
                                                      ) {
                                                        setSubDocumentId(
                                                          document.id
                                                        );
                                                        setReport(true);
                                                      }
                                                    }}
                                                    // disabled={
                                                    //   document.approval_status ===
                                                    //   "REJECTED"
                                                    // }
                                                    sx={{
                                                      borderRadius: "8px",
                                                      p: "0px 21px",
                                                      ml: 2,
                                                      mt: "6px",
                                                      height: "40px",
                                                      fontSize: "14px",
                                                      fontWeight: "400",
                                                      border:
                                                        document.approval_status ===
                                                        "REJECTED"
                                                          ? "1px solid red"
                                                          : "none",
                                                      background: "white",
                                                      color:
                                                        document.approval_status ===
                                                        "REJECTED"
                                                          ? "red"
                                                          : "black",
                                                      minWidth: "100px",
                                                    }}
                                                    variant={"text"}
                                                    fullWidth={false}
                                                  >
                                                    Report
                                                  </PrimaryButton>
                                                )}
                                              </>
                                            )}
                                          </Grid>
                                        </StyledTableCell>
                                      </StyledTableRow>
                                    )}
                                  </>
                                )
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        {displayComment >= 0 && (
                          <Grid container className="card-additional-comments">
                            <Grid container>
                              <Grid
                                item
                                xs={12}
                                className=" pl-2 py-3"
                                display={"flex"}
                                alignItems={"center"}
                              >
                                Additional Comments{" "}
                                {expandCommentSec ? (
                                  <ExpandLessIcon
                                    sx={{ ml: 2 }}
                                    onClick={() =>
                                      setExpandCommentSec(!expandCommentSec)
                                    }
                                  />
                                ) : (
                                  <ExpandMoreIcon
                                    sx={{ ml: 2 }}
                                    onClick={() =>
                                      setExpandCommentSec(!expandCommentSec)
                                    }
                                  />
                                )}
                              </Grid>
                            </Grid>
                            {expandCommentSec && (
                              <>
                                <Grid
                                  item
                                  xs={12}
                                  data-tut="vetting_docs_report_input_field"
                                >
                                  <InputField
                                    multiline
                                    value={inputValues}
                                    textChange={(e) => {
                                      setInputValues(e);
                                    }}
                                    rows={10}
                                    sx={{
                                      background: "#ffffff",
                                    }}
                                    placeholder={
                                      "The applicant may email once they have fulfilled the requirement of this request."
                                    }
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  sx={{ mb: 3, textAlign: "center" }}
                                >
                                  <PrimaryButton
                                    data-tut="vetting_docs_message_save_btn"
                                    disabled={isLoading || inputValues === ""}
                                    fullWidth={false}
                                    sx={{
                                      mt: 1,
                                      background: "white",
                                      border: "1px solid #5AB9F9",
                                      color: "#5AB9F9",
                                      fontSize: 16,
                                      fontWeight: 500,
                                    }}
                                    onClick={submitFeedback}
                                  >
                                    {isLoading && (
                                      <CircularProgress
                                        sx={{ mr: 1 }}
                                        size={20}
                                      />
                                    )}
                                    Save Comments
                                  </PrimaryButton>
                                </Grid>
                              </>
                            )}
                          </Grid>
                        )}
                        {documents[active].name === identifiers.nin &&
                          documents[active]?.nin && (
                            <Grid
                              item
                              md={11}
                              xs={12}
                              display={"flex"}
                              flexDirection={"column"}
                              alignItems={"center"}
                            >
                              <Grid
                                container
                                alignItems={"center"}
                                spacing={2}
                                sx={{
                                  backgroundColor: "white",
                                  margin: "10px 10px ",
                                  border: "1px dashed #DADADA",
                                  borderRadius: "12px",
                                }}
                                md={12}
                                xs={12}
                              >
                                <Grid
                                  container
                                  className="card-head-table mb-1"
                                >
                                  <Grid
                                    item
                                    xs={12}
                                    md={12}
                                    sx={{ pl: 1, py: 2 }}
                                  >
                                    <Typography
                                      variant={"body2"}
                                      fontSize={"16px"}
                                      fontWeight={"bold"}
                                      sx={{ pl: 2 }}
                                    >
                                      {documents[active].name}
                                    </Typography>
                                  </Grid>
                                </Grid>
                                <Grid item md={12}>
                                  <div className="document-card mt-0">
                                    <Typography
                                      fontSize={"18px"}
                                      variant={"body2"}
                                      fontWeight={"bold"}
                                      padding={"7px 0px"}
                                      textAlign={"center"}
                                    >
                                      {documents[active]?.nin}
                                    </Typography>
                                  </div>
                                </Grid>
                              </Grid>
                            </Grid>
                          )}
                        {documents[active].name === identifiers.rtw &&
                          documents[active]?.rtw_code && (
                            <Grid
                              item
                              xs={10}
                              md={10}
                              display={"flex"}
                              flexDirection={"column"}
                              alignItems={"center"}
                            >
                              <Grid
                                container
                                alignItems={"center"}
                                spacing={2}
                                sx={{
                                  backgroundColor: "white",
                                  margin: "10px 10px ",
                                  border: "1px dashed #DADADA",
                                  borderRadius: "12px",
                                }}
                              >
                                <Grid
                                  container
                                  className="card-head-table mb-1"
                                >
                                  <Grid
                                    item
                                    xs={12}
                                    md={12}
                                    sx={{ pl: 1, py: 2 }}
                                  >
                                    <Typography
                                      variant={"body2"}
                                      fontSize={"16px"}
                                      fontWeight={"bold"}
                                      sx={{ pl: 2 }}
                                    >
                                      {documents[active].name}
                                    </Typography>
                                  </Grid>
                                </Grid>
                                <Grid item md={12}>
                                  <div className="document-card mt-0">
                                    <Typography
                                      fontSize={"18px"}
                                      variant={"body2"}
                                      fontWeight={"bold"}
                                      padding={"7px 0px"}
                                      textAlign={"center"}
                                    >
                                      {documents[active]?.rtw_code}
                                    </Typography>
                                  </div>
                                </Grid>
                              </Grid>
                            </Grid>
                          )}
                        {documents[active].name === identifiers.dbs && (
                          <>
                            <Grid
                              container
                              sx={{
                                disaply: "flex",
                                flexDirection: "row",
                                justifyContent: "space-evenly",
                              }}
                            >
                              {documents[active]?.dbs_cnumber && (
                                <Grid
                                  item
                                  md={5}
                                  xs={10}
                                  display={"flex"}
                                  flexDirection={"column"}
                                  alignItems={"center"}
                                  sx={{
                                    backgroundColor: "white",
                                    margin: "10px 10px ",
                                    border: "1px dashed #DADADA",
                                    borderRadius: "12px",
                                  }}
                                >
                                  <Grid
                                    container
                                    className="card-head-table mb-1"
                                    sx={{
                                      padding: "8px 16px",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Typography
                                      variant={"body2"}
                                      fontSize={"16px"}
                                      fontWeight={"bold"}
                                    >
                                      C Number
                                    </Typography>
                                  </Grid>
                                  <Box className="document-card mt-0">
                                    <Typography
                                      fontSize={"18px"}
                                      variant={"body2"}
                                      fontWeight={"bold"}
                                      padding={"7px 0px"}
                                      textAlign={"center"}
                                    >
                                      {documents[active]?.dbs_cnumber}
                                    </Typography>
                                  </Box>
                                </Grid>
                              )}
                              {documents[active]?.dbs_dob && (
                                <Grid
                                  item
                                  md={5}
                                  xs={10}
                                  display={"flex"}
                                  flexDirection={"column"}
                                  alignItems={"center"}
                                  sx={{
                                    backgroundColor: "white",
                                    margin: "10px 10px ",
                                    border: "1px dashed #DADADA",
                                    borderRadius: "12px",
                                  }}
                                >
                                  <Grid
                                    container
                                    className="card-head-table mb-1"
                                    sx={{
                                      padding: "8px 16px",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Typography
                                      variant={"body2"}
                                      fontSize={"16px"}
                                      fontWeight={"bold"}
                                    >
                                      Date Of Birth
                                    </Typography>
                                  </Grid>
                                  <Box className="document-card mt-0">
                                    <Typography
                                      fontSize={"18px"}
                                      variant={"body2"}
                                      fontWeight={"bold"}
                                      padding={"7px 0px"}
                                      textAlign={"center"}
                                    >
                                      {documents[active]?.dbs_dob}
                                    </Typography>
                                  </Box>
                                </Grid>
                              )}
                            </Grid>
                            <TableContainer
                              component={Paper}
                              sx={{ boxShadow: "none", width: "90%" }}
                            >
                              <Table
                                sx={{
                                  minWidth: 600,
                                  borderSpacing: "0 1rem",
                                  borderCollapse: "separate",
                                  backgroundColor: "#f5fbff",
                                }}
                              >
                                <TableHead>
                                  <TableRow>
                                    <StyledTableCell
                                      sx={{ minWidth: "150px", py: 0 }}
                                    ></StyledTableCell>
                                    <StyledTableCell
                                      sx={{ py: 0 }}
                                    ></StyledTableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody className={"documents-table-body"}>
                                  <StyledTableRow
                                    className={"documents-table-body-row"}
                                  >
                                    <StyledTableCell>
                                      DBS Validation Proof
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      <Grid
                                        item
                                        display={"flex"}
                                        flexWrap={"nowrap"}
                                        md={6}
                                      >
                                        <PrimaryButton
                                          onClick={
                                            () => console.log("download")
                                            // download(
                                            //   document.document_repository_id,
                                            //   true
                                            // )
                                          }
                                          sx={{
                                            cursor: "pointer",
                                          }}
                                          variant={"text"}
                                          fullWidth={false}
                                        >
                                          <img
                                            src={images.upload}
                                            alt="view"
                                            className="action-icons"
                                          />
                                        </PrimaryButton>
                                        <PrimaryButton
                                          onClick={
                                            () => console.log("download")
                                            // download(
                                            //   document.document_repository_id,
                                            //   false
                                            // )
                                          }
                                          sx={{
                                            cursor: "pointer",
                                          }}
                                          variant={"text"}
                                          fullWidth={false}
                                        >
                                          <img
                                            src={images.downloadIcon}
                                            alt="view"
                                            className="action-icons"
                                          />
                                        </PrimaryButton>
                                      </Grid>
                                    </StyledTableCell>
                                  </StyledTableRow>
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </>
                        )}
                        <Grid
                          container
                          className="card-footer-table pt-2 pb-2"
                          display={"flex"}
                          justifyContent={"space-evenly"}
                        >
                          {documents[active].name !== identifiers.dbs && (
                            <Grid
                              data-tut="download_all_btn"
                              item
                              xs={12}
                              md={3}
                            >
                              <PrimaryButton
                                fullWidth={true}
                                sx={{
                                  mt: 1,
                                  background: "white",
                                  border: "1px solid #5AB9F9",
                                  color: "#5AB9F9",
                                  fontSize: 16,
                                  fontWeight: 500,
                                }}
                                onClick={async () => {
                                  await downloadAllDocuments(
                                    documents[active].id
                                  );
                                }}
                              >
                                Download All
                              </PrimaryButton>
                            </Grid>
                          )}
                          {documents[active].name === identifiers.dbs && (
                            <>
                              {status === "SUBMITTED" && (
                                <Grid item xs={12} md={3}>
                                  <PrimaryButton
                                    fullWidth={true}
                                    sx={{
                                      mt: 1,
                                      background: "white",
                                      border: "1px solid #5AB9F9",
                                      color: "#5AB9F9",
                                      fontSize: 16,
                                      fontWeight: 500,
                                    }}
                                    onClick={() => setYotiResultOpen(true)}
                                  >
                                    View verified ID
                                  </PrimaryButton>
                                </Grid>
                              )}
                              {status === "SUBMITTED" && (
                                <Grid item xs={12} md={3}>
                                  <PrimaryButton
                                    fullWidth={true}
                                    sx={{
                                      mt: 1,
                                      background: "white",
                                      border: "1px solid #5AB9F9",
                                      color: "#5AB9F9",
                                      fontSize: 16,
                                      fontWeight: 500,
                                    }}
                                    onClick={() => setUCheckPopUpOpen(true)}
                                  >
                                    View application form
                                  </PrimaryButton>
                                </Grid>
                              )}
                            </>
                          )}
                        </Grid>
                      </>
                    ) : (
                      <Grid container justifyContent={"center"}>
                        {documents[active].name === identifiers.dbs && (
                          <Grid item xs={12} md={12} padding="0px 10px" mb={2}>
                            {status === "SUBMITTED" && (
                              <Stack
                                direction={"row"}
                                alignItems="center"
                                sx={{
                                  background: "#279b4eb0",
                                  color: "black",
                                  borderRadius: "10px",
                                  padding: "10px",
                                  border: "1px dashed #45C9D0",
                                }}
                              >
                                <img
                                  src={images.successIcon}
                                  alt="success icon"
                                />
                                <Box sx={{ ml: 2 }}>
                                  Thank you for completing{" "}
                                  {candidate_info?.name}’s DBS Application. The
                                  application is under review by uCheck.
                                </Box>
                              </Stack>
                            )}
                            {status === "APPROVED" && (
                              <Stack
                                direction={"row"}
                                alignItems="center"
                                sx={{
                                  background: "#279b4eb0",
                                  color: "black",
                                  borderRadius: "10px",
                                  padding: "10px",
                                  border: "1px dashed #45C9D0",
                                }}
                              >
                                <img
                                  src={images.successIcon}
                                  alt="success icon"
                                />
                                <Box sx={{ ml: 2 }}>
                                  uCheck has been approved{" "}
                                  {candidate_info?.name}’s DBS Application. Once
                                  candidate has been uploaded the physical copy
                                  you can view that application.
                                </Box>
                              </Stack>
                            )}
                          </Grid>
                        )}
                        {documents[active].name === identifiers.opc &&
                        !documents[active].is_opc_needed ? (
                          <Grid
                            md={10}
                            xs={10}
                            className="pop-up-dotted-card-dbs content-card"
                            sx={{
                              marginTop: "55px",
                              marginBottom: "40px",
                              padding: "30px",
                            }}
                          >
                            <Typography
                              variant={"body1"}
                              color={"#333333"}
                              sx={{
                                mb: 1,
                                fontSize: "18px",
                                fontWeight: "500",
                                letterSpacing: 0.15,
                                marginBottom: "30px",
                              }}
                            >
                              The candidate has not stayed abroad for more than
                              6 months
                            </Typography>
                          </Grid>
                        ) : (
                          <Grid
                            item
                            xs={12}
                            md={12}
                            minHeight={"200px"}
                            textAlign={"center"}
                          >
                            <img
                              src={images.no_data_available}
                              width="100%"
                              height="100px"
                              style={{ objectFit: "contain" }}
                              alt={"No ReferenceForms"}
                            />
                            <Typography
                              variant="h6"
                              fontSize="14px"
                              fontWeight="400"
                            >
                              No Documents Uploaded
                            </Typography>
                          </Grid>
                        )}
                        {documents[active].name === identifiers.dbs &&
                          status === "SUBMITTED" && (
                            <Grid
                              container
                              className="card-footer-table pt-2 pb-2"
                              display={"flex"}
                              justifyContent={"space-evenly"}
                            >
                              <Grid item xs={12} md={5}>
                                <PrimaryButton
                                  fullWidth={true}
                                  sx={{
                                    mt: 1,
                                    background: "white",
                                    border: "1px solid #5AB9F9",
                                    color: "#5AB9F9",
                                    fontSize: 16,
                                    fontWeight: 500,
                                  }}
                                  data-tut="yoti_view_application_btn"
                                  onClick={() => setYotiResultOpen(true)}
                                >
                                  View verified ID
                                </PrimaryButton>
                              </Grid>
                              <Grid item xs={12} md={5}>
                                <PrimaryButton
                                  fullWidth={true}
                                  data-tut="ucheck_view_application_btn"
                                  sx={{
                                    mt: 1,
                                    background: "white",
                                    border: "1px solid #5AB9F9",
                                    color: "#5AB9F9",
                                    fontSize: 16,
                                    fontWeight: 500,
                                  }}
                                  onClick={() => setUCheckPopUpOpen(true)}
                                >
                                  View application form
                                </PrimaryButton>
                              </Grid>
                            </Grid>
                          )}
                      </Grid>
                    )}
                  </>
                )}
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default DocumentList;
