import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import UpdatedServices from "./UpdatedServices";
import {
  CandidateIDV,
  RoutingPageValue,
  setValue,
  uCheckStatus,
  VettingDocuments,
} from "../../../../../../../utils/redux";
import { identifiers } from "../../../../../../../utils/constants/identifiers";
import Yoti from "../common/Yoti/Yoti";
import UploadFiles from "../common/UploadFiles";
import UCheck from "./UCheck";
import Information from "./Information";

export interface DBSProps {
  active: number;
}

const DBS: FC<DBSProps> = ({ active }) => {
  const dispatch = useDispatch();
  const value = useSelector(RoutingPageValue);
  const documents = useSelector(VettingDocuments);
  const candidateIdv = useSelector(CandidateIDV);
  const UcheckStatus = useSelector(uCheckStatus);

  const [component, setComponent] = useState<
    "UpdatedServices" | "Type" | "Information" | "Upload"
  >("UpdatedServices");
  const [dbs, setDbs] = useState<{
    comments: string | undefined;
    objective: string;
    display: boolean;
  }>({
    comments: "",
    objective: "",
    display: true,
  });
  const [scheme, setScheme] = useState<"DBS" | "DBS_RTW">("DBS");

  const setDbsValue = (dbs_value: boolean) => {
    const rtw = documents.find((document) => document.name === identifiers.rtw);
    if (dbs_value) {
      dispatch(setValue(value + 1));
    } else {
      setDbs((prevState) => ({
        ...prevState,
        objective: documents[active].sub_documents[0].name
          .split(" ")[0]
          .toUpperCase(),
        display: false,
      }));
      if (!!rtw && rtw.is_british_or_irish_citizen) {
        setScheme("DBS_RTW");
      } else {
        setScheme("DBS");
      }
    }
  };

  useEffect(() => {
    if (value === 1) {
      if (documents[active].on_updated_dbs_services !== undefined) {
        if (!documents[active].on_updated_dbs_services) {
          const rtw = documents.find(
            (document) => document.name === identifiers.rtw
          );
          setDbs((prevState) => ({
            ...prevState,
            objective: documents[active].sub_documents[0].name
              .split(" ")[0]
              .toUpperCase(),
            display: false,
          }));
          if (!!rtw && rtw.is_british_or_irish_citizen) {
            setScheme("DBS_RTW");
          } else {
            setScheme("DBS");
          }
        } else {
          dispatch(setValue(value + 1));
        }
      }
    } else if (value === 2) {
      if (documents[active].dbs_type !== undefined) {
        dispatch(setValue(value + 1));
      }
    }
  }, [active, dispatch, documents, value]);

  useEffect(() => {
    if (!!UcheckStatus) {
      if (
        UcheckStatus === "IN_REVIEW" ||
        UcheckStatus === "SUBMITTED" ||
        UcheckStatus === "REJECTED"  ||
        UcheckStatus === 'APPROVED'
      ) {
        dispatch(setValue(2));
        setDbs((prevState) => ({
          ...prevState,
          display: true,
        }));
      }
    }
  }, [documents, active, UcheckStatus, dispatch]);

  useEffect(() => {
    if (value === 1) {
      setComponent("UpdatedServices");
    } else if (value === 2) {
      setComponent("Information");
    } else if (value === 3) {
      setComponent("Upload");
    }
  }, [value]);

  return (
    <>
      {dbs.display ? (
        <>
          {component === "UpdatedServices" && (
            <UpdatedServices
              setDbs={setDbsValue}
              vettingDocId={documents[active].id}
              comments={documents[active].dbs_comment}
              name={documents[active].sub_documents[0].name}
            />
          )}
          {component === "Information" && (
            <Information
              active={active}
              comments={documents[active].dbs_comment}
            />
          )}
          {component === "Upload" && <UploadFiles />}
        </>
      ) : (
        <>
          {candidateIdv?.status === "COMPLETED" ? (
            <UCheck type={documents[active].sub_documents[0].key as string} />
          ) : (
            <Yoti scheme={scheme} objective={dbs.objective} active={active} />
          )}
        </>
      )}
    </>
  );
};

export default DBS;
