import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import dayjs from "dayjs";
import { identifiers } from "../../../utils/constants/identifiers";
import "../../Authenticated/Agency/CandidateProfile/RequiredReference/RequiredReference.scss";
import { images } from "../../../utils/constants/images";
import PrimaryButton from "../../../components/Button/PrimaryButton";
import Modal from "../../../components/modal/Modal";
import axiosInstance from "../../../utils/axios";
import { URLS } from "../../../utils/constants/urls";
import { useDispatch, useSelector } from "react-redux";
import {
  setNotificationMessage,
  CandidateProfileReload,
  setCandidateProfileReload,
} from "../../../utils/redux";
import InputField from "../../../components/InputField";
import PageLoader from "../../../components/PageLoader";
import ViewReference from "../../Authenticated/Agency/CandidateProfile/RequiredReference/ViewReference";
import ReferenceForm from "../../Authenticated/Agency/CandidateProfile/RequiredReference/ReferenceForm";
import { useParams } from "react-router-dom";
import {
  CandidateInfo,
  AcademicData,
} from "../../../utils/redux/reducer/candidate-vetting-slice";
import axios from "axios";
import * as Amplify from "../../../utils/services/amplify";
import { useTour } from "@reactour/tour";
import { tourStep } from "../../../utils/constants/coachMarks";
import {
  User,
  setUserData,
} from "../../../utils/redux/reducer/authentication-slice";
import { CognitoUser } from "amazon-cognito-identity-js";
import { Auth } from "aws-amplify";

export interface Reference {
  approved: boolean;
  is_approved: boolean;
  comments: string;
  invalid: boolean;
  pushed_to_blockchain: boolean;
  candidate_id: string;
  referee_details: {
    candidate_course: string;
    candidate_education: string;
    candidate_employment_date: {
      from: string;
      to: string;
    };
    candidate_job_title: string;
    code: string;
    organisation: string;
    organisation_address: string;
    phone_number: string;
    referee_email: string;
    referee_name: string;
    relationship: string;
  };
  referee_comments: string;
  ked_questionnaire_value: {
    [key: string]: {
      label: string;
      value: string | boolean | number;
    };
  };
  id: string;
  is_complete: boolean;
  type: string;
  reference_request_id: string;
  request_reference_id: string;
  generate_pdf: boolean;
  referee_documents: {
    id: string;
    candidate_reference_id: string;
    key: string;
    uploaded_on: Date | number;
    reference_form: {
      agency_id: string;
      id: string;
      name: string;
      type: string;
    };
  }[];
  is_gap_reference: boolean;
  additional_questionnaire_value: any[];
}

const ReferenceList: FC<{
  type: string;
  role: "agency" | "candidate";
  setReInitiateRef?: (value: boolean) => void;
  showAddForm?: boolean;
  setShowAddForm?: (showAddForm: boolean) => void;
  setReferenceId?: Dispatch<SetStateAction<string>>;
}> = ({
  type,
  role,
  setReInitiateRef,
  setShowAddForm,
  showAddForm,
  setReferenceId,
}) => {
  const dispatch = useDispatch();
  const { id, candidate_id } = useParams() as any;
  const { setIsOpen, currentStep, setCurrentStep } = useTour();

  const reload = useSelector(CandidateProfileReload);
  const profileInfo = useSelector(CandidateInfo);
  const profile = useSelector(AcademicData);
  const user: { [key: string]: string } = useSelector(User) as {
    [key: string]: string;
  };

  const [references, setReferences] = useState<Reference[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [report, setReport] = useState(false);
  const [inputValues, setInputValues] = useState<string>("");
  const [approveModal, setApproveModal] = useState(false);
  const [openViewModal, setOpenViewModal] = useState<boolean>(false);
  const [selectedReference, setSelectedReference] = useState<Reference>();
  // const [referenceReportedIndex, setReferenceReportedIndex] =
  //   useState<number>();

  const getReferences = useCallback(async () => {
    let referenceType;
    if (type === "employment_gap") {
      referenceType = "gap";
    } else {
      referenceType = type;
    }
    try {
      if (role === "agency") {
        const { data } = await axiosInstance.get(
          `${URLS.agency_candidate}/${candidate_id}/request/${id}/references/${referenceType}`
        );
        setReferences(data);
        setIsLoading(false);
      } else {
        if (!showAddForm || showAddForm) {
          const { data } = await axiosInstance.get(
            `${URLS.candidate_request_details}${id}/references/${referenceType}`
          );
          setReferences(data);
          setIsLoading(false);
        }
      }
    } catch (e) {
      setIsLoading(false);
      console.log(e);
      if (await Auth.currentUserInfo()) {
        dispatch(
          setNotificationMessage({
            display: true,
            severity: "error",
            message: "There is an error in fetching references",
          })
        );
      }
    }
  }, [candidate_id, dispatch, id, role, showAddForm, type]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      await getReferences();
    })();
  }, [getReferences]);

  const setCoachMarks = useCallback(
    async (value: any) => {
      //if (!!user && user["custom:reference_tutorial"] !== "true") {
      const currentUser: CognitoUser = await Amplify.UserDetail();
      const body = value;
      await Amplify.UpdateUserDetails(body, currentUser);
      await Amplify.RefreshSession();
      dispatch(setUserData({ ...user, ...body }));
      //}
    },
    [user, dispatch]
  );

  useEffect(() => {
    if (role === "agency") {
      if (!!user && user["custom:candidate_ref"] !== "true") {
        setCurrentStep(tourStep.candidate_required_ref.index);
      }
    }

    if (role === "candidate") {
      if (!!user && user["custom:req_ref_list"] !== "true") {
        if (references?.length === 0) {
          setCurrentStep(
            tourStep.candidate_required_reference_listing_page.index
          );
        }
      }
    }

    if (role === "candidate") {
      if (!!user && user["custom:reference_report"] !== "true") {
        let a = references.findIndex((reference) => reference.invalid);
        // setReferenceReportedIndex(a);
        if (a >= 0) {
          setCurrentStep(tourStep.candidate_reference_reported.index);
        }
      }
    }
  }, [role, references, user, setCurrentStep]);

  useEffect(() => {
    if (role === "agency") {
      if (!!user && user["custom:reference_received"] !== "true") {
        if (openViewModal) {
          if (selectedReference?.is_complete) {
            setCurrentStep(tourStep.required_ref_completed.index);
          }
        }
      }
    }
  }, [user, role, openViewModal, selectedReference, setCurrentStep]);

  useEffect(() => {
    if (tourStep.candidate_required_ref.index === currentStep) {
      setIsOpen(true);
    } else if (references?.length > 0) {
      if (
        currentStep ===
        tourStep.candidate_required_ref.index +
          tourStep.candidate_required_ref.steps
      ) {
        setIsOpen(false);
        if (!!user && user["custom:candidate_ref"] !== "true") {
          (async () => {
            await setCoachMarks({
              "custom:candidate_ref": "true",
            });
          })();
        }
      }
    } else {
      if (
        currentStep ===
        tourStep.candidate_required_ref.index +
          (tourStep.candidate_required_ref.steps - 1)
      ) {
        setIsOpen(false);
        if (!!user && user["custom:candidate_ref"] !== "true") {
          (async () => {
            await setCoachMarks({
              "custom:candidate_ref": "true",
            });
          })();
        }
      }
    }
    if (
      tourStep.candidate_required_reference_listing_page.index === currentStep
    ) {
      setIsOpen(true);
    } else if (
      currentStep ===
      tourStep.candidate_required_reference_listing_page.index +
        tourStep.candidate_required_reference_listing_page.steps
    ) {
      setIsOpen(false);
      if (!!user && user["custom:req_ref_list"] !== "true") {
        (async () => {
          await setCoachMarks({
            "custom:req_ref_list": "true",
          });
        })();
      }
    }
    let a = references.findIndex((reference) => reference.invalid);
    if (a >= 0) {
      if (!!user && user["custom:reference_report"] !== "true") {
        if (tourStep.candidate_reference_reported.index === currentStep) {
          setIsOpen(true);
        } else if (
          currentStep ===
          tourStep.candidate_reference_reported.index +
            tourStep.candidate_reference_reported.steps
        ) {
          setIsOpen(false);
          if (!!user && user["custom:reference_report"] !== "true") {
            (async () => {
              await setCoachMarks({
                "custom:reference_report": "true",
              });
            })();
          }
        }
      }
    }

    if (openViewModal) {
      if (tourStep.required_ref_completed.index === currentStep) {
        setIsOpen(true);
      } else if (
        currentStep ===
        tourStep.required_ref_completed.index +
          tourStep.required_ref_completed.steps
      ) {
        setIsOpen(false);
        if (!!user && user["custom:reference_received"] !== "true") {
          (async () => {
            await setCoachMarks({
              "custom:reference_received": "true",
            });
          })();
        }
      }
    }
  }, [
    currentStep,
    openViewModal,
    references?.length,
    setCoachMarks,
    setIsOpen,
    user,
    references,
  ]);

  const download = async (id: string) => {
    try {
      setIsLoading(true);
      const session = await Amplify.currentSession();
      const token = session.getIdToken().getJwtToken();

      const { data } = await axios.get(
        `${process.env.REACT_APP_DOWNLOADS_API_URL}${URLS.download_candidate_referee_documents}${id}/download`,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      setIsLoading(false);
      window.open(data.download_url, "_blank");
    } catch (e) {
      setIsLoading(false);
      console.log(e);
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "error",
          message: "There is an error in downloading",
        })
      );
    }
  };

  const reportReference = useCallback(async () => {
    setIsLoading(true);
    try {
      let payload = {
        comment: inputValues,
      };

      await axiosInstance.patch(
        `${URLS.agency_refrence}/${selectedReference?.id}/report`,
        payload
      );
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "success",
          message: "Reference rejected successfully",
        })
      );
      await getReferences();
      dispatch(setCandidateProfileReload(!reload));
      setReport(false);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "error",
          message: "There is an error in rejecting the reference",
        })
      );
      setReport(false);
      setIsLoading(false);
    }
  }, [inputValues, selectedReference?.id, dispatch, getReferences, reload]);

  const approveReference = useCallback(async () => {
    setIsLoading(true);
    try {
      await axiosInstance.post(
        `${URLS.approve_reference}${selectedReference?.id}/approve`
      );
      await axiosInstance.post(
        `${URLS.approve_reference}${selectedReference?.id}/pdf`
      );
      await axiosInstance.post(
        `${URLS.approve_reference}${selectedReference?.id}/email`
      );
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "success",
          message: "Reference Approved successfully",
        })
      );
      await getReferences();
      setApproveModal(false);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "error",
          message: "There is an error in approving the reference",
        })
      );
      setApproveModal(false);
      setIsLoading(false);
    }
  }, [selectedReference, dispatch, getReferences]);

  const reportModalText = useMemo(() => {
    return (
      <Grid container>
        <Grid item>
          <Typography
            variant="h6"
            sx={{
              marginBottom: "20px",
              fontSize: 18,
              fontWeight: 500,
              color: "#000000",
              textAlign: "center",
              letterSpacing: "0.15px",
            }}
          >
            Report Reference?
          </Typography>
          <Typography
            variant="h6"
            sx={{
              mb: 3,
              fontSize: 16,
              fontWeight: 500,
              color: "#727272",
            }}
          >
            Reference will be rejected and Applicant will have to create a new
            Reference.
            <br />
            Are you sure?
          </Typography>
          <Grid container>
            <Grid item>
              <Grid
                item
                xs={12}
                className=" pl-2 py-3"
                display={"flex"}
                alignItems={"center"}
              >
                Additional Comments{" "}
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <InputField
                multiline
                value={inputValues}
                textChange={(e) => {
                  console.log(e, inputValues);
                  setInputValues(e);
                }}
                rows={10}
                sx={{ background: "#ffffff" }}
                placeholder={
                  "The applicant may email once they have fulfilled the requirement of this request."
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          alignItems="right"
          justifyContent="right"
          sx={{ flexWrap: "noWrap" }}
        >
          <PrimaryButton
            variant={"text"}
            fullWidth={false}
            sx={{
              mt: 1,
              mr: 2,
              background: "white",
              border: "1px solid #5AB9F9",
              color: "#5AB9F9",
              fontSize: 16,
              fontWeight: 500,
            }}
            onClick={reportReference}
            disabled={isLoading || inputValues === ""}
          >
            {isLoading && <CircularProgress sx={{ mr: 1 }} size={20} />}
            Report Reference
          </PrimaryButton>
          <PrimaryButton
            disabled={isLoading}
            fullWidth={false}
            sx={{
              mt: 1,
              background: "#5AB9F9",
              color: "#ffffff",
              fontSize: 16,
              fontWeight: 500,
            }}
            onClick={() => {
              setReport(false);
            }}
          >
            Cancel
          </PrimaryButton>
        </Grid>
      </Grid>
    );
  }, [inputValues, reportReference, isLoading]);

  const openReportModal = useMemo(() => {
    return (
      <Modal
        open={report}
        setModalClose={setReport}
        children={reportModalText}
        title={undefined}
        size="sm"
        className={undefined}
      />
    );
  }, [report, reportModalText]);

  const approveModalText = useMemo(() => {
    return (
      <Grid container>
        <Grid item>
          <Typography
            variant="h6"
            sx={{
              marginBottom: "20px",
              fontSize: 18,
              fontWeight: 500,
              color: "#000000",
              textAlign: "center",
              letterSpacing: "0.15px",
            }}
          >
            Approve Reference?
          </Typography>
          <Typography
            variant="h6"
            sx={{
              mb: 3,
              fontSize: 16,
              fontWeight: 500,
              color: "#727272",
            }}
          >
            Reference will be Approved
            <br />
            Are you sure?
          </Typography>
        </Grid>
        <Grid
          container
          alignItems="right"
          justifyContent="right"
          sx={{ flexWrap: "noWrap" }}
        >
          <PrimaryButton
            variant={"text"}
            fullWidth={false}
            sx={{
              mt: 1,
              mr: 2,
              background: "white",
              border: "1px solid #5AB9F9",
              color: "#5AB9F9",
              fontSize: 16,
              fontWeight: 500,
            }}
            onClick={approveReference}
            disabled={isLoading}
          >
            {isLoading && <CircularProgress sx={{ mr: 1 }} size={30} />}
            Approve Reference
          </PrimaryButton>
          <PrimaryButton
            disabled={isLoading}
            fullWidth={false}
            sx={{
              mt: 1,
              background: "#5AB9F9",
              color: "#ffffff",
              fontSize: 16,
              fontWeight: 500,
            }}
            onClick={() => {
              setApproveModal(false);
            }}
          >
            Cancel
          </PrimaryButton>
        </Grid>
      </Grid>
    );
  }, [approveReference, isLoading]);

  const viewReferenceModal = useMemo(() => {
    return (
      <Grid container>
        <Grid container className="card-head-table">
          <Grid
            item
            md={12}
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: { xs: "column", md: "row" },
              px: 2,
              py: 2,
              minHeight: "40px",
            }}
          >
            <Box display={"flex"} alignItems={"center"}>
              <img src={images.kedIcon} alt="kedIcon" />
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                {type === identifiers.character || type === identifiers.gap ? (
                  <Typography
                    variant={"body2"}
                    fontSize={"16px"}
                    fontWeight={"500"}
                    textTransform={"capitalize"}
                    sx={{ pl: 1, wordWrap: "break-all" }}
                  >
                    {/* {type} Reference : {profileInfo?.name} */}
                    {selectedReference?.is_gap_reference
                      ? `Employment Gap Reference :${profileInfo?.name}`
                      : `${type} Reference : ${profileInfo?.name}`}
                  </Typography>
                ) : (
                  <Typography
                    variant={"body2"}
                    fontSize={"16px"}
                    fontWeight={"500"}
                    textTransform={"capitalize"}
                    sx={{ pl: 1, wordWrap: "break-all" }}
                  >
                    {type} Reference : {profileInfo?.name}
                  </Typography>
                )}
                {selectedReference?.pushed_to_blockchain && (
                  <Typography
                    variant={"body2"}
                    fontSize={"16px"}
                    fontWeight={"500"}
                    textTransform={"capitalize"}
                    sx={{ pl: 1, wordWrap: "break-all" }}
                  >
                    Open Time Stamp Hash :{" "}
                    <span style={{ color: "#00B2FF" }}>
                      {" "}
                      b63c231da0bb036e334aeefc01fc5181
                    </span>
                  </Typography>
                )}
              </Box>
            </Box>
            <PrimaryButton
              fullWidth={false}
              sx={{
                background: selectedReference?.is_complete
                  ? "#6279F7"
                  : "#F8E592",
                color: "#ffffff",
                fontSize: 16,
                fontWeight: 500,
              }}
              data-tut={
                selectedReference?.is_complete ? "required_ref_completed" : ""
              }
            >
              {selectedReference?.is_complete ? "Complete" : "Pending"}
            </PrimaryButton>
          </Grid>
        </Grid>
        {role === "agency" && selectedReference?.is_complete ? (
          <ViewReference type={type} reference={selectedReference} />
        ) : (
          <ReferenceForm
            type={
              type === identifiers.character &&
              selectedReference?.is_gap_reference
                ? (identifiers.gap as string)
                : type
            }
            gap_reference={selectedReference?.is_gap_reference}
            formValues={selectedReference?.referee_details}
          />
        )}
      </Grid>
    );
  }, [type, profileInfo?.name, selectedReference, role]);

  const openApproveModal = useMemo(() => {
    return (
      <Modal
        open={approveModal}
        setModalClose={setApproveModal}
        children={approveModalText}
        title={undefined}
        size="sm"
        className={undefined}
      />
    );
  }, [approveModal, approveModalText]);

  const referenceModal = useMemo(() => {
    return (
      <Modal
        open={openViewModal}
        setModalClose={setOpenViewModal}
        children={viewReferenceModal}
        title={undefined}
        size="md"
        padding="0px"
        className={undefined}
        background={"#F5FBFF"}
      />
    );
  }, [openViewModal, viewReferenceModal]);

  const viewReference = (reference: Reference) => {
    setSelectedReference(reference);
    setOpenViewModal(true);
  };

  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#F5FBFF",
      color: "#727272",
      border: "none",
    },
    [`&.${tableCellClasses.body}`]: {
      borderBottom: "none",
      color: "#263238",
      fontSize: "14px",
      fontWeight: 600,
    },
    [`&.${tableCellClasses.head}:first-of-type`]: {
      borderTopLeftRadius: "4px",
      borderBottomLeftRadius: "4px",
    },
    [`&.${tableCellClasses.head}:last-of-type`]: {
      borderTopRightRadius: "4px",
      borderBottomRightRadius: "4px",
    },
    [`&.${tableCellClasses.body}:first-of-type`]: {
      borderTopLeftRadius: "12px",
      borderBottomLeftRadius: "12px",
    },
    [`&.${tableCellClasses.body}:last-of-type`]: {
      borderTopRightRadius: "12px",
      borderBottomRightRadius: "12px",
    },
  }));

  const StyledTableRow = styled(TableRow)(() => ({
    "&": {
      backgroundColor: "white",
      paddingBottom: "15px",
      paddingTop: "15px",
    },
  }));

  return (
    <Grid container className="card-vetting">
      {isLoading && <PageLoader />}
      {referenceModal}
      {openReportModal}
      {openApproveModal}
      <Grid container className="card-head-table">
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            px: 2,
            py: 1,
            minHeight: "40px",
          }}
        >
          <Box sx={{ display: "flex" }}>
            <Typography
              variant={"body2"}
              fontSize={"16px"}
              fontWeight={"500"}
              textTransform={"capitalize"}
              sx={{ pl: 1 }}
            >
              {(type === "employment_gap" ? "Employment Gap" : type) +
                " Reference" +
                (role === "agency" ? ": " + profileInfo?.name : "")}
            </Typography>
          </Box>
          {role === "candidate" && (
            <PrimaryButton
              fullWidth={false}
              sx={{
                background: "#5AB9F9",
                color: "#ffffff",
                fontWeight: 500,
                maxHeight: "40px",
                minWidth: "150px",
              }}
              data-tut="candidate_add_ref_btn"
              onClick={() => {
                if (setShowAddForm) {
                  setShowAddForm(true);
                }
              }}
            >
              Add Referee
            </PrimaryButton>
          )}
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{ mt: 2, justifyContent: "center", display: "flex" }}
      >
        {references.length !== 0 ? (
          <TableContainer
            sx={{
              boxShadow: "none",
              backgroundColor: "transparent",
              width: "90%",
            }}
          >
            <Table
              sx={{
                minWidth: 700,
                borderSpacing: "0 1rem",
                borderCollapse: "separate",
              }}
            >
              <TableHead>
                <TableRow>
                  {role !== "agency" && (
                    <StyledTableCell sx={{ width: "150px" }}></StyledTableCell>
                  )}
                  <StyledTableCell sx={{ minWidth: "131px" }}>
                    Referee Name
                  </StyledTableCell>
                  <StyledTableCell sx={{ minWidth: "131px" }}>
                    Reference Type
                  </StyledTableCell>
                  {type === identifiers.employment && (
                    <StyledTableCell>Employment Duration</StyledTableCell>
                  )}
                  {type === identifiers.academic && (
                    <StyledTableCell sx={{ minWidth: "200px" }}>
                      Course Duration
                    </StyledTableCell>
                  )}
                  <StyledTableCell data-tut="reference_table">
                    Status
                  </StyledTableCell>
                  {role === "agency" && (
                    <StyledTableCell>Action</StyledTableCell>
                  )}
                  {role !== "agency" && <StyledTableCell></StyledTableCell>}
                </TableRow>
              </TableHead>
              <TableBody className={"request-table-body"}>
                {references.map((reference, index) => (
                  <StyledTableRow
                    key={index}
                    className={"request-table-body-row"}
                  >
                    {role !== "agency" && (
                      <StyledTableCell>
                        {reference?.invalid && (
                          <PrimaryButton
                            data-tut="candidate_reference_reinitiate_btn"
                            fullWidth={false}
                            className="textButton"
                            sx={{
                              color: "#ffffff",
                              background: "#df4848",
                              borderRadius: "6px",
                              minWidth: "108px",
                            }}
                            onClick={() => {
                              if (setShowAddForm) {
                                setShowAddForm(true);
                                if (setReInitiateRef && setReferenceId) {
                                  setReInitiateRef(true);
                                  setReferenceId(reference.id);
                                }
                              }
                            }}
                          >
                            Re-initiate
                          </PrimaryButton>
                        )}
                      </StyledTableCell>
                    )}
                    <StyledTableCell
                      onClick={() => {
                        viewReference(reference);
                      }}
                    >
                      {reference.referee_details.referee_name}
                    </StyledTableCell>
                    {type === identifiers.character ||
                    type === identifiers.gap ? (
                      <StyledTableCell
                        onClick={() => {
                          viewReference(reference);
                        }}
                        sx={{ textTransform: "capitalize" }}
                      >
                        {reference.is_gap_reference
                          ? "Employment Gap"
                          : "Character"}
                      </StyledTableCell>
                    ) : (
                      <StyledTableCell
                        onClick={() => {
                          viewReference(reference);
                        }}
                        sx={{ textTransform: "capitalize" }}
                      >
                        {type}
                      </StyledTableCell>
                    )}
                    {type !== identifiers.character && (
                      <StyledTableCell
                        onClick={() => {
                          viewReference(reference);
                        }}
                      >
                        {`${dayjs(
                          reference.referee_details?.candidate_employment_date
                            ?.from
                        ).format(identifiers.date_format as string)}  - ${dayjs(
                          reference.referee_details?.candidate_employment_date
                            ?.to
                        ).format(identifiers.date_format as string)}`}
                      </StyledTableCell>
                    )}
                    <StyledTableCell
                      onClick={() => {
                        viewReference(reference);
                      }}
                    >
                      <Button
                        fullWidth={false}
                        sx={{
                          background: reference.is_complete
                            ? "#C5EAFD"
                            : "#F8E592",
                          color: "#333333",
                          fontSize: 14,
                          fontWeight: 500,
                          padding: "4px 14px",
                        }}
                      >
                        {reference.is_complete ? "Complete" : "Pending"}
                      </Button>
                    </StyledTableCell>
                    {role === "agency" && (
                      <StyledTableCell>
                        {profile.application_status !== "Approved" && (
                          <Stack direction={"row"} alignItems={"center"}>
                            {!reference.is_approved ? (
                              <>
                                {reference?.invalid ? (
                                  <Tooltip
                                    title={reference?.referee_comments}
                                    arrow
                                  >
                                    <img src={images.tooltip} alt={"tooltip"} />
                                  </Tooltip>
                                ) : (
                                  <PrimaryButton
                                    onClick={() => {
                                      if (!reference.invalid) {
                                        setSelectedReference(reference);
                                        setReport(true);
                                      }
                                    }}
                                    //disabled={reference.invalid}
                                    sx={{
                                      borderRadius: "8px",
                                      p: "0px 21px",
                                      mt: "6px",
                                      height: "40px",
                                      fontSize: "14px",
                                      fontWeight: "400",
                                      border: reference.invalid
                                        ? "1px solid red"
                                        : "none",
                                      background: "white",
                                      color: reference.invalid
                                        ? "red"
                                        : "black",
                                      minWidth: "100px",
                                    }}
                                    variant={"text"}
                                    fullWidth={false}
                                  >
                                    Report
                                  </PrimaryButton>
                                )}
                              </>
                            ) : (
                              <Button
                                fullWidth={false}
                                //disabled={reference.invalid}
                                sx={{
                                  borderRadius: "6px",
                                  background: "white",
                                  minWidth: "100px",
                                  maxHeight: "32px",
                                  display: "flex",
                                  justifyContent: "flex-start",
                                }}
                                onClick={async () => {
                                  await download(reference.id);
                                }}
                              >
                                <img
                                  src={images.downloadIcon}
                                  alt={"download"}
                                />
                              </Button>
                            )}
                            {!reference.invalid && (
                              <>
                                {reference.is_complete && (
                                  <>
                                    {!reference.is_approved && (
                                      <>
                                        <Button
                                          fullWidth={false}
                                          disabled={reference.invalid}
                                          className="approve-ref-btn"
                                          sx={{
                                            color: "white",
                                            borderRadius: "6px",
                                            background: "#5ab9f9",
                                            minWidth: "100px",
                                            maxHeight: "32px",
                                          }}
                                          onClick={() => {
                                            setSelectedReference(reference);
                                            setApproveModal(true);
                                          }}
                                        >
                                          Approve
                                        </Button>
                                      </>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                          </Stack>
                        )}
                      </StyledTableCell>
                    )}
                    {role !== "agency" && (
                      <StyledTableCell>
                        {reference?.invalid && (
                          <Tooltip
                            data-tut="candidate_reference_reported"
                            title={reference?.referee_comments}
                            arrow
                          >
                            <img src={images.tooltip} alt={"tooltip"} />
                          </Tooltip>
                        )}
                      </StyledTableCell>
                    )}
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <>
            <Stack minHeight={"200px"} textAlign={"center"}>
              <img
                src={images.no_data_available}
                alt={"No Data Available"}
                width="100%"
                height="100px"
                style={{ objectFit: "contain" }}
              />
              <Typography variant="h6" fontSize="14px" fontWeight="400">
                No References Available
              </Typography>
            </Stack>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default ReferenceList;
