import React, { FC, useCallback, useEffect, useState } from "react";
import { Grid, Box, Typography } from "@mui/material";
import PageLoader from "../../../../../../../../components/PageLoader";
import axios from "axios";
import { URLS } from "../../../../../../../../utils/constants/urls";
import {
  setNotificationMessage,
  setReload,
  Reload,
  VettingDocuments,
} from "../../../../../../../../utils/redux";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import * as Amplify from "../../../../../../../../utils/services/amplify";
import { identifiers } from "../../../../../../../../utils/constants/identifiers";

const Yoti: FC<{
  scheme: "RTW" | "DBS_RTW" | "DBS";
  objective: string;
  active: number;
}> = ({ scheme, objective, active }) => {
  const dispatch = useDispatch();
  const { id } = useParams() as any;
  const reload = useSelector(Reload);
  const documents = useSelector(VettingDocuments);
  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState<string>("");
  const [status, setStatus] = useState<"COMPLETED" | "ONGOING" | "SUBMITTED">(
    "ONGOING"
  );

  const getYotiUrl = useCallback(async () => {
    try {
      setLoading(true);
      const session = await Amplify.currentSession();
      const token = session.getIdToken().getJwtToken();
      const { data } = await axios.post(
        `${process.env.REACT_APP_NODE_API_URL}${URLS.yoti_rtw}/request/${id}?scheme=${scheme}&objective=${objective}&url=${process.env.REACT_APP_NODE_API_URL}`,
        null,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      setUrl(data.url);
      setStatus(data.status);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "error",
          message: "There is an error in getting YOTI Url",
        })
      );
    }
  }, [dispatch, id, objective, scheme]);
  useEffect(() => {
    (async () => {
      await getYotiUrl();
    })();
  }, [getYotiUrl]);

  return (
    <Grid container>
      {loading && <PageLoader />}
      <Grid item xs={12}>
        <Box
          sx={{
            background: "#D9D9D9",
            borderRadius: "20px",
            minHeight: "550px",
            display: "flex",
          }}
          margin={3}
          marginTop={0}
          justifyContent={"center"}
          alignItems={"center"}
        >
          {status === "ONGOING" ? (
            <iframe
              src={url}
              style={{
                height: "550px",
                width: "100%",
                borderRadius: "20px",
                border: "2px solid lightgray",
              }}
              allow="camera"
              title={"Yoti RTW - DBS"}
            ></iframe>
          ) : (
            <Typography
              variant={"h5"}
              sx={{ color: "#3a3a3a" }}
              fontWeight={700}
            >
              You have completed your {scheme.split("_").join(" and ")} process
            </Typography>
          )}
        </Box>
      </Grid>
      {documents[active].name === identifiers.dbs && (
        <Grid
          item
          xs={12}
          className="card-footer-table"
          display={"flex"}
          justifyContent={"center"}
          sx={{ p: 2 }}
        >
          <Typography>
            The identity verification process will take up to 10 minutes to
            process once you submit your identity details. Therefore, after you
            submit your identity details please wait for 10 minutes and{" "}
            <span
              style={{
                color: "blue",
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={() => dispatch(setReload(!reload))}
            >
              CLICK HERE
            </span>{" "}
            to proceed to the next step.
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default Yoti;
