import { Grid } from "@mui/material";
import React, { FC, useEffect, useCallback } from "react";
import "./AdditionalComments.scss";
import InputField from "../../../../../components/InputField";
import { useDispatch, useSelector } from "react-redux";
import { Comment, IsEditable, setComment } from "../../../../../utils/redux";
import { useTour } from "@reactour/tour";
import { tourStep } from "../../../../../utils/constants/coachMarks";
import {
  User,
  setUserData,
} from "../../../../../utils/redux/reducer/authentication-slice";
import * as Amplify from "../../../../../utils/services/amplify";
import { CognitoUser } from "amazon-cognito-identity-js";

const AdditionalComments: FC = () => {
  const { setIsOpen, currentStep, setCurrentStep } = useTour();

  const user: { [key: string]: string } = useSelector(User) as {
    [key: string]: string;
  };
  const dispatch = useDispatch();
  const comment = useSelector(Comment);
  const isEditable = useSelector(IsEditable);

  const setAdditionalCommentCoachMarks = useCallback(async () => {
    //if (!!user && user["custom:reference_tutorial"] !== "true") {
    const currentUser: CognitoUser = await Amplify.UserDetail();
    const body = {
      "custom:additional_comments": "true",
    };
    await Amplify.UpdateUserDetails(body, currentUser);
    await Amplify.RefreshSession();
    dispatch(setUserData({ ...user, ...body }));
    //}
  }, [user, dispatch]);

  useEffect(() => {
    if (!!user && user["custom:additional_comments"] !== "true") {
      setCurrentStep(tourStep.additional_comments.index);
    }
  }, [setCurrentStep, user]);

  useEffect(() => {
    if (tourStep.additional_comments.index === currentStep) {
      setIsOpen(true);
    } else if (
      currentStep ===
      tourStep.additional_comments.index + tourStep.additional_comments.steps
    ) {
      setIsOpen(false);
      if (!!user && user["custom:additional_comments"] !== "true") {
        (async () => {
          await setAdditionalCommentCoachMarks();
        })();
      }
    }
  }, [currentStep, setAdditionalCommentCoachMarks, setIsOpen, user]);

  return (
    <Grid item md={12}>
      <Grid container className="card-additional-comments">
        <Grid container className="card-head-table">
          <Grid item xs={12} md={12} className="card-header-text pl-3 py-3">
            Notes to Request
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} padding={"10px 20px"}>
          <InputField
            data-tut="comments_field"
            multiline
            rows={10}
            sx={{
              background: "#ffffff",
            }}
            placeholder={
              "The applicant may email once they have fulfilled the requirement of this request."
            }
            disabled={!isEditable}
            inputValue={comment}
            textChange={(value: string) => dispatch(setComment(value))}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AdditionalComments;
