import { ICountry } from 'country-state-city/lib/interface';
import { Country } from 'country-state-city';
import { FormModel } from '../../../../../../../../../components/Form';
import {
  AddressHistory,
  PersonalInformation,
} from '../../../../../../../../../utils/redux/reducer/u-check-slice';
import { Validators } from '../../../../../../../../../utils/validators';
import { identifiers } from '../../../../../../../../../utils/constants/identifiers';
import { UCheckValidators } from '../../../../../../../../../utils/validators/uCheckValidators';

const countries: ICountry[] = Country.getAllCountries();

export const PreviousAddressModel: (
  uCheckPersonalInformation: PersonalInformation,
  uCheckCurrentAddress: AddressHistory,
  uCheckPreviousAddress: AddressHistory[],
  count: number,
  disabled?: boolean
) => FormModel[] = (
  personalInformation,
  currentAddress,
  previousAddress,
  count,
  disabled
) => {
  const form: FormModel[] = [];

  for (let i = 0; i < count; ++i) {
    form.push(
      {
        label: `Previous Address ${i + 1}`,
        variant: 'body1',
        type: 'label',
        field: `label_${i + 1}`,
        validators: [],
        required: false,
        placeholder: '',
        value: '',
        responsive: { xs: 12 },
      },
      {
        label: `Previous Address ${i + 1} Address line 1*`,
        value: '',
        size: 'small',
        type: 'text',
        typeValue: 'text',
        variant: 'outlined',
        placeholder: '',
        field: `address_line1_${i + 1}`,
        validators: [
          { check: Validators.required, message: 'This field is mandatory' },
          {
            check: UCheckValidators.TownCounty,
            message: 'Forename should contain only A-Z, spaces and -',
          },
        ],
        responsive: { md: 6, xs: 12 },
        required: true,
        style: { background: '#FFFFFF', borderRadius: '8px' },
        disabled: disabled || i + 1 < count,
      },
      {
        label: `Previous Address ${i + 1} Address line 2`,
        value: '',
        size: 'small',
        type: 'text',
        typeValue: 'text',
        variant: 'outlined',
        placeholder: '',
        field: `address_line2_${i + 1}`,
        validators: [
          {
            check: UCheckValidators.TownCounty,
            message: 'Forename should contain only A-Z, spaces and -',
          },
        ],
        responsive: { md: 6, xs: 12 },
        required: false,
        style: { background: '#FFFFFF', borderRadius: '8px' },
        disabled: disabled || i + 1 < count,
      },
      {
        label: `Previous Address ${i + 1} Town*`,
        value: '',
        size: 'small',
        type: 'text',
        typeValue: 'text',
        variant: 'outlined',
        placeholder: '',
        field: `address_town_${i + 1}`,
        validators: [
          { check: Validators.required, message: 'This field is mandatory' },
        ],
        responsive: { md: 6, xs: 12 },
        required: true,
        style: { background: '#FFFFFF', borderRadius: '8px' },
        disabled: disabled || i + 1 < count,
      },
      {
        label: `Previous Address ${i + 1} County${
          !!previousAddress[i]?.country_code &&
          previousAddress[i]?.country_code !== 'GB'
            ? '*'
            : ''
        }`,
        value: '',
        size: 'small',
        type: 'text',
        typeValue: 'text',
        variant: 'outlined',
        placeholder: '',
        field: `address_county_${i + 1}`,
        validators:
          !!previousAddress[i]?.country_code &&
          previousAddress[i]?.country_code !== 'GB'
            ? [
                {
                  check: Validators.required,
                  message: 'This field is mandatory',
                },
              ]
            : [],
        responsive: { md: 6, xs: 12 },
        required:
          !!previousAddress[i]?.country_code &&
          previousAddress[i]?.country_code !== 'GB',
        style: { background: '#FFFFFF', borderRadius: '8px' },
        disabled: disabled || i + 1 < count,
      },
      {
        label: `Previous Address ${i + 1} Country*`,
        value: '',
        size: 'small',
        type: 'drop-down',
        options: countries.map((country) => ({
          label: country.name,
          value: `${country.isoCode}`,
        })),
        typeValue: 'text',
        variant: 'outlined',
        placeholder: '',
        field: `country_code_${i + 1}`,
        validators: [
          { check: Validators.required, message: 'This field is mandatory' },
        ],
        responsive: { md: 6, xs: 12 },
        required: true,
        style: { background: '#FFFFFF', borderRadius: '8px' },
        disabled: disabled || i + 1 < count,
      }
    );
    if (
      previousAddress[i]?.country_code === 'GB' ||
      previousAddress[i]?.country_code === 'IM' ||
      previousAddress[i]?.country_code === 'JE' ||
      previousAddress[i]?.country_code === 'GG'
    ) {
      form.push({
        label: `Previous Address ${i + 1} Postcode*`,
        value: '',
        size: 'small',
        type: 'text',
        typeValue: 'text',
        variant: 'outlined',
        placeholder: '',
        field: `postcode_${i + 1}`,
        validators: [
          { check: Validators.required, message: 'This field is mandatory' },
        ],
        responsive: { md: 6, xs: 12 },
        required: true,
        style: { background: '#FFFFFF', borderRadius: '8px' },
        disabled: disabled || i + 1 < count,
      });
    }
    form.push(
      {
        label: `Previous Address ${i + 1} Resident From*`,
        value: '',
        size: 'small',
        type: 'date',
        typeValue: 'text',
        variant: 'outlined',
        placeholder: '',
        disableFuture: true,
        minDate: new Date(personalInformation.date_of_birth),
        maxDate:
          i === 0
            ? new Date(currentAddress?.resident_from_gyear_month as string)
            : new Date(
                previousAddress[i - 1]?.resident_from_gyear_month as string
              ),
        inputFormat: identifiers.month_format as string,
        openTo: 'year',
        views: ['year', 'month'],
        field: `resident_from_gyear_month_${i + 1}`,
        validators: [
          { check: Validators.required, message: 'This field is mandatory' },
        ],
        responsive: { md: 6, xs: 12 },
        required: true,
        style: { background: '#FFFFFF', borderRadius: '8px' },
        disabled: disabled || i + 1 < count,
      },
      {
        label: `Previous Address ${i + 1} Resident To*`,
        value: '',
        size: 'small',
        type: 'date',
        typeValue: 'text',
        variant: 'outlined',
        placeholder: '',
        disableFuture: true,
        minDate: new Date(personalInformation.date_of_birth),
        inputFormat: identifiers.month_format as string,
        openTo: 'year',
        views: ['year', 'month'],
        field: `resident_to_gyear_month_${i + 1}`,
        validators: [
          { check: Validators.required, message: 'This field is mandatory' },
        ],
        responsive: { md: 6, xs: 12 },
        required: true,
        style: { background: '#FFFFFF', borderRadius: '8px' },
        disabled: true,
      }
    );
    if (i < count - 1) {
      form.push({
        label: `* Remove Previous Address ${
          i + 2
        } to edit / delete this Address`,
        variant: 'body2',
        type: 'label',
        field: `label_${i + 1}`,
        validators: [],
        required: false,
        placeholder: '',
        value: '',
        responsive: { xs: 12 },
        sx: { textAlign: 'end' },
      });
    }
    form.push({
      type: 'divider',
      label: 'divider',
      field: `divider-${i + 1}`,
      variant: '',
      validators: [],
      required: false,
      placeholder: '',
      value: '',
    });
  }

  return form;
};
