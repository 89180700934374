import React, {
  FC,
  MouseEvent,
  RefObject,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { styled, Theme } from "@mui/material/styles";
import {
  CircularProgress,
  Typography,
  Switch,
  Stack,
  Box,
} from "@mui/material";
import Form, { FormDataModel } from "../../../components/Form";
import { LoginForm } from "./LoginForm";
import PrimaryButton from "../../../components/Button/PrimaryButton";
import { removeErrorFieldsFromValues } from "../../../utils/validators";
import { Link } from "react-router-dom";
import * as Amplify from "../../../utils/services/amplify";
import { useDispatch, useSelector } from "react-redux";
import {
  setAuthentication,
  setRequest,
  setNotificationMessage,
} from "../../../utils/redux";
import { CognitoUser, CognitoUserAttribute } from "amazon-cognito-identity-js";
import { images } from "../../../utils/constants/images";
import { ChangePasswordForm } from "./ChangePasswordForm";
import { agency, candidate } from "../../../utils/constants/routes";
import "./Login.scss";
import { identifiers } from "../../../utils/constants/identifiers";
import { RequestId } from "../../../utils/redux";
import axiosInstance from "../../../utils/axios";
import { URLS } from "../../../utils/constants/urls";
import OtpInputField from "../../../components/OtpInputField/OtpInputField";
import RefreshIcon from "@mui/icons-material/Refresh";
import Modal from "../../../components/modal/Modal";

const Login: FC<{ role: string }> = ({ role }) => {  
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const requestId = useSelector(RequestId);
  let loginForm: RefObject<Form | null | undefined> = useRef();
  let changePasswordForm: RefObject<Form | null | undefined> = useRef();
  const [hasError, setHasError] = useState<boolean>(false);
  const [changePassword, setChangePassword] = useState<boolean>(false);
  const [keepMeLoggedIn, setKeepMeLoggedIn] = useState<boolean>(false);
  const [loginActive, setLoginActive] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [confirmationLoading, setConfirmationLoading] =
    useState<boolean>(false);
  const [resendLoading, setResendLoading] = useState<boolean>(false);
  const [user, setUser] = useState<CognitoUser | undefined>();
  const [openModal, setOpenModal] = useState(false);
  const [otp, setOtp] = useState("");
  const [verifyAttribute, setVerifyAttribute] = useState<
    "email" | "phone" | undefined
  >();

  const handleLogin = useCallback(
    async (e?: MouseEvent<HTMLButtonElement>, callback?: () => void) => {
      if (e) {
        e.preventDefault();
      }
      setLoading(true);
      const { getFormData } = loginForm.current as {
        getFormData: () => { formData: FormDataModel; isFormValid: boolean };
      };
      const { formData, isFormValid } = getFormData();
      const body: FormDataModel = removeErrorFieldsFromValues(formData);
      try {
        setHasError(false);
        if (isFormValid) {
          const data: CognitoUser = await Amplify.Login({ ...body } as {
            email: string;
            password: string;
          });
          const { attributes } = data as any;
          const { challengeParam } = data as any;
          if (
            (attributes && attributes["custom:role"] === role) ||
            (challengeParam &&
              challengeParam.userAttributes["custom:role"] === role)
          ) {
            if (
              data.challengeName &&
              data.challengeName === "NEW_PASSWORD_REQUIRED"
            ) {
              setLoading(false);
              setUser(data);
              setChangePassword(true);
            } else {
              if (role === "agency") {
                if (
                  attributes.given_name &&
                  attributes.family_name &&
                  attributes.phone_number &&
                  attributes.address &&
                  attributes["custom:company"] &&
                  attributes["custom:designation"]
                ) {
                  setLoading(false);
                  // Navigate to Dashboard
                  dispatch(
                    setAuthentication({
                      authenticated: true,
                      isProfileComplete: true,
                      role,
                      user: attributes,
                    })
                  );
                  Navigate(agency.dashboard);
                } else {
                  setLoading(false);
                  // Navigate to User Profile Completion
                  dispatch(
                    setAuthentication({
                      authenticated: true,
                      isProfileComplete: false,
                      role,
                      user: attributes,
                    })
                  );
                  Navigate(agency.profile_completion);
                }
              } else {
                if (attributes.phone_number_verified) {
                  dispatch(
                    setAuthentication({
                      authenticated: true,
                      isProfileComplete: true,
                      role,
                      user: attributes,
                    })
                  );
                  if (requestId) {
                    try {
                      await axiosInstance.put(
                        `${URLS.requests}/${requestId}/map`
                      );
                      setLoading(false);
                      dispatch(setRequest({ id: null }));
                      Navigate(`${candidate.requests}/${requestId}`);
                    } catch (e) {
                      console.log(e);
                      dispatch(
                        setNotificationMessage({
                          display: true,
                          severity: "error",
                          message: "There is an error while logging in",
                        })
                      );
                      setLoading(false);
                    }
                  } else {
                    setLoading(false);
                    Navigate(candidate.dashboard);
                  }
                } else {
                  await Amplify.SendVerificationCode("phone_number");
                  setVerifyAttribute("phone");
                  setOtp("");
                  setOpenModal(true);
                  setLoading(false);
                  setConfirmationLoading(false);
                }
              }
            }
          } else {
            setLoading(false);
            dispatch(
              setNotificationMessage({
                display: true,
                severity: "error",
                message: "You are not Authorized to view this app.",
              })
            );
            await Amplify.Logout();
          }
        } else {
          setHasError(true);
          setLoading(false);
        }
      } catch (error: any) {
        setLoading(false);
        if (callback) {
          callback();
        } else {
          if (error.code === "PasswordResetRequiredException") {
            setLoading(false);
            // setUser(data);
            setChangePassword(true);
          }
          // Check if User Not Confirmed Comes in Error or Success Section
          else if (error.code === "UserNotConfirmedException") {
            // Send Confirmation Code for Email Verification
            setVerifyAttribute("email");
            dispatch(
              setNotificationMessage({
                display: true,
                severity: "success",
                message: "OTP has been sent to your Email",
              })
            );
            setOpenModal(true);
            setLoading(false);
          } else {
            dispatch(
              setNotificationMessage({
                display: true,
                severity: "error",
                message: "Please enter valid username and password",
              })
            );
          }
        }
      }
    },
    [Navigate, dispatch, requestId, role]
  );

  const handleKeypress = async (e: any) => {
    if (e.charCode === 13) {
      await handleLogin(e);
    }
  };

  const updatePassword = async (
    e: MouseEvent<HTMLButtonElement>,
    callback?: () => void
  ) => {
    e.preventDefault();
    setLoading(true);
    const { getFormData } = changePasswordForm.current as {
      getFormData: () => { formData: FormDataModel; isFormValid: boolean };
    };
    const { formData, isFormValid } = getFormData();
    const body: FormDataModel = removeErrorFieldsFromValues(formData);
    try {
      setHasError(false);
      if (isFormValid) {
        if (body.new_password === body.new_password_conf) {
          await Amplify.ChangePassword(
            {
              new_password: body.new_password as string,
              sessionAttributes: {} as CognitoUserAttribute,
            },
            user as CognitoUser
          );
          setLoading(false);
          const { attributes } = user as any;
          if (
            attributes &&
            !!attributes.given_name &&
            !!attributes.family_name &&
            !!attributes.phone_number &&
            !!attributes.address &&
            !!attributes["custom:company"] &&
            !!attributes["custom:designation"]
          ) {
            dispatch(
              setAuthentication({
                authenticated: true,
                isProfileComplete: true,
                role,
                user: attributes,
              })
            );
            Navigate(agency.dashboard);
          } else {
            // Navigate to User Profile Completion
            dispatch(
              setAuthentication({
                authenticated: true,
                isProfileComplete: false,
                role,
                user: attributes,
              })
            );
            Navigate(agency.profile_completion);
          }
        } else {
          setHasError(true);
          setLoading(false);
        }
      } else {
        setHasError(true);
        setLoading(false);
      }
    } catch (error: any) {
      setLoading(false);
      if (callback) {
        callback();
      } else {
        dispatch(
          setNotificationMessage({
            display: true,
            severity: "error",
            message:
              "Unable to Reset Password. Please try again after sometime",
          })
        );
      }
    }
  };

  const verifyAccount = useCallback(async () => {
    setConfirmationLoading(true);
    const { getFormData } = loginForm.current as {
      getFormData: () => { formData: FormDataModel; isFormValid: boolean };
    };
    const { formData } = getFormData();
    const body: FormDataModel = removeErrorFieldsFromValues(formData);
    try {
      await Amplify.ConfirmUser(otp, { ...body } as {
        email: string;
      });
      await handleLogin();
    } catch (e) {
      console.log(e);
    }
  }, [handleLogin, otp]);

  const resendConfirmationCode = useCallback(async () => {
    try {
      setResendLoading(true);
      if (verifyAttribute === "email") {
        const { getFormData } = loginForm.current as {
          getFormData: () => { formData: FormDataModel; isFormValid: boolean };
        };
        const { formData } = getFormData();
        const body: FormDataModel = removeErrorFieldsFromValues(formData);
        await Amplify.resendConfirmationCode(body.email as string);
      } else {
        await Amplify.SendVerificationCode("phone_number");
      }
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "success",
          message:
            "Confirmation code has been re-sent to your " +
            (verifyAttribute === "email" ? "Email ID" : "Phone Number"),
        })
      );
      setResendLoading(false);
    } catch (err) {
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "error",
          message: "Unable to resend verification code. Reason: " + err,
        })
      );
      setResendLoading(false);
    }
  }, [dispatch, verifyAttribute]);

  const confirmOtp = useCallback(async () => {
    setConfirmationLoading(true);
    try {
      await Amplify.VerifyAttributeUpdate("phone_number", otp);
      await Amplify.RefreshSession();
      const user = await Amplify.UserDetail();
      dispatch(
        setNotificationMessage({
          display: true,
          message: "OTP has been verified Successfully",
          severity: "success",
        })
      );
      setOpenModal(false);
      dispatch(
        setAuthentication({
          authenticated: true,
          isProfileComplete: true,
          role: "candidate",
          user: user.attributes,
        })
      );
      await axiosInstance.put(`${URLS.requests}/${requestId}/map`);
      setConfirmationLoading(false);
      Navigate(`${candidate.requests}/${requestId}`);
    } catch (e: any) {
      setConfirmationLoading(false);
      if (e.response) {
        // setActionMessage(true, "warning", e.response.data.message);
      }
    }
  }, [Navigate, dispatch, otp, requestId]);

  const verificationModal = useMemo(() => {
    return (
      <Box sx={{ p: 1 }}>
        <Stack>
          <Typography
            variant={"h5"}
            fontWeight={600}
            color={"#333333"}
            sx={{ mb: 1 }}
          >
            {"Verify " +
              (verifyAttribute === "email" ? "Email" : "Phone Number")}
          </Typography>
          {!!verifyAttribute && (
            <Typography variant={"caption"} color={"#727272"} sx={{ mb: 5 }}>
              Please provide OTP sent to
              {verifyAttribute === "email" ? " Email" : " Phone Number"}
            </Typography>
          )}
        </Stack>
        <Stack direction={"row"} justifyContent={"center"}>
          <OtpInputField otpValue={otp} handleOtpCallback={setOtp} />
        </Stack>
        <Stack>
          <PrimaryButton
            disabled={confirmationLoading || resendLoading || loading}
            sx={{ mt: 3, color: "#ffffff" }}
            variant={"contained"}
            onClick={() =>
              verifyAttribute === "email" ? verifyAccount() : confirmOtp()
            }
          >
            {confirmationLoading && (
              <CircularProgress sx={{ mr: 1 }} size={20} />
            )}
            Confirm
          </PrimaryButton>
          <PrimaryButton
            fullWidth={false}
            disabled={confirmationLoading || resendLoading || loading}
            sx={{ mt: 3, color: "#333333", width: "150px" }}
            variant={"text"}
            onClick={resendConfirmationCode}
          >
            {resendLoading && <CircularProgress sx={{ mr: 1 }} size={20} />}
            <RefreshIcon /> Resend OTP
          </PrimaryButton>
        </Stack>
      </Box>
    );
  }, [
    verifyAttribute,
    otp,
    confirmationLoading,
    resendLoading,
    loading,
    resendConfirmationCode,
    verifyAccount,
    confirmOtp,
  ]);

  const openVerificationModal = useMemo(() => {
    return (
      <Modal
        open={openModal}
        setModalClose={setOpenModal}
        children={verificationModal}
        isBackdrop={true}
        title={undefined}
        size={"sm"}
        className={undefined}
      />
    );
  }, [openModal, verificationModal]);

  const AntSwitch = styled(Switch)(({ theme }: { theme: Theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255,255,255,.35)"
          : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  }));
  return (
    <Box onKeyPress={(e) => handleKeypress(e)}>
      {openVerificationModal}
      {!changePassword ? (
        <>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="flex-start"
              spacing={0}
            >
              <Typography
                variant="h6"
                sx={{
                  mb: 1,
                  fontSize: 14,
                  fontWeight: 500,
                  color: "#333333",
                }}
              >
                Log In
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  mb: 1,
                  fontSize: 24,
                  fontWeight: 600,
                  color: "#333333",
                }}
              >
                Hi, Welcome Back!
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  mb: 5,
                  fontSize: 12,
                  fontWeight: 400,
                  color: "#727272",
                }}
              >
                Please enter your email address and password to login
              </Typography>
            </Stack>
            <img src={images.kedIcon} className="logo-icon" alt="kedIcon" />
          </Stack>
          <Form
            hasError={hasError}
            fieldError={identifiers.field_error as string}
            ref={loginForm as RefObject<Form>}
            model={LoginForm()}
            values={{}}
            onChange={(field, value, formData, isFormValid) =>
              setLoginActive(isFormValid)
            }
          />
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={2}
            >
              <AntSwitch
                checked={keepMeLoggedIn}
                onChange={() => setKeepMeLoggedIn(!keepMeLoggedIn)}
                inputProps={{ "aria-label": "ant design" }}
              />
              <Typography
                variant="body1"
                sx={{
                  fontSize: 12,
                  fontWeight: 400,
                  color: "#727272",
                }}
              >
                Keep me logged in
              </Typography>
            </Stack>
            <Link
              to={
                role === "agency"
                  ? agency.forgot_password
                  : candidate.forgot_password
              }
              className="redirection-link my-3"
            >
              <Typography
                variant="body1"
                sx={{
                  fontSize: 12,
                  fontWeight: 500,
                  color: "#5AB9F9",
                }}
                align={"right"}
              >
                Forgot Password ?
              </Typography>
            </Link>
          </Stack>
          <PrimaryButton
            disabled={loading || !loginActive}
            type="submit"
            sx={{
              mt: 1,
              background: "#5AB9F9",
              color: "#ffffff",
              boxShadow: " 0px 10px 25px rgba(90, 185, 249, 0.25)",
              borderRadius: "8px",
              fontSize: 16,
              fontWeight: 500,
            }}
            onClick={handleLogin}
          >
            {loading && <CircularProgress sx={{ mr: 1 }} size={20} />}
            Log In
          </PrimaryButton>
          {role === "agency" ? (
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={1}
              sx={{ mt: 4 }}
            >
              <Typography
                variant="body1"
                sx={{
                  color: "#727272",
                  fontSize: 12,
                  fontWeight: 500,
                }}
                align={"center"}
              >
                Don’t have an account yet?{" "}
              </Typography>
              <a
                href={URLS.static_web_url}
                className="redirection-link"
                target="_blank"
                rel={"noreferrer"}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: 12,
                    fontWeight: 500,
                    color: "#5AB9F9",
                  }}
                >
                  Get in touch!
                </Typography>
              </a>
            </Stack>
          ) : (
            <>
              {!!requestId && (
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                  sx={{ mt: 4 }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      color: "#727272",
                      fontSize: 12,
                      fontWeight: 500,
                    }}
                    align={"center"}
                  >
                    Don’t have an account yet?{" "}
                  </Typography>
                  <Link to={candidate.sign_up} className="redirection-link">
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: 12,
                        fontWeight: 500,
                        color: "#5AB9F9",
                      }}
                    >
                      Sign Up
                    </Typography>
                  </Link>
                </Stack>
              )}
            </>
          )}
        </>
      ) : (
        <>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="flex-start"
              spacing={0}
            >
              <Typography
                variant="h6"
                sx={{
                  mb: 1,
                  fontSize: 14,
                  fontWeight: 500,
                  color: "#333333",
                }}
              >
                Step 2/3
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  mb: 1,
                  fontSize: 24,
                  fontWeight: 600,
                  color: "#333333",
                }}
              >
                Password Details
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  mb: 5,
                  fontSize: 12,
                  fontWeight: 400,
                  color: "#727272",
                }}
              >
                please create a new password to proceed
              </Typography>
            </Stack>
            <img src={images.kedIcon} alt="kedIcon" />
          </Stack>

          <Form
            hasError={hasError}
            ref={changePasswordForm as RefObject<Form>}
            model={ChangePasswordForm()}
            values={{}}
          />
          <PrimaryButton
            disabled={loading}
            sx={{
              mt: 1,
              background: "#5AB9F9",
              color: "#ffffff",
              fontSize: 16,
              fontWeight: 500,
            }}
            onClick={updatePassword}
          >
            {loading && <CircularProgress sx={{ mr: 1 }} size={20} />}
            Change Password
          </PrimaryButton>
        </>
      )}
    </Box>
  );
};

export default Login;
