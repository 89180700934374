import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import PrimaryButton from "../../../../components/Button/PrimaryButton";
import { useDispatch, useSelector } from "react-redux";
import {
  ReferenceData,
  setNotificationMessage,
  setReferenceData,
} from "../../../../utils/redux";
import { useNavigate, useParams } from "react-router-dom";
import { images } from "../../../../utils/constants/images";
import { identifiers } from "../../../../utils/constants/identifiers";
import { URLS } from "../../../../utils/constants/urls";
import axiosInstance from "../../../../utils/axios";
import { routes } from "../../../../utils/constants/routes";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import axios from "axios";
import PageLoader from "../../../../components/PageLoader";
import { tourStep } from "../../../../utils/constants/coachMarks";
import { useTour } from "@reactour/tour";

const AdditionalRefereeForm: FC = () => {
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const reference = useSelector(ReferenceData);
  const { type, id } = useParams();
  const { setIsOpen, currentStep, setCurrentStep } = useTour();

  const [loading, setLoading] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState(0);

  useEffect(() => {
    if (!reference.referee_uplaod_yor_own_form) {
      setCurrentStep(tourStep.referee_uplaod_yor_own_form.index);
    }
  }, [reference, setCurrentStep]);

  useEffect(() => {
    if (tourStep.referee_uplaod_yor_own_form.index === currentStep) {
      setIsOpen(true);
    } else if (
      currentStep ===
      tourStep.referee_uplaod_yor_own_form.index +
        tourStep.referee_uplaod_yor_own_form.steps
    ) {
      setIsOpen(false);
      dispatch(
        setReferenceData({ ...reference, referee_uplaod_yor_own_form: true })
      );
    }
  }, [currentStep, dispatch, reference, setIsOpen]);

  const [referenceDocuments, setReferenceDocuments] = useState<
    {
      agency_id: string;
      id: string;
      name: string;
      type: string;
      selected_file: File | null;
    }[]
  >(
    reference.reference_document_forms.map((reference_document) => ({
      ...reference_document,
      selected_file: reference_document.selected_file,
    }))
  );

  useEffect(() => {
    let count = 0;
    reference.reference_document_forms.forEach((document) => {
      if (!!document.selected_file) {
        count++;
      }
    });
    setSelectedFiles(count);
  }, [reference]);

  const handleFileUpload = async (
    event: ChangeEvent<HTMLInputElement>,
    document_id: string,
    index: number
  ) => {
    const files = referenceDocuments.map((document) => document.selected_file);
    const reference_documents = JSON.parse(JSON.stringify(referenceDocuments));
    if (event.target.files) {
      let max_size = identifiers.agency_max_file_size as number;
      if (event.target.files[0].size <= max_size) {
        for (let i = 0; i < reference_documents.length; i++) {
          if (i === index) {
            reference_documents[i].selected_file = event.target.files[0];
            reference_documents[i].is_uploading = false;
          } else {
            reference_documents[i].selected_file = files[i];
            reference_documents[i].is_uploading = false;
          }
        }
        setSelectedFiles((selectedFile) => selectedFile + 1);
        setReferenceDocuments(reference_documents);
      } else {
        dispatch(
          setNotificationMessage({
            display: true,
            severity: "error",
            message: `You cannot upload file more than ${
              (identifiers.agency_max_file_size as number) / 1000000
            }MB`,
          })
        );
      }
    }
  };

  const removeFile = (index: number) => {
    const files = referenceDocuments.map((document) => document.selected_file);
    const reference_documents = JSON.parse(JSON.stringify(referenceDocuments));
    for (let i = 0; i < reference_documents.length; i++) {
      if (i === index) {
        reference_documents[i].selected_file = null;
      } else {
        reference_documents[i].selected_file = files[i];
      }
    }
    setSelectedFiles((selectedFile) => selectedFile - 1);
    setReferenceDocuments(reference_documents);
  };

  const download = async (file_id: string) => {
    setLoading(true);
    let referenceType =
      type === "employment-gap" ? identifiers.character : type;
    try {
      const { data } = await axiosInstance.get(
        `${URLS.referee_file_download}/${reference.request_id}/type/${referenceType}/reference/${file_id}/download`,
        { noAuth: true } as any
      );
      setLoading(false);
      window.open(data.url, "_blank");
    } catch (e) {
      setLoading(false);
      console.log(e);
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "error",
          message: "There is an error in downloading",
        })
      );
    }
  };

  const downloadDocuments = async () => {
    setLoading(true);
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_DOWNLOADS_API_URL}${URLS.download_referee_documents}${id}/references/download`,
        { noAuth: true } as any
      );
      window.open(data.download_url, "_blank");
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "error",
          message: "Unable to download candidate documents",
        })
      );
    }
  };

  return (
    <>
      {loading && <PageLoader />}
      <Grid container padding={{ xs: "10px 30px", md: "50px" }}>
        <Grid item xs={12} lg={12}>
          <Typography
            variant={"h6"}
            sx={{
              fontWeight: 500,
              lineHeight: "28px",
              color: "#333333",
            }}
            textTransform={"capitalize"}
          >
            Download and Upload attached document
          </Typography>
          <Typography
            variant={"body2"}
            sx={{
              lineHeight: "28px",
              color: "#727272",
            }}
            textTransform={"capitalize"}
          >
            Download the accompanying document, fill it out, and upload it here.
            You may download all documents but will have to upload it manually.
          </Typography>
          <Box
            sx={{
              textAlign: "right",
              paddingRight: "28px",
              mt: 2,
            }}
          >
            <PrimaryButton
              fullWidth={false}
              sx={{
                backgroundColor: "#e0a800",
                borderColor: "#d39e00",
                color: "#FFFFFF",
                fontSize: 16,
                fontWeight: 500,
                p: "10px",
                width: "150px",
                height: "30px",
              }}
              data-tut="downLoad_all_files"
              onClick={downloadDocuments}
            >
              Download All
            </PrimaryButton>
          </Box>
        </Grid>
        <Grid item xs={12} md={12} mt={2}>
          {referenceDocuments.map((reference_document, index) => (
            <Stack
              key={reference_document.id}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                border: "1px solid gray",
                borderRadius: "15px",
                padding: "20px 10px",
                width: "100%",
                mb: 2,
                overflowX: "auto",
              }}
            >
              <Stack
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "10px",
                  flexDirection: "row",
                  width: { xs: "700px", md: "100%" },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    width: "80%",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      width: "40%",
                      justifyContent: "flex-end",
                      p: 1,
                    }}
                  >
                    {!!reference_document.selected_file ? (
                      <>
                        <PrimaryButton
                          variant={"text"}
                          sx={{ ml: 1, pb: "15px" }}
                          fullWidth={false}
                        >
                          <Box
                            sx={{
                              background: "#00B2FF",
                              color: "white",
                              mr: 2,
                              padding: "8px 10px",
                              display: "flex",
                              borderRadius: "4px",
                              width: "150px",
                            }}
                          >
                            <AttachFileIcon />
                            <Typography
                              variant={"body2"}
                              width={"100%"}
                              whiteSpace={"nowrap"}
                              overflow={"hidden"}
                              textOverflow={"ellipsis"}
                              sx={{ mr: 1 }}
                            >
                              {reference_document.selected_file.name}
                            </Typography>
                          </Box>
                        </PrimaryButton>
                        <PrimaryButton
                          variant={"text"}
                          sx={{ ml: 1, pb: "15px" }}
                          fullWidth={false}
                          onClick={() => removeFile(index)}
                        >
                          <Box
                            sx={{
                              background: "rgba(217, 217, 217, 0.12)",
                              borderRadius: "4px",
                              width: "40px",
                              height: "40px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              src={images.deleteIcon}
                              alt="delete"
                              width="20px"
                              height="20px"
                            />
                          </Box>
                        </PrimaryButton>
                      </>
                    ) : (
                      <label htmlFor={"contained-button-file-" + index}>
                        <input
                          id={"contained-button-file-" + index}
                          hidden
                          accept={identifiers.accept as string}
                          size={identifiers.maxFileSize as number}
                          type="file"
                          onChange={(e) =>
                            handleFileUpload(e, reference_document.id, index)
                          }
                        />
                        <Button
                          variant="contained"
                          component="span"
                          className="uploadBtn"
                          sx={{
                            background: "#5AB9F9",
                            color: "#ffffff",
                            fontSize: 16,
                            fontWeight: 500,
                            p: "10px",
                            width: "150px",
                          }}
                          data-tut={index === 0 ? "upload_files" : ""}
                        >
                          Upload File
                        </Button>
                      </label>
                    )}
                  </Box>
                  <Box sx={{ width: "calc(100% - 48px)" }}>
                    <Typography sx={{ ml: 3 }}>
                      {reference_document.name}
                    </Typography>
                  </Box>
                </Box>

                <Box
                  onClick={async () => {
                    await download(reference_document.id);
                  }}
                  sx={{
                    background: "#F5FBFF",
                    borderRadius: "50px",
                    width: "48px",
                    height: "48px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <img src={images.downloadIcon} alt={"Download"} />
                </Box>
              </Stack>
            </Stack>
          ))}
        </Grid>
        <Grid item xs={12} md={12} display={"flex"} justifyContent={"flex-end"}>
          <Typography variant={"caption"} paddingRight={"28px"}>
            * Files will only be uploaded once you submit the reference
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          display={"flex"}
          justifyContent={"space-evenly"}
          mt={5}
        >
          <PrimaryButton
            fullWidth={false}
            sx={{
              background: "white",
              color: "#5AB9F9",
              fontSize: 16,
              fontWeight: 500,
              p: "10px",
              width: "150px",
              border: "1px solid #5AB9F9",
            }}
            onClick={() => {
              const active_index = reference.active_index;
              dispatch(
                setReferenceData({
                  ...reference,
                  active_index: active_index - 1,
                })
              );
              Navigate(
                `../${routes[reference.available_routes[active_index - 1]]}`
              );
            }}
          >
            Go Back
          </PrimaryButton>
          <PrimaryButton
            disabled={selectedFiles < reference.reference_document_forms.length}
            fullWidth={false}
            sx={{
              background: "#5AB9F9",
              color: "#ffffff",
              fontSize: 16,
              fontWeight: 500,
              p: "10px",
              width: "150px",
            }}
            onClick={() => {
              const active_index = reference.active_index;
              dispatch(
                setReferenceData({
                  ...reference,
                  reference_document_forms: referenceDocuments,
                  active_index: active_index + 1,
                })
              );
              Navigate(
                `../${routes[reference.available_routes[active_index + 1]]}`
              );
            }}
          >
            Proceed
          </PrimaryButton>
        </Grid>
      </Grid>
    </>
  );
};

export default AdditionalRefereeForm;
