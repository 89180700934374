import { ICountry } from 'country-state-city/lib/interface';
import { Country } from 'country-state-city';
import { FormModel } from '../../../../../../../../../components/Form';
import { Validators } from '../../../../../../../../../utils/validators';
import { identifiers } from '../../../../../../../../../utils/constants/identifiers';
import {
  AddressHistory,
  PersonalInformation,
} from '../../../../../../../../../utils/redux/reducer/u-check-slice';
import { UCheckValidators } from '../../../../../../../../../utils/validators/uCheckValidators';

const countries: ICountry[] = Country.getAllCountries();

export const CurrentAddressModel: (
  uCheckPersonalInformation: PersonalInformation,
  uCheckCurrentAddress: AddressHistory,
  disabled?: boolean
) => FormModel[] = (personalInformation, currentAddress, disabled) => {
  const form: FormModel[] = [
    {
      label: 'Address line 1*',
      value: '',
      size: 'small',
      type: 'text',
      typeValue: 'text',
      variant: 'outlined',
      placeholder: '',
      field: 'address_line1',
      validators: [
        { check: Validators.required, message: 'This field is mandatory' },
        {
          check: UCheckValidators.TownCounty,
          message: 'Forename should contain only A-Z, spaces and -',
        },
      ],
      responsive: { md: 6, xs: 12 },
      required: true,
      style: { background: '#FFFFFF', borderRadius: '8px' },
      disabled: disabled,
    },
    {
      label: 'Address line 2',
      value: '',
      size: 'small',
      type: 'text',
      typeValue: 'text',
      variant: 'outlined',
      placeholder: '',
      field: 'address_line2',
      validators: [
        {
          check: UCheckValidators.TownCounty,
          message: 'Forename should contain only A-Z, spaces and -',
        },
      ],
      responsive: { md: 6, xs: 12 },
      required: false,
      style: { background: '#FFFFFF', borderRadius: '8px' },
      disabled: disabled,
    },
    {
      label: 'Town*',
      value: '',
      size: 'small',
      type: 'text',
      typeValue: 'text',
      variant: 'outlined',
      placeholder: '',
      field: 'address_town',
      validators: [
        { check: Validators.required, message: 'This field is mandatory' },
        {
          check: UCheckValidators.TownCounty,
          message: 'Forename should contain only A-Z, spaces and -',
        },
      ],
      responsive: { md: 6, xs: 12 },
      required: true,
      style: { background: '#FFFFFF', borderRadius: '8px' },
      disabled: disabled,
    },
    {
      label: `County${
        !!currentAddress.country_code && currentAddress.country_code !== 'GB'
          ? '*'
          : ''
      }`,
      value: '',
      size: 'small',
      type: 'text',
      typeValue: 'text',
      variant: 'outlined',
      placeholder: '',
      field: 'address_county',
      validators:
        !!currentAddress.country_code && currentAddress.country_code !== 'GB'
          ? [
              {
                check: Validators.required,
                message: 'This field is mandatory',
              },
              {
                check: UCheckValidators.TownCounty,
                message: 'Forename should contain only A-Z, spaces and -',
              },
            ]
          : [
              {
                check: UCheckValidators.TownCounty,
                message: 'Forename should contain only A-Z, spaces and -',
              },
            ],
      responsive: { md: 6, xs: 12 },
      required:
        !!currentAddress.country_code && currentAddress.country_code !== 'GB',
      style: { background: '#FFFFFF', borderRadius: '8px' },
      disabled: disabled,
    },
    {
      label: 'Country*',
      value: '',
      size: 'small',
      type: 'drop-down',
      options: countries.map((country) => ({
        label: country.name,
        value: `${country.isoCode}`,
      })),
      typeValue: 'text',
      variant: 'outlined',
      placeholder: '',
      field: 'country_code',
      validators: [
        { check: Validators.required, message: 'This field is mandatory' },
      ],
      responsive: { md: 6, xs: 12 },
      required: true,
      style: { background: '#FFFFFF', borderRadius: '8px' },
      disabled: disabled,
    },
  ];
  if (
    currentAddress.country_code === 'GB' ||
    currentAddress.country_code === 'IM' ||
    currentAddress.country_code === 'JE' ||
    currentAddress.country_code === 'GG'
  ) {
    form.push({
      label: 'Postcode *',
      value: '',
      size: 'small',
      type: 'text',
      typeValue: 'text',
      variant: 'outlined',
      placeholder: '',
      field: 'postcode',
      validators: [
        { check: Validators.required, message: 'This field is mandatory' },
      ],
      responsive: { md: 6, xs: 12 },
      required: true,
      style: { background: '#FFFFFF', borderRadius: '8px' },
      disabled: disabled,
    });
  }
  form.push({
    label: 'Resident From*',
    value: '',
    size: 'small',
    type: 'date',
    typeValue: 'text',
    variant: 'outlined',
    placeholder: '',
    disableFuture: true,
    minDate: new Date(personalInformation.date_of_birth),
    inputFormat: identifiers.month_format as string,
    openTo: 'year',
    views: ['year', 'month'],
    field: `resident_from_gyear_month`,
    validators: [
      { check: Validators.required, message: 'This field is mandatory' },
    ],
    responsive: { md: 6, xs: 12 },
    required: true,
    style: { background: '#FFFFFF', borderRadius: '8px' },
    disabled: disabled,
  });

  return form;
};
