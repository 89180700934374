import React, { FC } from "react";
import { Stack, Typography, Toolbar, Box, AppBar, Button } from "@mui/material";
import "./NavBar.scss";
import { images } from "../../utils/constants/images";
import MenuIcon from "@mui/icons-material/Menu";
import { useSelector } from "react-redux";
import { User } from "../../utils/redux/reducer/authentication-slice";
import NotificationListing from "./NotificationListing";

const NavBar: FC<{
  title: string;
  open: boolean;
  setOpen: (value: boolean) => void;
}> = ({ title, open, setOpen }) => {
  const user = useSelector(User);

  return (
    <Box
      sx={{
        flexGrow: 1,
        p: 0,
        position: "sticky",
        top: "0px",
        background: "white",
        zIndex: "100",
      }}
    >
      <AppBar position="static" color="transparent">
        <Toolbar
          sx={{
            justifyContent: "space-between",
            padding: { xs: "20px", sm: "20px", md: "0px" },
          }}
        >
          <Typography
            variant="h6"
            component="div"
            sx={{
              flexGrow: 1,
              display: { xs: "none", sm: "none", md: "block" },
              px: 5,
            }}
          >
            {title}
          </Typography>
          <Box
            sx={{
              display: { xs: "flex", sm: "flex", md: "none" },
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Button
              variant={"text"}
              sx={{ justifyContent: "flex-start", color: "inherit" }}
              onClick={() => setOpen(!open)}
            >
              <MenuIcon />
            </Button>
            <img src={images.kedSmallIcon} alt="ked.io" />
          </Box>

          <Stack
            direction="row"
            alignItems="center"
            spacing={3}
            sx={{ mr: 3, pr: { xs: 0, md: 2 } }}
          >
            <NotificationListing></NotificationListing>
            <Stack direction="row" spacing={2} alignItems="center">
              <img
                height="28px"
                width="28px"
                src={user && user.picture ? user.picture : images.dummyIcon}
                className="images"
                alt="user"
              />
              {user && (
                <Typography
                  variant="h6"
                  sx={{
                    flexGrow: 1,
                    fontWeight: 500,
                    fontSize: "14px",
                    lineHeight: "16px",
                    display: { xs: "none", sm: "none", md: "block" },
                  }}
                >
                  {user.given_name + " " + user.family_name}
                </Typography>
              )}
              {/*<Box sx={{ display: { xs: "none", sm: "none", md: "block" } }}>*/}
              {/*  <img src={images.chevronDownIcon} alt="profile" />*/}
              {/*</Box>*/}
            </Stack>
          </Stack>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default NavBar;
