import React, { FC, useCallback, useEffect, useState, MouseEvent } from "react";
import {
  Typography,
  Box,
  Button,
  Popover,
  Card,
  CardHeader,
  CardContent,
  Grid,
} from "@mui/material";
import { images } from "../../../utils/constants/images";
import { URLS } from "../../../utils/constants/urls";
import axiosInstance from "../../../utils/axios";
import { setNotificationMessage } from "../../../utils/redux";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
import PageLoader from "../../../components/PageLoader";

const NotificationListing: FC = () => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const [notifications, setNotifications] = useState<any[]>([]);
  const [notificationsCount, setNotificationsCount] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);

  const openPopOver = Boolean(anchorEl);
  const id = openPopOver ? "simple-popover" : undefined;

  const getNotifications = useCallback(async () => {
    setLoading(true);
    try {
      const { status, data } = await axiosInstance.get(URLS.notification);
      if (status === 200) {
        let information=data.user_notification_list.sort((a:any, b:any) => b.created_at - a.created_at)
        setNotifications(information);
      }
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      console.log(e);
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "error",
          message: "Unable to get notifications",
        })
      );
    }
  }, [dispatch]);

  const getNotificationsCount = useCallback(async () => {
    setLoading(true);
    try {
      const { status, data } = await axiosInstance.get(URLS.notification_count);
      if (status === 200) {
        setNotificationsCount(data.data);
      }
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      console.log(e);
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "error",
          message: "Unable to get notifications count",
        })
      );
    }
  }, [dispatch]);

  useEffect(() => {
    (async () => {
      await getNotificationsCount();
    })();
  }, [getNotificationsCount]);

  const handleClick = async (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    await getNotifications();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {loading && <PageLoader />}
      <Button
        aria-describedby={id}
        onClick={handleClick}
        sx={{ backgroundColor: "white", boxShadow: "none" }}
        data-tut="notification_btn"
      >
        <img
          src={images.notificationIcon}
          alt="notification"
          className="images"
        />
      </Button>
      <Popover
        id={id}
        open={openPopOver}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        anchorEl={anchorEl}
        onClose={handleClose}
      >
        <Card
          sx={{
            maxWidth: 375,
            minWidth: 375,
            borderRadius: "12px",
            filter:
              "drop-shadow(0px 4.8px 14.4px rgba(47, 60, 69, 0.18)) drop-shadow(0px 25.6px 57.6px rgba(47, 60, 69, 0.22))",
          }}
        >
          {notifications.length === 0 ? (
            <Grid md={12} textAlign={"center"} sx={{ p: 3 }}>
              <img
                src={images.emptyIcon}
                alt={"no notifications"}
                className={"vertical-align-middle empty-image"}
              />
              <Typography variant={"body2"}>No notifications found</Typography>
            </Grid>
          ) : (
            <>
              <CardHeader
                sx={{ boxShadow: "0px 1px 1px rgba(182, 204, 217, 0.25)" }}
                avatar={
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: 500,
                      fontSize: "12px",
                      color: "white",
                      background: "#5AB9F9",
                      borderRadius: "4px",
                      padding: "4px 8px",
                    }}
                  >
                    {notificationsCount?.unseen || 0}
                  </Typography>
                }
                action={
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: 500,
                      fontSize: "12px",
                      color: "#5AB9F9",
                      lineHeight: "32px",
                    }}
                  >
                    Mark all as read
                  </Typography>
                }
              />
              {notifications?.map((notification) => (
                <CardContent>
                  <Box sx={{ display: "flex" }}>
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: 400,
                        fontSize: "12px",
                        color: " #727272",
                      }}
                    >
                      {dayjs(new Date(notification.created_at)).format("HH:mm")}
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: 400,
                        fontSize: "12px",
                        color: " #727272",
                        marginLeft: "auto",
                      }}
                    >
                      {dayjs(new Date(notification.created_at)).format(
                        "DD-MM-YYYY"
                      )}
                    </Typography>
                  </Box>
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: 500,
                      fontSize: "16px",
                      color: "#333333",
                    }}
                  >
                    {notification.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      cpolor: "#333333",
                      fontSize: "14px",
                      fontWeight: 400,
                      marginTop: "8px",
                    }}
                  >
                    {notification.message}
                  </Typography>
                </CardContent>
              ))}
            </>
          )}
        </Card>
      </Popover>
    </>
  );
};

export default NotificationListing;
