import React, { FC } from "react";
import { useSelector } from "react-redux";
import { Authenticated, ProfileComplete, UserRole } from "../../utils/redux";
import AuthenticatedLayout from "../AuthenticatedLayout";
import { Navigate } from "react-router-dom";
import { agency, candidate } from "../../utils/constants/routes";

const PrivateRoute: FC<{ Component: FC; title: string; role: string }> = ({
  Component,
  title,
  role,
}) => {
  const isAuthenticated = useSelector(Authenticated);
  const isProfileComplete = useSelector(ProfileComplete);
  const userRole = useSelector(UserRole);

  return isAuthenticated ? (
    isProfileComplete ? (
      userRole === role ? (
        <AuthenticatedLayout title={title}>
          <Component />
        </AuthenticatedLayout>
      ) : (
        <Navigate
          to={userRole === "agency" ? agency.dashboard : candidate.dashboard}
        />
      )
    ) : (
      <Navigate to={agency.profile_completion} />
    )
  ) : (
    <Navigate to={role === "agency" ? agency.login : candidate.login} />
  );
};

export default PrivateRoute;
