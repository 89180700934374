import { Grid, Modal, useMediaQuery } from "@mui/material";
import React, { FC, useCallback, useEffect, useState } from "react";
import ListComponent from "./ListComponent";
import InviteForm from "./InviteForm";
import "./InviteMembers.scss";
import axiosInstance from "../../../../utils/axios";
import { URLS } from "../../../../utils/constants/urls";
import { Box, useTheme } from "@mui/system";
import PageLoader from "../../../../components/PageLoader";
import { setNotificationMessage } from "../../../../utils/redux";
import { useDispatch, useSelector } from "react-redux";
import { useTour } from "@reactour/tour";
import { tourStep } from "../../../../utils/constants/coachMarks";
import * as Amplify from "../../../../utils/services/amplify";
import { CognitoUser } from "amazon-cognito-identity-js";
import {
  User,
  setUserData,
} from "../../../../utils/redux/reducer/authentication-slice";
import { Auth } from "aws-amplify";

export interface TeamMemberInterface {
  name: string;
  email: string;
  id: string;
}

const InviteMembers: FC<any> = () => {
  const dispatch = useDispatch();
  const [teamMembers, setTeamMembers] = useState<TeamMemberInterface[]>([]);
  const [count, setCount] = useState<number>(0);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>();
  const theme = useTheme();
  const isDekstop = useMediaQuery(theme.breakpoints.up("md"));

  const { setIsOpen, currentStep, setCurrentStep } = useTour();
  const user: { [key: string]: string } = useSelector(User) as {
    [key: string]: string;
  };

  const setCoachMarksValue = useCallback(async () => {
    //if (!!user && user["custom:reference_tutorial"] !== "true") {
    const currentUser: CognitoUser = await Amplify.UserDetail();
    const body = {
      "custom:invite_members": "true",
    };
    await Amplify.UpdateUserDetails(body, currentUser);
    await Amplify.RefreshSession();
    dispatch(setUserData({ ...user, ...body }));
    //}
  }, [user, dispatch]);

  const getTeamMembers = useCallback(async () => {
    setIsLoading(true);
    try {
      let member_count = 0;
      const members = [];
      const { data } = await axiosInstance.get(URLS.team_members);
      data.forEach((member: TeamMemberInterface) => {
        if (member.id) {
          member_count += 1;
        }
      });
      setIsLoading(false);
      setCount(member_count);
      for (let i = member_count; i < 5; ++i) {
        members.push({});
      }
      setTeamMembers([...data, ...members]);
    } catch (e) {
      setIsLoading(false);
      console.log(e);
      if (await Auth.currentUserInfo()) {
        dispatch(
          setNotificationMessage({
            display: true,
            severity: "error",
            message: "There is an error in fetching team members",
          })
        );
      }
    }
  }, [dispatch]);

  useEffect(() => {
    if (isLoading !== undefined && !isLoading) {
      if (!!user && user["custom:invite_members"] !== "true") {
        setCurrentStep(tourStep.invite_members_screen.index);
      }
    }
  }, [user, isLoading, setCurrentStep]);

  useEffect(() => {
    if (tourStep.invite_members_screen.index === currentStep) {
      setIsOpen(true);
    } else if (isDekstop) {
      if (
        currentStep ===
        tourStep.invite_members_screen.index +
          tourStep.invite_members_screen.steps
      ) {
        setIsOpen(false);
        if (!!user && user["custom:invite_members"] !== "true") {
          (async () => {
            await setCoachMarksValue();
          })();
        }
      }
    } else if (!isDekstop) {
      if (count !== 5) {
        if (
          currentStep ===
          tourStep.invite_members_screen.index +
            tourStep.invite_members_screen.steps
        ) {
          setIsOpen(false);
          if (!!user && user["custom:invite_members"] !== "true") {
            (async () => {
              await setCoachMarksValue();
            })();
          }
        }
      } else {
        if (
          currentStep ===
          tourStep.invite_members_screen.index +
            (tourStep.invite_members_screen.steps - 1)
        ) {
          setIsOpen(false);
          if (!!user && user["custom:invite_members"] !== "true") {
            (async () => {
              await setCoachMarksValue();
            })();
          }
        }
      }
    }
  }, [count, currentStep, isDekstop, setCoachMarksValue, setIsOpen, user]);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    (async () => {
      await getTeamMembers();
    })();
  }, [getTeamMembers]);

  return (
    <>
      <Grid container spacing={0}>
        {isLoading && <PageLoader />}
        <Grid
          item
          xs={12}
          lg={4}
          justifyContent="center"
          alignItems="center"
          className="list-container"
          display={"flex"}
        >
          <ListComponent
            setOpen={setOpen}
            members={teamMembers}
            count={count}
            getTeamMembers={getTeamMembers}
          />
        </Grid>
        <Grid
          container
          item
          xs={12}
          sm={12}
          md={12}
          lg={8}
          justifyContent="center"
          alignItems="center"
          className="invite-form"
        >
          <InviteForm
            varaint="outlined"
            getTeamMembers={getTeamMembers}
            count={count}
          />
        </Grid>
      </Grid>
      <Modal
        open={open}
        onClose={handleClose}
        className="modalContainer"
        closeAfterTransition={true}
      >
        <Box
          position="fixed"
          bottom="0px"
          padding="20px 0px 0px 0px"
          sx={{ background: "white", borderRadius: "24px 24px 0px 0px" }}
        >
          <InviteForm
            varaint=""
            setOpen={(value: boolean) => setOpen(value)}
            getTeamMembers={getTeamMembers}
            count={count}
          />
        </Box>
      </Modal>
    </>
  );
};

export default InviteMembers;
