import { FormValidators } from '../../components/Form';

const regex = {
  email: new RegExp(
    '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}])|(([a-zA-Z\\-\\d]+\\.)+[a-zA-Z]{2,}))$'
  ),
  mobile: new RegExp(/^\d{10}$/),
  username: new RegExp(/^[a-zA-Z\d](?:[a-zA-Z\d_-]*[a-zA-Z\d])?$/),
  url: new RegExp(
    '^(https?://)?(www\\.)?([-a-z\\d]{1,63}\\.)*?[a-z\\d][-a-z\\d]{0,61}[a-z\\d]\\.[a-z]{2,6}(/[-\\w@+.~#?&/=%]*)?$'
  ),
  price: new RegExp(/^\d+(\.\d{1,2})?$/),
  number: new RegExp('^\\d+$'),
  amount: new RegExp(/^[+]?(\d+(?:[.]\d*)?|\.\d+)$/),
  floatNumber: new RegExp(/^\d+(\.\d{1,2})?$/),
  amountRefunded: new RegExp(/^d{10}$/),
  referenceNumber: new RegExp('^[a-zA-Z\\d]+$'),
  GST: new RegExp(
    /^(0[1-9]|[1-2]\d|3[0-5])([a-zA-Z]{5}\d{4}[a-zA-Z][1-9a-zA-Z][zZ][\da-zA-Z])+$/
  ),
  Time: new RegExp('^(\\d|0\\d|1\\d|2[0-3]):[0-5]\\d$'),
  pinCode: new RegExp(/^\d{6}$/),
  jobRole: new RegExp(/([A-Za-z0-9 ])\w+/),
  name: new RegExp(/^[a-zA-Z. ]+$/),
  password: new RegExp(
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z\d])(?!.*\s).{8,32}$/
  ),
  nin: new RegExp(/^[A-CEGHJ-PR-TW-Z][A-CEGHJ-NPR-TW-Z][0-9]{6}[A-D]?$/),
  rtw: new RegExp(/^[a-zA-Z0-9]+$/),
  file_name: new RegExp(/^[\w\- ]+$/),
  firstName: new RegExp(/^[a-zA-Z][a-zA-Z ]*$/),
  domain_names: [
    'gmail.com',
    'yahoo.com',
    'hotmail.com',
    'aol.com',
    'hotmail.co.uk',
    'hotmail.fr',
    'msn.com',
    'yahoo.fr',
    'wanadoo.fr',
    'orange.fr',
    'comcast.net',
    'yahoo.co.uk',
    'yahoo.com.br',
    'yahoo.co.in',
    'live.com',
    'rediffmail.com',
    'free.fr',
    'gmx.de',
    'web.de',
    'yandex.ru',
    'ymail.com',
    'libero.it',
    'outlook.com',
    'uol.com.br',
    'bol.com.br',
    'mail.ru',
    'cox.net',
    'hotmail.it',
    'sbcglobal.net',
    'sfr.fr',
    'live.fr',
    'verizon.net',
    'live.co.uk',
    'googlemail.com',
    'yahoo.es',
    'ig.com.br',
    'live.nl',
    'bigpond.com',
    'terra.com.br',
    'yahoo.it',
    'neuf.fr',
    'yahoo.de',
    'alice.it',
    'rocketmail.com',
    'att.net',
    'laposte.net',
    'facebook.com',
    'bellsouth.net',
    'yahoo.in',
    'hotmail.es',
    'charter.net',
    'yahoo.ca',
    'yahoo.com.au',
    'rambler.ru',
    'hotmail.de',
    'tiscali.it',
    'shaw.ca',
    'yahoo.co.jp',
    'sky.com',
    'earthlink.net',
    'optonline.net',
    'freenet.de',
    't-online.de',
    'aliceadsl.fr',
    'virgilio.it',
    'home.nl',
    'qq.com',
    'telenet.be',
    'me.com',
    'yahoo.com.ar',
    'tiscali.co.uk',
    'yahoo.com.mx',
    'voila.fr',
    'gmx.net',
    'mail.com',
    'planet.nl',
    'tin.it',
    'live.it',
    'ntlworld.com',
    'arcor.de',
    'yahoo.co.id',
    'frontiernet.net',
    'hetnet.nl',
    'live.com.au',
    'yahoo.com.sg',
    'zonnet.nl',
    'club-internet.fr',
    'juno.com',
    'optusnet.com.au',
    'blueyonder.co.uk',
    'bluewin.ch',
    'skynet.be',
    'sympatico.ca',
    'windstream.net',
    'mac.com',
    'centurytel.net',
    'chello.nl',
    'live.ca',
    'aim.com',
    'bigpond.net.au',
  ],
};

export class Validators {
  static domainNames(value: string, message: string) {
    if (!!value) {
      let domain = value.split('@')[1];
      const result = regex.domain_names.includes(domain);

      if (result) {
        return { error: true, message };
      }
    }
  }

  static password(value: string, message: string) {
    const result = regex.password.test(value);
    if (!result) {
      return { error: true, message };
    }
  }

  static firstName(value: string, message: string) {
    if (!!value) {
      const result = regex.firstName.test(value);
      if (!result) {
        return { error: true, message };
      }
    }
  }

  static pinCode(value: string, message: string) {
    if (!!value) {
      const result = regex.pinCode.test(value);
      if (!result) {
        return { error: true, message };
      }
    }
  }

  static validUrl(value: string, message: string) {
    if (!!value) {
      const result = regex.url.test(value.toLowerCase());
      if (!result) {
        return { error: true, message };
      }
    }
  }

  static email(value: string, message: string) {
    if (!!value) {
      const result = regex.email.test(value);
      if (!result) {
        return { error: true, message };
      }
    }
  }

  static Name(value: string, message: string) {
    if (!!value) {
      const result = regex.name.test(value);
      if (!result) {
        return { error: true, message };
      }
    }
  }

  static JobRole(value: string, message: string) {
    if (value) {
      const result = regex.jobRole.test(value);
      if (!result) {
        return { error: true, message };
      }
    }
  }

  static username(value: string, message: string) {
    if (value) {
      const result = regex.username.test(value);
      if (!result) {
        return { error: true, message };
      }
    }
  }

  static mobile(value: string, message: string) {
    if (value) {
      const result = regex.mobile.test(value);
      if (!result) {
        return { error: true, message };
      }
    }
  }

  static required(value: string, message: string) {
    if (!value || !value.toString().trim().length || value === '') {
      return { error: true, message };
    }
    return false;
  }

  static maxCharLimit(value: string, message: string, num: number) {
    if (!!value) {
      const length = value.trim() ? value.toString().length : 0;
      if (length !== 0 && length > num) {
        return { error: true, message };
      }
    }
  }

  static minCharLimit(value: string, message: string, num: number) {
    if (!!value) {
      const length = value.trim() ? value.toString().length : 0;
      if (length < num) {
        return { error: true, message };
      }
    }
  }

  static minDate(value: string, message: string, num: number) {
    if (!!value) {
      if (new Date(value).getTime() < num) {
        return { error: true, message };
      }
    }
  }

  static maxDate(value: string, message: string, num: number) {
    if (!!value) {
      if (new Date(value).getTime() > new Date(num).getTime()) {
        return { error: true, message };
      }
    }
  }

  static number(value: string, message: string) {
    const length = value ? value.toString().length : 0;

    if (length > 0) {
      const result = regex.number.test(value);
      if (!result) {
        return { error: true, message };
      }
    }

    return false;
  }

  static amount(value: string, message: string) {
    const length = value ? value.toString().length : 0;

    if (length > 0) {
      const result = regex.amount.test(value);
      if (!result) {
        return { error: true, message };
      }
    }

    return false;
  }

  static phoneNumber(value: string, message: string) {
    const length = value ? value.toString().length : 0;

    if (length > 0) {
      const result = regex.number.test(value);
      if (!result || length < 10 || length > 10) {
        return { error: true, message };
      }
    }

    return false;
  }

  static minCount(value: string, message: string, num: number) {
    const length = value ? value.length : 0;
    if (length < num) {
      return { error: true, message };
    }
    return false;
  }

  static min(value: string, message: string, num: number) {
    if (parseFloat(value) < num) {
      return { error: true, message };
    }
    return false;
  }

  static max(value: string, message: string, num: number) {
    if (parseFloat(value) > num) {
      return { error: true, message };
    }
    return false;
  }

  static floatNumber(value: string, message: string) {
    const length = value ? value.toString().length : 0;

    if (length > 0) {
      const result = regex.floatNumber.test(value);
      if (!result) {
        return { error: true, message };
      }
    }

    return false;
  }

  static NIN(value: string, message: string) {
      const length = value ? value.toString().length : 0;

      if (length > 0) {
        const result = regex.nin.test(value);
        if (!result || length < 9 || length > 9) {
          return { error: true, message };
        }
    }
  }

  static RTW(value: string, message: string) {
    if (!!value) {
      const length = value ? value.toString().length : 0;

      if (length > 0) {
        const result = regex.rtw.test(value);
        if (!result || length < 9 || length > 9) {
          return { error: true, message };
        }
      }
    }
  }

  static FileName(value: string, message: string) {
    if (!!value) {
      const result = regex.file_name.test(value);
      if (!result) {
        return { error: true, message };
      }
    }
  }
}

export const validateInput = (validators: FormValidators[], value: string) => {
  if (validators && validators.length) {
    for (let i = 0; i < validators.length; i++) {
      const error = validators[i].check(
        value,
        validators[i].message,
        validators[i]?.num || validators[i]?.gender || validators[i]?.title
      );
      if (error) {
        return error;
      }
    }
  }
  return false;
};
