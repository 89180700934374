import React, { FC, useEffect } from "react";
import {
  Alert,
  Box,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import PrimaryButton from "../../../../components/Button/PrimaryButton";
import { useDispatch, useSelector } from "react-redux";
import { ReferenceData, setReferenceData } from "../../../../utils/redux";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../../utils/constants/routes";
import { useTour } from "@reactour/tour";
import { tourStep } from "../../../../utils/constants/coachMarks";

const StartReference: FC = () => {
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const { setIsOpen, currentStep, setCurrentStep } = useTour();

  const reference = useSelector(ReferenceData);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  useEffect(() => {
    if (!reference.referee_start_application) {
      if (isDesktop) {
        setCurrentStep(tourStep.referee_start_application.index);
      } else {
        setCurrentStep(tourStep.referee_start_application.index + 1);
      }
    }
  }, [isDesktop, reference, setCurrentStep]);

  useEffect(() => {
    if (isDesktop) {
      if (tourStep.referee_start_application.index === currentStep) {
        setIsOpen(true);
      } else if (
        currentStep ===
        tourStep.referee_start_application.index +
          tourStep.referee_start_application.steps
      ) {
        setIsOpen(false);
        dispatch(
          setReferenceData({ ...reference, referee_start_application: true })
        );
      }
    } else {
      if (tourStep.referee_start_application.index + 1 === currentStep) {
        setIsOpen(true);
      } else if (
        currentStep ===
        tourStep.referee_start_application.index +
          tourStep.referee_start_application.steps
      ) {
        setIsOpen(false);
        dispatch(
          setReferenceData({ ...reference, referee_start_application: true })
        );
      }
    }
  }, [currentStep, dispatch, isDesktop, reference, setIsOpen]);

  return (
    <Stack
      display={"flex"}
      justifyContent={"space-evenly"}
      alignItems={"center"}
      sx={{ minHeight: "calc(100vh - 120px)" }}
    >
      {reference.completed && (
        <Alert sx={{ width: "90%" }} severity="success">
          You have already filled out this reference
        </Alert>
      )}
      <Grid
        container
        justifyContent={"center"}
        // flexGrow={2}
        height={"fit-content"}
        alignItems={"center"}
      >
        <Grid item xs={10} lg={8}>
          <Typography
            variant="h6"
            sx={{
              fontWeight: 500,
              fontSize: "20px",
              lineHeight: "28px",
              color: "#333333",
            }}
          >
            {"Hi " + reference.referee_details?.referee_name}
          </Typography>
          <Typography
            variant="h6"
            sx={{
              fontWeight: 500,
              fontSize: "18px",
              lineHeight: "28px",
              color: "#333333",
              letterSpacing: "0.15px",
              mt: "80px",
              mb: "20px",
              wordWrap: "break-word",
            }}
          >
            {reference.candidate_profile?.name} has applied to join “
            {reference.company}” and has given your name as a referee.
          </Typography>
          <Typography
            variant="h6"
            sx={{
              fontWeight: 500,
              fontSize: "18px",
              lineHeight: "28px",
              color: "#333333",
              letterSpacing: "0.15px",
            }}
          >
            By clicking on complete reference now you accept to provide true
            information about the candidate.
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent={"center"}
        flexGrow={0.5}
        alignItems={"center"}
        sx={{
          position: { xs: "fixed", sm: "fixed", md: "relative" },
          bottom: "0px",
          left: "0px",
          width: "100%",
          zIndex: "99",
          textAlign: { xs: "center", md: "right" },
          background: { xs: "white", md: "transperant" },
          boxShadow: { xs: "0px -4px 7px #e6e6e6", md: "none" },
          borderRadius: { xs: "15px 15px 0px 0px", md: "none" },
        }}
      >
        <Grid item xs={12} lg={8}>
          <Box sx={{ textAlign: "center", mt: 3 }}>
            <PrimaryButton
              disabled={reference.completed}
              fullWidth={false}
              sx={{
                background: "#5AB9F9",
                color: "#ffffff",
                fontSize: 16,
                fontWeight: 500,
                p: "2px 80px",
              }}
              data-tut="start_application_btn"
              onClick={() => {
                dispatch(setReferenceData({ ...reference, active_index: 0 }));
                Navigate(routes[reference.available_routes[0]]);
              }}
            >
              Start
            </PrimaryButton>
          </Box>
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent={"center"}
        //  flexGrow={1}
        xs={12}
        alignItems={"center"}
      >
        <Grid item xs={10} lg={8} sx={{ mb: 4 }}>
          <Typography
            variant="h6"
            sx={{
              fontWeight: 400,
              fontSize: "12px",
              lineHeight: "20px",
              color: "#333333",
            }}
          >
            By agreeing to fill the reference, you agree that the information
            you provide is accurate and may be disclosed to a third party such
            as the person to whom this reference applies. I accept the Terms and
            Conditions. To know more read our Privacy Policy.
          </Typography>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default StartReference;
