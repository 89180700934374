import React, {
  ChangeEvent,
  FC,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  Paper,
  Typography,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  tableCellClasses,
  styled,
  TableCell,
  Box,
  FormControlLabel,
  RadioGroup,
  Radio,
  Stack,
  TablePagination,
  TableFooter,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { images } from "../../../../../utils/constants/images";
import SearchIcon from "@mui/icons-material/Search";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import InputField from "../../../../../components/InputField";
import "../DocumentRepository.scss";
import mime from "mime";
import axiosInstance from "../../../../../utils/axios";
import { URLS } from "../../../../../utils/constants/urls";
import axios from "axios";
import { identifiers } from "../../../../../utils/constants/identifiers";
import { DocumentModel } from "../DocumentRepositoryModel";
import dayjs from "dayjs";
import PrimaryButton from "../../../../../components/Button/PrimaryButton";
import Modal from "../../../../../components/modal/Modal";
import PageLoader from "../../../../../components/PageLoader";
import LinearProgress, {
  LinearProgressProps,
} from "@mui/material/LinearProgress";
import { Document, Page } from "react-pdf";
import { debounce } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { setNotificationMessage } from "../../../../../utils/redux";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import { Validators } from "../../../../../utils/validators";
import { useTour } from "@reactour/tour";
import { tourStep } from "../../../../../utils/constants/coachMarks";
import {
  User,
  setUserData,
} from "../../../../../utils/redux/reducer/authentication-slice";
import * as Amplify from "../../../../../utils/services/amplify";
import { CognitoUser } from "amazon-cognito-identity-js";
import { Auth } from "aws-amplify";

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "white",
    color: "#727272",
  },
  [`&.${tableCellClasses.body}`]: {
    color: "#263238",
    fontSize: "14px",
    fontWeight: 600,
    borderTop: "1px solid #DADADA",
    borderBottom: "1px solid #DADADA",
  },
  [`&.${tableCellClasses.head}:first-of-type`]: {
    borderTopLeftRadius: "4px",
    borderBottomLeftRadius: "4px",
    maxWidth: "1em",
    width: "1em",
  },

  [`&.${tableCellClasses.head}:last-of-type`]: {
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
    maxWidth: "350px",
    paddingLeft: "40px",
    width: "350px",
  },
  [`&.${tableCellClasses.body}:first-of-type`]: {
    borderTopLeftRadius: "12px",
    borderBottomLeftRadius: "12px",
    borderLeft: "1px solid #DADADA",
  },
  [`&.${tableCellClasses.body}:last-of-type`]: {
    borderTopRightRadius: "12px",
    borderBottomRightRadius: "12px",
    borderRight: "1px solid #DADADA",
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  "&": {
    backgroundColor: "white",
    borderRadius: "8px",
    paddingBottom: "15px",
    paddingTop: "15px",
  },
}));

interface HeadCell {
  disablePadding: boolean;
  id: string;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: "document_name_low_search",
    numeric: true,
    disablePadding: true,
    label: "Document Name",
  },
  {
    id: "uploaded_on",
    numeric: true,
    disablePadding: false,
    label: "Upload Date",
  },
];

interface EnhancedTableProps {
  order: boolean;
  loading: boolean;
  orderBy: string;
  onRequestSort: (property: string) => void;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { orderBy, order, onRequestSort, loading } = props;

  const createSortHandler = (property: string) => {
    onRequestSort(property);
  };

  return (
    <StyledTableRow>
      <StyledTableCell></StyledTableCell>
      {headCells.map((headCell) => (
        <StyledTableCell
          key={headCell.id}
          align={headCell.numeric ? "left" : "right"}
          padding={headCell.disablePadding ? "none" : "normal"}
          sortDirection={order ? "asc" : "desc"}
          title={
            loading ? "Please wait until all documents are fetched" : undefined
          }
        >
          <TableSortLabel
            active={orderBy === headCell.id}
            direction={order ? "asc" : "desc"}
            onClick={() => createSortHandler(headCell.id)}
            disabled={loading}
          >
            {headCell.label}
            {orderBy === headCell.id && (
              <Box component="span" sx={visuallyHidden}>
                {order ? "sorted ascending" : "sorted descending"}
                descending
              </Box>
            )}
          </TableSortLabel>
        </StyledTableCell>
      ))}
      <StyledTableCell>Action</StyledTableCell>
    </StyledTableRow>
  );
}

const LinearProgressWithLabel: FC<
  { value: number; reqNumber: number } & LinearProgressProps
> = ({ value, reqNumber, ...props }) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress
          color="success"
          variant="determinate"
          {...props}
          value={(value / reqNumber) * 100}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color={"secondary"}>{`${Math.round(
          value
        )}%`}</Typography>
      </Box>
    </Box>
  );
};

const initial_filter_state: {
  search: string;
  index: number;
  page_size: number;
  name: string;
  sort: boolean;
} = {
  search: "",
  index: 0,
  page_size: 10,
  name: "uploaded_on",
  sort: false,
};

const Documents: FC = () => {
  const fileUploadRef = useRef<any>(null);
  const dispatch = useDispatch();
  const theme = useTheme();
  const isDekstop = useMediaQuery(theme.breakpoints.up("md"));

  const [documentName, setDocumentName] = useState<string>("");
  const [hasError, setHasError] = useState<boolean>(false);
  const [documents, setDocuments] = useState<DocumentModel[]>([]);
  const [filter, setFilter] = useState<{
    search: string;
    index: number;
    page_size: number;
    name: string;
    sort: boolean;
  }>(initial_filter_state);
  const [loading, setLoading] = useState<boolean>();
  const [fileLoading, setFileLoading] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState(false);
  const [document, setDocument] = useState<DocumentModel | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [percentageProcess, setPercentageProcess] = useState(0);
  const [opacity, setOpacity] = useState("1");
  const [documentType, setDocumentType] = useState<"pdf" | "image">();
  const [documentUrl, setDocumentUrl] = useState<string>();
  const [viewDocument, setViewDocument] = useState(false);
  const [viewSigner, setViewSigner] = useState(false);
  const [numPages, setNumPages] = useState<number | null>(null);
  const [count, setCount] = useState<number>(0);
  const [selected, setSelected] = useState<
    "COMPLIANCE_OFFICER" | "CANDIDATE" | "BOTH"
  >("BOTH");
  const [dataFetching, setDataFetching] = useState<boolean>(false);
  const user: { [key: string]: string } = useSelector(User) as {
    [key: string]: string;
  };
  const { setIsOpen, currentStep, setCurrentStep } = useTour();

  const getAdditionalForms = async (
    search: string,
    name: string,
    sort: boolean
  ) => {
    const searchQuery = !!search && `&keyword=${search}`;
    const sortColumn = !!name && `&asc_sort=${sort}&sort_column=${name}`;
    let page_key = null;
    const document_list: DocumentModel[] = [];

    try {
      setLoading(true);
      setDataFetching(true);
      do {
        const { data }: { status: number; data: any } =
          await axiosInstance.post(
            `${URLS.document}?page_size=${filter.page_size}${sortColumn || ""}${
              searchQuery || ""
            }`,
            page_key
          );
        setLoading(false);
        document_list.push(...data.data);
        if (!!page_key) {
          setDocuments((prevState) => [...prevState, ...data.data]);
        } else {
          setDocuments(data.data);
        }
        page_key = JSON.parse(data.page_key);
      } while (!!page_key && page_key !== "null");
      setDocuments(document_list);
      setCount(document_list.length);
      setDataFetching(false);
    } catch (e: any) {
      setLoading(false);
      setDataFetching(false);
      if (await Auth.currentUserInfo()) {
        dispatch(
          setNotificationMessage({
            display: true,
            severity: "error",
            message: "Unable to Get List of Reference Forms",
          })
        );
      }
    }
  };

  const setCoachMarksValue = useCallback(
    async (value: any) => {
      //if (!!user && user["custom:reference_tutorial"] !== "true") {
      const currentUser: CognitoUser = await Amplify.UserDetail();
      const body = value;
      await Amplify.UpdateUserDetails(body, currentUser);
      await Amplify.RefreshSession();
      dispatch(setUserData({ ...user, ...body }));
      //}
    },
    [user, dispatch]
  );

  useEffect(() => {
    if (loading !== undefined && !loading) {
      if (!!user && user["custom:document_repository"] !== "true") {
        setTimeout(() => {
          setCurrentStep(tourStep.document_repository.index);
        }, 1000);
      }
    }
  }, [user, loading, setCurrentStep]);

  useEffect(() => {
    if (tourStep.document_repository.index === currentStep) {
      setIsOpen(true);
    } else if (
      currentStep ===
      tourStep.document_repository.index + tourStep.document_repository.steps
    ) {
      setIsOpen(false);
      if (!!user && user["custom:document_repository"] !== "true") {
        (async () => {
          await setCoachMarksValue({
            "custom:document_repository": "true",
          });
        })();
      }
      if (!!user && user["custom:document_set_signer"] !== "true") {
        if (count > 0) {
          setTimeout(() => {
            setCurrentStep(tourStep.set_signer.index);
          }, 1000);
        }
      }
    }

    if (tourStep.set_signer.index === currentStep) {
      setIsOpen(true);
    } else if (
      currentStep ===
      tourStep.set_signer.index + tourStep.set_signer.steps
    ) {
      setIsOpen(false);
      if (!!user && user["custom:document_set_signer"] !== "true") {
        (async () => {
          await setCoachMarksValue({
            "custom:document_set_signer": "true",
          });
        })();
      }
    }
  }, [count, currentStep, setCoachMarksValue, setCurrentStep, setIsOpen, user]);

  const delayedQuery = useRef(debounce(getAdditionalForms, 800)).current;

  const fetchData = useRef(debounce(getAdditionalForms, 100)).current;

  useEffect(() => {
    (async () => {
      await fetchData(
        initial_filter_state.search,
        initial_filter_state.name,
        initial_filter_state.sort
      );
    })();
  }, [fetchData]);

  const setSearch = async (search: string) => {
    setFilter((prevState) => ({ ...prevState, search, index: 0 }));
    delayedQuery(search, filter.name, filter.sort);
  };

  const handleRequestSort = async (property: string) => {
    const sort = filter.name === property ? !filter.sort : true;
    await fetchData(filter.search, property, sort);
    setFilter((prevState) => ({
      ...prevState,
      name: property,
      sort: prevState.name === property ? !prevState.sort : true,
      index: 0,
    }));
  };

  const handleFileUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    setIsUploading(true);
    if (event.target.files) {
      let max_size = identifiers.agency_max_file_size as number;
      if (event.target.files[0].size <= max_size) {
        setFileLoading(true);
        const file: File = event.target.files[0];
        const additionalForm: {
          name: string;
          type: string;
          directory: string;
          file_name: string;
          document_type: string;
        } = {
          type: mime.getType(file.name)
            ? (mime.getType(file.name) as string)
            : file.type,
          name: `${documentName}_${new Date().getTime()}.${mime.getExtension(
            mime.getType(file.name) as string
          )}`,
          file_name: documentName,
          directory: "documents",
          document_type: "document",
        };
        try {
          const { data } = await axiosInstance.post(
            `${URLS.file_upload}`,
            additionalForm
          );
          await uploadFile(
            file,
            data.url,
            mime.getType(file.name)
              ? (mime.getType(file.name) as string)
              : file.type
          );
          setFileLoading(false);
        } catch (e: any) {
          setIsUploading(false);
          setFileLoading(false);
          if (fileUploadRef.current) {
            fileUploadRef.current.value = null;
          }
          if (e.response.status === 409) {
            dispatch(
              setNotificationMessage({
                display: true,
                severity: "error",
                message: `File with name ${documentName} already exists`,
              })
            );
          } else {
            console.log(e);
          }
        }
      } else {
        dispatch(
          setNotificationMessage({
            display: true,
            severity: "error",
            message: `You cannot upload file more than ${
              (identifiers.agency_max_file_size as number) / 1000000
            }MB`,
          })
        );
        if (fileUploadRef.current) {
          fileUploadRef.current.value = null;
        }
      }
    }
  };

  const uploadFile = async (file: File, url: string, mime: string) => {
    setOpacity("0.6");
    try {
      const { status } = await axios.put(url, file, {
        headers: { "Content-Type": mime },
        onUploadProgress: function (progressEvent) {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / (progressEvent.total || 100)
          );
          setPercentageProcess(percentCompleted);
        },
      });
      if (status === 200) {
        setOpacity("1");
        setIsUploading(false);
        dispatch(
          setNotificationMessage({
            display: true,
            severity: "success",
            message: `File ${documentName} has been uploaded Successfully`,
          })
        );
        if (fileUploadRef.current) {
          fileUploadRef.current.value = null;
        }
        setDocumentName("");
        setLoading(false);
        setFileLoading(false);
        setTimeout(
          () => fetchData(filter.search, filter.name, filter.sort),
          5000
        );
      }
    } catch (e) {
      setOpacity("1");
      setIsUploading(false);
      setLoading(false);
      setFileLoading(false);
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "error",
          message: "There is an error in uploading document",
        })
      );
    }
  };

  const handleUpload = (e: SyntheticEvent) => {
    e.preventDefault();
    if (!!documentName) {
      if (fileUploadRef.current) {
        fileUploadRef.current.click();
      }
    } else {
      setHasError(true);
    }
  };

  const setSigner = useCallback(async () => {
    try {
      setLoading(true);
      await axiosInstance.patch(`${URLS.document}/${document?.id}`, {
        signer: selected.toLowerCase(),
      });
      await fetchData(filter.search, filter.name, filter.sort);
      setLoading(false);
      setViewSigner(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "error",
          message: "There is an error in updating signature",
        })
      );
    }
  }, [
    document?.id,
    selected,
    fetchData,
    filter.search,
    filter.name,
    filter.sort,
    dispatch,
  ]);

  const setName = (
    value: string,
    field: string,
    error?: { error: boolean; message: string }
  ) => {
    setDocumentName(value);
    if (!!error) {
      setHasError(error.error);
    } else {
      setHasError(!!error as boolean);
    }
  };

  const download = async (id: string, view: boolean) => {
    try {
      setLoading(true);
      const { data } = await axiosInstance.get(`${URLS.document}/${id}`);
      const type =
        data.mimetype.indexOf("pdf") > -1
          ? "pdf"
          : data.mimetype.indexOf("image") > -1
          ? "image"
          : null;
      if (!!type && view) {
        setDocumentType(type);
        setDocumentUrl(data.url);
        setViewDocument(true);
      } else {
        window.open(data.url, "_blank");
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "error",
          message: "There is an error in downloading",
        })
      );
    }
  };

  const deleteFile = async (selectedDocument: DocumentModel) => {
    setDocument(selectedDocument);
    setOpenModal(true);
  };

  const signerFile = async (selectedDocument: DocumentModel) => {
    setDocument(selectedDocument);
    setSelected(selectedDocument.envelope_signers);
    setViewSigner(true);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setFilter((prevState) => ({ ...prevState, index: newPage }));
  };

  const confirmDelete = useCallback(async () => {
    try {
      setLoading(true);
      await axiosInstance.delete(
        `${URLS.document}/${document?.id}?type=document`
      );
      setOpenModal(false);
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "success",
          message: `File ${documentName} has been deleted Successfully`,
        })
      );
      setLoading(false);
      setDocument(null);
      await fetchData(filter.search, filter.name, filter.sort);
    } catch (e) {
      setLoading(false);
      console.log(e);
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "error",
          message: "There is an error in deleting document",
        })
      );
    }
  }, [
    dispatch,
    document?.id,
    documentName,
    fetchData,
    filter.name,
    filter.search,
    filter.sort,
  ]);

  const deleteModalText = useMemo(() => {
    return (
      <Grid container>
        <Grid item>
          <Typography
            variant="h6"
            sx={{
              marginBottom: "20px",
              fontSize: 18,
              fontWeight: 500,
              color: "#000000",
              textAlign: "center",
              letterSpacing: "0.15px",
            }}
          >
            Delete Document Permanently
          </Typography>
          <Typography
            variant="h6"
            sx={{
              mb: 3,
              fontSize: 16,
              fontWeight: 500,
              color: "#727272",
            }}
          >
            {document?.document_name} will be deleted permanently from the
            repository.
            <br />
            Are you sure?
          </Typography>
        </Grid>
        <Grid container alignItems="right" justifyContent="right">
          <Button
            sx={{
              border: "none",
              color: "#727272",
              fontSize: 16,
              fontWeight: 500,
              mr: 2,
              mt: 1,
              p: "10px",
            }}
            disabled={loading}
            onClick={confirmDelete}
          >
            {loading && <CircularProgress sx={{ mr: 1 }} size={30} />}Delete
            Document
          </Button>
          <PrimaryButton
            disabled={loading}
            fullWidth={false}
            sx={{
              mt: 1,
              background: "#5AB9F9",
              color: "#ffffff",
              fontSize: 16,
              fontWeight: 500,
            }}
            onClick={() => {
              setOpenModal(false);
              setDocument(null);
            }}
          >
            Cancel
          </PrimaryButton>
        </Grid>
      </Grid>
    );
  }, [confirmDelete, document?.document_name, loading]);

  const viewSignerText = useMemo(() => {
    return (
      <Grid container>
        <Grid item>
          <Typography
            variant="h6"
            sx={{
              marginBottom: "20px",
              fontSize: 18,
              fontWeight: 500,
              color: "#000000",
              letterSpacing: "0.15px",
            }}
          >
            Document Signer
          </Typography>
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={selected}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setSelected(
                  event.target.value as
                    | "COMPLIANCE_OFFICER"
                    | "CANDIDATE"
                    | "BOTH"
                );
              }}
            >
              <FormControlLabel
                value="COMPLIANCE_OFFICER"
                control={<Radio />}
                label="Compliance Officer"
              />
              <FormControlLabel
                value="CANDIDATE"
                control={<Radio />}
                label="Applicant"
              />
              <FormControlLabel value="BOTH" control={<Radio />} label="Both" />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid container alignItems="right" justifyContent="right">
          <Button
            sx={{
              border: "none",
              color: "#727272",
              fontSize: 16,
              fontWeight: 500,
              mr: 2,
              mt: 1,
              p: "10px",
            }}
            disabled={loading}
            onClick={setSigner}
          >
            {loading && <CircularProgress sx={{ mr: 1 }} />}Submit
          </Button>
          <PrimaryButton
            disabled={loading}
            fullWidth={false}
            sx={{
              mt: 1,
              background: "#5AB9F9",
              color: "#ffffff",
              fontSize: 16,
              fontWeight: 500,
            }}
            onClick={() => {
              setViewSigner(false);
            }}
          >
            Cancel
          </PrimaryButton>
        </Grid>
      </Grid>
    );
  }, [setSigner, selected, loading]);

  const viewModalText = useMemo(() => {
    return (
      <Grid container>
        <Grid item>
          {documentType === "pdf" && (
            <Document
              file={{ url: documentUrl }}
              onLoadSuccess={({ numPages }) => setNumPages(numPages)}
            >
              {Array.from(new Array(numPages), (el, index) => (
                <Page key={`page_${index + 1}`} pageNumber={index + 1} />
              ))}
            </Document>
          )}
          {documentType === "image" && (
            <img src={documentUrl} alt={"document"} className={"img-fluid"} />
          )}
        </Grid>
      </Grid>
    );
  }, [documentType, documentUrl, numPages]);

  const openDeleteModal = useMemo(() => {
    return (
      <Modal
        open={openModal}
        setModalClose={setOpenModal}
        children={deleteModalText}
        title={undefined}
        size="sm"
        className={undefined}
      />
    );
  }, [openModal, deleteModalText]);

  const openSignerModal = useMemo(() => {
    return (
      <Modal
        open={viewSigner}
        setModalClose={setViewSigner}
        children={viewSignerText}
        title={undefined}
        size="sm"
        className={undefined}
      />
    );
  }, [viewSigner, viewSignerText]);

  const openViewModal = useMemo(() => {
    return (
      <Modal
        open={viewDocument}
        setModalClose={setViewDocument}
        children={viewModalText}
        title={undefined}
        size="sm"
        className={undefined}
      />
    );
  }, [viewDocument, viewModalText]);

  return (
    <>
      {loading && <PageLoader />}
      <Grid container spacing={2} sx={{ px: 0 }}>
        {openDeleteModal}
        {openViewModal}
        {openSignerModal}
        <Grid item xs={12} md={4}>
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            padding="0"
            className="mob-card text-center pt-0"
          >
            <Grid item md={12} className=" card-heading-right mb-1 ">
              Upload Documents
            </Grid>
            <Grid item xs={12} className="dotted-card content-card">
              <Typography variant={"body1"} color={"#727272"} sx={{ mb: 1 }}>
                Document Name
              </Typography>
              <InputField
                placeholder={"Type Here..."}
                size={"small"}
                sx={{
                  ".MuiOutlinedInput-notchedOutline": { border: 0 },
                  background: "#F6F7FB",
                  fontSize: "12px",
                  borderRadius: "5px",
                  mb: 3,
                }}
                variant={"outlined"}
                inputValue={documentName}
                textChange={setName}
                validators={[
                  {
                    check: Validators.required,
                    message: identifiers.field_error as string,
                  },
                  {
                    check: Validators.FileName,
                    message:
                      "File name can only contain Alpha-Numeric Characters, underscores and dashes",
                  },
                ]}
                // hasError={hasError}
                // fieldError={identifiers.field_error as string}
              />
              <Button
                variant="contained"
                component="label"
                className="upload-button"
                onClick={handleUpload}
                data-tut={!isDekstop ? "choose_btn" : ""}
                disabled={loading || hasError || fileLoading}
              >
                {/* {fileLoading && <CircularProgress sx={{ mr: 1 }} size={20} />} */}
                Choose File
              </Button>
              <input
                ref={fileUploadRef}
                hidden
                accept={identifiers.accept as string}
                type="file"
                onChange={handleFileUpload}
              />
              <p className="instructions mt-4">
                {/*or drag file in here <br />*/}
                accepted file .doc, .docx and .pdf (max size 2MB)
              </p>
            </Grid>
            {isUploading && (
              <Grid
                item
                className="dotted-card pt-1 text-start pb-1 "
                sx={{ mt: 2 }}
                width={"100%"}
                md={9}
              >
                {documentName}
                <LinearProgressWithLabel
                  variant="determinate"
                  value={percentageProcess}
                  reqNumber={100}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: { xs: "space-between", md: "flex-start" },
            }}
          >
            <Grid item xs={12} lg={3}>
              <InputField
                data-tut="search_bar"
                sx={{ mr: 1 }}
                placeholder="Search Document..."
                value={filter.search}
                textChange={setSearch}
                inputProps={{
                  sx: {
                    p: 1,
                  },
                  title: dataFetching
                    ? "Please wait until all documents are fetched"
                    : undefined,
                }}
                InputProps={{
                  endAdornment: <SearchIcon />,
                }}
                disabled={dataFetching}
              />
            </Grid>
          </Grid>
        </Grid>
        {!loading && (
          <>
            <Grid item xs={12} md={12} lg={8} paddingRight={3}>
              <Grid
                container
                sx={{
                  borderRadius: "18px",
                  p: 0,
                  backgroundColor: "white",
                }}
              >
                <Grid container className="card-head">
                  <Grid
                    data-tut="list_table"
                    item
                    md={5}
                    className="card-header-text"
                  >
                    Uploaded Documents: {count}
                  </Grid>
                </Grid>
                <Grid container spacing={2} className={"mb-3"}>
                  <Grid item xs={12} md={12}>
                    <TableContainer
                      component={Paper}
                      sx={{ boxShadow: "none" }}
                    >
                      <Table
                        sx={{
                          minWidth: 600,
                          borderSpacing: "0 1rem",
                          borderCollapse: "separate",
                        }}
                      >
                        <TableHead>
                          <EnhancedTableHead
                            order={filter.sort}
                            orderBy={filter.name}
                            onRequestSort={handleRequestSort}
                            loading={dataFetching}
                          />
                        </TableHead>
                        <TableBody className={"documents-table-body"}>
                          {documents
                            .slice(
                              filter.index * filter.page_size,
                              filter.index * filter.page_size + filter.page_size
                            )
                            .map((document, index) => (
                              <StyledTableRow
                                className={"documents-table-body-row"}
                                key={index}
                              >
                                <StyledTableCell></StyledTableCell>
                                <StyledTableCell
                                  sx={{
                                    width: "250px",
                                    wordBreak: "break-all",
                                  }}
                                >
                                  {document.document_name}
                                </StyledTableCell>
                                <StyledTableCell sx={{ width: "150px" }}>
                                  {dayjs(new Date(document.uploaded_on)).format(
                                    "DD-MM-YYYY"
                                  )}
                                </StyledTableCell>
                                <StyledTableCell>
                                  <Stack direction={"row"}>
                                    <Button
                                      onClick={() => deleteFile(document)}
                                      sx={{
                                        border: "none",
                                        color: "#727272",
                                        fontSize: 16,
                                        fontWeight: 500,
                                        mr: 2,
                                        mt: 1,
                                        p: "10px",
                                        cursor: "pointer",
                                      }}
                                    >
                                      <img
                                        src={images.deleteIcon}
                                        alt="delete"
                                      />
                                    </Button>
                                    <Button
                                      onClick={() =>
                                        download(document.id, false)
                                      }
                                      sx={{
                                        border: "none",
                                        color: "#727272",
                                        fontSize: 16,
                                        fontWeight: 500,
                                        mr: 2,
                                        mt: 1,
                                        p: "10px",
                                        cursor: "pointer",
                                      }}
                                    >
                                      <img
                                        src={images.downloadIcon}
                                        alt="download"
                                      />
                                    </Button>
                                    <Button
                                      onClick={() =>
                                        download(document.id, true)
                                      }
                                      sx={{
                                        border: "none",
                                        color: "#727272",
                                        fontSize: 16,
                                        fontWeight: 500,
                                        mr: 2,
                                        mt: 1,
                                        p: "10px",
                                        cursor: "pointer",
                                      }}
                                    >
                                      <img
                                        src={images.viewIcon}
                                        alt="view"
                                        className="action-icon"
                                      />
                                    </Button>
                                    <Button
                                      onClick={() => signerFile(document)}
                                      sx={{
                                        border: "none",
                                        color: "#727272",
                                        fontSize: 16,
                                        fontWeight: 500,
                                        mr: 2,
                                        mt: 1,
                                        p: "10px",
                                        cursor: "pointer",
                                      }}
                                      data-tut="set_signer"
                                    >
                                      <DriveFileRenameOutlineIcon />
                                    </Button>
                                  </Stack>
                                </StyledTableCell>
                              </StyledTableRow>
                            ))}
                          {documents.length === 0 && !loading && (
                            <StyledTableRow
                              className={"request-table-body-row"}
                            >
                              <StyledTableCell colSpan={7}>
                                <Stack textAlign={"center"}>
                                  <img
                                    src={images.emptyIcon}
                                    alt={"no request"}
                                    className={
                                      "vertical-align-middle empty-image mb-2"
                                    }
                                  />
                                  <Stack
                                    direction={"row"}
                                    justifyContent={"center"}
                                  >
                                    <Typography variant={"body2"}>
                                      No Documents Found, Upload To see the
                                      Documents.
                                    </Typography>
                                  </Stack>
                                </Stack>
                              </StyledTableCell>
                            </StyledTableRow>
                          )}
                        </TableBody>
                        <TableFooter>
                          <TableRow>
                            <TablePagination
                              colSpan={6}
                              count={documents.length}
                              rowsPerPage={filter.page_size}
                              page={filter.index}
                              onRowsPerPageChange={(e) => {
                                setFilter((prevState) => ({
                                  ...prevState,
                                  page_size: parseInt(e.target.value, 10),
                                }));
                              }}
                              onPageChange={handleChangePage}
                              ActionsComponent={TablePaginationActions}
                            />
                          </TableRow>
                        </TableFooter>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={12} lg={4}>
              <Grid
                container
                direction="column"
                alignItems="center"
                justifyContent="center"
                //className="card-right text-center p-5"
                sx={{
                  background: "#f5fbff",
                  padding: "40px 0 70px 0 ",
                  display: { xs: "none", md: "inline-block" },
                  borderRadius: "10px",
                  textAlign: "center",
                  p: 5,
                }}
              >
                <Grid
                  item
                  width="100%"
                  className="mb-5 card-heading-right mt-5"
                >
                  Upload Documents
                </Grid>
                <Grid
                  item
                  sx={{ opacity: `${opacity}`, width: "100%" }}
                  className="dotted-card"
                >
                  <Typography
                    variant={"body1"}
                    color={"#727272"}
                    sx={{ mb: 1 }}
                  >
                    Document Name
                  </Typography>
                  <InputField
                    placeholder={"Enter Document Name"}
                    size={"small"}
                    sx={{
                      ".MuiOutlinedInput-notchedOutline": { border: 0 },
                      background: "#F6F7FB",
                      fontSize: "12px",
                      borderRadius: "5px",
                      mb: 3,
                    }}
                    variant={"outlined"}
                    inputValue={documentName}
                    textChange={setName}
                    validators={[
                      {
                        check: Validators.required,
                        message: identifiers.field_error as string,
                      },
                      {
                        check: Validators.FileName,
                        message:
                          "File name can only contain Alpha-Numeric Characters, underscores and dashes",
                      },
                    ]}
                    // hasError={hasError}
                    // fieldError={identifiers.field_error as string}
                  />
                  <Button
                    variant="contained"
                    component="label"
                    className="upload-button"
                    onClick={handleUpload}
                    data-tut={isDekstop ? "choose_btn" : ""}
                    disabled={loading || hasError || fileLoading}
                  >
                    {/* {fileLoading && <CircularProgress sx={{ mr: 1 }} />} */}
                    Choose File
                  </Button>
                  <input
                    ref={fileUploadRef}
                    hidden
                    accept={identifiers.accept as string}
                    type="file"
                    size={identifiers.maxFileSize as number}
                    onChange={handleFileUpload}
                  />
                  <p className="instructions mt-4">
                    {/*or drag file in here <br />*/}
                    accepted file .doc, .docx and .pdf (max size 2MB)
                  </p>
                </Grid>
                {isUploading && (
                  <Grid
                    item
                    className="dotted-card pt-3 text-start pb-3 "
                    sx={{ mt: 2 }}
                    width={"100%"}
                  >
                    {documentName}
                    <LinearProgressWithLabel
                      variant="determinate"
                      value={percentageProcess}
                      reqNumber={100}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default Documents;
