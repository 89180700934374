import React, { FC, MouseEvent, useState, useEffect, useCallback } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Container,
  Grid,
  TextFieldProps,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { images } from "../../../../utils/constants/images";
import PrimaryButton from "../../../../components/Button/PrimaryButton";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { agency } from "../../../../utils/constants/routes";
import axiosInstance from "../../../../utils/axios";
import { URLS } from "../../../../utils/constants/urls";
import DateInput from "../../../../components/DateInput/DateInput";
import InputField from "../../../../components/InputField";
import { identifiers } from "../../../../utils/constants/identifiers";

import "./CreateRequest.scss";
import { Validators } from "../../../../utils/validators";
import { useDispatch, useSelector } from "react-redux";
import { setNotificationMessage } from "../../../../utils/redux";
import { useTour } from "@reactour/tour";
import { tourStep } from "../../../../utils/constants/coachMarks";
import { CognitoUser } from "amazon-cognito-identity-js";
import * as Amplify from "../../../../utils/services/amplify";
import {
  User,
  setUserData,
} from "../../../../utils/redux/reducer/authentication-slice";

const CreateRequest: FC = () => {
  const { setIsOpen, currentStep, setCurrentStep } = useTour();
  const dispatch = useDispatch();
  const user: { [key: string]: string } = useSelector(User) as {
    [key: string]: string;
  };
  const Navigate = useNavigate();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));
  const minDate: number = new Date(
    dayjs(new Date()).format("YYYY-MM-DD 00:00:00.000")
  ).getTime();
  const [formData, setFormData] = useState<{
    [key: string]: string | { error: boolean; message: string } | undefined;
  }>({
    company: "",
    designation: "",
    date: "",
  });
  const [loading, setLoading] = useState<boolean>(false);

  const setCoachMarksValue = useCallback(async () => {
    //if (!!user && user["custom:reference_tutorial"] !== "true") {
    const currentUser: CognitoUser = await Amplify.UserDetail();
    const body = {
      "custom:create_request": "true",
    };
    await Amplify.UpdateUserDetails(body, currentUser);
    await Amplify.RefreshSession();
    dispatch(setUserData({ ...user, ...body }));
    //}
  }, [dispatch, user]);

  useEffect(() => {
    if (!!user && user["custom:create_request"] !== "true") {
      setCurrentStep(tourStep.create_request.index);
    }
  }, [setCurrentStep, user]);

  useEffect(() => {
    if (tourStep.create_request.index === currentStep) {
      setIsOpen(true);
    } else if (
      currentStep ===
      tourStep.create_request.index + tourStep.create_request.steps
    ) {
      setIsOpen(false);
      if (!!user && user["custom:create_request"] !== "true") {
        (async () => {
          await setCoachMarksValue();
        })();
      }
    }
  }, [currentStep, setCoachMarksValue, setIsOpen, user]);

  const setData = (
    value: string,
    field: string,
    error: { error: boolean; message: string } | undefined
  ) => {
    setFormData((prevState) => ({
      ...prevState,
      [field]: value as string,
      [field + "Error"]: error ? error : { error: false, message: "" },
    }));
  };

  const isInvalid = (): boolean => {
    const companyError = formData.companyError as
      | { error: boolean; message: string }
      | undefined;
    const designationError = formData.designationError as
      | { error: boolean; message: string }
      | undefined;
    const dateError = formData.dateError as
      | { error: boolean; message: string }
      | undefined;
    return (
      (companyError ? companyError.error : true) ||
      (designationError ? designationError.error : true) ||
      (dateError ? dateError.error : false)
    );
  };

  const handleSubmit = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setLoading(true);
    if (!isInvalid()) {
      try {
        const { data } = await axiosInstance.post(URLS.requests, formData);
        setLoading(false);
        Navigate(agency.requests + "/" + data.id);
      } catch (e) {
        console.log(e);
        setLoading(false);
        dispatch(
          setNotificationMessage({
            display: true,
            severity: "error",
            message: "There is an error in creating new request",
          })
        );
      }
    }
  };

  return (
    <Container sx={matches ? {} : { px: 0 }}>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={
          matches
            ? { minHeight: "calc(100vh - 70px)" }
            : { minHeight: "calc(100vh - 100px)" }
        }
      >
        <Grid container>
          <Grid
            item
            data-tut="request_details"
            xs={12}
            sx={{ justifyContent: "center" }}
          >
            <Typography variant={"h6"} align={"center"} fontWeight={"bold"}>
              Request Details
            </Typography>
            <Typography variant={"body2"} align={"center"}>
              Please fill in the required details for your New Request
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} lg={4}>
            <Card
              data-tut="hiring_ompany"
              className="cards"
              sx={{ p: matches ? 2 : 1 }}
            >
              <CardHeader
                avatar={
                  <img
                    src={images.requestUpload}
                    alt="Upload logo"
                    className="vertical-align-middle"
                  />
                }
                title="Hiring Company"
              />
              <CardContent sx={{ py: 0 }}>
                <InputField
                  field={"company"}
                  label={"Company Name"}
                  textChange={setData}
                  inputValue={formData.company as string}
                  type={"text"}
                  sx={{ mt: 2 }}
                  validators={[
                    {
                      check: Validators.required,
                      message: "This field is required",
                    },
                  ]}
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <Card
              data-tut="job_role"
              className="cards"
              sx={{ p: matches ? 2 : 1 }}
            >
              <CardHeader
                avatar={
                  <img
                    src={images.requestDesignation}
                    alt="Designation logo"
                    className="vertical-align-middle"
                  />
                }
                title="Job Role"
              />
              <CardContent sx={{ py: 0 }}>
                <InputField
                  field={"designation"}
                  label={"Job Role"}
                  textChange={setData}
                  inputValue={formData.designation as string}
                  type={"text"}
                  sx={{ mt: 2 }}
                  validators={[
                    {
                      check: Validators.required,
                      message: "This field is required",
                    },
                  ]}
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <Card
              data-tut="closing_date"
              className="cards"
              sx={{ p: matches ? 2 : 1 }}
            >
              <CardHeader
                avatar={
                  <img
                    src={images.calenderIconCreateRequest} // icon will be replaced
                    alt="Date logo"
                    className="vertical-align-middle"
                  />
                }
                title="Closing Date"
              />
              <CardContent sx={{ py: 0 }}>
                <DateInput
                  field={"date"}
                  disablePast={true}
                  textChange={setData}
                  inputFormat={identifiers.date_format as string}
                  onChange={(e) => e}
                  value={
                    formData.date
                      ? dayjs(new Date(formData.date as string))
                      : null
                  }
                  renderInput={(params: TextFieldProps) => {
                    const dateError = formData.dateError as
                      | { error: boolean; message: string }
                      | undefined;
                    return (
                      <InputField
                        {...params}
                        sx={{ mt: 2 }}
                        fieldError={dateError ? dateError.message : ""}
                        hasError={dateError ? dateError.error : false}
                      />
                    );
                  }}
                  validators={[
                    {
                      check: Validators.minDate,
                      message: "Please enter valid Date",
                      num: minDate,
                    },
                  ]}
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Grid container justifyContent={"center"}>
          <Grid item xs={12} sm={6} lg={4} textAlign={"center"}>
            <PrimaryButton
              disabled={loading || isInvalid()}
              fullWidth={false}
              sx={{
                mt: 1,
                background: "#5AB9F9",
                color: "#ffffff",
                fontSize: 16,
                fontWeight: 500,
              }}
              onClick={handleSubmit}
              data-tut="confirm_details"
            >
              {loading && (
                <CircularProgress sx={{ mr: 1, color: "white" }} size={20} />
              )}
              Create Request
            </PrimaryButton>
          </Grid>
        </Grid>
        {/*<div className="row margin-top-button">*/}
        {/*  <div className="col text-center">*/}
        {/*    <button className="btn btn-default confirm-button">*/}
        {/*      Confirm Details*/}
        {/*    </button>*/}
        {/*  </div>*/}
        {/*</div>*/}
      </Grid>
    </Container>
  );
};

export default CreateRequest;
