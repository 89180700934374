import { ICountry } from 'country-state-city/lib/interface';
import { Country } from 'country-state-city';
import { FormModel } from '../../../../../../../../../components/Form';
import { Validators } from '../../../../../../../../../utils/validators';
import { getNames, registerLocale } from 'i18n-nationality';
import {
  gender,
  titles,
  yes_no,
} from '../../../../../../../../../utils/constants/constants';
import { PersonalInformation } from '../../../../../../../../../utils/redux/reducer/u-check-slice';
import { UCheckValidators } from '../../../../../../../../../utils/validators/uCheckValidators';

registerLocale(require('i18n-nationality/langs/en.json'));
const countries: ICountry[] = Country.getAllCountries();
const nationalities = getNames('en');

export const PersonalInformationFormModel: (
  personalInformationForm: PersonalInformation,
  dbs_type: string,
  disabled?:boolean,
) => FormModel[] = (personalInformationForm, dbs_type,disabled) => {
  const PersonalInfoForm: FormModel[] = [
    {
      label: 'Title*',
      value: '',
      size: 'small',
      type: 'drop-down',
      options: titles,
      typeValue: 'text',
      variant: 'outlined',
      placeholder: '',
      field: 'title',
      validators: [
        { check: Validators.required, message: 'This field is mandatory' },
        {
          check: UCheckValidators.Title,
          gender: personalInformationForm.gender || '',
          message: 'Selected Gender is not Valid for Selected Title',
        },
      ],
      responsive: { md: 6, xs: 12 },
      required: true,
      style: { background: '#FFFFFF', borderRadius: '8px' },
      disabled:disabled
    },
    {
      label: 'Forename*',
      value: '',
      size: 'small',
      type: 'text',
      typeValue: 'text',
      variant: 'outlined',
      placeholder: '',
      field: 'forename',
      validators: [
        { check: Validators.required, message: 'This field is mandatory' },
        {
          check: UCheckValidators.Name,
          message: 'Forename should contain only A-Z, spaces and -',
        },
        {
          check: Validators.minCharLimit,
          message: 'Forename should contain at least 1 character',
          num: 1,
        },
        {
          check: Validators.maxCharLimit,
          message: 'Forename should contain at max 60 characters',
          num: 60,
        },
      ],
      responsive: { md: 6, xs: 12 },
      required: true,
      style: { background: '#FFFFFF', borderRadius: '8px' },
      disabled:disabled
    },
    {
      label: 'Middle Name 1',
      value: '',
      size: 'small',
      type: 'text',
      typeValue: 'text',
      variant: 'outlined',
      placeholder: '',
      field: 'middlename_1',
      validators: [
        {
          check: UCheckValidators.Name,
          message: 'Middle Name should contain only Uppercase letters, spaces',
        },
        {
          check: Validators.minCharLimit,
          message: 'Middle Name should contain at least 1 character',
          num: 1,
        },
        {
          check: Validators.maxCharLimit,
          message: 'Middle Name should contain at max 60 characters',
          num: 60,
        },
      ],
      responsive: { xs: 12, md: 6 },
      required: false,
      style: { background: '#FFFFFF', borderRadius: '8px' },
      disabled:disabled
    },
    {
      label: 'Middle Name 2',
      value: '',
      size: 'small',
      type: 'text',
      typeValue: 'text',
      variant: 'outlined',
      placeholder: '',
      field: 'middlename_2',
      validators: [
        {
          check: UCheckValidators.Name,
          message: 'Middle Name should contain only Uppercase letters, spaces',
        },
        {
          check: Validators.minCharLimit,
          message: 'Middle Name should contain at least 1 character',
          num: 1,
        },
        {
          check: Validators.maxCharLimit,
          message: 'Middle Name should contain at max 60 characters',
          num: 60,
        },
      ],
      responsive: { xs: 12, md: 6 },
      required: false,
      style: { background: '#FFFFFF', borderRadius: '8px' },
      disabled:disabled
    },
    {
      label: 'Middle Name 3',
      value: '',
      size: 'small',
      type: 'text',
      typeValue: 'text',
      variant: 'outlined',
      placeholder: '',
      field: 'middlename_3',
      validators: [
        {
          check: UCheckValidators.Name,
          message: 'Middle Name should contain only Uppercase letters, spaces',
        },
        {
          check: Validators.minCharLimit,
          message: 'Middle Name should contain at least 1 character',
          num: 1,
        },
        {
          check: Validators.maxCharLimit,
          message: 'Middle Name should contain at max 60 characters',
          num: 60,
        },
      ],
      responsive: { xs: 12, md: 6 },
      required: false,
      style: { background: '#FFFFFF', borderRadius: '8px' },
      disabled:disabled
    },
    {
      label: 'Surname*',
      value: '',
      size: 'small',
      type: 'text',
      typeValue: 'text',
      variant: 'outlined',
      placeholder: '',
      field: 'present_surname',
      validators: [
        { check: Validators.required, message: 'This field is mandatory' },
        {
          check: UCheckValidators.Name,
          message: 'Surname should contain only A-Z, spaces and -',
        },
        {
          check: Validators.minCharLimit,
          message: 'Surname should contain at least 1 character',
          num: 1,
        },
        {
          check: Validators.maxCharLimit,
          message: 'Surname should contain at max 60 characters',
          num: 60,
        },
      ],
      responsive: { xs: 12, md: 6 },
      required: true,
      style: { background: '#FFFFFF', borderRadius: '8px' },
      disabled:disabled
    },
    {
      label: 'Date of Birth*',
      value: '',
      size: 'small',
      type: 'date',
      typeValue: 'text',
      variant: 'outlined',
      placeholder: '',
      disableFuture: true,
      maxDate: new Date(new Date().getFullYear() - 16, 11, 31, 0, 0, 0, 0),
      minDate: new Date(new Date().getFullYear() - 113, 0, 1, 0, 0, 0, 0),
      openTo: 'year',
      views: ['year', 'month', 'day'],
      field: 'date_of_birth',
      validators: [
        { check: Validators.required, message: 'This field is mandatory' },
      ],
      responsive: { md: 6, xs: 12 },
      required: true,
      style: { background: '#FFFFFF', borderRadius: '8px' },
      disabled:disabled
    },
    {
      label: 'Gender*',
      value: '',
      size: 'small',
      type: 'drop-down',
      options: gender,
      typeValue: 'text',
      variant: 'outlined',
      placeholder: '',
      field: 'gender',
      validators: [
        { check: Validators.required, message: 'This field is mandatory' },
        {
          check: UCheckValidators.Gender,
          title: personalInformationForm.title || '',
          message: 'Selected Gender is not Valid for Selected Title',
        },
      ],
      responsive: { md: 6, xs: 12 },
      required: true,
      style: { background: '#FFFFFF', borderRadius: '8px' },
      disabled:disabled
    },
    // {
    //   label: 'Applicant Email Address*',
    //   value: '',
    //   size: 'small',
    //   type: 'text',
    //   typeValue: 'text',
    //   variant: 'outlined',
    //   placeholder: '',
    //   field: 'contact_email',
    //   validators: [
    //     { check: Validators.required, message: 'This field is mandatory' },
    //     { check: Validators.email, message: 'Invalid Email ID' },
    //   ],
    //   responsive: { md: 6, xs: 12 },
    //   required: true,
    //   style: { background: '#ffffff', borderRadius: '8px' },
    // },
    {
      label: 'Birth Nationality*',
      value: '',
      size: 'small',
      type: 'drop-down',
      options: Object.keys(nationalities).map((key: string) => ({
        label: nationalities[key],
        value: key,
      })),
      typeValue: 'text',
      variant: 'outlined',
      placeholder: '',
      field: 'birth_nationality',
      validators: [
        { check: Validators.required, message: 'This field is mandatory' },
      ],
      responsive: { md: 6, xs: 12 },
      required: true,
      style: { background: '#FFFFFF', borderRadius: '8px' },
      disabled:disabled
    },
    {
      label: 'County Of Birth',
      value: '',
      size: 'small',
      type: 'text',
      typeValue: 'text',
      variant: 'outlined',
      placeholder: '',
      field: 'birth_county',
      validators: [
        {
          check: UCheckValidators.TownCounty,
          message:
            "Birth County should contain only Alpha Numeric Characters, ', (,), - and \\",
        },
        {
          check: Validators.minCharLimit,
          message: 'Birth County should contain at least 1 character',
          num: 1,
        },
        {
          check: Validators.maxCharLimit,
          message: 'Birth County should contain at max 30 characters',
          num: 30,
        },
      ],
      responsive: { md: 6, xs: 12 },
      required: false,
      style: { background: '#FFFFFF', borderRadius: '8px' },
      disabled:disabled
    },
    {
      label: 'Country Of Birth*',
      value: '',
      size: 'small',
      type: 'drop-down',
      options: countries.map((country) => ({
        label: country.name,
        value: `${country.isoCode}`,
      })),
      typeValue: 'text',
      variant: 'outlined',
      placeholder: '',
      field: 'birth_country',
      validators: [
        { check: Validators.required, message: 'This field is mandatory' },
      ],
      responsive: { md: 6, xs: 12 },
      required: true,
      style: { background: '#FFFFFF', borderRadius: '8px' },
      disabled:disabled
    },
    {
      label: 'Town Of Birth*',
      value: '',
      size: 'small',
      type: 'text',
      typeValue: 'text',
      variant: 'outlined',
      placeholder: '',
      field: 'birth_town',
      validators: [
        { check: Validators.required, message: 'This field is mandatory' },
        {
          check: UCheckValidators.TownCounty,
          message:
            "Birth Town should contain only Alpha Numeric Characters, ', (,), - and \\",
        },
        {
          check: Validators.minCharLimit,
          message: 'Birth Town should contain at least 1 character',
          num: 1,
        },
        {
          check: Validators.maxCharLimit,
          message: 'Birth Town should contain at max 30 characters',
          num: 30,
        },
      ],
      responsive: { xs: 12, md: 6 },
      required: true,
      style: { background: '#FFFFFF', borderRadius: '8px' },
      disabled:disabled
    },
  ];
  if (dbs_type === 'scottish') {
    PersonalInfoForm.push({
      label: 'Mother maiden name*',
      value: '',
      size: 'small',
      type: 'text',
      typeValue: 'text',
      variant: 'outlined',
      placeholder: '',
      field: 'mother_maidenname',
      validators: [
        { check: Validators.required, message: 'This field is mandatory' },
        {
          check: UCheckValidators.Name,
          message: 'First and last character of name must be a letter.',
        },
      ],
      responsive: { xs: 12, md: 6 },
      required: true,
      style: { background: '#FFFFFF', borderRadius: '8px' },
      disabled:disabled
    });
  }
  PersonalInfoForm.push(
    // {
    //   label: 'Phone Number*',
    //   value: '',
    //   size: 'small',
    //   type: 'text',
    //   typeValue: 'tel',
    //   variant: 'outlined',
    //   placeholder: '',
    //   field: 'contact_number',
    //   validators: [
    //     { check: Validators.required, message: 'This field is mandatory' },
    //     {
    //       check: Validators.mobile,
    //       message: 'Please enter a valid phone number',
    //     },
    //   ],
    //   responsive: { md: 6, xs: 12 },
    //   required: true,
    //   style: { background: '#ffffff', borderRadius: '8px' },
    // },
    {
      label: 'Do you have National Insurance Number?*',
      value: '',
      size: 'small',
      type: 'drop-down',
      options: yes_no,
      typeValue: 'text',
      variant: 'outlined',
      placeholder: '',
      field: 'has_nin',
      validators: [
        { check: Validators.required, message: 'This field is mandatory' },
      ],
      responsive: { md: 6, xs: 12 },
      required: true,
      style: { background: '#FFFFFF', borderRadius: '8px' },
      disabled:disabled
    }
  );

  if (
    !!personalInformationForm.has_nin &&
    personalInformationForm.has_nin === 'y'
  ) {
    PersonalInfoForm.push({
      label: 'National Insurance Number*',
      value: '',
      size: 'small',
      type: 'text',
      typeValue: 'text',
      variant: 'outlined',
      placeholder: '',
      field: 'nin',
      validators: [
        { check: Validators.required, message: 'This field is mandatory' },
        { check: Validators.NIN, message: 'Please enter valid NI number' },
      ],
      responsive: { xs: 12, md: 6 },
      required: true,
      style: { background: '#FFFFFF', borderRadius: '8px' },
      disabled:disabled
    });
  }

  return PersonalInfoForm;
};
