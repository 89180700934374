import React, {
  FC,
  useEffect,
  useState,
  ChangeEvent,
  useCallback,
} from "react";
import {
  Grid,
  Select,
  SelectChangeEvent,
  Typography,
  TableCell,
  styled,
  tableCellClasses,
  TableRow,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  RadioGroup,
  FormControlLabel,
  Radio,
  Paper,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import {
  SubDocument,
  VettingDocument,
  DBSWorkforceType,
  DBSRBDetails,
} from "../RequestDetailsModel";
import CheckBox from "../../../../../components/CheckBox";
import "../RequestDetails.scss";
import InputField from "../../../../../components/InputField";
import { Validators } from "../../../../../utils/validators";
import { useDispatch, useSelector } from "react-redux";
import { IsEditable, setVettingDocuments } from "../../../../../utils/redux";
import { AcademicDocument } from "../../../../../utils/redux/reducer/candidate-vetting-slice";
import { useTour } from "@reactour/tour";
import { tourStep } from "../../../../../utils/constants/coachMarks";
import {
  User,
  setUserData,
} from "../../../../../utils/redux/reducer/authentication-slice";
import * as Amplify from "../../../../../utils/services/amplify";
import { CognitoUser } from "amazon-cognito-identity-js";
import { identifiers } from "../../../../../utils/constants/identifiers";

export interface DocumentListProps {
  documents: VettingDocument[];
  active: number;
  setDocuments?: (documents: VettingDocument[]) => void;
  yotiReport?: { check_result: any; documents: any[]; status: string };
  academicData?: AcademicDocument[];
}

const DocumentList: FC<DocumentListProps> = ({
  documents,
  active,
  setDocuments,
}) => {
  const dispatch = useDispatch();
  const [displayTimePeriod, setDisplayTimePeriod] = useState<boolean>(false);
  const isEditable = useSelector(IsEditable);
  const user: { [key: string]: string } = useSelector(User) as {
    [key: string]: string;
  };

  const setAddressProofCoachMarksValue = useCallback(async () => {
    //if (!!user && user["custom:reference_tutorial"] !== "true") {
    const currentUser: CognitoUser = await Amplify.UserDetail();
    const body = {
      "custom:address_proof": "true",
    };
    await Amplify.UpdateUserDetails(body, currentUser);
    await Amplify.RefreshSession();
    dispatch(setUserData({ ...user, ...body }));
    //}
  }, [user, dispatch]);
  const setDbsCoachMarksValue = useCallback(async () => {
    //if (!!user && user["custom:reference_tutorial"] !== "true") {
    const currentUser: CognitoUser = await Amplify.UserDetail();
    const body = {
      "custom:dbs": "true",
    };
    await Amplify.UpdateUserDetails(body, currentUser);
    await Amplify.RefreshSession();
    dispatch(setUserData({ ...user, ...body }));
    //}
  }, [user, dispatch]);

  const { setIsOpen, currentStep, setCurrentStep } = useTour();

  useEffect(() => {
    if (documents[active].name === identifiers.poa) {
      if (user["custom:address_proof"] !== "true") {
        setCurrentStep(tourStep.address_proof.index);
      }
    } else if (documents[active].name === identifiers.dbs) {
      if (user["custom:dbs"] !== "true") {
        setCurrentStep(tourStep.dbs.index);
      }
    }
  }, [documents, active, user, setCurrentStep]);

  useEffect(() => {
    if (documents[active].name === identifiers.poa) {
      if (tourStep.address_proof.index === currentStep) {
        setIsOpen(true);
      } else if (
        currentStep ===
        tourStep.address_proof.index + tourStep.address_proof.steps
      ) {
        setIsOpen(false);
        if (!!user && user["custom:address_proof"] !== "true") {
          (async () => {
            await setAddressProofCoachMarksValue();
          })();
        }
      }
    } else if (documents[active].name === identifiers.dbs) {
      if (tourStep.dbs.index === currentStep) {
        setIsOpen(true);
      } else if (currentStep === tourStep.dbs.index + tourStep.dbs.steps) {
        setIsOpen(false);
        if (!!user && user["custom:dbs"] !== "true") {
          (async () => {
            await setDbsCoachMarksValue();
          })();
        }
      }
    }
  }, [
    currentStep,
    documents,
    active,
    setIsOpen,
    user,
    setAddressProofCoachMarksValue,
    setDbsCoachMarksValue,
  ]);

  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#f5fbff",
      color: "#727272",
      border: "none",
    },
    [`&.${tableCellClasses.body}`]: {
      color: "#263238",
      fontSize: "14px",
      fontWeight: 500,
      padding: "5px 16px",
    },
    [`&.${tableCellClasses.head}:first-of-type`]: {
      borderTopLeftRadius: "4px",
      borderBottomLeftRadius: "4px",
    },
    [`&.${tableCellClasses.head}:last-of-type`]: {
      borderTopRightRadius: "4px",
      borderBottomRightRadius: "4px",
      width: "14rem",
      textAlign: "start",
    },
    [`&.${tableCellClasses.body}:first-of-type`]: {
      borderTopLeftRadius: "12px",
      borderBottomLeftRadius: "12px",
      borderLeft: "1px solid #DADADA",
    },
    [`&.${tableCellClasses.body}:last-of-type`]: {
      borderTopRightRadius: "12px",
      borderBottomRightRadius: "12px",
      borderRight: "1px solid #DADADA",
    },
  }));

  const StyledTableRow = styled(TableRow)(() => ({
    "&": {
      borderRadius: "8px",
      borderTop: "1px solid #DADADA",
      backgroundColor: "white",
    },
  }));

  useEffect(() => {
    let hasOptions: boolean = false;
    documents[active].sub_documents.forEach((document: SubDocument) => {
      if (document.options) {
        hasOptions = true;
      }
    });
    setDisplayTimePeriod(hasOptions);
  }, [documents, active]);

  const handleChange = (subDocument: SubDocument, index: number) => {
    const document = JSON.parse(JSON.stringify(subDocument));
    if (documents[active].type === "single") {
      const subDocuments: SubDocument[] = JSON.parse(
        JSON.stringify(documents[active].sub_documents)
      );
      if (subDocuments) {
        subDocuments.forEach((document) => {
          document.checked = false;
        });
        subDocuments[index].checked = true;
      }
      const vettingDocuments: VettingDocument[] = JSON.parse(
        JSON.stringify(documents)
      );
      vettingDocuments[active].sub_documents = subDocuments;
      if (setDocuments) {
        setDocuments(vettingDocuments);
      } else {
        dispatch(setVettingDocuments(vettingDocuments));
      }
    } else {
      document.checked = !document.checked;
      updateDocuments(document, index);
    }
  };
  const handleChangeDbsWorforceType = (
    document: DBSWorkforceType,
    index: number
  ) => {
    const subDocuments: DBSWorkforceType[] = JSON.parse(
      JSON.stringify(documents[active].dbs_workforce_type)
    );
    if (subDocuments) {
      subDocuments.forEach((document) => {
        document.checked = false;
      });
      subDocuments[index].checked = true;
    }
    const vettingDocuments: VettingDocument[] = JSON.parse(
      JSON.stringify(documents)
    );
    vettingDocuments[active].dbs_workforce_type = subDocuments;

    dispatch(setVettingDocuments(vettingDocuments));
  };
  const handleChangeDbsQuestions = (
    document: DBSRBDetails,
    value: string,
    index: number
  ) => {
    const subDocuments: DBSRBDetails[] = JSON.parse(
      JSON.stringify(documents[active].dbs_rb_details)
    );
    if (subDocuments) {
      subDocuments[index].value = value;
    }
    const vettingDocuments: VettingDocument[] = JSON.parse(
      JSON.stringify(documents)
    );
    vettingDocuments[active].dbs_rb_details = subDocuments;

    dispatch(setVettingDocuments(vettingDocuments));
  };

  const setDropdownValue = (
    value: string,
    subDocument: SubDocument,
    index: number
  ) => {
    const selectedSubDocument: SubDocument = JSON.parse(
      JSON.stringify(subDocument)
    );
    if (selectedSubDocument.options) {
      selectedSubDocument.value = selectedSubDocument.options.findIndex(
        (document) => document.value === value
      );
    }
    updateDocuments(selectedSubDocument, index);
  };

  const updateDocuments = (subDocument: SubDocument, index: number) => {
    const sub_documents: SubDocument[] = JSON.parse(
      JSON.stringify(documents[active].sub_documents)
    );
    sub_documents[index] = subDocument;
    const vettingDocuments: VettingDocument[] = JSON.parse(
      JSON.stringify(documents)
    );
    vettingDocuments[active].sub_documents = sub_documents;
    if (setDocuments) {
      setDocuments(vettingDocuments);
    } else {
      dispatch(setVettingDocuments(vettingDocuments));
    }
  };

  const setRequired = (
    value: string,
    field: string,
    error?: { error: boolean; message: string }
  ) => {
    const vettingDocuments: VettingDocument[] = JSON.parse(
      JSON.stringify(documents)
    );
    vettingDocuments[active].documents_required = value;
    vettingDocuments[active].documents_required_error = error;
    if (setDocuments) {
      setDocuments(vettingDocuments);
    } else {
      dispatch(setVettingDocuments(vettingDocuments));
    }
  };

  const handleChangeText = (comment: string) => {
    const vettingDocuments: VettingDocument[] = JSON.parse(
      JSON.stringify(documents)
    );
    vettingDocuments[active].dbs_comment = comment;
    if (setDocuments) {
      setDocuments(vettingDocuments);
    } else {
      dispatch(setVettingDocuments(vettingDocuments));
    }
  };

  return (
    <Grid container className="card-vetting pb-4 pb-md-0">
      <Grid container className="card-head-table">
        <Grid item xs={12} md={6} sx={{ pl: { xs: 1, md: 3 }, py: 3 }}>
          <Typography
            variant={"body2"}
            fontSize={"16px"}
            fontWeight={"bold"}
            sx={{ pl: 2 }}
          >
            {documents[active].name}
          </Typography>
        </Grid>
        {documents[active].default_documents_required !== "N/A" && (
          <>
            <Grid
              item
              xs={12}
              md={4}
              sx={{
                py: { xs: 0, md: 3 },
                pl: { xs: 3, md: 0 },
                textAlign: { xs: "left", md: "right" },
              }}
            >
              <Typography
                variant={"body2"}
                fontSize={"16px"}
                fontWeight={"bold"}
              >
                Number of Documents required for this section
              </Typography>
            </Grid>
            <Grid
              data-tut="required_no_documents_input"
              item
              xs={12}
              md={2}
              sx={{ px: 2, py: 1 }}
            >
              <InputField
                inputValue={documents[active].documents_required}
                size={"small"}
                className="headerTimePeriod mt-2"
                textChange={setRequired}
                disabled={!documents[active].checked || !isEditable}
                validators={[
                  {
                    check: Validators.required,
                    message: "This field is mandatory",
                  },
                  !!documents[active].sub_documents && {
                    check: Validators.max,
                    message: `Required Documents cannot be greater that ${documents[active].sub_documents.length}`,
                    num: documents[active].sub_documents.length,
                  },
                  {
                    check: Validators.min,
                    message: "Required ReferenceForms should be at least 1",
                    num: 1,
                  },
                  {
                    check: Validators.number,
                    message: "Please enter a valid Number",
                  },
                ]}
                hasError={
                  documents[active].documents_required_error?.error || false
                }
                fieldError={
                  documents[active].documents_required_error?.message || ""
                }
              />
            </Grid>
          </>
        )}
      </Grid>
      <Grid
        item
        display={"flex"}
        flexDirection={{ xs: "column", md: "row" }}
        justifyContent={
          documents[active].name === identifiers.dbs ? "space-evenly" : "center"
        }
        alignItems={"flex-start"}
        xs={12}
      >
        <Grid
          item
          xs={11}
          md={documents[active].name === identifiers.dbs ? 7 : 10}
        >
          {documents[active].name === identifiers.dbs && (
            <Typography sx={{ mt: 2 }}>1 : Select Workforce type</Typography>
          )}
          {documents[active].name === identifiers.dbs && (
            <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
              <Table
                sx={{
                  minWidth: { xs: 400, md: 500 },
                  borderSpacing: "0 1rem",
                  borderCollapse: "separate",
                  backgroundColor: "#f5fbff",
                }}
              >
                <TableBody className={"documents-table-body"}>
                  {documents[active].dbs_workforce_type.map(
                    (document, index) => (
                      <>
                        <StyledTableRow
                          className={"documents-table-body-row"}
                          key={index}
                        >
                          <StyledTableCell sx={{ width: "30px" }}>
                            <CheckBox
                              checked={document.checked}
                              type="circle"
                              sx={{ ml: 1 }}
                              onChange={() =>
                                handleChangeDbsWorforceType(document, index)
                              }
                              disabled={!isEditable}
                            />
                          </StyledTableCell>
                          <StyledTableCell
                            sx={{ minWidth: "150px", textAlign: "start" }}
                          >
                            {document?.label}
                          </StyledTableCell>
                        </StyledTableRow>
                      </>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          {documents[active].name === identifiers.dbs && (
            <Typography sx={{ mt: 2 }}>2 : Select Disclosure Type </Typography>
          )}
          {documents[active].name === identifiers.dbs && (
            <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
              <Table
                sx={{
                  minWidth: { xs: 400, md: 500 },
                  borderSpacing: "0 1rem",
                  borderCollapse: "separate",
                  backgroundColor: "#f5fbff",
                }}
              >
                <TableBody className={"documents-table-body"}>
                  {documents[active].dbs_rb_details.map((document, index) => (
                    <>
                      <StyledTableRow
                        className={"documents-table-body-row"}
                        key={index}
                      >
                        <StyledTableCell
                          sx={{ minWidth: "150px", textAlign: "start" }}
                        >
                          {document?.label}
                        </StyledTableCell>
                        <StyledTableCell sx={{ width: "200px" }}>
                          <FormControl>
                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="row-radio-buttons-group"
                              value={document.value}
                              onChange={(
                                event: ChangeEvent<HTMLInputElement>
                              ) => {
                                handleChangeDbsQuestions(
                                  document,
                                  event.target.value,
                                  index
                                );
                              }}
                            >
                              <FormControlLabel
                                value="y"
                                control={<Radio />}
                                label="Yes"
                                disabled={!isEditable}
                              />

                              <FormControlLabel
                                value="n"
                                control={<Radio />}
                                label="No"
                                disabled={!isEditable}
                              />
                            </RadioGroup>
                          </FormControl>
                        </StyledTableCell>
                      </StyledTableRow>
                    </>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}

          {documents[active].name === identifiers.dbs && (
            <Typography sx={{ mt: 2 }}>3 : Select DBS type</Typography>
          )}
          <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
            <Table
              sx={{
                minWidth: { xs: 400, md: 500 },
                borderSpacing: "0 1rem",
                borderCollapse: "separate",
                backgroundColor: "#f5fbff",
              }}
            >
              {documents[active].name !== identifiers.dbs && (
                <TableHead>
                  <TableRow>
                    <StyledTableCell sx={{ width: "30px" }}></StyledTableCell>
                    <StyledTableCell
                      sx={{ minWidth: "150px", textAlign: "start" }}
                    >
                      Document Name
                    </StyledTableCell>
                    {displayTimePeriod && (
                      <StyledTableCell sx={{ pl: 3 }}>
                        Time Period
                      </StyledTableCell>
                    )}
                  </TableRow>
                </TableHead>
              )}
              <TableBody className={"documents-table-body"}>
                {documents[active].sub_documents.map((document, index) => (
                  <>
                    <StyledTableRow
                      className={"documents-table-body-row"}
                      key={index}
                    >
                      <StyledTableCell sx={{ width: "30px" }}>
                        <CheckBox
                          data-tut="address_proof_step_1"
                          checked={document.checked}
                          type="circle"
                          sx={{ ml: 1 }}
                          onChange={() => handleChange(document, index)}
                          disabled={!documents[active].checked || !isEditable}
                        />
                      </StyledTableCell>
                      <StyledTableCell
                        sx={{ minWidth: "150px", textAlign: "start" }}
                      >
                        {document?.name}
                      </StyledTableCell>
                      {displayTimePeriod && document.options && (
                        <StyledTableCell
                          data-tut={
                            documents[active].name === identifiers.poa
                              ? "address_proof_step_2"
                              : ""
                          }
                        >
                          <FormControl sx={{ m: 1 }}>
                            <Select
                              className="timePeriod"
                              sx={{
                                boxShadow: "none",
                                ".MuiOutlinedInput-notchedOutline": {
                                  border: 0,
                                },
                              }}
                              value={document.options[document.value].value}
                              inputProps={{ "aria-label": "Without label" }}
                              disabled={
                                !documents[active].checked ||
                                !document.checked ||
                                !isEditable
                              }
                              onChange={(event: SelectChangeEvent) =>
                                setDropdownValue(
                                  event.target.value,
                                  document,
                                  index
                                )
                              }
                            >
                              {document.options.map((option, index) => (
                                <MenuItem
                                  key={index}
                                  value={option.value}
                                  sx={{
                                    color: "#727272",
                                    fontWeight: "600",
                                    display: "flex",
                                  }}
                                >
                                  {option.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </StyledTableCell>
                      )}
                    </StyledTableRow>
                  </>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        {documents[active].name === identifiers.dbs && (
          <Grid xs={12} md={4} data-tut="dbs_check_custom_msg">
            <Typography
              variant={"body2"}
              sx={{ pl: 1, pt: "30px" }}
              style={{ fontWeight: 500 }}
            >
              Custom Message for Applicant(Optional)
            </Typography>
            <InputField
              value={documents[active].dbs_comment}
              textChange={handleChangeText}
              multiline
              rows={5}
              sx={{
                background: "#ffffff",
                marginTop: "10px",
                border: "0px !important",
                borderRadius: "16px !important",
              }}
              placeholder={"For e.g. provide xxx which is 3 month old..."}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default DocumentList;
