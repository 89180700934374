import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Alert, Box, CircularProgress, Grid, Typography } from "@mui/material";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import PersonalInformation from "./PersonalInformation";
import PersonalHistory from "./PersonalHistory";
import AddressInformation from "./AddressInformation";
import Review from "./Review";
import PrimaryButton from "../../../../../../../../components/Button/PrimaryButton";
import IdInformationPage from "./Idinformation";
import ConfirmPage from "./Confim";
import { URLS } from "../../../../../../../../utils/constants/urls";
import { useParams } from "react-router-dom";
import axiosInstance from "../../../../../../../../utils/axios";
import { useDispatch, useSelector } from "react-redux";
import {
  Reload,
  setNotificationMessage,
  setReload,
  setUCheckFormID,
  UCheckCurrentAddress,
  UCheckDrivingLicence,
  UCheckFormID,
  UCheckPassportInformation,
  UCheckPersonalHistory as uCheckPersonalHistory,
  UCheckPersonalInformation as uCheckPersonalInformation,
  UCheckPreviousAddresses,
} from "../../../../../../../../utils/redux";
import {
  PersonalHistory as UCheckPersonalHistory,
  PersonalInformation as UCheckPersonalInformation,
  uCheckStatus,
  UserInformationData,
} from "../../../../../../../../utils/redux/reducer/u-check-slice";
import { generatePayload } from "./UCheckModel";
import { images } from "../../../../../../../../utils/constants/images";
import Modal from "../../../../../../../../components/modal/Modal";
import { useTour } from "@reactour/tour";
import { tourStep } from "../../../../../../../../utils/constants/coachMarks";
import {
  setUserData,
  User,
} from "../../../../../../../../utils/redux/reducer/authentication-slice";
import { CognitoUser } from "amazon-cognito-identity-js";
import * as Amplify from "../../../../../../../../utils/services/amplify";

const steps = [
  "Personal Information",
  "Personal History",
  "Address",
  "ID",
  "Review",
  "Confirm",
];

const UCheckForm: FC<{
  type: string;
  setViewPopUpModal?: Dispatch<SetStateAction<boolean>>;
  viewPopUpModal?: boolean;
}> = ({ type, setViewPopUpModal, viewPopUpModal }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { setIsOpen, currentStep, setCurrentStep } = useTour();

  const reload = useSelector(Reload);
  const status = useSelector(uCheckStatus);
  const uCheckFormID: string | undefined = useSelector(UCheckFormID);
  const personalInformation: UCheckPersonalInformation = useSelector(
    uCheckPersonalInformation
  );
  const personalHistory: UCheckPersonalHistory = useSelector(
    uCheckPersonalHistory
  );
  const currentAddress = useSelector(UCheckCurrentAddress);
  const reportedComment = useSelector(UserInformationData);
  const previousAddresses = useSelector(UCheckPreviousAddresses);
  const passportDetails = useSelector(UCheckPassportInformation);
  const drivingLicenceDetails = useSelector(UCheckDrivingLicence);
  const user: { [key: string]: string } = useSelector(User) as {
    [key: string]: string;
  };

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [reportedModal, setReportedModal] = useState(false);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [validationAlertPopUp, setValidationAlertPopUp] = useState(false);
  const [errorMessages, setErrorMessages] = useState<
    {
      code: string;
      message: string;
    }[]
  >([]);

  const setCoachMarksValue = useCallback(async () => {
    //if (!!user && user["custom:reference_tutorial"] !== "true") {
    const currentUser: CognitoUser = await Amplify.UserDetail();
    const body = {
      "custom:ucheck_tutorial": "true",
    };
    await Amplify.UpdateUserDetails(body, currentUser);
    await Amplify.RefreshSession();
    dispatch(setUserData({ ...user, ...body }));
    //}
  }, [user, dispatch]);

  useEffect(() => {
    if (!!user && user["custom:ucheck_tutorial"] !== "true") {
      if (viewPopUpModal) {
        setCurrentStep(tourStep.candidate_ucheck_pop_up.index);
      }
    }
  }, [viewPopUpModal, user, setCurrentStep]);

  useEffect(() => {
    if (tourStep.candidate_ucheck_pop_up.index === currentStep) {
      setIsOpen(true);
    } else if (
      currentStep ===
      tourStep.candidate_ucheck_pop_up.index +
        tourStep.candidate_ucheck_pop_up.steps
    ) {
      setIsOpen(false);
      if (!!user && user["custom:ucheck_tutorial"] !== "true") {
        (async () => {
          await setCoachMarksValue();
        })();
      }
    }
  }, [currentStep, setCoachMarksValue, setIsOpen, user]);

  const handleNext = () => {
    setActiveStep((prevState) => prevState + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const submitUCheck = useCallback(async () => {
    setIsLoading(true);
    try {
      const payload = {
        request_id: id,
        candidate_id: user?.sub,
        status: "IN_REVIEW",
      };
      await axiosInstance.put(
        `${URLS.authenticated}ucheck/application/status?stage=${process.env.REACT_APP_UCHECK_ENV}`,
        payload
      );
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "success",
          message: "DBS Application form submitted Successfully",
        })
      );
      dispatch(setReload(!reload));
      setIsLoading(false);
      if (setViewPopUpModal) {
        setViewPopUpModal(false);
      }
    } catch (e) {
      console.log(e);
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "error",
          message: "There is an error in submitting DBS Application Form",
        })
      );
      setIsLoading(false);
    }
  }, [id, user, dispatch, reload, setViewPopUpModal]);

  const saveToDraft = useCallback(
    async (submit: boolean) => {
      setIsLoading(true);
      try {
        const payload = generatePayload(
          personalInformation,
          personalHistory,
          currentAddress,
          previousAddresses,
          passportDetails,
          drivingLicenceDetails,
          "candidate",
          user
        );
        const method = !!uCheckFormID ? "put" : "post";
        const { data } = await axiosInstance[method](
          `${
            URLS.requests
          }/${id}/candidate/ucheck/draft?allow_partial_filling=${!submit}&stage=${
            process.env.REACT_APP_UCHECK_ENV
          }`,
          payload
        );

        if (data?.errors) {
          console.log(data?.errors);
          setValidationAlertPopUp(true);
          setErrorMessages(data?.errors);
          dispatch(
            setNotificationMessage({
              display: true,
              severity: "error",
              message: "There is an error in saving DBS Application Form",
            })
          );
        } else {
          dispatch(
            setNotificationMessage({
              display: true,
              severity: "success",
              message: "DBS Application form saved as Draft Successfully",
            })
          );
          if (submit) {
            await submitUCheck();
          } else {
            dispatch(setUCheckFormID(data.id));
          }
        }
        setIsLoading(false);
      } catch (e: any) {
        console.log(e);
        if (e?.errors) {
          console.log(e?.errors);
          setValidationAlertPopUp(true);
          setErrorMessages(e?.errors);
          dispatch(
            setNotificationMessage({
              display: true,
              severity: "error",
              message: "There is an error in saving DBS Application Form",
            })
          );
        } else {
          dispatch(
            setNotificationMessage({
              display: true,
              severity: "error",
              message: "There is an error in saving DBS Application Form",
            })
          );
        }

        setIsLoading(false);
      }
    },
    [
      personalInformation,
      personalHistory,
      currentAddress,
      previousAddresses,
      passportDetails,
      drivingLicenceDetails,
      id,
      uCheckFormID,
      dispatch,
      submitUCheck,
      user,
    ]
  );

  const viewModalText = useMemo(() => {
    return (
      <>
        <Grid container>
          <Grid
            item
            xs={12}
            sx={{
              background: "#E56D6D",
              padding: "20px ",
              justifyContent: "space-between",
              flexDirection: "row",
            }}
            display={"flex"}
            alignItems={"center"}
          >
            <Box
              sx={{ display: "flex", direction: "row", alignItems: "center" }}
            >
              <img src={images.tooltip} alt={"tooltip"} />
              <Typography
                variant="h6"
                sx={{
                  ml: 2,
                  fontSize: 14,
                  fontWeight: 500,
                  color: "#ffffff",
                  textAlign: "center",
                  letterSpacing: "0.15px",
                }}
              >
                Reported feedback
              </Typography>
            </Box>
            <img
              onClick={() => {
                setReportedModal(false);
              }}
              style={{ width: "20px", cursor: "pointer" }}
              src={images.closeBtn}
              alt={"close button"}
            />
          </Grid>
          <Grid item padding={"20px"}>
            <Typography
              variant="h6"
              sx={{
                mb: 3,
                fontSize: 16,
                fontWeight: 500,
                color: "#727272",
              }}
            >
              {reportedComment.comment}
            </Typography>
          </Grid>
        </Grid>
      </>
    );
  }, [reportedComment]);

  const openReportedModal = useMemo(() => {
    return (
      <Modal
        open={reportedModal}
        setModalClose={setReportedModal}
        children={viewModalText}
        title={undefined}
        size="sm"
        padding="0px"
        Radius={"15px"}
        isBackdrop={true}
        className={undefined}
      />
    );
  }, [reportedModal, viewModalText]);

  const removeMessage = (i: string) => {
    let a = errorMessages.filter((a: any) => a.message !== i);
    setErrorMessages(a);
  };

  return (
    <>
      {openReportedModal}
      <Grid container padding={2}>
        {validationAlertPopUp && (
          <Grid item xs={12} mb={2}>
            {errorMessages.map((value) => (
              <Alert
                sx={{ mb: 2 }}
                icon={false}
                onClose={() => {
                  removeMessage(value.message);
                }}
                severity={"error"}
              >
                {value.message}
              </Alert>
            ))}
          </Grid>
        )}
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "inline-block",
              width: { xs: "100%", md: "calc(100% - 180px)" },
              overflowX: { xs: "auto", md: "unset" },
              overflowY: { xs: "hidden", md: "unset" },
            }}
          >
            <Stepper
              sx={{ width: { xs: "900px", md: "100%" }, padding: "5px" }}
              alternativeLabel
              activeStep={activeStep}
            >
              {steps.map((label, index) => (
                <Step
                  data-tut={
                    index === 0
                      ? "candidate_ucheck_personal_information"
                      : index === 2
                      ? "candidate_ucheck_address_information"
                      : ""
                  }
                  key={label}
                  completed={activeStep > index}
                >
                  <StepButton color="inherit">{label}</StepButton>
                </Step>
              ))}
            </Stepper>
          </Box>
          <Box
            sx={{
              mt: { xs: 1, md: 0 },
              display: "inline-block",
              width: { xs: "100%", md: "180px" },
            }}
          >
            {status !== "REJECTED" && (
              <PrimaryButton
                disabled={isLoading}
                fullWidth={true}
                sx={{
                  height: "40px",
                  background: "#1276D3",
                  color: "white",
                }}
                data-tut="candidate_ucheck_save_draft"
                onClick={() => saveToDraft(false)}
              >
                {isLoading && <CircularProgress sx={{ mr: 1 }} size={20} />}
                Save draft
              </PrimaryButton>
            )}
            {status === "REJECTED" && (
              <PrimaryButton
                disabled={isLoading}
                fullWidth={true}
                sx={{
                  height: "40px",
                  background: "#E56D6D",
                  color: "white",
                  padding: "5px 8px 5px 0px",
                }}
                onClick={() => setReportedModal(true)}
              >
                <img src={images.tooltip} alt={"tooltip"} />
                View Feedback
              </PrimaryButton>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} mt={5}>
          {activeStep === 0 && (
            <PersonalInformation
              activeStep={activeStep}
              handleBack={handleBack}
              handleNext={handleNext}
              steps={steps}
              dbs_type={type}
            />
          )}
          {activeStep === 1 && (
            <PersonalHistory
              activeStep={activeStep}
              handleBack={handleBack}
              handleNext={handleNext}
              steps={steps}
            />
          )}
          {activeStep === 2 && (
            <AddressInformation
              activeStep={activeStep}
              handleBack={handleBack}
              handleNext={handleNext}
              steps={steps}
            />
          )}
          {activeStep === 3 && (
            <IdInformationPage
              activeStep={activeStep}
              handleBack={handleBack}
              handleNext={handleNext}
              steps={steps}
            />
          )}
          {activeStep === 4 && (
            <Review
              role="candidate"
              dbs_type={type}
              activeStep={activeStep}
              handleBack={handleBack}
              handleNext={handleNext}
              steps={steps}
            />
          )}
          {activeStep === 5 && (
            <ConfirmPage
              activeStep={activeStep}
              handleBack={handleBack}
              handleNext={handleNext}
              steps={steps}
              saveToDraft={saveToDraft}
              isLoading={isLoading}
              dbs_type={type}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default UCheckForm;
