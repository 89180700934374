import React, { FC, RefObject, useRef } from "react";
import { Grid } from "@mui/material";
import Form, {
  FormDataModel,
} from "../../../../../../../../../components/Form";
import { PassportModel } from "./PassportModel";
import { DrivingLicenceModel } from "./DrivingLicenceModel";
import PrimaryButton from "../../../../../../../../../components/Button/PrimaryButton";
import { UCheckProps } from "../UCheckModel";
import { removeErrorFieldsFromValues } from "../../../../../../../../../utils/validators";
import { useDispatch, useSelector } from "react-redux";
import {
  UCheckDrivingLicence,
  UCheckPassportInformation,
  setLicenceInformation,
  setPassPortInformation,
} from "../../../../../../../../../utils/redux";

const IdInformationPage: FC<UCheckProps> = ({
  activeStep,
  handleBack,
  handleNext,
}) => {
  let PassportIdForm: RefObject<Form | null | undefined> = useRef();
  let DrivingLicenceIdForm: RefObject<Form | null | undefined> = useRef();
  const dispatch = useDispatch();
  const form1Value = useSelector(UCheckPassportInformation);
  const form2Value = useSelector(UCheckDrivingLicence);

  // const [hasPassport, setHasPassport] = useState("n");
  // const [hasUkLicence, setHasUkLicence] = useState("n");

  // useEffect(() => {
  //   setHasUkLicence(form2Value.uk_driving_licence);
  //   setHasPassport(form1Value.has_passport);
  // }, [form1Value, form2Value]);

  const passportOnChangeHandler = (
    field: string,
    value: string,
    formData: FormDataModel,
    isFormValid: boolean
  ) => {
    let formBody: FormDataModel = removeErrorFieldsFromValues(formData);
    if (field === "has_passport") {
      if (value === "y") {
        formBody = {
          ...formBody,
          passport_number: "",
          passport_dob: "",
          passport_issue_date: "",
          passport_nationality: "",
        };
      }
      console.log(isFormValid, formData);
      dispatch(
        setPassPortInformation({
          ...form1Value,
          ...formBody,
          form_valid: value !== "y",
        })
      );
    } else {
      console.log(isFormValid, formData);
      dispatch(
        setPassPortInformation({ ...formBody, form_valid: isFormValid })
      );
    }
    console.log(form1Value);
  };

  const drivingLicenceOnChangeHandler = (
    field: string,
    value: string,
    formData: FormDataModel,
    isFormValid: boolean
  ) => {
    let formBody: FormDataModel = removeErrorFieldsFromValues(formData);
    if (field === "uk_driving_licence") {
      if (value === "y") {
        formBody = {
          ...formBody,
          driver_licence_number: "",
          driver_licence_type: "",
          driver_licence_dob: "",
          driver_licence_valid_from: "",
          driver_licence_issue_country: "",
        };
      }
      console.log(isFormValid, formData);
      dispatch(
        setLicenceInformation({
          ...form2Value,
          ...formBody,
          form_valid: value !== "y",
        })
      );
    } else {
      console.log(isFormValid, formData);
      dispatch(setLicenceInformation({ ...formBody, form_valid: isFormValid }));
    }
    console.log(form2Value);
  };
  console.log(
    form1Value.has_passport === "n" && form2Value.uk_driving_licence === "n"
  );
  return (
    <>
      <Grid container spacing={2} justifyContent={"space-evenly"}>
        <Grid item xs={12} md={6}>
          <Grid
            container
            spacing={2}
            // sx={{
            //   display: "flex",
            //   flexDirection: "row",
            //   width: { xs: "100%", md: "49%" },
            //   flexWrap: "wrap",
            //   mr: 1,
            //   mb: { xs: 2, md: 0 },
            // }}
          >
            <Form
              values={form1Value || {}}
              ref={PassportIdForm as RefObject<Form>}
              model={PassportModel(form1Value)}
              onChange={(field, value, formData, isFormValid) => {
                passportOnChangeHandler(field, value, formData, isFormValid);
              }}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
        <Grid
          container
          spacing={2}
            // sx={{
            //   display: "flex",
            //   flexDirection: "row",
            //   flexWrap: "wrap",
            // }}
          >
            <Form
              values={form2Value || {}}
              ref={DrivingLicenceIdForm as RefObject<Form>}
              model={DrivingLicenceModel(form2Value)}
              onChange={(field, value, formData, isFormValid) =>
                drivingLicenceOnChangeHandler(
                  field,
                  value,
                  formData,
                  isFormValid
                )
              }
            />
          </Grid>
        </Grid>

        <Grid
          item
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            pt: 2,
            mt: 2,
            borderTop: "1px solid #0000004d",
          }}
          xs={12}
        >
          <PrimaryButton
            variant={"text"}
            fullWidth={false}
            sx={{
              mr: 2,
              background: "white",
              border: "1px solid #5AB9F9",
              color: "#5AB9F9",
              fontSize: 16,
              height: "46px",
              minWidth: "100px",
            }}
            disabled={activeStep === 0}
            onClick={handleBack}
          >
            Go Back
          </PrimaryButton>
          <PrimaryButton
            fullWidth={false}
            sx={{
              minWidth: "100px",
              background: "#1276D3",
              color: "white",
            }}
            disabled={
              !(form1Value.form_valid && form2Value.form_valid) ||
              (form1Value.has_passport === "n" &&
                form2Value.uk_driving_licence === "n")
            }
            onClick={handleNext}
          >
            Next
          </PrimaryButton>
        </Grid>
      </Grid>
    </>
  );
};

export default IdInformationPage;
