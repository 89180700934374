import React, { FC, useState, useRef, RefObject, useEffect } from "react";
import {
  Grid,
  Typography,
  Box,
  AccordionDetails,
  AccordionSummary,
  Accordion,
} from "@mui/material";
import PrimaryButton from "../../../../../../../../../components/Button/PrimaryButton";
import CheckBox from "../../../../../../../../../components/CheckBox";
import { UCheckProps } from "../UCheckModel";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  UCheckPersonalHistory,
  UCheckPersonalInformation,
  setPersonalHistory,
  setPersonalInformation,
} from "../../../../../../../../../utils/redux";
import { useDispatch, useSelector } from "react-redux";
import { identifiers } from "../../../../../../../../../utils/constants/identifiers";
import { PersonalInformationFormModel } from "../PersonalInformation/PersonalInformationFormModel";
import Form, {
  FormDataModel,
} from "../../../../../../../../../components/Form";
import { removeErrorFieldsFromValues } from "../../../../../../../../../utils/validators";
import { PersonalHistoryForm2Model } from "../PersonalHistory/PersonalHistoryForm2Model";
import { PersonalHistoryForm1Model } from "../PersonalHistory/PersonalHistoryForm1Model";
import {
  AddressHistory,
  OtherNames,
  UCheckCurrentAddress,
  UCheckDrivingLicence,
  UCheckPassportInformation,
  UCheckPreviousAddresses,
  setCurrentAddress,
  setLicenceInformation,
  setPassPortInformation,
  setPreviousAddress,
} from "../../../../../../../../../utils/redux/reducer/u-check-slice";
import { CurrentAddressModel } from "../AddressInformation/CurrentAddressModel";
import { PreviousAddressModel } from "../AddressInformation/PreviousAddressModel";
import dayjs from "dayjs";
import { PassportModel } from "../Idinformation/PassportModel";
import { DrivingLicenceModel } from "../Idinformation/DrivingLicenceModel";

const Review: FC<UCheckProps & { dbs_type: string }> = ({
  role,
  dbs_type,
  activeStep,
  handleBack,
  handleNext,
  disabled,
}) => {
  const dispatch = useDispatch();
  const today = dayjs(new Date().setDate(1));
  let PersonalHistoryForm: RefObject<Form | null | undefined> = useRef();
  let PersonalHistoryOtherNameForm: RefObject<Form | null | undefined> =
    useRef();
  let PersonalInfoForm: RefObject<Form | null | undefined> = useRef();
  let CurrentAddressForm: RefObject<Form | null | undefined> = useRef();
  let PreviousAddressForm: RefObject<Form | null | undefined> = useRef();
  let PassportIdForm: RefObject<Form | null | undefined> = useRef();
  let DrivingLicenceIdForm: RefObject<Form | null | undefined> = useRef();

  const DbsPersonalInformationValues = useSelector(UCheckPersonalInformation);
  const uCheckPersonalHistory = useSelector(UCheckPersonalHistory);
  const uCheckCurrentAddress = useSelector(UCheckCurrentAddress);
  const uCheckPreviousAddresses = useSelector(UCheckPreviousAddresses);
  const uCheckPassportInformation = useSelector(UCheckPassportInformation);
  const uCheckDrivingLicenceInformation = useSelector(UCheckDrivingLicence);

  const [condition1, setCondition1] = useState(false);
  const [condition2, setCondition2] = useState(false);
  const [form2Value, setForm2Value] = useState<FormDataModel>({});
  const [count, setCount] = useState<number>(0);

  useEffect(() => {
    const formValue: any = {};
    uCheckPersonalHistory.other_names.forEach((other_name, i) => {
      Object.keys(other_name).forEach((key) => {
        formValue[key + "_" + (i + 1)] = other_name[key as keyof OtherNames];
      });
    });
    setForm2Value({
      has_other_name: uCheckPersonalHistory.has_other_name,
      ...formValue,
    });
    setCount(uCheckPersonalHistory.other_names.length);
  }, [uCheckPersonalHistory.other_names, uCheckPersonalHistory.has_other_name]);

  const [addresscount, setAddressCount] = useState<number>(0);
  const [previousAddressesValue, setPreviousAddressesValue] =
    useState<FormDataModel>({});

  useEffect(() => {
    const formValue: any = {};
    uCheckPreviousAddresses.address.forEach((address, i) => {
      Object.keys(address).forEach((key) => {
        formValue[key + "_" + (i + 1)] = address[key as keyof AddressHistory];
      });
    });
    setPreviousAddressesValue(formValue);
    setAddressCount(uCheckPreviousAddresses.address.length);
  }, [uCheckPreviousAddresses.address]);

  const personalInfoamtionOnChangeHandler = (
    field: string,
    value: string,
    formData: FormDataModel,
    isFormValid: boolean
  ) => {
    let formBody: FormDataModel = removeErrorFieldsFromValues(formData);
    if (field === "has_nin") {
      if (value === "y") {
        formBody = {
          ...formBody,
          nin: "",
        };
      }
      dispatch(
        setPersonalInformation({
          ...DbsPersonalInformationValues,
          ...formBody,
          form_valid: value !== "y",
        })
      );
    } else {
      dispatch(
        setPersonalInformation({ ...formBody, form_valid: isFormValid })
      );
    }
    console.log(DbsPersonalInformationValues, isFormValid, formData);
  };

  const onForm1ChangeHandler = (
    field: string,
    value: string,
    formData: FormDataModel,
    isFormValid: boolean
  ) => {
    let formBody: FormDataModel = removeErrorFieldsFromValues(formData);
    if (field === "surname_changed") {
      formBody = {
        ...formBody,
        birth_surname: "",
        birth_forename: "",
        birth_surname_until: "",
      };
    }
    dispatch(
      setPersonalHistory({
        ...uCheckPersonalHistory,
        ...formBody,
        form1_valid:
          field === "surname_changed" && value === "y" ? false : isFormValid,
      })
    );
  };
  const onForm2ChangeHandler = (
    field: string,
    value: string,
    formData: FormDataModel,
    isFormValid: boolean
  ) => {
    if (field === "has_other_name") {
      let other_names = [];
      if (value === "y") {
        other_names.push({
          other_name_type: "",
          forename: "",
          surname: "",
          used_from: "",
          used_to: "",
        });
      } else {
        other_names = [];
      }
      const formBody: FormDataModel = removeErrorFieldsFromValues(formData);
      dispatch(
        setPersonalHistory({
          ...uCheckPersonalHistory,
          ...formBody,
          other_names,
          form2_valid: value !== "y",
        })
      );
    } else {
      const formBody: FormDataModel = removeErrorFieldsFromValues(formData);
      const other_names: any[] = Array.from({ length: count }, () => ({}));
      let valid = true;
      Object.keys(formBody).forEach((key) => {
        const name = key.split("_");
        if (key !== "has_other_name") {
          const index = parseInt(key.split("_")[key.split("_").length - 1]) - 1;
          name.splice(name.length - 1, 1);
          other_names[index][name.join("_")] = formBody[key];
        }
      });
      other_names.forEach((other_name) => {
        if (Object.keys(other_name).length < 2) {
          valid = false;
        }
      });
      dispatch(
        setPersonalHistory({
          ...uCheckPersonalHistory,
          other_names,
          form2_valid: isFormValid && valid,
        })
      );
    }
  };

  const handleCurrentAddress = (
    field: string,
    value: string,
    formData: FormDataModel,
    isFormValid: boolean
  ) => {
    if (field === "resident_from_gyear_month") {
      if (today.diff(value, "M") < 60) {
        const previous_address: AddressHistory = {
          address_line1: "",
          address_line2: "",
          address_town: "",
          address_county: "",
          postcode: "",
          country_code: "",
          resident_from_gyear_month: "",
          resident_to_gyear_month: value,
        };
        dispatch(
          setPreviousAddress({ address: [previous_address], form_valid: false })
        );
      } else {
        setCount(0);
        dispatch(setPreviousAddress({ address: [], form_valid: true }));
      }
    }
    const address: FormDataModel = removeErrorFieldsFromValues(formData);
    dispatch(setCurrentAddress({ address, form_valid: isFormValid }));
  };

  const handlePreviousAddress = (
    field: string,
    value: string,
    formData: FormDataModel,
    isFormValid: boolean
  ) => {
    let new_count: number = 0;
    if (field.indexOf("resident_from_gyear_month") > -1) {
      const index = parseInt(field.split("resident_from_gyear_month_")[1]);
      const previous_addresses = JSON.parse(
        JSON.stringify(uCheckPreviousAddresses.address)
      );
      previous_addresses[index - 1].resident_from_gyear_month = value;
      if (index < addresscount) {
        previous_addresses.splice(index, addresscount - index);
        new_count = index;
      }
      if (today.diff(value, "M") < 60) {
        previous_addresses.push({
          address_line1: "",
          address_line2: "",
          address_town: "",
          address_county: "",
          postcode: "",
          country_code: "",
          resident_from_gyear_month: "",
          resident_to_gyear_month: value,
        });
      }
      dispatch(
        setPreviousAddress({
          address: previous_addresses,
          form_valid: false,
        })
      );
      setAddressCount(new_count);
      return false;
    }
    const formBody: FormDataModel = removeErrorFieldsFromValues(formData);
    const previous_addresses: any[] = Array.from(
      { length: new_count || addresscount },
      () => ({})
    );
    Object.keys(formBody).forEach((key) => {
      const name = key.split("_");
      const index = parseInt(key.split("_")[key.split("_").length - 1]) - 1;
      name.splice(name.length - 1, 1);
      previous_addresses[index][name.join("_") as keyof AddressHistory] =
        formBody[key] as string;
    });
    console.log(previous_addresses);
    console.log(isFormValid);
    dispatch(
      setPreviousAddress({
        address: previous_addresses,
        form_valid: isFormValid,
      })
    );
  };
  const passportOnChangeHandler = (
    field: string,
    value: string,
    formData: FormDataModel,
    isFormValid: boolean
  ) => {
    let formBody: FormDataModel = removeErrorFieldsFromValues(formData);
    if (field === "has_passport") {
      if (value === "y") {
        formBody = {
          ...formBody,
          passport_number: "",
          passport_dob: "",
          passport_issue_date: "",
          passport_nationality: "",
        };
      }
      console.log(isFormValid, formData);
      dispatch(
        setPassPortInformation({
          ...uCheckPassportInformation,
          ...formBody,
          form_valid: value !== "y",
        })
      );
    } else {
      console.log(isFormValid, formData);
      dispatch(
        setPassPortInformation({ ...formBody, form_valid: isFormValid })
      );
    }
  };

  const drivingLicenceOnChangeHandler = (
    field: string,
    value: string,
    formData: FormDataModel,
    isFormValid: boolean
  ) => {
    let formBody: FormDataModel = removeErrorFieldsFromValues(formData);
    if (field === "uk_driving_licence") {
      if (value === "y") {
        formBody = {
          ...formBody,
          driver_licence_number: "",
          driver_licence_type: "",
          driver_licence_dob: "",
          driver_licence_valid_from: "",
          driver_licence_issue_country: "",
        };
      }
      console.log(isFormValid, formData);
      dispatch(
        setLicenceInformation({
          ...uCheckDrivingLicenceInformation,
          ...formBody,
          form_valid: value !== "y",
        })
      );
    } else {
      console.log(isFormValid, formData);
      dispatch(setLicenceInformation({ ...formBody, form_valid: isFormValid }));
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box mb={2}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{ background: "#5AB9F9", width: "100%" }}
              >
                <Typography sx={{ color: "white" }}>
                  Personal Information
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ display: "flex", flexWrap: "wrap" }}>
                <Grid container spacing={2}>
                  <Form
                    values={DbsPersonalInformationValues}
                    //hasError={hasError}
                    fieldError={identifiers.field_error as string}
                    ref={PersonalInfoForm as RefObject<Form>}
                    model={PersonalInformationFormModel(
                      DbsPersonalInformationValues,
                      dbs_type,
                      disabled
                    )}
                    onChange={personalInfoamtionOnChangeHandler}
                  />
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Box>
          <Box mb={2}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{ background: "#5AB9F9", width: "100%" }}
              >
                <Typography sx={{ color: "white" }}>
                  Personal History
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
              >
                <Grid container>
                  <Grid
                    container
                    spacing={2}
                    xs={12}
                    md={6}
                    sx={{ mr: { xs: 0, md: 2 }, mb: { xs: 2, md: 0 } }}
                  >
                    <Form
                      values={uCheckPersonalHistory}
                      ref={PersonalHistoryForm as RefObject<Form>}
                      model={PersonalHistoryForm1Model(
                        DbsPersonalInformationValues,
                        uCheckPersonalHistory,
                        disabled
                      )}
                      onChange={onForm1ChangeHandler}
                    />
                  </Grid>
                  <Grid container spacing={2} xs={12} md={6}>
                    <Form
                      values={form2Value}
                      ref={PersonalHistoryOtherNameForm as RefObject<Form>}
                      model={PersonalHistoryForm2Model(
                        DbsPersonalInformationValues,
                        uCheckPersonalHistory,
                        count,
                        disabled
                      )}
                      onChange={onForm2ChangeHandler}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Box>
          <Box mb={2}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{ background: "#5AB9F9", width: "100%" }}
              >
                <Typography sx={{ color: "white" }}>
                  Address Information
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography
                      variant="h6"
                      sx={{
                        fontSize: 18,
                        fontWeight: 500,
                        color: "#000000",
                      }}
                    >
                      Current Address
                    </Typography>
                  </Grid>
                  <Form
                    values={uCheckCurrentAddress.address}
                    ref={CurrentAddressForm as RefObject<Form>}
                    model={CurrentAddressModel(
                      DbsPersonalInformationValues,
                      uCheckCurrentAddress.address,
                      disabled
                    )}
                    onChange={handleCurrentAddress}
                  />
                  {addresscount > 0 && (
                    <>
                      <Grid item xs={12}>
                        <Typography
                          variant="h6"
                          sx={{
                            fontSize: 18,
                            fontWeight: 500,
                            color: "#000000",
                          }}
                        >
                          Previous Addresses
                        </Typography>
                      </Grid>
                      <Form
                        values={previousAddressesValue}
                        ref={PreviousAddressForm as RefObject<Form>}
                        model={PreviousAddressModel(
                          DbsPersonalInformationValues,
                          uCheckCurrentAddress.address,
                          uCheckPreviousAddresses.address,
                          addresscount,
                          disabled
                        )}
                        onChange={handlePreviousAddress}
                      />
                    </>
                  )}
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Box>
          <Box mb={2}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{ background: "#5AB9F9", width: "100%" }}
              >
                <Typography sx={{ color: "white" }}>ID Information </Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
              >
                <Grid container>
                  <Grid
                    container
                    spacing={2}
                    xs={12}
                    lg={6}
                    sx={{ mr: { xs: 0, md: 2 }, mb: { xs: 2, md: 2, lg: 0 } }}
                  >
                    <Form
                      values={uCheckPassportInformation || {}}
                      ref={PassportIdForm as RefObject<Form>}
                      model={PassportModel(uCheckPassportInformation, disabled)}
                      onChange={(field, value, formData, isFormValid) => {
                        passportOnChangeHandler(
                          field,
                          value,
                          formData,
                          isFormValid
                        );
                      }}
                    />
                  </Grid>
                  <Grid container spacing={2} xs={12} lg={6}>
                    <Form
                      values={uCheckDrivingLicenceInformation || {}}
                      ref={DrivingLicenceIdForm as RefObject<Form>}
                      model={DrivingLicenceModel(
                        uCheckDrivingLicenceInformation,
                        disabled
                      )}
                      onChange={(field, value, formData, isFormValid) =>
                        drivingLicenceOnChangeHandler(
                          field,
                          value,
                          formData,
                          isFormValid
                        )
                      }
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Box>
        </Grid>
        {role === "candidate" && (
          <Grid
            item
            sx={{
              p: 2,
              mt: 2,
              background: "#D9D9D933",
            }}
            xs={12}
          >
            <Typography sx={{ fontSize: "18px", fontWeight: 500 }}>
              Please Read:
            </Typography>
            <Typography sx={{ fontSize: "20px", fontWeight: 500 }}>
              REVIEW ALL INFORMATION
            </Typography>
            <Typography sx={{ fontSize: "16px", color: "gray" }}>
              Please ensure that all the information you have provided is
              accurate and error free. In the next step you will be required to
              confirm the accuracy of the information by providing an electronic
              signature.
            </Typography>
          </Grid>
        )}
        {role === "candidate" && (
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              mb: 2,
            }}
          >
            <CheckBox
              style={{ zoom: 0.8, marginBottom: "8px" }}
              type={"square"}
              checked={condition1}
              onChange={() => setCondition1(!condition1)}
            />
            <Typography
              variant={"body1"}
              display={"inline-block"}
              sx={{ pr: 2 }}
            >
              I have provided complete and true information in support of my
              application and I understand that knowingly making a false
              statement for this purpose is a criminal offense.
            </Typography>
          </Grid>
        )}
        {role === "candidate" && (dbs_type as string) !== "scottish" && (
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
            }}
          >
            <CheckBox
              style={{ zoom: 0.8, marginBottom: "8px" }}
              type={"square"}
              checked={condition2}
              onChange={() => setCondition2(!condition2)}
            />
            <Typography
              variant={"body1"}
              display={"inline-block"}
              sx={{ pr: 2 }}
            >
              I understand that applications for Standard/Enhanced/Basic DBS
              checks will be countersigned by uCheck Limited before they are
              sent to the DBS for processing. uCheck Limited utilises an
              automated countersigning tool on some applications which may fast
              track them to the DBS. I consent to the use of this tool to
              countersign my application if applicable.
            </Typography>
          </Grid>
        )}
        {role === "candidate" && (
          <Grid
            item
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              pt: 2,
              mt: 2,
              borderTop: "1px solid #0000004d",
            }}
            xs={12}
          >
            <PrimaryButton
              variant={"text"}
              fullWidth={false}
              sx={{
                mr: 2,
                background: "white",
                border: "1px solid #5AB9F9",
                color: "#5AB9F9",
                fontSize: 16,
                height: "46px",
                minWidth: "100px",
              }}
              disabled={activeStep === 0}
              onClick={handleBack}
            >
              Go Back
            </PrimaryButton>
            {(dbs_type as string) !== "scottish" && (
              <PrimaryButton
                fullWidth={false}
                sx={{
                  minWidth: "100px",
                  background: "#1276D3",
                  color: "white",
                }}
                onClick={handleNext}
                disabled={
                  !DbsPersonalInformationValues.form_valid ||
                  !(condition1 && condition2) ||
                  !(
                    uCheckPersonalHistory.form1_valid &&
                    uCheckPersonalHistory.form2_valid
                  ) ||
                  !(
                    uCheckCurrentAddress.form_valid &&
                    uCheckPreviousAddresses.form_valid
                  ) ||
                  !(
                    uCheckPassportInformation.form_valid &&
                    uCheckDrivingLicenceInformation.form_valid
                  ) ||
                  (uCheckPassportInformation.has_passport === "n" &&
                    uCheckDrivingLicenceInformation.uk_driving_licence === "n")
                }
              >
                Next
              </PrimaryButton>
            )}
            {(dbs_type as string) === "scottish" && (
              <PrimaryButton
                fullWidth={false}
                sx={{
                  minWidth: "100px",
                  background: "#1276D3",
                  color: "white",
                }}
                onClick={handleNext}
                disabled={
                  !DbsPersonalInformationValues.form_valid ||
                  !condition1 ||
                  !(
                    uCheckPersonalHistory.form1_valid &&
                    uCheckPersonalHistory.form2_valid
                  ) ||
                  !(
                    uCheckCurrentAddress.form_valid &&
                    uCheckPreviousAddresses.form_valid
                  ) ||
                  !(
                    uCheckPassportInformation.form_valid &&
                    uCheckDrivingLicenceInformation.form_valid
                  ) ||
                  (uCheckPassportInformation.has_passport === "n" &&
                    uCheckDrivingLicenceInformation.uk_driving_licence === "n")
                }
              >
                Next
              </PrimaryButton>
            )}
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default Review;
