import React, { FC, useState, useEffect } from "react";
import {
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import DocumentList from "./DocumentList";
import { VettingDocument } from "../../../Authenticated/Agency/RequestDetails/RequestDetailsModel";
import PageLoader from "../../../../components/PageLoader";
import { useSelector } from "react-redux";
import { VettingDocuments as VettingDocumentsData } from "../../../../utils/redux";
import { useTour } from "@reactour/tour";
import { tourStep } from "../../../../utils/constants/coachMarks";

interface VettingDocumentsProps {
  isLoading: boolean;
  setOpenPopUp: (value: boolean) => void;
}

const VettingDocuments: FC<VettingDocumentsProps> = ({
  isLoading,
  setOpenPopUp,
}) => {
  const { setIsOpen, currentStep, setCurrentStep } = useTour();

  const documents = useSelector(VettingDocumentsData);

  const [active, setActive] = useState<number>(0);

  useEffect(() => {
    if (isLoading !== undefined && !isLoading) {
      setCurrentStep(tourStep.unthorized_request_page.index);
    }
  }, [isLoading, setCurrentStep]);

  useEffect(() => {
    if (tourStep.unthorized_request_page.index === currentStep) {
      setIsOpen(true);
    } else if (
      currentStep ===
      tourStep.unthorized_request_page.index +
        tourStep.unthorized_request_page.steps
    ) {
      setIsOpen(false);
    }
  }, [currentStep, setIsOpen]);

  const handleActive = (item: VettingDocument) => {
    if (item.index === 0) {
      setActive(item.index);
    } else {
      setOpenPopUp(true);
    }
  };

  return (
    <>
      {isLoading && <PageLoader />}
      <Grid container spacing={2}>
        <Grid item md={12} lg={4}>
          <List dense sx={{ width: "100%", p: 0 }}>
            {documents?.map((item) => {
              return (
                <ListItem
                  key={item.index}
                  sx={{ px: { xs: 0, md: 3 } }}
                  disablePadding
                >
                  <ListItemButton
                    onClick={() => handleActive(item)}
                    disableTouchRipple={item.index > 0}
                    disableRipple={item.index > 0}
                  >
                    <ListItemText
                      sx={{
                        background:
                          item.index === active ? "#E3F4FF" : "#FFFFFF",
                        border: "1px solid rgba(90, 185, 249, 0.25)",
                        borderRadius: "8px",
                        margin: "0 0 10px 0",
                        padding: "8px 20px",
                      }}
                      primary={
                        <Typography
                          variant={"body2"}
                          fontWeight={500}
                          color={"#212121"}
                          fontSize={14}
                        >
                          {item.name}
                        </Typography>
                      }
                      secondary={
                        <Typography
                          variant={"body2"}
                          fontWeight={400}
                          color={"#333333"}
                          fontSize={14}
                        >
                          {item.sub_text}
                        </Typography>
                      }
                    />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        </Grid>
        {documents.length > 0 && (
          <Grid item md={12} lg={8}>
            <DocumentList documents={documents} active={active} />
          </Grid>
        )}
      </Grid>
    </>
  );
};
export default VettingDocuments;
