import React, { FC, useState, useEffect, useCallback } from "react";
import {
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import RightSideFrame from "./RightSideFrame";
import CheckBox from "../../../../../components/CheckBox";
import PrimaryButton from "../../../../../components/Button/PrimaryButton";
import { useParams } from "react-router-dom";
import axiosInstance from "../../../../../utils/axios";
import { URLS } from "../../../../../utils/constants/urls";
import PageLoader from "../../../../../components/PageLoader";
import { useDispatch, useSelector } from "react-redux";
import { setNotificationMessage } from "../../../../../utils/redux";
import InputField from "../../../../../components/InputField";
import { useTour } from "@reactour/tour";
import { tourStep } from "../../../../../utils/constants/coachMarks";
import { CognitoUser } from "amazon-cognito-identity-js";
import * as Amplify from "../../../../../utils/services/amplify";
import {
  User,
  setUserData,
} from "../../../../../utils/redux/reducer/authentication-slice";

export interface AdditionalFormsProps {
  document: {
    id: string;
    name: string;
  }[];
  token: string;
  signed: boolean;
  sign_required: boolean;
  envelope_completed: boolean;
  envelope_id?: string;
  comment?: string;
  is_rejected?: boolean;
}

const AdditionalForm: FC<AdditionalFormsProps> = ({
  document,
  signed,
  token,
  sign_required,
  envelope_completed,
  envelope_id,
  comment,
  is_rejected,
}) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  const { setIsOpen, currentStep, setCurrentStep } = useTour();
  const user: { [key: string]: string } = useSelector(User) as {
    [key: string]: string;
  };
  const setCoachMarksValue = useCallback(async () => {
    //if (!!user && user["custom:reference_tutorial"] !== "true") {
    const currentUser: CognitoUser = await Amplify.UserDetail();
    const body = {
      "custom:additional_forms": "true",
    };
    await Amplify.UpdateUserDetails(body, currentUser);
    await Amplify.RefreshSession();
    dispatch(setUserData({ ...user, ...body }));
    //}
  }, [user, dispatch]);

  useEffect(() => {
    if (!!user && user["custom:additional_forms"] !== "true") {
      setCurrentStep(tourStep.candidate_additional_forms_list.index);
    }
  }, [setCurrentStep, user]);

  useEffect(() => {
    if (tourStep.candidate_additional_forms_list.index === currentStep) {
      setIsOpen(true);
    } else if (
      currentStep ===
      tourStep.candidate_additional_forms_list.index +
        tourStep.candidate_additional_forms_list.steps
    ) {
      setIsOpen(false);
      if (!!user && user["custom:additional_forms"] !== "true") {
        (async () => {
          await setCoachMarksValue();
        })();
      }
    }
  }, [currentStep, setCoachMarksValue, setIsOpen, user]);

  const download = async () => {
    try {
      setLoading(true);
      const { data } = await axiosInstance.get(
        `${URLS.requests}/${id}/envelope/${envelope_id}/download`
      );
      window.open(data.url, "_blank");
      setLoading(false);
    } catch (e) {
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "error",
          message: "Unable to download Signed ReferenceForms",
        })
      );
    }
  };

  return (
    <Grid container spacing={2} sx={{ mt: 3 }}>
      {loading && <PageLoader />}
      <Grid item xs={12} md={12} lg={4}>
        <List
          dense
          sx={{
            width: "100%",
            bgcolor: "background.paper",
            p: 0,
          }}
        >
          {document.map((document, index) => {
            return (
              <ListItem
                key={index}
                sx={{ px: { xs: 0, md: 3 } }}
                disablePadding
              >
                <ListItemIcon>
                  <CheckBox
                    checked={true}
                    disabled
                    type="shield"
                    sx={{ marginBottom: "8px" }}
                    shieldColor={is_rejected ? "error" : "success"}
                  />
                </ListItemIcon>
                <ListItemButton disableTouchRipple>
                  <ListItemText
                    sx={{
                      background: is_rejected ? "#F6D3D3" : "#E3F4FF",
                      border: "1px solid rgba(90, 185, 249, 0.25)",
                      borderRadius: "8px",
                      margin: "0 0 10px 0",
                      padding: "8px 20px",
                    }}
                    data-tut={
                      index === 0 ? "candidate_additional_forms_list" : ""
                    }
                    primary={
                      <Typography
                        fontSize={14}
                        fontWeight={500}
                        sx={{ color: "#212121" }}
                      >
                        {document.name}
                      </Typography>
                    }
                  />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
        {comment && (
          <Grid md={12} container>
            <Grid item>
              <Grid
                item
                xs={12}
                className=" pl-2 py-3"
                display={"flex"}
                alignItems={"center"}
              >
                Additional Comments{" "}
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <InputField
                multiline
                value={comment}
                // textChange={(e) => {
                //   console.log(e,);
                //  // setInputValues(e);
                // }}
                disabled={true}
                rows={10}
                sx={{ background: "#ffffff" }}
                // placeholder={
                //   "The applicant may email once they have fulfilled the requirement of this request."
                // }
              />
            </Grid>
          </Grid>
        )}
        {envelope_completed && (
          <Grid container justifyContent={"center"} sx={{ mt: 2 }}>
            <Grid item xs={8}>
              <PrimaryButton variant={"outlined"} onClick={download}>
                Download Signed Documents
              </PrimaryButton>
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid item xs={12} md={12} lg={8}>
        <RightSideFrame
          token={token}
          signed={signed}
          sign_required={sign_required}
          is_rejected={is_rejected}
        />
      </Grid>
    </Grid>
  );
};
export default AdditionalForm;
